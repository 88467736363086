import { dataPickUpAction, getType } from '../../actions';
import { getTodayMonth, getToday} from '../../../utils/Date/SupportDate'

const todayMonth = getTodayMonth();
const initialState = {
    reservation: {
        month: todayMonth,
        typeView: "month", // glist, week, selected,
        branchID: 0,
        branch: "Select Branch",
        area: "Select Area",
    },
    cancel: {
        month: todayMonth,
        branchID: 0,
        area: "",
    },
    waiting: {
        month: todayMonth,
        branchID: 0,
        area: "",
    },
    invoiceManage: {
        month: todayMonth,
        branchID: 0,
        area: "",
    },
    flightManage: {
        month: todayMonth,
        branchID: 0,
        area: "",
    },
    checkInManage: {
        month: todayMonth,
        branchID: 0,
        area: "",
    },
    checkOutManage: {
        month: todayMonth,
        branchID: 0,
        area: "",
    },
    glist: {
        day: getToday(),
        branchID: 0,
        area: "",
    },
    checkOutAcc: {
        date: getToday(),
        branchID: 0,
        area: "",
    },
}

const dataPickUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(dataPickUpAction.changeDataReservation): {
            return {
                ...state,
                reservation: action.payload
            };
        }
        case getType(dataPickUpAction.changeDataWaiting): {
            return {
                ...state,
                waiting: action.payload
            };
        }
        case getType(dataPickUpAction.changeDataCancel): {
            return {
                ...state,
                cancel: action.payload
            };
        }
        case getType(dataPickUpAction.changeDataInvoiceManage): {
            return {
                ...state,
                invoiceManage: action.payload
            };
        }
        case getType(dataPickUpAction.changeDataFlightManage): {
            return {
                ...state,
                flightManage: action.payload
            };
        }
        case getType(dataPickUpAction.checkInManage): {
            return {
                ...state,
                checkInManage: action.payload
            };
        }
        case getType(dataPickUpAction.checkOutManage): {
            return {
                ...state,
                checkOutManage: action.payload
            };
        }
        case getType(dataPickUpAction.changeDataGlist): {
            return {
                ...state,
                glist: action.payload
            };
        }
        case getType(dataPickUpAction.changeDataCheckOutAcc): {
            return {
                ...state,
                checkOutAcc: action.payload
            };
        }
        //checkOutAcc
        default: return state;
    }
}

export default dataPickUpReducer;
