import { call, put, select } from 'redux-saga/effects';
import { unitsAction, makeRoomAction } from '../../actions';

import * as scheduleApi from '../../../api/schedule';
import * as bookingApi from '../../../api/booking';
import * as branchApi from '../../../api/branch';

import { errorToast } from '../../../utils/Toast/index'
import { getTodayMonth, changeDataToDDMMYYYY } from '../../../utils/Date/SupportDate'

export function* loadDataBookingByID(data) {
    //   yield put(unitsAction.showLoading());
    const state = yield select();
    const { token } = state;
    const { id, branchID } = data.payload

    try {
        yield put(unitsAction.showLoading());

        const resultListRoom = yield call(() => scheduleApi.takeListRoomSchedule(branchID, getTodayMonth(), token));
        const result = yield call(() => bookingApi.takeBookingDataById(branchID, id, token));
        const branchInformation = yield call(() => branchApi.findElementBranch(branchID, "makeRoom", token));
        
        // console.log("resultListRoom", resultListRoom);
        // console.log("result", result);
        // console.log("branchInformation", branchInformation);
        // console.log("vo here")
        //listSchedule
        //  scheduleSelect: 
        let scheduleSelect;
        if (result.data.data.listSchedule.length > 0) {
            result.data.data.listSchedule.forEach((item, index) => {
                if(item.booking_id === id) return scheduleSelect = index;
            })
        } else scheduleSelect = 0;

        yield put(makeRoomAction.changeAllDataChiddenMakeRoom({
            ...result.data.data,
            scheduleSelect,
            listGuestSelect: result.data.listGuestSelect,
            listRoomNumber: resultListRoom.data.data.listNumberRoom,
            listRoomPrice: resultListRoom.data.data.listRoomPrice,
            listRequest1: resultListRoom.data.data.listRequest1,
        }));
        // console.log("vo here")


        yield put(unitsAction.fetchBranchInformation(branchInformation.data.data))
        //fetchBranchInformation: 
        yield put(makeRoomAction.fillMakeRoomOther({
            ...result.data.data2,
            signature: branchInformation.data.data.signature,
        }))
      //  console.log("vo here")

        document.title=`R${result.data.data.listSchedule[0].roomSelect}-(${changeDataToDDMMYYYY(result.data.data.listSchedule[0].checkin)})`

        yield put(unitsAction.hiddenLoading());
    } catch (error) {
        console.log("error", error)
        errorToast("Error when load data guest");
    }
}

export function* takeHistoryByID(data) {
    //   yield put(unitsAction.showLoading());
    const state = yield select();
    const { token } = state;
    const { id, branchID } = data.payload

    try {
        yield put(unitsAction.showLoading());

        const resultListRoom = yield call(() => scheduleApi.takeListRoomSchedule(branchID, getTodayMonth(), token));
        const result = yield call(() => bookingApi.getHistoryOnLog(branchID, id, token));

        const branchInformation = yield call(() => branchApi.findElementBranch(branchID, "makeRoom", token));
        
        //listSchedule
     //   console.log("resukt", result)
        //  scheduleSelect:     
        let scheduleSelect = 0;
        // if (result.data.data.listSchedule.length > 0) {
        //     result.data.data.listSchedule.forEach((item, index) => {
        //         if(item.booking_id === id) return scheduleSelect = index;
        //     })
        // } else scheduleSelect = 0;

        console.log("vo here", scheduleSelect)

        yield put(makeRoomAction.changeAllDataChiddenMakeRoom({
            ...result.data.data,
            timeEdit: result.data.timeEdit,
            deviceEdit: result.data.deviceEdit,
            viewPassPort: true,
            scheduleSelect,
            listSchedule: result.data.data.listSchedule,
            listGuestSelect: result.data.data.listGuestSelect,
            listRoomNumber: resultListRoom.data.data.listNumberRoom,
            listRoomPrice: resultListRoom.data.data.listRoomPrice,
            listRequest1: resultListRoom.data.data.listRequest1,
        }));

        yield put(unitsAction.fetchBranchInformation(branchInformation.data.data))
        //fetchBranchInformation: 
        yield put(makeRoomAction.fillMakeRoomOther({
            pickUp: result.data.data.pickUp,
            dropOff: result.data.data.dropOff,
            rentCarList: result.data.data.rentCarList,
            changeRentCar: false,
            vat: result.data.data.vat,
            remarkVAT: "",
            status: result.data.data.status,
            confirm: result.data.data.confirm,
            isMakeHI: false,
            area: result.data.data.area,
            color: result.data.data.color,
            maker: result.data.data.maker,
            signature: result.data.data.signature,
            loading: true,
            signature: branchInformation.data.data.signature,
        }))

        document.title=`R${result.data.data.listSchedule[0].roomSelect}-(${changeDataToDDMMYYYY(result.data.data.listSchedule[0].checkin)})`

        yield put(unitsAction.hiddenLoading());
    } catch (error) {
        console.log("error", error)
        errorToast("Error when load data guest");
    }
}

export function* loadDataBookingBySelected(data) {
    //   yield put(unitsAction.showLoading());
    const state = yield select();
    const { token } = state;
    const { branchID, roomSelect, checkin } = data.payload;

    try {
        yield put(unitsAction.showLoading());

        const result = yield call(() => scheduleApi.takeListRoomSchedule(branchID, getTodayMonth(), token));
        const branchInformation = yield call(() => branchApi.findElementBranch(branchID, "makeRoom", token));

        yield put(unitsAction.fetchBranchInformation(branchInformation.data.data));
        yield put(makeRoomAction.fillMakeRoom({
            branchID: Number(branchID),
            listRoomNumber: result.data.data.listNumberRoom,
            listRoomPrice: result.data.data.listRoomPrice,
            listRequest1: result.data.data.listRequest1,

            scheduleSelect: 0,
            listSchedule: [
                {
                    roomSelect: roomSelect,
                    move: false,
                    discount: 0,
                    price: result.data.data.listRoomPrice[roomSelect],
                    checkin: checkin,
                    checkout: "",
                    nights: "",
                    shortime: false,
                    earlyCheckin: false,
                    lateOut: false,
                    timeEarly: "15:00",
                    timeLateOut: "12:00",
                    timeShortTime: "",
                }
            ]
        }));

        yield put(makeRoomAction.fillMakeRoomOtherSignature(branchInformation.data.data.signature));

        yield put(unitsAction.hiddenLoading());
    } catch (error) {
        errorToast("Error when load data guest");
    }
}

export function* loadDataBookingByWaitingList(data) {
    const state = yield select();
    const { token } = state;
    const { branchID, } = data.payload;
    try {
        yield put(unitsAction.showLoading());
        const result = yield call(() => scheduleApi.takeListRoomSchedule(branchID, getTodayMonth(), token));

      //  console.log("rusult", result);
        const branchInformation = yield call(() => branchApi.findElementBranch(branchID, "makeRoom", token));

        yield put(unitsAction.fetchBranchInformation(branchInformation.data.data));

        yield put(makeRoomAction.fillMakeRoom({
            branchID: Number(branchID),
            listRoomNumber: result.data.data.listNumberRoom,
            listRoomPrice: result.data.data.listRoomPrice,
            listRequest1: result.data.data.listRequest1,

            scheduleSelect: 0,
            listSchedule: [
                {
                    roomSelect: "",
                    move: false,
                    discount: 0,
                    price: 0,
                    checkin: "",
                    checkout: "",
                    nights: "",
                    timeEarly: "15:00",
                    timeLateOut: "12:00",
                    timeShortTime: "",
                }
            ]
        }));

        document.title=`Make Waiting`

        yield put(makeRoomAction.changeChiddenMakeRoomOther({ value: "waiting", target: "area" ,  option:""}));
        yield put(makeRoomAction.fillMakeRoomOtherSignature(branchInformation.data.data.signature));

        yield put(unitsAction.hiddenLoading());
    } catch (error) {
        console.log("error", error)
        errorToast("Error when load data guest");
    }
}
