import { modalAction, getType } from '../../actions';

const initialState = {
    modaltype: '',
    title: '',
    button1: 'Close',
    button2: 'OK',
    isOk: false,
    size: "",
    content: '',
    backdrop: "",
    component: null,
    componentContent: null,
}

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(modalAction.modalChange): {
            const { type, title, content, button1, button2, componentContent, size } = action.payload;
            return {
                ...state,
                modaltype: type,
                title: title,
                content: content,
                button1: button1,
                button2: button2,
                componentContent,
                size
            };
        }

        case getType(modalAction.modalIsOk): {
            return {
                ...state,
                isOk: true,
            }
        }
        case getType(modalAction.modalReset): {
            return {
                ...state,
                isOk: false,
            }
        }

        case getType(modalAction.modalComponent): {
            return {
                ...state,
                component: action.payload,
            }
        }

        case getType(modalAction.modalComponentContent): {
            return {
                ...state,
                componentContent: action.payload,
            }
        }

        default: return state;
    }
}

export default modalReducer;
