import React from 'react';
import { useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';

import './modalstyple.css'

ModalNotify.propTypes = {
};

function ModalNotify(props) {
  const modalData = useSelector(state => state.modal);
  const { modaltype, title, content, component, componentContent, isOk, size } = modalData;

  return (
    <Modal
      size={size}
      show={isOk}
      onHide={() => props.closeModal()}
      centered={modaltype}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body__content">
        <h4>{content}</h4>
        {componentContent}
      </Modal.Body>
      <Modal.Footer>
        {component}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalNotify;
