import React, { useEffect, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalAction, dataPickUpAction, unitsAction } from '../../../../redux/actions';

import ModalNotify from '../../../../features/ModalNotify';
import CompanyItemComponent from "../../../../components/Company";
import * as bookingApi from '../../../../api/booking';

import { coverNameToID, coverBranchIDToFullName } from '../../../../utils/Branch/SupportBranch';
import { changeDataToDDMMYYYY } from '../../../../utils/Date/SupportDate'
import { errorToast, informationToast, } from '../../../../utils/Toast/index'; //warningToast, 
// import { useParams } from "react-router-dom";

function GuestCheckOutAccContainer() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const [data, setData] = useState({
        loading: true,
        content: [],
        isVAT: false,
    })
    const dataBranchOnState = useSelector(state => state.dataPickUp.checkOutAcc);
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    useEffect(() => {
        if (branchID) {
            onLoadData(dataBranchOnState.date);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataBranchOnState.date, branchID]);

    function onLoadData(date) {
        const onLoad = async () => {
            try {
                dispatch(unitsAction.showLoading());
                const dataResult = await bookingApi.takeListGuestCheckOutOnDate(branchID, date, token);

                dispatch(unitsAction.hiddenLoading());
                if (dataResult) {
                    if (dataResult.data.status === 1) {
                        setData({
                            content: dataResult.data.data,
                            isVAT: false,
                            loading: false,
                        })
                        informationToast("" + dataResult.data.msg);
                    }
                    else errorToast("Error when load guest check out");
                } else return errorToast("Error when load guest check out");

            } catch (error) { errorToast("Error when load guest check out"); }
            document.title = `Az${coverNameToID(Number(branchID))} Guest Check Out ACC`;
        }

        onLoad();
    }

    function onChangeDataSelect(e, target) {
        const newData = {
            ...dataBranchOnState,
            ["" + target]: e
        };
        dispatch(dataPickUpAction.changeDataCheckOutAcc(newData));
    }

    function closeModal() { dispatch(modalAction.modalReset()); }

    return (<>
        <ModalNotify closeModal={closeModal} />
        <h1 className="mt-5">AZUMAYA {coverBranchIDToFullName(Number(branchID)).toLocaleUpperCase()} -GUEST CHECK OUT ( ACC ) : {changeDataToDDMMYYYY(dataBranchOnState.date)}</h1>
        <div className="d-flex">
            <div className="d-flex">
                <div className="d-flex">
                    <div style={{ margin: 'auto', fontSize: '1.4rem' }}> Select date:</div>
                    <input
                        type="date"
                        className="ml-2"
                        style={{ fontSize: '1.3rem' }}
                        onChange={e => onChangeDataSelect(e.target.value, "date")}
                        value={dataBranchOnState.date}
                    ></input>
                </div>
            </div>
        </div>
        <div className="" style={{ background: "aliceblue" }}>
            {data.loading ? "" :
                <CompanyItemComponent
                    data={data.content}
                    type="guestCheckOutAcc"
                />}
        </div>
    </>)
}

export default GuestCheckOutAccContainer;

