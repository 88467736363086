export const ADDRESS_CHANGE = {
    "Hai Ba Trung 1": {
        name: "CHI NHÁNH HÀ NỘI - CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        address: "Số 16 Bùi Thị Xuân, Phường Nguyễn Du, Quận Hai Bà Trưng, Thành phố Hà Nội, Việt Nam",
        phone: "Điện thoại: (024) 6278 6688"
    },
    "Linh Lang": {
        name: "CHI NHÁNH LINH LANG – CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        address: "Số 26 phố Linh Lang, Phường Cống Vị, Quận Ba Đình, Thành phố Hà Nội, Việt Nam",
        phone: "Điện thoại: (024) 38620 620"
    },
    "Kim Ma 2": {
        name: "CHI NHÁNH KIM MÃ 2- CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        address: "Số 18A Phạm Huy Thông, Phường Ngọc Khánh, Quận Ba Đình, Thành phố Hà Nội, Việt Nam",
        phone: "Điện thoại: (024) 3724 7570"
    },
    "Kim Ma 3": {
        name: "CHI NHÁNH KIM MÃ 3- CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        address: "Số 68 Phạm Huy Thông, Phường Ngọc Khánh, Quận Ba Đình, Thành phố Hà Nội, Việt Nam",
        phone: "Điện thoại: (024) 37 349 730"
    },
    "Hai Phong": {
        name: "CHI NHÁNH HẢI PHÒNG - CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        address: "Số 195 đường Vũ Trọng Khánh, Phường Đằng Giang, Quận Ngô Quyền, Thành phố Hải Phòng, Việt Nam",
        phone: "Điện thoại: 0225 3828 969"
    },
    "Da Nang": {
        name: "CHI NHÁNH ĐÀ NẴNG - CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        address: "31 Nguyễn Tất Thành ,Quận Hải Châu ,TP. Đà Nẵng",
        phone: "Điện thoại: (0236) 3 743 888"
    },
    "Thai Van Lung 1":{
        name: "CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        address: "8A/7D1-8A/8D1-8A/9D1 Thái Văn Lung , Phường Bến Nghé , Quận 1.TP.HCM",
        phone: "Điện thoại: (028) 3824 6835"
    },
    "Le Thanh Ton":{
        name: "CHI NHÁNH CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        address: "15/3 Lê Thánh Tôn, Phường Bến Nghé , Quận 1,TP. HCM",
        phone: "Điện thoại: (028) 3827 1110"
    },
    "Thai Van Lung 2":{
        name: "CHI NHÁNH THÁI VĂN LUNG 2 - CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        address: "27-29 Thái Văn Lung , Phường Bến Nghé , Quận 1.TP.HCM",
        phone: "Điện thoại: (028) 3827 1071"
    },
    "Azumaya": {
        name: "CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        address: "",
        phone: ""
    }
}

export const BANK_ACCOUNT = {
    "Hai Ba Trung 1 VND": {
        accountName: "Chi nhánh Hà Nội – Công ty TNHH MTV khách sạn Azumaya",
        accountNumber: "06111 005 08 008",
        bankName:"Ngân hàng Thương mại Cổ phần Quân Đội (MB) - Chi nhánh Hoàn Kiếm, Hà Nội",
        swiftCode:"MSCBVNVX"
                //Ngân hàng Thương mại Cổ phần Quân Đội (MB) - Chi nhánh Hoàn Kiếm, Hà Nội	 	 	 	 	 
    },
    "Hai Ba Trung 1 USD": {
        accountName: "CN HN-CT TNHH MTV KHACH SAN AZUMAYA",
        accountNumber: "061 1100 654 007",
        bankName: "Military Commercial Joint Stock Bank (MB),Hoan Kiem Branch, Hai Ba Trung",
        bankAddress: "",
        swiftCode:"MSCBVNVX"
    }, 
    "Kim Ma 2 VND": {
        accountName: "Chi nhánh Kim Mã 2 – Công ty TNHH Một thành viên khách sạn Azumaya",
        accountNumber: "2200 587 162",
        bankName: "Ngân hàng  TMCP Đầu tư và Phát triển Việt Nam (BIDV)- Chi nhánh Thăng Long",
        swiftCode:"BIDVVNVX"
    }, 
    "Kim Ma 2 USD": {
        accountName: "CHI NHANH KIM MA 2 – CONG TY TNHH MTV KHACH SAN AZUMAYA",
        accountNumber: "2200 587 162",
        bankName: "JOINT STOCK COMMERCIAL BANK FOR INVESTMENT AND DEVELOPMENT OF VIETNAM",
        bankAddress: "No 8, Pham Hung, Hanoi, Vietnam",
        swiftCode: "BIDVVNVX"
    }, 
    "Kim Ma 3 VND": {
        accountName: "Chi nhánh Kim Mã 3 – Công ty TNHH MTV khách sạn Azumaya",
        accountNumber: "086 1101 196 009",
        bankName: "Ngân hàng Thương mại Cổ phần Quân Đội (MB) - Chi nhánh Ba Đình - Hà Nội",
        swiftCode: "MSCBVNVX"
    },
    "Kim Ma 3 USD": {
        accountName: "CN KIM MA3-CTY TNHH MTV KS AZUMAYA",
        accountNumber: "6751868374169", 
        bankName: "Military Commercial Joint Stock Bank (MB), Ba Dinh Branch, Hanoi",
        bankAddress: "No. 03, Lieu Giai Str., Cong Vi Ward, Ba Dinh Dist., Ha Noi",
        swiftCode: "MSCBVNVX"
    },
    "Linh Lang VND": {
        accountName: "Chi nhánh Linh Lang – Công ty TNHH MTV khách sạn Azumaya",
        accountNumber: "086 1100 898 007",
        bankName: "Ngân hàng Thương mại Cổ phần Quân Đội (MB) - Chi nhánh Ba Đình - Hà Nội",
        swiftCode: "MSCBVNVX"
    }, 
    "Linh Lang USD": {
        accountName: "LINH LANG BRANCH - AZUMAYA HOTEL COMPANY LIMITED",
        accountNumber: "086 1100 899 006",
        bankName: "Military Commercial Joint Stock Bank (MB), Ba Dinh Branch, Hanoi",
        bankAddress: "No. 03, Lieu Giai Str., Cong Vi Ward, Ba Dinh Dist., Ha Noi",
        swiftCode: "MSCBVNVX"
    }, 
    "Hai Phong VND": {
        accountName: "Chi nhánh Hải Phòng - Công ty TNHH một thành viên khách sạn Azumaya",
        accountNumber: "1440407946",
        bankName: "BIDV - Chi nhánh Thống Nhất, TP Hồ Chí Minh",
        swiftCode:"BIDVVNVX"
    },  
    "Hai Phong USD": {
        accountName: "Chi nhánh Hải Phòng - Công ty TNHH một thành viên khách sạn Azumaya",
        accountNumber: "1440407946",
        bankName: "BIDV - Chi nhánh Thống Nhất, TP Hồ Chí Minh",
        bankAddress: "",
        swiftCode:"BIDVVNVX"
    },
    "Da Nang VND": {
        accountName: "Chi nhánh Đà Nẵng – Công ty TNHH MTV Khách Sạn Azumaya",
        accountNumber: "1440412278",
        bankName: "BIDV - Chi nhánh Thống Nhất, TP Hồ Chí Minh",
        swiftCode:"BIDVVNVX"
    }, 
    "Da Nang USD": {
        accountName: "",
        accountNumber: "",
        bankName: "",
        bankAddress: "",
        swiftCode:""
    }, 
    "Thai Van Lung 1 VND": {
        accountName: "Công ty TNHH MTV khách sạn Azumaya",
        accountNumber: "1440221845",
        bankName: "BIDV_ Chi Nhánh Thống Nhất",
        swiftCode:"BIDVVNVX"
    }, 
    "Thai Van Lung 1 USD": {
        accountName: "Công ty TNHH MTV khách sạn Azumaya",
        accountNumber: "1440007304",
        bankName: "BIDV_ Chi Nhánh Thống Nhất",
        bankAddress: "",
        swiftCode:"BIDVVNVX"
    }, 
    "Thai Van Lung 2 VND": {
        accountName: "Chi Nhánh Thái Văn Lung 2 - Công ty TNHH MTV khách sạn Azumaya",
        accountNumber: "217603569",
        bankName: "ACB - PGD Hai Bà Trưng - CN Sài Gòn",
        swiftCode:"ASCBVNVX"
    }, 
    "Thai Van Lung 2 USD": {
        accountName: "Chi Nhánh Thái Văn Lung 2 - Công ty TNHH MTV khách sạn Azumaya",
        accountNumber: "250874209",
        bankName: "ACB - PGD Hai Bà Trưng - CN Sài Gòn",
        bankAddress: "",
        swiftCode:"ASCBVNVX"
    }, 
    "Le Thanh Ton VND": {
        accountName: "Chi Nhánh Công ty TNHH MTV khách sạn Azumaya",
        accountNumber: "1440221854",
        bankName: "BIDV_ Chi Nhánh Thống Nhất",
        swiftCode:"BIDVVNVX"
    }, 
    "Le Thanh Ton USD": {
        accountName: "Chi Nhánh Công ty TNHH MTV khách sạn Azumaya",
        accountNumber: "1440007368",
        bankName: "BIDV_ Chi Nhánh Thống Nhất",
        bankAddress: "",
        swiftCode:"BIDVVNVX"
    }, 
    "Azumaya VND": {
        accountName: "CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        accountNumber: "",
        baneName: "",
        bankAddress: "",
        swiftCode:"BIDVVNVX"
    },
    "Azumaya USD": {
        accountName: "CÔNG TY TNHH MỘT THÀNH VIÊN KHÁCH SẠN AZUMAYA",
        accountNumber: "",
        bankName: "",
        bankAddress: "",
        swiftCode:""
    }
}
