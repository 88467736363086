import React from 'react';

function RoomDropDown(props) {
    const { room, data } = props;
    // console.log("room", room)
    // console.log("data", data)

    //justify-content-between
    return (
        <React.Fragment>

            {room ? (data.length > 0 && room.length < 3) ? <div //&& room.length < 3
                className="dropdown"
                style={{
                    position: "absolute",
                    width: "120px",
                    left: "125px",
                    top: "25px",
                    zIndex: "999",
                    backgroundColor: "aqua"
                }}>
                {renderDropDown(data)}
            </div> : "": ""}

        </React.Fragment >
    );

    function renderDropDown(ddItem) {
        var result = [];
        ddItem.map((Item, index) => {
        //    console.log("Item", Item)
            if (String(Item).includes(""+room) && room.length >= 1) {
                return result.push(
                    <span className="dropdown-item d-flex justify-content-between" key={index}
                        // onClick={() => props.onChangeItem(Item, item.ID, checksubname())}
                        onClick={() => props.onBlur(Item)}
                    >{Item} </span>
                )
            }
        });
        return result;
    }

}

export default RoomDropDown;
