import axios from 'axios';
//PATCH REQUEST

export const createNewCompany = (value, token) => {
    let newURL = "/company/make_new";
    return axios.post(`${newURL}`, value, {
        headers: { Authorization: token }
    });
}

// router.patch('/edit_company/:id', auth, companyCtrl.editCompany);
export const editCompany = (value, id, token) => {
    let newURL = "/company/edit_company/" + id;
    return axios.patch(`${newURL}`, value, {
        headers: { Authorization: token }
    });
}

export const takeMultiCompany = (id, listCompany) => {
    let newURL = "/company/take_multi";
    return axios.post(`${newURL}`, listCompany, {});
}

export const findCompanyByRequest = (type, value, token) => {
    let newURL = "/company/find_company/" + type;
    return axios.post(`${newURL}`, { value }, {
        headers: { Authorization: token }
    });
}

// router.get('/view_history/:id', auth, guestCtrl.viewGuestHistory);
export const viewCompanyHistory = (id, token) => {
    let newURL = "/company/view_history/" + id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const deleteCompany = (id, token) => {
    let newURL = "/company/delete_company/" + id;
    return axios.delete(`${newURL}`, {
        headers: { Authorization: token }
    });
}