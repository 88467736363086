import React from "react";

function ComponentConfirmMakeNewNumber(props) {
    return (<>
        <h4 style={{ color: 'red' }}>Your change will increase the number of invoices, do you still want to continue?</h4>
        <h4 style={{ color: 'red' }}>Thay đổi của bạn sẽ tăng số hóa đơn, bạn vẫn muốn tiếp tục ?</h4>
        <div>---</div>
        <h5 style={{ color: '' }}>If you have not change content room, service, rate.</h5>
        <h5>Please reload page</h5>
        <div className="mt-2">
            <button type="button" className="btn btn-danger mr-2"
                onClick={props.onClose}>Cancel</button>
            <button type="button" className="btn btn-primary"
                onClick={props.onSave}>Continue</button>
        </div>
    </>)
}

export default ComponentConfirmMakeNewNumber;
