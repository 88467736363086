import React, { useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalAction, flightManageAction, dataPickUpAction, unitsAction } from '../../redux/actions';

import ModalNotify from '../../features/ModalNotify';
import CompanyItemComponent from "../../components/Company";
import * as bookingApi from '../../api/booking';

import { coverNameToID, coverBranchIDToFullName } from '../../utils/Branch/SupportBranch';
import { changeDataToYYYY_MMMM } from '../../utils/Date/SupportDate'
import { errorToast, informationToast, } from '../../utils/Toast/index'; //warningToast, 
// import { useParams } from "react-router-dom";

function GuestCheckInManageContainer() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const flightData = useSelector(state => state.flightManage);
    const dataBranchOnState = useSelector(state => state.dataPickUp.flightManage);
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    useEffect(() => {
        if (branchID) {
            onLoadDataGuestCheckInWithMonth(dataBranchOnState.month);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataBranchOnState.month, branchID]);

    function onLoadDataGuestCheckInWithMonth(monthReq) {
        const onLoad = async () => {
            try {
                dispatch(unitsAction.showLoading());
                const dataResult = await bookingApi.takeListGuestCheckInMonth(branchID, monthReq, token);

                dispatch(unitsAction.hiddenLoading());
                if (dataResult) {
                    if (dataResult.data.status === 1) {
                        dispatch(flightManageAction.fillFlightManage({
                            content: dataResult.data.data,
                            loading: false,
                        }))
                        informationToast("" + dataResult.data.msg);
                    }
                    else errorToast("Error when load Guest Check In");
                } else return errorToast("Error when load Guest Check In");

            } catch (error) { errorToast("Error when load Guest Check In"); }
            document.title = `Az${coverNameToID(Number(branchID))} Guest Check In Manage`;
        }

        onLoad();
    }

    function onChangeDataSelect(e, target) {
        const newData = {
            ...dataBranchOnState,
            ["" + target]: e
        };
        dispatch(dataPickUpAction.changeDataFlightManage(newData));
    }

    function closeModal() { dispatch(modalAction.modalReset()); }
  //  function openModal() { dispatch(modalAction.modalIsOk()); }

    return (<>
        <ModalNotify closeModal={closeModal} />
        <h1 className="mt-5">AZUMAYA {coverBranchIDToFullName(Number(branchID)).toLocaleUpperCase()} - GUEST CHECK IN MANAGE : {changeDataToYYYY_MMMM(dataBranchOnState.month)}</h1>
        <div className="d-flex">
            <div className="d-flex">
                <div className="d-flex">
                    <div style={{ margin: 'auto', fontSize: '1.4rem' }}> Select Month:</div>
                    <input
                        type="month"
                        className="ml-2"
                        style={{ fontSize: '1.3rem' }}
                        onChange={e => onChangeDataSelect(e.target.value, "month")}
                        value={dataBranchOnState.month}
                    ></input>
                </div>
            </div>
        </div>
        <div className="" style={{ background: "aliceblue" }}>
            {flightData.loading ? "" :
                <CompanyItemComponent
                    data={flightData.content}
                    type="guestCheckInManage"
                />}
        </div>
    </>)
}

export default GuestCheckInManageContainer
