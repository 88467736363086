import { companyAction, getType } from '../../actions';

const initialState = {
    loading: true,
    content: [],
    typeSearch: "Name",
    keySearch: ""
}

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(companyAction.resetCompany): {
            return {
                loading: true,
                content: [],
                typeSearch: "Name",
                keySearch: ""
            };
        }

        case getType(companyAction.fillCompany): {
            return {
                ...state,
                content: action.payload,
            };
        }

        case getType(companyAction.changeValueKeySearch): {

            return {
                ...state,
                keySearch: action.payload
            }
        }

        case getType(companyAction.changeTypeSearch): {
            return {
                ...state,
                typeSearch: action.payload
            }
        }

        case getType(companyAction.changeChildrenCompany): {
            const { data, index } = action.payload;
            const newContent = [...state.content];
            newContent[index] = data;
            return {
                ...state,
                content: newContent
            }
        }

        default: return state;
    }
}

export default companyReducer;
