import React from "react";

function ModalNotifyTextAndButton(props) {
    const {color,title} = props;
    return (<>
        <h4 style={{ color: color }}>{title}</h4>
        {/* <h4 style={{ color: color }}>Thay đổi của bạn sẽ tăng số hóa đơn, bạn vẫn muốn tiếp tục ?</h4> */}
        <div>---</div>
        {/* <div className={btnName1? "":"op-hidden"}>
            <button className="btn btn-primary"
                onClick={props.featureButton1}>{btnName1}</button>
            <button className="btn btn-success ml-2"
                //disabled={!isAddService}
                onClick={props.featureButton1} >{btnName2}</button>
            <button className="btn btn-danger ml-2"
                onClick={props.featureButton3}
            >{btnName3}</button>
        </div> */}
    </>)
}

export default ModalNotifyTextAndButton;
