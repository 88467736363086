import { guestInformationAction, getType } from '../../actions';

const initialState = {
    loading: true,
    content: [],
    typeSearch: "Name",
    keySearch: ""
}

const guestInformationReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(guestInformationAction.resetGuestInformation): {
            return {
                loading: true,
                content: [],
                typeSearch: "Name",
                keySearch: ""
            };
        }

        case getType(guestInformationAction.fillGuestInformation): {
            return {
                ...state,
                content: action.payload,
            };
        }

        case getType(guestInformationAction.changeTypeSearchGuestInformation): {
            return {
                ...state,
                typeSearch: action.payload
            }
        }

        case getType(guestInformationAction.changeValueKeySearchGuestInformation): {
            return {
                ...state,
                keySearch: action.payload
            }
        }

        case getType(guestInformationAction.changeChildrenGuestInformation): {
            const { data, index } = action.payload;
            
            const newContent = [...state.content];
            newContent[index] = data;
            return {
                ...state,
                content: newContent
            }
        }

        default: return state;
    }
}

export default guestInformationReducer;
