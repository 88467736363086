import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LIST_GUEST_ID_BLOCK } from '../../../constaints/listID/listID';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from "@fortawesome/free-solid-svg-icons";

function MakeRoomDataOtherDetails(props) {
    const { listGuestSelect } = useSelector(state => state.makeRoom);
    const makeRoomService = useSelector(state => state.makeRoomOther);
    const signature = useSelector(state => state.makeRoomOther.signature);
    const [color, setColor] = useState("black");
    const [openColor, setOpenColor] = useState(false);
    const [openSignature, setOpenSignature] = useState(false);
    const auth = useSelector(state => state.auth);
    const { role, branchID } = auth.user;

    const isEdit = makeRoomService.area !== "cancel" && (role === 0 || role === 2 || role === 3);
    const isBlockList = listGuestSelect.length === 0 ? false : LIST_GUEST_ID_BLOCK.includes(listGuestSelect[0]._id);

   const LIST_COLOR_TEAM = {
        vat: ['red'],
        waiting: ['black'],
        hotel: ['gray'],
        OTA: [""],
        normal1:["#ff5b5b","#ff9900","#ffff00","#00ff00","#00ffff","#0000ff","#9900ff","#ff00ff"], //"#ff0000",
        normal2:["#f4cccc","#fce5cd","#fff2cc","#d9ead3","#d0e0e3","#cfe2f3","#d9d2e9","#ead1dc"],
        normal3:["#ea9999","#f9cb9c","#ffe599","#b6d7a8","#a2c4c9","#9fc5e8","#b4a7d6","#d5a6bd"],
        normal4:["#e06666","#f6b26b","#ffd966","#93c47d","#76a5af","#6fa8dc","#8e7cc3","#c27ba0"],
        normal5:["#ce3636","#e69138","#f1c232","#6aa84f","#45818e","#3d85c6","#674ea7","#a64d79"], //
        normal6:["#990000","#b45f06","#bf9000","#38761d","#134f5c","#0b5394","#351c75","#741b47"],
        normal7:["#660000","#783f04","#7f6000","#274e13","#0c343d","#073763","#20124d","#4c1130"]
   };

   const LIST_OTA_BRANCH = {
    "1"  : [],
    "3"  : ["#0000ff","#0000ff","#0000ff"],    
    "4"  : [],
    "7"  : ["#ff9900","#00ff00","#9900ff","#ffd966","#ffd966"],
    "8"  : ["#00ff00","#00ffff"],
    "9"  : [],
    "11" : ["#00ff00", "#00ff00", "#00ff00"], 
    "12" : ["#00ff00", "#ff00ff","#0000ff"],//#0000ff  //#7f6000
    "13" : ["#ff9900","#00ff00","#9900ff"],
    "15" : ["#00ff00", "#00ffff"],
    "16" : [],
    "97" : [],
    "98" : [],
    "99" : [],

   }

    useEffect(() => {
        if (makeRoomService.loading === false) {
            // LIST_COLOR_TEAM.OTA = ["green", "yellow"];
            
            setColor(makeRoomService.color)
        }
    }, []); //makeRoomService.color

    function onChangeChildren(value, target, index) {
        if (target === "color") {
            setColor(value)
        }
        props.onChangeValueMakeRoomOther(value, target, index)
    }

    function changeContentRequest1GuestOTAwithBranch(index) {

        function selectText(array) {
            if(index > array.length - 1) return "";
            return array[index]
        }

        switch(Number(branchID)) {
            case 3: case 13:
                return selectText(["EXPEDIA", "BOOKING.COM", "AGODA"]);
            case 7: return selectText(["EXPEDIA", "BOOKING.COM", "AGODA","ROKUTEN","HafH"]);
            case 8: return selectText(["EXPEDIA'S GUEST", "BOOKING.COM'S GUEST"]);
            case 11: return selectText(["Expedia prepaid Booking ID", "Agoda Booking ID", "Trip.com prepaid Booking ID"]);
            case 12: case 15:
                return selectText(["EXPEDIA GUEST", "BOOKING.COM GUEST", "AGODA GUEST"]);
            default:
                return "";
        }
    }

    function renderListColor(list, type) {
        var result = [];

        function changeMiniText(text) {
            if(text.includes("expe")) return "Exp";
            if(text.includes("book")) return "Bk";
            if(text.includes("agoda")) return "Ago";
            if(text.includes("trip")) return "Trip";
            if(text.includes("rokuten")) return "Rkt";
            if(text.includes("hafh")) return "HafH";
        }

        if (list.length > 0) {
            list.forEach((color, index) => {
                const noteKey = changeContentRequest1GuestOTAwithBranch(index);
                result.push(
                    <span style={{
                        backgroundColor: color,
                        width: '35px',
                        cursor: 'pointer',
                        textAlign:"center",
                        border: makeRoomService.color === color ? '2px solid black' : '0.8px solid white',
                    }} 
                        onClick={() => {
                            if (type === "OTA" && LIST_OTA_BRANCH[""+branchID].length > 1) {
                                props.onChangeValueMakeRoom(noteKey, "request1");
                                onChangeChildren(true, "confirm")
                            }
                            onChangeChildren(color, "color");
                            setOpenColor(false);
                        }}
                    >{type === "OTA" ? changeMiniText(String(noteKey).toLowerCase()): ""}</span>)
            })
        }
        return result;
    }

    function onChangeColor(value) {
        setColor(value);
    }

    function checkColor(type) {
        var correctColor;
        const keyOnColor = Object.keys(LIST_COLOR_TEAM);
        keyOnColor.forEach(key => {
            LIST_COLOR_TEAM[key].forEach(colorOnList => {
                if (colorOnList === color) {
                    correctColor = true;
                }
            })
        })

        if (correctColor) {
            if (type === "cancel") setOpenColor(false);
            props.onChangeValueMakeRoomOther(color, "color");
        } else {
            // setOpenColor(false)
            if (type === "cancel") {
                setOpenColor(false);
                setColor("black");
            }
            props.onChangeValueMakeRoomOther("black", "color");

        }
    }

    function renderTeamColor() {
        var result = [];
        const listKey = Object.keys(LIST_COLOR_TEAM);

     if(LIST_OTA_BRANCH[""+branchID].length > 0) {
            listKey.forEach((label) => {
                result.push(<div className="d-flex">
                    <div style={{ width: '75px', border: '0.8px solid black', fontSize:'1.3rem' }}>{label.toUpperCase()}</div>
                    <div className="d-flex flex-wrap">
                        {label === "OTA" ? renderListColor(LIST_OTA_BRANCH[""+branchID],"OTA") : renderListColor(LIST_COLOR_TEAM[label])}
                    </div>
                </div>)
            });
        } else {
            listKey.forEach((label) => {
                if(label !== "OTA") {
                    result.push(<div className="d-flex">
                    <div style={{ width: '75px', border: '0.8px solid black', fontSize:'1.3rem' }}>{label.toUpperCase()}</div>
                    <div className="d-flex flex-wrap">
                        {renderListColor(LIST_COLOR_TEAM[label], "Normal")}
                    </div>
                </div>)
                }

            });
        }

        return result;
    }

    function renderListSignature() {
        const dataReturn = [];

        if(signature) {
            if(signature.list.length > 0) {
                signature.list.forEach((item, index) => {
                    if(item !== "" && signature.select.includes(index)) {
                        dataReturn.push(<div 
                            style={{
                                border:'1px solid',
                                padding:'4px',
                                cursor:'pointer',
                                textAlign:'center',
                                fontSize: '1.3rem'
                            }} value = {item}
                        onClick={() => onChangeChildren(item, "maker")}>{item}</div>)
                    }
                })
            }
        } 
        return dataReturn
    }

    function renderListSignature3() {
        const dataReturn = [];


        if(signature) {
            if(signature.list.length > 0) {
                signature.list.forEach((item, index) => {
                    if(item !== "" && signature.select.includes(index)) {
                        dataReturn.push(<div 
                            style={{
                                border:'1px solid',
                                padding:'4px',
                                cursor:'pointer',
                                textAlign:'center',
                                fontSize: '1.3rem'
                            }} value = {item}
                        onClick={() => onChangeChildren(item, "maker")}>{item}</div>)
                    }
                })
            }
        } 
        return dataReturn
    }


    function renderListSignature3() {
        const dataReturn = [];

        if(signature) {
            if(signature.list.length > 0) {
                signature.list.forEach((item, index) => {
                    if(item !== "" && signature.select.includes(index)) {
                        dataReturn.push(<option
                            style={{
                                border:'1px solid',
                                padding:'4px',
                                cursor:'pointer',
                                textAlign:'center',
                                fontSize: '1.3rem'
                            }} value = {item}
                        onClick={() => onChangeChildren(item, "maker")}>{item}</option>)
                    }
                })
            }
        } 
        return dataReturn
    }

    function renderListSignature2() {
        const dataReturn = [];

        function convertNameFormat(name) {
            // Tách phần tên và phần bổ sung (Mr., Mrs., ...)
            if(!name.trim().includes(" ")) {
                return name
            }

            const lastText = name.trim().slice(name.length -1, name.length) ==="";
            if(!lastText) {
                name = name +" ";
            }
           // const lastName = name
            let nameParts = name.split(' ');
            let titlePart = nameParts.pop();
            let firstName = nameParts[0];
            let lastName = nameParts[1];
        
            // Lấy ký tự đầu của tên đầu tiên và kết hợp với phần họ
            let result = `${firstName.charAt(0)}.${lastName}`;
        
            return result;
        }
        

        if(signature) {
            if(signature.list.length > 0) {
                signature.list.forEach((item, index) => {
                    if(item !== "" && signature.select.includes(index)) {
                        dataReturn.push(<div 
                            style={{
                                border:'1px solid',
                                padding:'4px',
                                cursor:'pointer',
                                minWidth:'70px',
                                textAlign:'center',
                                fontWeight:'400',
                                backgroundColor: item.includes("Ms.") ? "#ff017e4d" : "#0000fe4d",
                                fontSize: '1.3rem'
                            }} value = {item}
                        onClick={() => onChangeChildren(item, "maker")}>
                            {convertNameFormat(item.trim().replace("(Ms.)","").replace("(Mr.)","").replace("(Ms. )","").replace("(Mr. )",""))}
                            </div>)
                    }
                })
            }
        } 
        return dataReturn
    }

    function renderListSignatureEdit() {
        const dataReturn2 = [];
        if(signature) {
            if(signature.list.length > 0) {
                signature.list.forEach((item, index) => {
                    dataReturn2.push(<div style={{display:'flex',flexDirection:'row-reverse',alignItems:'center'}}>
                            <input 
                                style={{border:'1px solid',padding:'4px',cursor:'pointer', height:'30px'}} 
                                value = {item}
                                onChange={e => onChangeChildren(e.target.value, "signature", {index:index, target:"list"})}
                            />
                            <input 
                                className="form-check-input mr-3" 
                                type="checkbox" 
                                onClick={() => {
                                    if(signature.select.includes(index)) {
                                        const newList = signature.select.filter(item => item !== index);
                                        onChangeChildren(newList, "signature", {index:index, target:"select"})
                                    } else {
                                        signature.select.push(index);
                                        onChangeChildren(signature.select, "signature", {index:index, target:"select"})
                                    }
                                }}
                                checked = {signature.select.includes(index)} 
                            />
                        </div>)
                })
            }
        } 

        return dataReturn2
    }

    return (
        <div style={{textAlign: 'left', margin:'5px', backgroundColor: 'rgba(216, 219, 221,0.6)'}}>
            <h3 className="ml-2">* Other Details</h3>

            <div className="collapse show" id="otherDetails">
                <div className="makeRoom_children d-flex">
                    <label className="makeRoom_label" > VAT ?:</label>
                    <input
                        type="checkbox"
                        disabled={!isEdit}
                        onChange={() => {
                            onChangeChildren(!makeRoomService.vat, "vat");
                            if (!makeRoomService.vat === true) { onChangeChildren("red", "color") }
                            else { !makeRoomService.confirm && onChangeChildren("black", "color") }
                        }}
                        id="vat"
                        name="vat"
                        value="vat"
                        checked={makeRoomService.vat} />
                    <label for="vat" className="makeRoom_text">VAT request.</label>
                </div>

                <div className="makeRoom_children">
                    <label className="makeRoom_label">Status Room:</label>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input " type="radio" name="status" id="statusRoom1" value="waiting"
                            checked={!makeRoomService.confirm}
                            disabled={makeRoomService.status === "confirm" || !isEdit}
                            onChange={() => onChangeChildren(false, "confirm")}
                        />
                        <label className="makeRoom_text form-check-label" for="statusRoom1"
                            disabled={makeRoomService.status || !isEdit }
                            onChange={() => onChangeChildren(false, "confirm")}
                        >Waiting</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input" type="radio" name="status" id="statusRoom2" value="confirm"
                            checked={makeRoomService.confirm}
                            disabled={!isEdit || isBlockList}
                            onChange={() => onChangeChildren(true, "confirm")}
                        />
                        <label className="makeRoom_text form-check-label" for="statusRoom2"
                            disabled={!isEdit}
                            onChange={() => onChangeChildren(true, "confirm")}
                        >Confirmed</label>
                    </div>
                </div>

                <div className="makeRoom_children d-flex"  >
                    <label className="makeRoom_label" > Color Team :</label>
                    <div style={{ position: 'relative', }}>
                        <div style={{
                            width: '70px',
                            backgroundColor: makeRoomService.color,
                            cursor: makeRoomService.vat ?"" :'pointer',
                            height: '100%'
                        }}
                            onClick={makeRoomService.vat ?"" :() => setOpenColor(!openColor)}
                        >{makeRoomService.color}</div>

                        {openColor && <div
                            style={{
                                position: "absolute",
                                backgroundColor: 'white',
                                bottom: '24px',
                                width: '375px',
                                height: '275px' ,
                                zIndex: '999',
                                fontSize: '1.4rem',
                                padding: '8px',
                                border: '1px solid aqua'
                            }}>
                            {renderTeamColor()}

                            <div className="d-flex justify-content-between">
                                <button className="btn btn-danger mt-1"
                                    onClick={() => checkColor("cancel")}>Cancel</button>
                                <div className="d-flex">
                                    <div className="mr-1">Color hex:</div>
                                    <input className="mr-3"
                                        onChange={e => onChangeColor(e.target.value)}
                                        onBlur={() => checkColor("check")}
                                        value={color}
                                        maxLength={7}
                                        style={{ width: '80px' }}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="makeRoom_children d-flex" style={{ position: 'relative', }}>
                    <label className="makeRoom_label" > Make By:</label>
                    <input
                        type="text"
                        style={{ fontSize: '1.3rem', width: '120px' }}
                        disabled={role !== 0 && role !== 3 && role !== 2}
                        value={makeRoomService.maker}
                           onChange={e => onChangeChildren(e.target.value, "maker")} />
                        <div className='d-flex align-items-center'>
                         {/* { role === 0 && ((branchID ===99 || branchID === 15) ? renderListSignature2() : renderListSignature())} */}
                         { role === 0 && renderListSignature2()}

                         {openSignature && <div
                            style={{
                                position: "absolute",
                                backgroundColor: 'white',
                                bottom: '24px',
                                width: '190px',
                                height: '225px',
                                zIndex: '999',
                                fontSize: '1.4rem',
                                padding: '8px',
                                border: '1px solid aqua'
                            }}>
                                {renderListSignatureEdit()}
                            <div className="">
                                 <button className="btn btn-primary mt-1"
                                    onClick={() => {
                                        props.onUpdateSignature();
                                        setOpenSignature(false)
                                    }}>Save</button>
                                <button className="btn btn-danger mt-1"
                                    onClick={() => {
                                        props.onReturnDefaultSignature();
                                        setOpenSignature(false)
                                    }}>Cancel</button>
                            </div>
                        </div>}
                        {
                            role === 0 && <FontAwesomeIcon
                                icon={faTools}
                                onClick = {() => setOpenSignature(!openSignature)}
                                style={{
                                    color:'blue', 
                                    marginTop: 'unset', 
                                    cursor: 'pointer', 
                                    fontSize:'1.5rem',
                                    marginLeft:'12px'
                                }} />
                        }

                        </div> 
                    </div>
                    <br></br>
                <br></br>
            </div>
        </div >
    )
};

export default MakeRoomDataOtherDetails;
