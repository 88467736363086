import React from "react";

function MailComponentRoomSelect(props) {
    const { onChangeData, listRoomData , selectTabMain, data, type} = props;
    const  optionCaseList = [
        "1 Option",
        "2 Option",
        "3 option",
        "1 Option with change room",
        "2 Option with change room"
    ];

    function renderListRoomSelect(dataRender, target) {
        const result = [];
        if(dataRender.length > 0) {
            dataRender.forEach((room, index) => {
                result.push(<option style={{width:"220px"}} key={index} value={room} selected={room === data[target]}>{room}</option>)
            })
        }
        return result;
    }

    return (selectTabMain === "suggestRoom" 
    &&  <div>
        <div className={!type ? 'd-flex ml-5' : 'd-flex mt-2'}>
            <label for="case" style={{width: !type ? "120px" : "65px", textAlign:'left'}}>Case:</label>         
            <select id="case" name="case"
                style={{width:"220px"}}
                onChange={e => onChangeData("optionCase", e.target.value)}>
                {renderListRoomSelect(optionCaseList,"optionCase")}
            </select>
        </div>

        <div className={!type ? 'd-flex ml-5' : 'd-flex'}>
            <label for="option1" style={{width: !type ? "120px" : "65px", textAlign:'left'}}>Option 1:</label>         
            <select id="option1" name="option1"
                style={{width:"220px"}}
                onChange={e => onChangeData("option1", e.target.value)}>
                {renderListRoomSelect(listRoomData.listRoomType,"option1")}
            </select>
        </div>

        {data.optionCase !== "1 Option" && <div className={!type ? 'd-flex ml-5' : 'd-flex'}>
           <label for="option2" style={{width: !type ? "120px" : "65px", textAlign:'left'}}>Option 2:</label>         
            <select id="option2" name="option2"
                style={{width:"220px"}}
                onChange={e => onChangeData("option2", e.target.value)}>
                {renderListRoomSelect(listRoomData.listRoomType,"option2")}
            </select>
        </div>}

        {(data.optionCase.includes("2 Option with change") || data.optionCase===("3 Option" )) &&<div className={!type ? 'd-flex ml-5' : 'd-flex'}>
           <label for="option3" style={{width: !type ? "120px" : "65px", textAlign:'left'}}>Option 3:</label>         
            <select id="option3" name="option3"
                style={{width:"220px"}}
                onChange={e => onChangeData("option3", e.target.value)}>
                {renderListRoomSelect(listRoomData.listRoomType,"option3")}
            </select>
        </div>}
    </div>)
}

export default MailComponentRoomSelect;