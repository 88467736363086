export const AZKM3_GLIST = {
    title: 'Azumaya Kim Ma 3',
    titleRowSpan : 4,
    glist1: {
        col1: [
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: '',
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "Ngày",
                roomType: 'date',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },

        ],
        col2: [
            {
                roomNumber: "101",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "102",
                roomType: 'Lake side',
            },
            {
                roomNumber: "103",
                roomType: 'Lake side',
            },
            {
                roomNumber: "104",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "105",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "106",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "107",
                roomType: 'Deluxe L no Window',
            },
        ],
        col3: [
            {
                roomNumber: "201",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "202",
                roomType: 'Lake side',
            },
            {
                roomNumber: "203",
                roomType: 'Lake side',
            },
            {
                roomNumber: "204",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "205",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "206",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "207",
                roomType: 'Deluxe L no Window',
            },
        ],
        col4: [
            {
                roomNumber: "301",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "302",
                roomType: 'Lake side',
            },
            {
                roomNumber: "303",
                roomType: 'Lake side',
            },
            {
                roomNumber: "304",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "305",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "306",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "307",
                roomType: 'Deluxe L no Window',
            },
        ],
        col5: [
            {
                roomNumber: "401",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "402",
                roomType: 'Lake side',
            },
            {
                roomNumber: "403",
                roomType: 'Lake side',
            },
            {
                roomNumber: "404",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "405",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "406",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "407",
                roomType: 'Deluxe L no Window',
            },
        ],
        col6: [
            {
                roomNumber: "501",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "502",
                roomType: 'Lake side',
            },
            {
                roomNumber: "503",
                roomType: 'Lake side',
            },
            {
                roomNumber: "504",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "505",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "506",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "507",
                roomType: 'Deluxe L no Window',
            },
        ],
        col7: [
            {
                roomNumber: "601",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "602",
                roomType: 'Lake side',
            },
            {
                roomNumber: "603",
                roomType: 'Lake side',
            },
            {
                roomNumber: "604",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "605",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "606",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "607",
                roomType: 'Deluxe L no Window',
            },
        ],
    },
    glist2: {
    },
    glist3: {
    },
    statusTab: {
        col1: {},
        col2: {},
    },
    other1: {
        guest: [],
    },
    restaurant:{
        col1: [
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: '',
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "Ngày",
                roomType: 'date',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },

        ],
        col2: [
            {
                roomNumber: "101",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "102",
                roomType: 'Lake side',
            },
            {
                roomNumber: "103",
                roomType: 'Lake side',
            },
            {
                roomNumber: "104",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "105",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "106",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "107",
                roomType: 'Deluxe L no Window',
            },
        ],
        col3: [
            {
                roomNumber: "201",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "202",
                roomType: 'Lake side',
            },
            {
                roomNumber: "203",
                roomType: 'Lake side',
            },
            {
                roomNumber: "204",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "205",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "206",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "207",
                roomType: 'Deluxe L no Window',
            },
        ],
        col4: [
            {
                roomNumber: "301",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "302",
                roomType: 'Lake side',
            },
            {
                roomNumber: "303",
                roomType: 'Lake side',
            },
            {
                roomNumber: "304",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "305",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "306",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "307",
                roomType: 'Deluxe L no Window',
            },
        ],
        col5: [
            {
                roomNumber: "401",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "402",
                roomType: 'Lake side',
            },
            {
                roomNumber: "403",
                roomType: 'Lake side',
            },
            {
                roomNumber: "404",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "405",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "406",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "407",
                roomType: 'Deluxe L no Window',
            },
        ],
        col6: [
            {
                roomNumber: "501",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "502",
                roomType: 'Lake side',
            },
            {
                roomNumber: "503",
                roomType: 'Lake side',
            },
            {
                roomNumber: "504",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "505",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "506",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "507",
                roomType: 'Deluxe L no Window',
            },
        ],
        col7: [
            {
                roomNumber: "601",
                roomType: 'Standard no win',
            },
            {
                roomNumber: "602",
                roomType: 'Lake side',
            },
            {
                roomNumber: "603",
                roomType: 'Lake side',
            },
            {
                roomNumber: "604",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "605",
                roomType: 'Deluxe M no window',
            },
            {
                roomNumber: "606",
                roomType: 'Deluxe L Window',
            },
            {
                roomNumber: "607",
                roomType: 'Deluxe L no Window',
            },
        ],
    },
    other2: {
    }
}

export const AZKM2_GLIST = {
    title: 'Azumaya Kim Ma 2',
    titleRowSpan : 4,
    glist1: {
        col1: [
            {
                roomNumber: "101",
                roomType: 'ANNEX w KIT',
            },
            {
                roomNumber: "801",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "",
                roomType: '',
                name: '',
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "",
                roomType: '',
                name: '',
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: 'EXECUTIVE w KIT',
            },
            {
                roomNumber: "202",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "203",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "204",
                roomType: 'LAKE SIDE L',
            },
            {
                roomNumber: "205",
                roomType: 'LAKE SIDE M',
            },
            {
                roomNumber: "206",
                roomType: 'LAKE SIDE M',
            },
        ],
        col3: [
            {
                roomNumber: "301",
                roomType: 'EXECUTIVE w KIT',
            },
            {
                roomNumber: "302",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "303",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "304",
                roomType: 'LAKE SIDE L',
            },
            {
                roomNumber: "305",
                roomType: 'LAKE SIDE M',
            },
            {
                roomNumber: "306",
                roomType: 'LAKE SIDE M',
            },
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: 'EXECUTIVE w KIT',
            },
            {
                roomNumber: "402",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "403",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "404",
                roomType: 'LAKE SIDE L',
            },
            {
                roomNumber: "405",
                roomType: 'LAKE SIDE M',
            },
            {
                roomNumber: "406",
                roomType: 'LAKE SIDE M',
            },
        ],
        col5: [
            {
                roomNumber: "501",
                roomType: 'EXECUTIVE w KIT',
            },
            {
                roomNumber: "502",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "503",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "504",
                roomType: 'LAKE SIDE L',
            },
            {
                roomNumber: "505",
                roomType: 'LAKE SIDE M',
            },
            {
                roomNumber: "506",
                roomType: 'LAKE SIDE M',
            },
        ],
        col6: [
            {
                roomNumber: "601",
                roomType: 'EXECUTIVE w KIT',
            },
            {
                roomNumber: "602",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "603",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "604",
                roomType: 'LAKE SIDE L',
            },
            {
                roomNumber: "605",
                roomType: 'LAKE SIDE M',
            },
            {
                roomNumber: "606",
                roomType: 'LAKE SIDE M',
            },
        ],
        col7: [
            {
                roomNumber: "701",
                roomType: 'EXECUTIVE w KIT',
            },
            {
                roomNumber: "702",
                roomType: 'DELUXE',
            },
            {
                roomNumber: "703",
                roomType: 'EXECUTIVE w KIT',
            },
            {
                roomNumber: "",
                roomType: '',
                name: '',
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "705",
                roomType: 'Lake Side M',
            },
            {
                roomNumber: "706",
                roomType: 'SUITE w KIT',
            },
        ],
    },
    glist2: {
    },
    glist3: {
    },
    statusTab: {
        col1: {},
        col2: {},
    },
    other1: {
        guest: [],
    },
    other2: {
    },
    restaurant: {
        col1: [
            {
                roomNumber: "101",
                roomType: 'ANNEX M',
            },
            {
                roomNumber: "801",
                roomType: 'DELUXE L',
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: '',
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: '',
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: 'EXECUTIVE',
            },
            {
                roomNumber: "202",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "203",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "204",
                roomType: 'LAKE SIDE L',
            },
            {
                roomNumber: "205",
                roomType: 'LAKE SIDE M',
            },
            {
                roomNumber: "206",
                roomType: 'LAKE SIDE M',
            },
        ],
        col3: [
            {
                roomNumber: "301",
                roomType: 'EXECUTIVE',
            },
            {
                roomNumber: "302",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "303",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "304",
                roomType: 'LAKE SIDE L',
            },
            {
                roomNumber: "305",
                roomType: 'LS M wT',
            },
            {
                roomNumber: "306",
                roomType: 'LS M wT',
            },
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: 'EXECUTIVE',
            },
            {
                roomNumber: "402",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "403",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "404",
                roomType: 'LS L wT',
            },
            {
                roomNumber: "405",
                roomType: 'LS M wT',
            },
            {
                roomNumber: "406",
                roomType: 'LS M wT',
            },
        ],
        col5: [
            {
                roomNumber: "501",
                roomType: 'EXECUTIVE',
            },
            {
                roomNumber: "502",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "503",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "504",
                roomType: 'LS L wT',
            },
            {
                roomNumber: "505",
                roomType: 'LS M wT',
            },
            {
                roomNumber: "506",
                roomType: 'LS M wT',
            },
        ],
        col6: [
            {
                roomNumber: "601",
                roomType: 'EXECUTIVE',
            },
            {
                roomNumber: "602",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "603",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "604",
                roomType: 'LS L wT',
            },
            {
                roomNumber: "605",
                roomType: 'LS M wT',
            },
            {
                roomNumber: "606",
                roomType: 'LS M wT',
            },
        ],
        col7: [
            {
                roomNumber: "701",
                roomType: 'EXECUTIVE',
            },
            {
                roomNumber: "702",
                roomType: 'DELUXE M',
            },
            {
                roomNumber: "703",
                roomType: 'DELUXE L wK',
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: '',
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "705",
                roomType: 'LS M wT',
            },
            {
                roomNumber: "706",
                roomType: 'LS M wT',
            },
        ],
    },
}

export const AZLL_GLIST = {
    title: "Azumaya Linh Lang",
    glist1: {
        col1: [
            {   
                roomNumber: "101",
                roomType: 'Deluxe'
            },
            {
                roomNumber: "102",
                roomType:'Standard in Win'
            }, 
            {
                roomNumber: "103",
                roomType: 'Executive no Win'
            }, 
            {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "105",
                roomType: 'Suite w kit'
            },
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: 'Deluxe'
               },
               {
                roomNumber: "202",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "203",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "208",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "205",
                roomType: 'Executive'
               },
               {
                roomNumber: "206",
                roomType: 'Executive'
               }, 
               {
                roomNumber: "207",
                roomType: 'Executive'
               },
        ],
        col3: [
            {
                roomNumber: "301",
                roomType: 'Deluxe'
               },
               {
                roomNumber: "302",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "303",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "308",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "305",
                roomType: 'Executive'
               }, 
               {
                roomNumber: "306",
                roomType: 'Excutive'
               },
               {
                roomNumber: "307",
                roomType: 'Excutive'
               },
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: 'Deluxe'
            },
            {
                roomNumber: "402",
                roomType: 'Standard in Win'
            },
            {
                roomNumber: "403",
                roomType: 'Standard in Win'
            },
            {
                roomNumber: "408",
                roomType: 'Standard in Win'
            }, 
            {
                roomNumber: "405",
                roomType: 'Executive'
            },
            {
                roomNumber: "406",
                roomType: 'Excutive'
            },
            {
                roomNumber: "407",
                roomType: 'Excutive'
            },
        ],
        col5: [
            {
                roomNumber: "501",
                roomType: 'Deluxe'
            },
            {
                roomNumber: "502",
                roomType: 'Standard in Win'
            },
            {
                roomNumber: "503",
                roomType: 'Standard in Win'
            },
            {
                roomNumber: "508",
                roomType: 'Standard in Win'
            },
            {
                roomNumber: "505",
                roomType: 'Executive'
            }, 
            {
                roomNumber: "506",
                roomType: 'Executive'
            },
            { 
                roomNumber: "507",
                roomType: 'Executive'
            },
        ],
        col6: [
            {
                roomNumber: "601",
                roomType: 'Deluxe '
               },
               {
                roomNumber: "602",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "603",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "608",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "605",
                roomType: 'Executive'
               }, 
               {
                roomNumber: "606",
                roomType: 'Executive'
               },
               { 
                roomNumber: "607",
                roomType: 'Executive'
               },
        ],
        col7: [
            {
                roomNumber: "701",
                roomType: 'Deluxe '
               },
               {
                roomNumber: "702",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "703",
                roomType: 'Deluxe  '
               },
               {
                roomNumber: "706",
                roomType: 'Excutive no Win'
               },
               {
                roomNumber: "705",
                roomType: 'Suite 7F'
               }, 
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
        ],
        col8: [
            {
                roomNumber: "801",
                roomType: 'Deluxe'
               },
               { 
                roomNumber: "802",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
                },
                {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
        ]
    },
    glist2: {

    },
    glist3: {

    },
    statusTab: {
        col1: {},
        col2: {},
    },
    other1: {
        guest: [],
    },
    other2: {
    },
    restaurant: {
        col1: [
            {   
                roomNumber: "101",
                roomType: 'Deluxe'
            },
            {
                roomNumber: "102",
                roomType:'Standard in Win'
            }, 
            {
                roomNumber: "103",
                roomType: 'Executive no Win'
            }, 
            {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "105",
                roomType: 'Suite w kit'
            },
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: 'Deluxe'
               },
               {
                roomNumber: "202",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "203",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "208",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "205",
                roomType: 'Executive'
               },
               {
                roomNumber: "206",
                roomType: 'Executive'
               }, 
               {
                roomNumber: "207",
                roomType: 'Executive'
               },
        ],
        col3: [
            {
                roomNumber: "301",
                roomType: 'Deluxe'
               },
               {
                roomNumber: "302",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "303",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "308",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "305",
                roomType: 'Executive'
               }, 
               {
                roomNumber: "306",
                roomType: 'Excutive'
               },
               {
                roomNumber: "307",
                roomType: 'Excutive'
               },
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: 'Deluxe'
            },
            {
                roomNumber: "402",
                roomType: 'Standard in Win'
            },
            {
                roomNumber: "403",
                roomType: 'Standard in Win'
            },
            {
                roomNumber: "408",
                roomType: 'Standard in Win'
            }, 
            {
                roomNumber: "405",
                roomType: 'Executive'
            },
            {
                roomNumber: "406",
                roomType: 'Excutive'
            },
            {
                roomNumber: "407",
                roomType: 'Excutive'
            },
        ],
        col5: [
            {
                roomNumber: "501",
                roomType: 'Deluxe'
            },
            {
                roomNumber: "502",
                roomType: 'Standard in Win'
            },
            {
                roomNumber: "503",
                roomType: 'Standard in Win'
            },
            {
                roomNumber: "508",
                roomType: 'Standard in Win'
            },
            {
                roomNumber: "505",
                roomType: 'Executive'
            }, 
            {
                roomNumber: "506",
                roomType: 'Executive'
            },
            { 
                roomNumber: "507",
                roomType: 'Executive'
            },
        ],
        col6: [
            {
                roomNumber: "601",
                roomType: 'Deluxe '
               },
               {
                roomNumber: "602",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "603",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "608",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "605",
                roomType: 'Executive'
               }, 
               {
                roomNumber: "606",
                roomType: 'Executive'
               },
               { 
                roomNumber: "607",
                roomType: 'Executive'
               },
        ],
        col7: [
            {
                roomNumber: "701",
                roomType: 'Deluxe '
               },
               {
                roomNumber: "702",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "703",
                roomType: 'Deluxe  '
               },
               {
                roomNumber: "706",
                roomType: 'Excutive no Win'
               },
               {
                roomNumber: "705",
                roomType: 'Suite 7F'
               }, 
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
        ],
        col8: [
            {
                roomNumber: "801",
                roomType: 'Deluxe'
               },
               { 
                roomNumber: "802",
                roomType: 'Standard in Win'
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
                },
                {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
        ]
    },
}

export const AZHBT1_GLIST = {
    title: "Azumaya Hai Ba Trung",
    glist1: {
        col1: [
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {   
                roomNumber: "103",
                roomType: 'DELUXE 1F'
            },
            {
                roomNumber: "105",
                roomType:'DELUXE 1F'
            }, 
            {
                roomNumber: "905",
                roomType: 'S.SUITE wK (TWIN)'
            }, 
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: 'SUITE'
               },
               {
                roomNumber: "202",
                roomType: 'DELUXE INSIDE WIN'
               },
               {
                roomNumber: "203",
                roomType: 'DELUXE INSIDE WIN'
               },
               {
                roomNumber: "205",
                roomType: 'DELUXE INSIDE WIN'
               },
        ],
        col3: [
              {
                roomNumber: "301",
                roomType: 'SUITE'
               },
               {
                roomNumber: "302",
                roomType: 'DELUXE INSIDE WIN (TWIN)'
               },
               {
                roomNumber: "303",
                roomType: 'DELUXE INSIDE WIN'
               },
               {
                roomNumber: "305",
                roomType: 'EXECUTIVE'
               }, 
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: 'SUITE'
            },
            {
                roomNumber: "402",
                roomType: 'DELUXE INSIDE WIN (TWIN)'
            },
            {
                roomNumber: "403",
                roomType: 'DELUXE INSIDE WIN'
            },
            {
                roomNumber: "405",
                roomType: 'EXECUTIVE'
            },
        ],
        col5: [
            {
                roomNumber: "501",
                roomType: 'SUITE'
            },
            {
                roomNumber: "502",
                roomType: 'EXECUTIVE TWIN'
            },
            {
                roomNumber: "503",
                roomType: 'DELUXE INSIDE WIN'
            },
            {
                roomNumber: "505",
                roomType: 'EXECUTIVE'
            },
        ],
        col6: [
            {
                roomNumber: "601",
                roomType: 'SUITE'
               },
               {
                roomNumber: "602",
                roomType: 'EXECUTIVE TWIN'
               },
               {
                roomNumber: "603",
                roomType: 'EXECUTIVE'
               },
               {
                roomNumber: "605",
                roomType: 'EXECUTIVE WIT KITCHEN'
               },
        ],
        col7: [
            {
                roomNumber: "701",
                roomType: 'SUITE'
               },
               {
                roomNumber: "702",
                roomType: 'EXECUTIVE TWIN'
               },
               {
                roomNumber: "703",
                roomType: 'EXECUTIVE'
               },
               {
                roomNumber: "705",
                roomType: 'EXECUTIVE WIT KITCHEN'
               },
        ],
        col8: [
            {
                roomNumber: "801",
                roomType: 'SUITE'
               },
               { 
                roomNumber: "802",
                roomType: 'EXECUTIVE TWIN'
               },
               { 
                roomNumber: "803",
                roomType: 'EXECUTIVE'
               },
               { 
                roomNumber: "805",
                roomType: 'EXECUTIVE WITH KITCHEN'
               },
        ]
    },
    glist2: {

    },
    glist3: {

    },
    statusTab: {
        col1: {},
        col2: {},
    },
    other1: {
        guest: [],
    },
    other2: {
    },
    restaurant: {
        col1: [
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {   
                roomNumber: "103",
                roomType: 'DELUXE 1F'
            },
            {
                roomNumber: "105",
                roomType:'DELUXE 1F'
            }, 
            {
                roomNumber: "905",
                roomType: 'S.SUITE wK (TWIN)'
            }, 
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: 'SUITE'
               },
               {
                roomNumber: "202",
                roomType: 'DELUXE INSIDE WIN'
               },
               {
                roomNumber: "203",
                roomType: 'DELUXE INSIDE WIN'
               },
               {
                roomNumber: "205",
                roomType: 'DELUXE INSIDE WIN'
               },
        ],
        col3: [
              {
                roomNumber: "301",
                roomType: 'SUITE'
               },
               {
                roomNumber: "302",
                roomType: 'DELUXE INSIDE WIN (TWIN)'
               },
               {
                roomNumber: "303",
                roomType: 'DELUXE INSIDE WIN'
               },
               {
                roomNumber: "305",
                roomType: 'EXECUTIVE'
               }, 
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: 'SUITE'
            },
            {
                roomNumber: "402",
                roomType: 'DELUXE INSIDE WIN (TWIN)'
            },
            {
                roomNumber: "403",
                roomType: 'DELUXE INSIDE WIN'
            },
            {
                roomNumber: "405",
                roomType: 'EXECUTIVE'
            },
        ],
        col5: [
            {
                roomNumber: "501",
                roomType: 'SUITE'
            },
            {
                roomNumber: "502",
                roomType: 'EXECUTIVE TWIN'
            },
            {
                roomNumber: "503",
                roomType: 'DELUXE INSIDE WIN'
            },
            {
                roomNumber: "505",
                roomType: 'EXECUTIVE'
            },
        ],
        col6: [
            {
                roomNumber: "601",
                roomType: 'SUITE'
               },
               {
                roomNumber: "602",
                roomType: 'EXECUTIVE TWIN'
               },
               {
                roomNumber: "603",
                roomType: 'EXECUTIVE'
               },
               {
                roomNumber: "605",
                roomType: 'EXECUTIVE WIT KITCHEN'
               },
        ],
        col7: [
            {
                roomNumber: "701",
                roomType: 'SUITE'
               },
               {
                roomNumber: "702",
                roomType: 'EXECUTIVE TWIN'
               },
               {
                roomNumber: "703",
                roomType: 'EXECUTIVE'
               },
               {
                roomNumber: "705",
                roomType: 'EXECUTIVE WIT KITCHEN'
               },
        ],
        col8: [
            {
                roomNumber: "801",
                roomType: 'SUITE'
               },
               { 
                roomNumber: "802",
                roomType: 'EXECUTIVE TWIN'
               },
               { 
                roomNumber: "803",
                roomType: 'EXECUTIVE'
               },
               { 
                roomNumber: "805",
                roomType: 'EXECUTIVE WITH KITCHEN'
               },
        ]
    },
}

export const AZHP_GLIST = {
    title : "Azumaya Hai Phong",
    glist1: {
        col1: [
            {   
                roomNumber: "101",
                roomType: 'DELUXE L'
            },
            {   
                roomNumber: "102",
                roomType: 'STANDARD'
            },
            {   
                roomNumber: "103",
                roomType: 'DELUXE M'
            },
            {   
                roomNumber: "104",
                roomType: 'DELUXE M'
            },
            {
                roomNumber: "",
                roomType: "",
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "202",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "203",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "204",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "205",
                roomType: 'EXCUTIVE LV wK'
               },
               {
                roomNumber: "206",
                roomType: 'EXCUTIVE LV'
               },
               {
                roomNumber: "207",
                roomType: 'STANDARD NO WIN'
               },
        ],
        col3: [
            {
                roomNumber: "301",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "302",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "303",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "304",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "305",
                roomType: 'EXCUTIVE LV wK'
               },
               {
                roomNumber: "306",
                roomType: 'EXCUTIVE LV'
               },
               {
                roomNumber: "307",
                roomType: 'STANDARD NO WIN'
               },
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "402",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "403",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "404",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "405",
                roomType: 'EXCUTIVE LV wK'
               },
               {
                roomNumber: "406",
                roomType: 'EXCUTIVE LV'
               },
               {
                roomNumber: "407",
                roomType: 'STANDARD NO WIN'
               },
        ],
        col5: [
            {
                roomNumber: "501",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "502",
                roomType: 'SUITE wK'
               },
               {
                roomNumber: "503",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "504",
                roomType: 'EXCUTIVE LAKE VIEW'
               },
               {
                roomNumber: "505",
                roomType: 'EXCUTIVE LV wK'
               },
               {
                roomNumber: "506",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               
        ],
        col6: [
            {
                roomNumber: "601",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "602",
                roomType: 'SUITE wK'
               },
               {
                roomNumber: "603",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "604",
                roomType: 'EXCUTIVE LAKE VIEW'
               },
               {
                roomNumber: "605",
                roomType: 'EXCUTIVE LV wK'
               },
               {
                roomNumber: "606",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               
        ],
        col7: [
            {
                roomNumber: "701",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "702",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "703",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "704",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "705",
                roomType: 'STANDARD NO WIN'
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               
        ],
        col8: [
            {
                roomNumber: "801",
                roomType: 'EXECUTIVE CV'
               },
               {
                roomNumber: "802",
                roomType: 'SUITE'
               },
               {
                roomNumber: "803",
                roomType: 'SUITE'
               },
               {
                roomNumber: "804",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               
        ],
    },
    glist2: {},
    glist3: {},
    statusTab: {
        col1: {},
        col2: {},
    },
    other1: {
        col1: {},
        col2: {},
    },
    other2: {
        col1: {},
        col2: {},
    },
    restaurant: {
        col1: [
            {   
                roomNumber: "101",
                roomType: 'DELUXE L'
            },
            {   
                roomNumber: "102",
                roomType: 'STANDARD'
            },
            {   
                roomNumber: "103",
                roomType: 'DELUXE M'
            },
            {   
                roomNumber: "104",
                roomType: 'DELUXE M'
            },
            {
                roomNumber: "",
                roomType: "",
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "202",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "203",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "204",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "205",
                roomType: 'EXCUTIVE LV wK'
               },
               {
                roomNumber: "206",
                roomType: 'EXCUTIVE LV'
               },
               {
                roomNumber: "207",
                roomType: 'STANDARD NO WIN'
               },
        ],
        col3: [
            {
                roomNumber: "301",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "302",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "303",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "304",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "305",
                roomType: 'EXCUTIVE LV wK'
               },
               {
                roomNumber: "306",
                roomType: 'EXCUTIVE LV'
               },
               {
                roomNumber: "307",
                roomType: 'STANDARD NO WIN'
               },
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "402",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "403",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "404",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "405",
                roomType: 'EXCUTIVE LV wK'
               },
               {
                roomNumber: "406",
                roomType: 'EXCUTIVE LV'
               },
               {
                roomNumber: "407",
                roomType: 'STANDARD NO WIN'
               },
        ],
        col5: [
            {
                roomNumber: "501",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "502",
                roomType: 'SUITE wK'
               },
               {
                roomNumber: "503",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "504",
                roomType: 'EXCUTIVE LAKE VIEW'
               },
               {
                roomNumber: "505",
                roomType: 'EXCUTIVE LV wK'
               },
               {
                roomNumber: "506",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               
        ],
        col6: [
            {
                roomNumber: "601",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "602",
                roomType: 'SUITE wK'
               },
               {
                roomNumber: "603",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "604",
                roomType: 'EXCUTIVE LAKE VIEW'
               },
               {
                roomNumber: "605",
                roomType: 'EXCUTIVE LV wK'
               },
               {
                roomNumber: "606",
                roomType: 'DELUXE M'
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               
        ],
        col7: [
            {
                roomNumber: "701",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "702",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "703",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "704",
                roomType: 'STANDARD'
               },
               {
                roomNumber: "705",
                roomType: 'STANDARD NO WIN'
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               
        ],
        col8: [
            {
                roomNumber: "801",
                roomType: 'EXECUTIVE CV'
               },
               {
                roomNumber: "802",
                roomType: 'SUITE'
               },
               {
                roomNumber: "803",
                roomType: 'SUITE'
               },
               {
                roomNumber: "804",
                roomType: 'DELUXE L'
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
               },
               
        ],
    },
}

export const AZDN_GLIST = {
    title : "Azumaya Da Nang",
    glist1: {
        col1: [
            {
                roomNumber: "101",
                roomType:'Deluxe No Window'
            }, 
            {
                roomNumber: "102",
                roomType:'Deluxe No Window'
            }, 
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: 'Suite'
               },
               {
                roomNumber: "202",
                roomType: 'Deluxe No Window'
               },
               {
                roomNumber: "203",
                roomType: 'Deluxe No Win (Twin)'
               },
               {
                roomNumber: "204",
                roomType: 'Deluxe No Win (Twin)'
               },
               {
                roomNumber: "205",
                roomType: 'Deluxe No Window'
               },
        ],
        col3: [
              {
                roomNumber: "301",
                roomType: 'Suite'
               },
               {
                roomNumber: "302",
                roomType: 'Executive'
               },
               {
                roomNumber: "303",
                roomType: 'Executive (Twin)'
               },
               {
                roomNumber: "304",
                roomType: 'Executive (Twin)'
               }, 
               {
                roomNumber: "305",
                roomType: 'Executive'
               }, 
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: 'Suite'
               },
               {
                roomNumber: "402",
                roomType: 'Executive'
               },
               {
                roomNumber: "403",
                roomType: 'Executive'
               },
               {
                roomNumber: "404",
                roomType: 'Executive (Twin)'
               }, 
               {
                roomNumber: "405",
                roomType: 'Executive'
               }, 
        ],
        col5: [
            {
            roomNumber: "501",
            roomType: 'Suite'
            },
            {
            roomNumber: "502",
            roomType: 'Executive'
            },
            {
            roomNumber: "503",
            roomType: 'Executive'
            },
            {
            roomNumber: "504",
            roomType: 'Executive (Twin)'
            }, 
            {
            roomNumber: "505",
            roomType: 'Executive'
            }, 
        ],
        col6: [
            {
                roomNumber: "601",
                roomType: 'Suite'
               },
               {
                roomNumber: "602",
                roomType: 'Executive'
               },
               {
                roomNumber: "603",
                roomType: 'Executive'
               },
               {
                roomNumber: "604",
                roomType: 'Executive (Twin)'
               }, 
               {
                roomNumber: "605",
                roomType: 'Executive'
               }, 
        ],
        col7: [
            {
                roomNumber: "701",
                roomType: 'Suite'
               },
               {
                roomNumber: "702",
                roomType: 'Executive'
               },
               {
                roomNumber: "703",
                roomType: 'Executive'
               },
               {
                roomNumber: "704",
                roomType: 'Executive (Twin)'
               }, 
               {
                roomNumber: "705",
                roomType: 'Executive'
               }, 
        ],
    },
    glist2: {},
    glist3: {},
    statusTab: {
        col1: {},
        col2: {},
    },
    other1: {
        col1: {},
        col2: {},
    },
    other2: {},
    restaurant: {
        col1: [
            {
                roomNumber: "101",
                roomType:'Deluxe No Window'
            }, 
            {
                roomNumber: "102",
                roomType:'Deluxe No Window'
            }, 
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: 'Suite'
               },
               {
                roomNumber: "202",
                roomType: 'Deluxe No Window'
               },
               {
                roomNumber: "203",
                roomType: 'Deluxe No Win (Twin)'
               },
               {
                roomNumber: "204",
                roomType: 'Deluxe No Win (Twin)'
               },
               {
                roomNumber: "205",
                roomType: 'Deluxe No Window'
               },
        ],
        col3: [
              {
                roomNumber: "301",
                roomType: 'Suite'
               },
               {
                roomNumber: "302",
                roomType: 'Executive'
               },
               {
                roomNumber: "303",
                roomType: 'Executive (Twin)'
               },
               {
                roomNumber: "304",
                roomType: 'Executive (Twin)'
               }, 
               {
                roomNumber: "305",
                roomType: 'Executive'
               }, 
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: 'Suite'
               },
               {
                roomNumber: "402",
                roomType: 'Executive'
               },
               {
                roomNumber: "403",
                roomType: 'Executive'
               },
               {
                roomNumber: "404",
                roomType: 'Executive (Twin)'
               }, 
               {
                roomNumber: "405",
                roomType: 'Executive'
               }, 
        ],
        col5: [
            {
            roomNumber: "501",
            roomType: 'Suite'
            },
            {
            roomNumber: "502",
            roomType: 'Executive'
            },
            {
            roomNumber: "503",
            roomType: 'Executive'
            },
            {
            roomNumber: "504",
            roomType: 'Executive (Twin)'
            }, 
            {
            roomNumber: "505",
            roomType: 'Executive'
            }, 
        ],
        col6: [
            {
                roomNumber: "601",
                roomType: 'Suite'
               },
               {
                roomNumber: "602",
                roomType: 'Executive'
               },
               {
                roomNumber: "603",
                roomType: 'Executive'
               },
               {
                roomNumber: "604",
                roomType: 'Executive (Twin)'
               }, 
               {
                roomNumber: "605",
                roomType: 'Executive'
               }, 
        ],
        col7: [
            {
                roomNumber: "701",
                roomType: 'Suite'
               },
               {
                roomNumber: "702",
                roomType: 'Executive'
               },
               {
                roomNumber: "703",
                roomType: 'Executive'
               },
               {
                roomNumber: "704",
                roomType: 'Executive (Twin)'
               }, 
               {
                roomNumber: "705",
                roomType: 'Executive'
               }, 
        ],
    },
}

export const AZTVL2_GLIST = {
    title : "Azumaya Thai Van Lung 2",
    glist1: {
        col1: [
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "103",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "104",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "105",
                roomType: '(Executive back)'
            },
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: '(Semi Suite front)'
            },
            {
                roomNumber: "202",
                roomType: '(Exec Twin-No Win)'
            },
            {
                roomNumber: "203",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "204",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "205",
                roomType: '(Executive back)'
            },
        ],
        col3: [
            {
                roomNumber: "301",
                roomType: '(Semi Suite front)'
            },
            {
                roomNumber: "302",
                roomType: '(Exec Twin-No Win)'
            },
            {
                roomNumber: "303",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "304",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "305",
                roomType: '(Executive back)'
            },
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: '(Semi Suite front)'
            },
            {
                roomNumber: "402",
                roomType: '(Exec Twin-No Win)'
            },
            {
                roomNumber: "403",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "404",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "405",
                roomType: '(Executive back)'
            },
        ],
        col5: [
            {
                roomNumber: "501",
                roomType: '(Semi Suite front)'
            },
            {
                roomNumber: "502",
                roomType: '(Exec Twin-No Win)'
            },
            {
                roomNumber: "503",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "504",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "505",
                roomType: '(Executive back)'
            },
        ],
        col6: [
            {
                roomNumber: "",
                roomType: 'X',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
            {
                roomNumber: "602",
                roomType: '(Exec Twin-No Win)'
            },
            {
                roomNumber: "603",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "604",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "605",
                roomType: '(Executive back)'
            },
        ],
        col7: [
            {
                roomNumber: "",
                roomType: 'X',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
            {
                roomNumber: "",
                roomType: 'X',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
            {
                roomNumber: "703",
                roomType: '(DLX L-No Win)'
            },
            {
                roomNumber: "704",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "705",
                roomType: '(Executive back)'
            },
        ],
    },
    glist2: {
        col1: [
            {
                roomNumber: "106",
                roomType: 'Executive'
            },
            {
                roomNumber: "107",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
            {
                roomNumber: "109",
                roomType: 'Deluxe M'
            },
            {
                roomNumber: "006",
                roomType: 'Executive'
            },
        ],
        col2: [
            {
                roomNumber: "206",
                roomType: 'Executive'
            },
            {
                roomNumber: "207",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "208",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "209",
                roomType: 'Executive'
            },
            {
                roomNumber: "007",
                roomType: 'DLX L no Win'
            },
        ],
        col3: [
            {
                roomNumber: "306",
                roomType: 'Executive'
            },
            {
                roomNumber: "307",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "308",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "309",
                roomType: 'Executive'
            },
            {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
        ],
        col4: [
            {
                roomNumber: "406",
                roomType: 'Executive'
            },
            {
                roomNumber: "407",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "408",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "409",
                roomType: 'Executive'
            },
            {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
        ],
        col5: [
            {
                roomNumber: "506",
                roomType: 'Executive'
            },
            {
                roomNumber: "507",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "508",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "509",
                roomType: 'Executive'
            },
            {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
        ],
        col6: [
            {
                roomNumber: "606",
                roomType: 'Executive'
            },
            {
                roomNumber: "607",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "608",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "609",
                roomType: 'Executive'
            },
            {
                roomNumber: "",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
        ],
        col7: [
            {
                roomNumber: "706",
                roomType: 'Executive'
            },
            {
                roomNumber: "707",
                roomType: 'DLX L no Win'
            },
            {
                roomNumber: "X",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
            {
                roomNumber: "709",
                roomType: 'Executive'
            },
            {
                roomNumber: "X",
                roomType: '',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
        ],
    },
    glist3: {},
    statusTab: {
        col1: {},
        col2: {},
    },
    other1: {
        col1: {},
        col2: {},
    },
    other2: {
        col1: {},
        col2: {},   
    },
    restaurant: {
        col1: [
            {
                roomNumber: "Số lượng khách",
                roomType: "guestNumber",
                name: "2",
                color: "",
                type: "full",
                fontSize: "5rem"
            },
            {
                roomNumber: "Lễ tân",
                roomType: 'maker',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem"
            },
            {
                roomNumber: "103",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "104",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "105",
                roomType: '(Executive back)'
            },
        ],
        col2: [
            {
                roomNumber: "201",
                roomType: '(Semi Suite front)'
            },
            {
                roomNumber: "202",
                roomType: '(Exec Twin-No Win)'
            },
            {
                roomNumber: "203",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "204",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "205",
                roomType: '(Executive back)'
            },
        ],
        col3: [
            {
                roomNumber: "301",
                roomType: '(Semi Suite front)'
            },
            {
                roomNumber: "302",
                roomType: '(Exec Twin-No Win)'
            },
            {
                roomNumber: "303",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "304",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "305",
                roomType: '(Executive back)'
            },
        ],
        col4: [
            {
                roomNumber: "401",
                roomType: '(Semi Suite front)'
            },
            {
                roomNumber: "402",
                roomType: '(Exec Twin-No Win)'
            },
            {
                roomNumber: "403",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "404",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "405",
                roomType: '(Executive back)'
            },
        ],
        col5: [
            {
                roomNumber: "501",
                roomType: '(Semi Suite front)'
            },
            {
                roomNumber: "502",
                roomType: '(Exec Twin-No Win)'
            },
            {
                roomNumber: "503",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "504",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "505",
                roomType: '(Executive back)'
            },
        ],
        col6: [
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
            {
                roomNumber: "602",
                roomType: '(Exec Twin-No Win)'
            },
            {
                roomNumber: "603",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "604",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "605",
                roomType: '(Executive back)'
            },
        ],
        col7: [
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
            {
                roomNumber: "",
                roomType: 'X-hidden',
                name: "",
                color: "",
                type: "full",
                fontSize: "4rem",
            },
            {
                roomNumber: "703",
                roomType: '(DLX L-No Win)'
            },
            {
                roomNumber: "704",
                roomType: '(DLX M-No Win)'
            },
            {
                roomNumber: "705",
                roomType: '(Executive back)'
            },
        ],
    },
}
