import { flightManageAction, getType } from '../../actions';
import { getTodayMonth } from '../../../utils/Date/SupportDate'

const initialState = {
    loading: true,
    content: [],
    month: getTodayMonth()
}

const flightManageReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(flightManageAction.resetFlightManage): {
            return {
                loading: true,
                content: [],
            };
        }

        case getType(flightManageAction.fillFlightManage): {
            return action.payload;
        }


        default: return state;
    }
}

export default flightManageReducer;
