import React, { useState, useContext } from 'react';
import MakeRoomGuestSelectList from './guestSelectList';
import { useSelector, useDispatch } from 'react-redux';
// component
import ModalSupportOption from '../../../container/Unit/Modal/modalSupportOption';
// API & action
import * as guestAPI from '../../../api/guest';
import { unitsAction, modalAction } from '../../../redux/actions/index';
import { informationToast, errorToast } from '../../../utils/Toast/index';

import { UserContext } from '../../../container/MakeRoom/index';
import { coverNameToID } from '../../../utils/Branch/SupportBranch';
import { changeDataToDDMMYYYY } from '../../../utils/Date/SupportDate';
import { LIST_GUEST_ID_BLOCK, SELECT_KEEP_WITH_BRANCH_ID } from '../../../constaints/listID/listID';

function MakeRoomDataGuest(props) {
    const { style, branchID, lockQuickSelect, } = props;
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const makeRoomOnState = useSelector(state => state.makeRoom);
    const makeRoomOnOtherState = useSelector(state => state.makeRoomOther);
    const branchInformation = useSelector(state => state.branchInfor);
    const auth = useSelector(state => state.auth);
    const { role } = auth.user;

    const isEdit = makeRoomOnOtherState.area !== "cancel" && (role === 0 || role === 2 || role === 3);
      //  && (makeRoomOnOtherState.area !=="waiting" && makeRoomOnState.listGuestSelect.length === 0);
 
    const [guestName, selectGuestName] = useState("");
    const [listSuggest, setListSuggest] = useState([]);

    const { handleDeleteListChangeGuest, listChangeGuest } = useContext(UserContext);

    function onChangeFindGuest(value) {
        let nameRequest = "";

        if (value === "My Branch") {
            nameRequest = "KEEP AZ" + coverNameToID(Number(branchID));
        } else {
            nameRequest = value;
        }

        selectGuestName(nameRequest);
        dispatch(unitsAction.delay());
        dispatch(unitsAction.delay());
        dispatch(unitsAction.delay());

        if (nameRequest.length >= 3) {
            const findGuest = async () => {
                const findGuest = await guestAPI.findGuestByRequest("quick_name", nameRequest, token);
                if (findGuest) { setListSuggest(findGuest.data); }
            }
            findGuest()
        } else {
            setListSuggest([])
        }
    };

    function renderListQuickSelect() {
        let result = [];

        result.push(<button className="btn  mr-1"
            value={"My Branch"}
            onClick={e => onChangeFindGuest(e.target.value)}>My Branch</button>)

        if (branchInformation.listBranchArea.length > 0) {

            branchInformation.listBranchArea.forEach(branch => {
                if (branch.branchID !== Number(branchID)) {
                    result.push(<button className="btn  mr-1"
                        value={"KEEP AZ" + coverNameToID(branch.branchID)}
                        onClick={e => onChangeFindGuest(e.target.value)}>{"AZ" + coverNameToID(branch.branchID)}</button>)
                }
            })
        }

        result.push(<button className="btn btn-warning ml-3"
            onClick={() => onChangeFindGuest("LOCK ROOM")}>Lock Room</button>)
        return result;
    }

    function findDataGuest(id) {
        const newList = [...makeRoomOnState.listGuestSelect];
        const findFullDataGuest = async(id) => {
            console.log("id", id)
            const result = await guestAPI.findGuestByRequest("fullCompanyName", id ,token);
            if(LIST_GUEST_ID_BLOCK.includes(id)) result.data.isEdit = false;
            else result.data.isEdit = true;
            newList.push(result.data);
            props.onChangeValueMakeRoom(newList, "listGuestSelect");
        }
        findFullDataGuest(id);
    }

    function renderListSuggest() {
        var result = [];
        if (listSuggest.length > 0) {
            listSuggest.forEach((guest, index) => {
                result.push(<div style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    padding: '6px',
                    borderBottom: index !== listSuggest.length - 1 ? '1px solid aqua' : ""
                }}
                    onClick={() => {
                        selectGuestName([])
                        setListSuggest([])
                        const newList = [...makeRoomOnState.listGuestSelect];
                        if (newList.length > 0) {
                            var doubleGuest = false;
                            var errorGuest = false;
                            newList.forEach(guestOnNewList => {
                                if (guestOnNewList.name === guest.name
                                    && guestOnNewList.email1 === guest.email1) {
                                    doubleGuest = true;
                                } else if (guest.name.includes("KEEP") || guest.name.includes("LOCK")) {
                                    errorGuest = true;
                                } else if (guestOnNewList.name.includes("KEEP") || guestOnNewList.name.includes("LOCK ROOM")) { errorGuest = true; }
                            })

                            if (doubleGuest === true) return errorToast("Have guest on list select");
                            else if (errorGuest === true) return errorToast("Can not select this data");
                            else {
                                findDataGuest(guest._id)
                            };
                        } else {
                            findDataGuest(guest._id)
                        };
                    }}
                >
                    <div className='d-flex justify-content-between'>
                        <div>{guest.name} </div>
                        {guest.selectionCount > 0 && <div><i class="fa-solid fa-bolt mr-1" style={{color:'red'}}></i>{guest.selectionCount}</div>}
                    </div>
                
                    <div style={{ fontSize: '1.2rem', fontWeight: '300', display:'flex' }}>
                        <div>Mail: {guest.email1}</div>
                        <div className='ml-3'>{guest.passport_number ==="" ? "" : "- Passport: " + guest.passport_number}</div>
                        <div className='ml-3'>{guest.birth_day ==="" ? "" : "- Birth Day: " + changeDataToDDMMYYYY(guest.birth_day)}</div> 
                    </div>
                    <div style={{ fontSize: '1.2rem', fontWeight: '300' }}>Company: {guest.company_name}</div>
                </div>)
            });
        }
        return result;
    };

    function onOpenCreateNewGuest() {
        var changeModal;

        changeModal = modalAction.modalChange({
            type: "centered",
            title: "Create new guest",
            size: 'xl',
            content: < ModalSupportOption
                type={3}
                featureSupport1={onHandleGuestData}
                featureSupport2={closeModal}
                itemOption={{
                    type : makeRoomOnState.createNew ? "makeRoom" :"editRoom",
                    createNew : makeRoomOnState.createNew,
                    isEdit: true,
                    guestName,
                }}
            />
        })
        dispatch(changeModal);
        openModal();
    }

    function onHandleGuestData(data, type, index) {
        const onSaveNewGuestData = async () => {
            const result = await guestAPI.makeNewGuestData(data, token);

            if (result) {
                if (result.data.status === 2) errorToast("" + result.data.msg);
                else {
                    informationToast("" + result.data.msg); 
                    const newListGuest = [...makeRoomOnState.listGuestSelect];
                    newListGuest.push({
                        ...data,
                        _id: result.data.data._id
                    });
                    // add guest to list guest select
                    props.onChangeValueMakeRoom(true,"createNew","");
                    props.updateListGuest(newListGuest);
                    setListSuggest([]);
                    closeModal();
                }
            } else errorToast("Error when update guest data!!")
        }

        const onEditGuestData = async () => {
            const result = await guestAPI.editGuestData(data, data._id, token);

            if (result) {
                if (result.data.status === 2) errorToast("" + result.data.msg);
                else {
                    informationToast("" + result.data.msg); 
                }
            } else errorToast("Error when change guest data!!");

            props.onChangeGuestDataAfterEdit(data,index);
        }

        switch (type) {
            case "create_new": {
                onSaveNewGuestData();
                break;
            }
            case "change": {
                onEditGuestData();
                break;
            }
            default: break;
        }

    }

    function onChangeGuest() {
    }

    function onClearGuest(data, index) {
        // console.log("data", data);
        // console.log("index", index);
        
        var changeModal;

        changeModal = modalAction.modalChange({
            type: "centered",
            title: "Confirm clear guest",
            size: 'sm',
            content: < ModalSupportOption
                type={99}
                title={"You want clear guest on list select: \n" + data.name}
                button1="Clear"
                button2="Cancel"
                typeButton1="btn-danger"
                typeButton2="btn-primary"
                featureButton1={() => onClearGuestSelectOnList()}
                featureButton2={() => closeModal()}
            />
        })
        dispatch(changeModal);
        openModal();

        function onClearGuestSelectOnList() {
            const newList = [...makeRoomOnState.listGuestSelect];
            const saveList = [];

            newList.forEach((guest, indexGuest) => {
                // console.log("indexGuest", indexGuest)
                // console.log("index", index)
                if(indexGuest !== index) {
                    const listIDOnListChange = Object.keys(listChangeGuest.current);
                   // console.log("listIDOnListChange", listIDOnListChange)
                    if(listIDOnListChange.length > 0) {
                        const findIndex = listIDOnListChange.indexOf(guest._id);
                        if(findIndex !== -1) {
                            saveList.push(listChangeGuest.current[listIDOnListChange[findIndex]]);
                        } else saveList.push(guest);
                    } else saveList.push(guest);
                }
            });

            handleDeleteListChangeGuest(data);

            props.onChangeValueMakeRoom(makeRoomOnState.listGuestSelect.length - 2, "indexGuestSelect");
            props.onChangeValueMakeRoom(saveList, "listGuestSelect");
            closeModal()
        }
    }

    function closeModal() {
        dispatch(modalAction.modalReset());
    }

    function openModal() {
        dispatch(modalAction.modalIsOk());
    }

    return (
        <div style={{ flex: 1, width: '50%', }}>
            <h2 style={{ border: '1px solid black' }}>Guest Select List</h2>
            {
                isEdit && <div style={{ ...style.container, position: 'relative' }}>
                <div style={style.children} className='d-flex'>
                    <label style={style.label}>Find Guest Name:</label>
                    <input
                        type="text"
                        style={{ width: '250px', fontSize: '1.4rem' }}
                        value={guestName}
                        maxlength="50"
                        onChange={e => onChangeFindGuest(e.target.value.toLocaleUpperCase())}
                    />
                    <button
                        className="btn btn-primary"
                        onClick={() => onOpenCreateNewGuest()}
                    >Create New</button>
                   {listSuggest.length > 0 && <div style={{
                            backgroundColor: 'white',
                            fontSize: '1.5rem',
                            position: 'absolute',
                            border: '1px solid red',
                            left: '125px',
                            width: '80%',
                            top: "30px",
                            maxHeight: '287px',
                            overflowY: 'auto',
                            zIndex : '99'
                        }}>{renderListSuggest()}</div>
                   }</div>
               {lockQuickSelect &&
               <div style={style.children} 
                    disabled={makeRoomOnState.listGuestSelect.length >= 5}
                    className='d-flex'
                    >
                        <label style={style.label}>Quick Select:</label>
                        <div className='d-flex'>
                            {renderListQuickSelect()}
                        </div>
                </div>}
            </div>}

            {isEdit && <div className="mt-3"></div>}
            
            <MakeRoomGuestSelectList
                handleChangeListChangeGuest={props.handleChangeListChangeGuest}
                onChangeGuestDataAfterEdit= {props.onChangeGuestDataAfterEdit}
                onChangeValueMakeRoom={props.onChangeValueMakeRoom}
                changeViewPassPort={props.changeViewPassPort}
                onChangeGuest={onChangeGuest}
                onClearGuest={onClearGuest}
                key={""}
                isEdit={isEdit}
                style={style}
            />
        </div>
    )
};

export default MakeRoomDataGuest;
