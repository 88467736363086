import React from "react";

function MailComponentPickUpDropOffDetails(props) {

    const { selectTabMain, data, onChangeData} = props;
    return (selectTabMain === "makeRoom" && <>
                    {/* PICK UP */}
                    <div className='d-flex'>
                        <div style={{width:"80px", background:"aqua", marginLeft:'100px'}}>
                            Pick Up:
                        </div>
                        <div style={{border:'1px solid black'}}>
                            <div>
                            <label for="pickUpFlight"  style={{width:"80px"}}>Flight:</label>
                            <input type="text" id="pickUpFlight" name="pickUpFlight" value={data.pickUpFlight}
                                    style={{width:"80px"}}
                                    onChange={e => onChangeData(e.target.name, e.target.value)}/>
                            </div>

                            <div>
                                <label for="pickUpTime"  style={{width:"80px"}}>Time:</label>
                                <input type="text" id="pickUpTime" name="pickUpTime" value={data.pickUpTime}
                                        style={{width:"80px"}}
                                        onChange={e => onChangeData(e.target.name, e.target.value)}/>
                            </div>

                            <div>
                                <label for="pickUpPrice"  style={{width:"80px"}}>Price:</label>
                                <input type="number" id="pickUpPrice" name="pickUpPrice" value={data.pickUpPrice}
                                        style={{width:"80px"}}
                                        onChange={e => onChangeData(e.target.name, e.target.value)}/>
                            </div>

                            <div className="form-check form-switch cursorPointer">
                                <label className="form-check-label mr-3 cursorPointer" for="domestic" style={{width:"120px"}}>Domestic :</label>
                                <input className="form-check-input cursorPointer" type="checkbox" id="domestic" 
                                    name="domestic"
                                    checked={data.domestic}  
                                    onClick={e => onChangeData(e.target.name, !data.domestic)} ></input>
                            </div>
                        </div>
                    </div>

                {/* DROP OFF */}

                    <div className='d-flex mt-3' >
                        <div style={{width:"80px", background:"aqua", marginLeft:'100px'}}>
                            Drop Off :
                        </div>
                        <div style={{border:'1px solid black'}}>

                            <div>
                                <label for="dropOffTime"  style={{width:"80px"}}>Time:</label>
                                <input type="text" id="dropOffTime" name="dropOffTime" value={data.dropOffTime}
                                        style={{width:"80px"}}
                                        onChange={e => onChangeData(e.target.name, e.target.value)}/>
                            </div>

                            <div>
                                <label for="dropOffPrice"  style={{width:"80px"}}>Price:</label>
                                <input type="number" id="dropOffPrice" name="dropOffPrice" value={data.dropOffPrice}
                                        style={{width:"80px"}}
                                        onChange={e => onChangeData(e.target.name, e.target.value)}/>
                            </div>

                        </div>
                    </div>
    </>)
}

export default MailComponentPickUpDropOffDetails;