import React from 'react'; //{ useEffect, useRef, createContext, useState }
import { useSelector, } from 'react-redux';
// import { useParams } from 'react-router-dom';

function ViewPassPortGuest(props) {
    const makeRoomOnState = useSelector(state => state.makeRoom);
    const { listGuestSelect, indexGuestSelect } = makeRoomOnState;

    return (<div style={{ flex: 1, width: '50%', }}>
        <h2 style={{ border: '1px solid black' }}>View Full Pass Port</h2>
        {
            listGuestSelect.length > 0 && <div style={{height:'710px',overflow:'scroll',position:'relative'}}>
            <img
                src={"https://database.azumayareport.com/"+listGuestSelect[indexGuestSelect].passport + "?v=" + (new Date().getTime())}
                alt="passport"
                style={{width:'100%'}}
            ></img>
        </div>
        } 
    </div>)
}

export default ViewPassPortGuest
