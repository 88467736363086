import React, { useEffect, useState } from "react";
import { changeDataToDDMMYYYY, changeDataFullFormat_DDMMYYYY, changeDataFullFormat } from '../../../utils/Date/SupportDate';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function ModalShowRoomAfterCheckType(props) {
    const { type, typeRoomRequest  }  = props.data;
    const [selectRoom, setSelectRoom] = useState("");
    const [listSelectRoom, setListSelectRoom] = useState([]);
    const { branchID } = useParams();
    const dataRoomSuggest = props.data.schedule.listRoomReturn;

    useEffect(() => {
        document.title = 'Check Room Available';

        if(type === "roomAvailability") {
            setSelectRoom(props.data.schedule[0].roomSelect)
        }
    },[]);

    // console.log("type", type)
    // console.log("seelctRoom", selectRoom)

    function renderSchedule() {
        let result=[];
        props.data.schedule.forEach((item, index) => {
            result.push(<tr>
                <td style={{ border: "1px solid" }} onClick={() => setSelectRoom(item.roomSelect)}>
                    <input 
                        type="radio" 
                        name="select" 
                        checked={item.roomSelect === selectRoom}
                        onChange={() => setSelectRoom(item.roomSelect)}
                        ></input>
                </td>
                <td style={{border:"1px solid"}} >{item.roomSelect}</td>
                <td style={{border:"1px solid"}} >{changeDataToDDMMYYYY(item.checkin)}</td>
                <td style={{border:"1px solid"}} >{changeDataToDDMMYYYY(item.checkout)}</td>
            </tr>)
        })

        return result;
    }

    function renderRoomSelect() {
        let result2 = [];

        if (listSelectRoom.length > 0) {
            listSelectRoom.forEach(item => {
                result2.push(<button className={listSelectRoom.includes(item) ? "btn btn-primary mr-1" : "mr-1"}
                    style={{
                        border: listSelectRoom.includes(item) ? "1px solid red" : "",
                        background: listSelectRoom.includes(item) ? "#482979" : "",
                        fontSize: '1.4rem',
                        padding: '4px'
                    }}
                >{item}</button>)
            })
        }

        return result2
    }

    function renderSuggestRoom() {
        var result =[];
        let price;

        result.push(<div className="d-flex">
            <div style={{width:'250px', border:'1px solid', padding:'4px', background:'yellow'}}>Room Type</div>
            <div style={{width:'150px', border:'1px solid', padding:'4px', background:'yellow'}}>Price</div>
            <div style={{ width: '650px', border:'1px solid', padding:'4px', background: 'yellow' }}>Room Availability</div>
        </div>)

        function makeButton(list) {
            let result2 = [];
            if(list.length > 0) {
                list.sort((a, b) => {
                    if (a > b) return -1;
                    else if (a < b) return 1;
                    else return 0;
                }).forEach(item => {
                    result2.push(<button
                        onClick={() => {
                            const newListSelect = [...listSelectRoom];
                            const index = newListSelect.indexOf(item);
                            if (index !== -1) {
                                newListSelect.splice(index, 1);
                            } else {
                                newListSelect.push(item);
                            }

                            // eslint-disable-next-line no-unused-expressions
                            setListSelectRoom(newListSelect);
                        }}
                        style={{
                            border: listSelectRoom.includes(item) ? "1px solid red" : "",
                            background: listSelectRoom.includes(item) ? "#482979" : "",
                            fontSize: '1.4rem',
                            padding: '4px'
                        }}
                        className={listSelectRoom.includes(item) ? "btn btn-primary mr-1" : "mr-1"}
                    >{item}</button>)
                })
            }
            return result2;
        }


        const listRoomType = Object.keys(dataRoomSuggest);
        listRoomType.forEach((key, index) => {
            if (Number(branchID) !== 11) price = Number(key.slice(key.length - 7, 100));
            else price = Math.round(Number(key.slice(key.length - 10, 100)) / 25000 * 100) / 100;

            result.push(<div className="d-flex">
                <div style={{ width: '250px', border: '1px solid', padding: '4px', }}>{key.slice(0, key.length - 7)}</div>
                <div style={{ width: '150px', border: '1px solid', padding: '4px', }}>{price.toLocaleString()}</div>
                <div style={{ width: '650px', border: '1px solid ', padding: '4px', textAlign:'left' }}>{makeButton(dataRoomSuggest[key])}</div>
            </div>)
            // result.push(<option key={index} value={key.slice(key.length - 7, 100)}>{key.slice(0, key.length - 7)}</option>)
        })

        return result;
    }

    return (
         <>
         {
                type === "roomAvailability" ? <div>
                    <div className="ml-3 mr-2">
                        {
                            props.data.schedule && <>
                                <h3>Room type guest request: {typeRoomRequest}</h3>
                                <h3>The Booking is in the [WAITING LIST] area</h3>
                                <h3>Room below was available to the requested schedule: </h3>

                                <table style={{ margin: 'auto' }}>
                                    <tr style={{ border: '1px solid black' }}>
                                        <th style={{ border: '1px solid black', background: 'yellow' }}>Select room</th>
                                        <th style={{ border: '1px solid black', background: 'yellow' }}>Room Number</th>
                                        <th style={{ border: '1px solid black', background: 'yellow', width: '120px' }}>Check In</th>
                                        <th style={{ border: '1px solid black', background: 'yellow', width: '120px' }}>Check Out</th>
                                    </tr>
                                    {renderSchedule()}
                                </table>
                            </>
                        }
                    </div>
                    <div className="mt-5">
                        <button className="btn btn-primary mr-2" style={{ fontSize: '1.4rem' }} onClick={() => props.featureButton1(selectRoom)}>Move Room to Schedule</button>
                        <button className="btn btn-success mr-2" style={{ fontSize: '1.4rem' }} onClick={props.featureButton2}>Room on Waiting List</button>
                        {/* <button className="btn btn-danger" style={{fontSize:'1.1rem'}} onClick={() => props.onClose()}> Cancel</button> */}
                    </div>
                </div>: 
                <div className="ml-3 mr-2">
                        {props.data.schedule.checkin  && <>
                            <div className="d-flex ml-5">
                                <h3 style={{flex:1 }}>Room type guest request: </h3>
                                <div style={{ flex: 3, textAlign:'left' }}>
                                    <h4>* Room Type: {typeRoomRequest}</h4>
                                    <h4>* Check in  : {changeDataFullFormat_DDMMYYYY(props.data.schedule.checkin)}</h4>
                                    <h4>* Check out : {changeDataFullFormat_DDMMYYYY(props.data.schedule.checkout)}</h4>
                                </div>
                            </div>
                            <div className="d-flex ml-5 mb-3">        
                                <h3 className="mr-2">Room with type have not availability please select other room :</h3>
                                <h4 className="d-flex">
                                    <button className="mr-1">Room</button>
                                    <div className="mr-5"> {`=> Not select`}</div>
                                    <button className=" mr-1"
                                        style={{
                                            border: "1px solid red",
                                            background: "#482979",
                                            color: 'white'
                                        }}
                                        >Room</button>
                                    <div> {`=> Selected`}</div>
                                </h4>
                            </div>
                            {renderSuggestRoom()}
                            <div>---</div>
                            <div className="">
                                <div>List Room Select: </div>
                                {renderRoomSelect()}
                            </div>
                            <div className="mt-5">
                                {
                                    listSelectRoom.length > 0 &&
                                    <button className="btn btn-primary mr-2" style={{ fontSize: '1.4rem' }} onClick={() => props.featureButton1(
                                        listSelectRoom, 
                                        listSelectRoom[0], 
                                        // dataRoomSuggest,
                                        changeDataFullFormat(props.data.schedule.checkin),
                                        changeDataFullFormat(props.data.schedule.checkout),
                                        props.data.schedule.nights)
                                       }
                                        >Select Room</button>
                                }
                                <button className="btn btn-danger mr-2" style={{ fontSize: '1.4rem' }} onClick={() => setListSelectRoom([])}>Reset list Select</button>
                                {/* <button className="btn btn-success mr-2" style={{ fontSize: '1.4rem' }} onClick={props.featureButton2}>View Room on Waiting List</button> */}
                                {/* <button className="btn btn-danger" style={{fontSize:'1.1rem'}} onClick={() => props.onClose()}> Cancel</button> */}
                            </div>
                        </>
                      }
                </div>
         }
          
        </>
    )
}

export default ModalShowRoomAfterCheckType;
