import React, { useState, useEffect } from 'react';
import CompanyItemComponent from '../../Company';

function ComponentConfirmMakeHotelInvoice(props) {
    const { data, type, group_id, invoice_id, typeInvoice } = props; //isAddService ,
    const [linkData, setLinkData] = useState("");

   // console.log("data", data.data);
    // console.log("type", type)
    // console.log("typeInvoice ", typeInvoice )


    useEffect(() => {
        setLinkData(group_id)
    }, [group_id])

    //console.log("type", type)
    
    return (<>
        {
            (type === "redoInvoice" || typeInvoice === "PMRQ" || typeInvoice === "Booking") ?
                ""
            :<>
                <div>
                <label>ID Group: </label>
                <input
                    className="mr-2 ml-2"
                    style={{ width: '100px' }}
                    value={linkData}
                    onChange={e => setLinkData(e.target.value)}></input>
                <button className="btn btn-primary"
                    onClick={() => props.onChange(linkData)}>Save</button>
                <button className="btn btn-success ml-2"
                    onClick={props.onReload}>Reload</button>
                </div>
                <span style={{ fontSize: '1rem', color: '#5f497a' }}>* Same ID group can connect other booking</span>
                <label style={{ width:'100%',fontSize: '1.3rem',  }}>Hotel invoice number: {invoice_id}</label>
            </>
        }

        <CompanyItemComponent
            type={type}
            data={data.data}
            search={data.search}
        />

        {
            type === "redoInvoice" ? <div>
                    <button className="btn btn-primary"
                        onClick={props.onReload}>Confirm Redo</button>
                    <button className="btn btn-danger ml-2"
                        onClick={props.onClose}
                    >Cancel</button>
                </div>
                :  <div>
                    <button className="btn btn-primary"
                        onClick={props.onPrintAll}>{typeInvoice === "PMRQ" ? "Make All Schedule" : "Print All Schedule"}</button>
                        {
                            (typeInvoice !== "PMRQ" && typeInvoice !== "Booking") && <button className="btn btn-success ml-2"
                                  //disabled={!isAddService}
                            onClick={props.onOpenAddService} >Add Service</button>
                        }
                    <button className="btn btn-danger ml-2"
                        onClick={props.onClose}
                    >Cancel</button>
                </div>
        }

    </>)
}

export default ComponentConfirmMakeHotelInvoice;
