import { makeHotelInvoiceAction, getType } from '../../actions';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';
import { RATE_VAT } from "../../../constaints/other/otherConstaints";

const initialState = {
    branchInformation: {
        name: "",
        address: "",
        phone: "",
    },
    isLoading: true,
    isCompanyPay: false,
    typeBill: "A",
    saveTypeBill: "A",
    invoice_id: "",
    rateUSD: 0,
    rateYEN: 0,
    name: "",
    company: "",
    address: "",
    tax: "",
    isAutoUpdate: undefined,
    isReloadVAT: true,
    currency: "VND",
    listItem: [
        // {
        //     type: "DELUXE (BIG WINDOWS)",
        //     roomSelect: "",
        //     checkIn: "",
        //     checkOut: "",
        //     night: "",
        //     roomRateUSD: 0,
        //     roomRateVND: 0,
        //     roomRateYEN: 0,
        //     roomRateCOMPANY: 0,
        //     listOptions: [
        //         // {
        //         //     content: "",
        //         //     rate: "",
        //         // },
        //     ]
        // },
    ],
    listService: [
        // {
        //     content: "",
        //     rate: "",
        // }
    ],
    listRequest: [],
    listChange: [],
    vat: 0,
    vatRate: RATE_VAT,
    totalAccommodation: 0,
    sumTotal: 0,
}

const makeHotelInvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(makeHotelInvoiceAction.fillDataMakeHI): {
            return {
                ...state,
                ...action.payload,
                isLoading: false,
            };
        }
        case getType(makeHotelInvoiceAction.changeItemMakeHI): {
            // target =  typeBill 
            const { index, value, target } = action.payload;

            const newState = { ...state }
            const { rateYEN, rateUSD } = newState;
            
            if (!newState.listChange.includes(target)) {
                newState.listChange.push(target);
            }

            function changPriceWithRate(item, priceChange) {
                if (target === "roomRateVND" || target === "roomRateCOMPANY") {
                    let rateCharge = Math.round((priceChange / rateUSD) * 100) / 100
                    item.roomRateCOMPANY = value;
                    item.roomRateVND = value;
                    item.roomRateUSD = rateCharge;
                    item.roomRateYEN = Math.round(priceChange / rateYEN);
                }
                if (target === "roomRateUSD") {  
                    item.roomRateVND = Math.round(priceChange);
                    item.roomRateCOMPANY = Math.round(priceChange / rateUSD);
                    item.roomRateYEN = Math.round(priceChange / rateYEN);
                }
                if (target === "roomRateYEN") {
                    item.roomRateUSD = Math.round(priceChange / (rateUSD / rateYEN));
                    item.roomRateVND = Math.round((priceChange * Number(rateYEN)));
                    item.roomRateCOMPANY = Math.round((priceChange * Number(rateYEN)));
                }
            }

            if (index === undefined) {
                // if(target === "typeBill" && value === "F" ) {
                //     console.log("vo here hehe")
                // }

                for (let i = 0; i < newState.listItem.length; i++) {
                    if (newState.listItem[i].listOptions.length > 0) {
                        for (let j = 0; j < newState.listItem[i].listOptions.length; j++) {
                            if ((value === "VND" || value === "COMPANY") && newState.currency === "USD") newState.listItem[i].listOptions[j].rate = Math.round((changeStringVNDtoNumber(newState.listItem[i].listOptions[j].rate) * rateUSD));
                            if ((value === "VND" || value === "COMPANY") && newState.currency === "YEN") newState.listItem[i].listOptions[j].rate = Math.round((changeStringVNDtoNumber(newState.listItem[i].listOptions[j].rate) * rateYEN));

                            if (value === "USD" && (newState.currency === "VND" || newState.currency === "COMPANY")) newState.listItem[i].listOptions[j].rate = Math.round((changeStringVNDtoNumber(newState.listItem[i].listOptions[j].rate) / rateUSD) * 100) / 100;
                            if (value === "USD" && newState.currency === "YEN") newState.listItem[i].listOptions[j].rate = Math.round((changeStringVNDtoNumber(newState.listItem[i].listOptions[j].rate) / (rateUSD / rateYEN)) * 1000) / 1000;

                            if (value === "YEN" && (newState.currency === "VND" || newState.currency === "COMPANY")) newState.listItem[i].listOptions[j].rate = Math.round((changeStringVNDtoNumber(newState.listItem[i].listOptions[j].rate / rateYEN)));
                            if (value === "YEN" && newState.currency === "USD") newState.listItem[i].listOptions[j].rate = Math.round((changeStringVNDtoNumber(newState.listItem[i].listOptions[j].rate) * (rateUSD / rateYEN)));
                        }
                    }
                }

                if(target === "currency") {
                    if ((value === "VND" || value === "COMPANY") && newState.currency === "USD") newState.vat = Math.round((changeStringVNDtoNumber(newState.vat) * rateUSD));
                    if ((value === "VND" || value === "COMPANY") && newState.currency === "YEN") newState.vat = Math.round((changeStringVNDtoNumber(newState.vat) * rateYEN));

                    if (value === "USD" && (newState.currency === "VND" || newState.currency === "COMPANY")) newState.vat = Math.round((changeStringVNDtoNumber(newState.vat) / rateUSD) * 100) / 100;
                    if (value === "USD" && newState.currency === "YEN") newState.vat = Math.round((changeStringVNDtoNumber(newState.vat) / (rateUSD / rateYEN)) * 1000) / 1000;

                    if (value === "YEN" && (newState.currency === "VND" || newState.currency === "COMPANY")) newState.vat = Math.round((changeStringVNDtoNumber(newState.vat / rateYEN)));
                    if (value === "YEN" && newState.currency === "USD") newState.vat = Math.round((changeStringVNDtoNumber(newState.vat) * (rateUSD / rateYEN)));
                
                    if(value === "COMPANY") newState.isCompanyPay = true;
                    //  else newState.isCompanyPay = false;
                }

                if (newState.listService.length > 0) {
                    for (let j = 0; j < newState.listService.length; j++) {
                        if ((value === "VND" || value === "COMPANY") && newState.currency === "USD") newState.listService[j].rate = Math.round((changeStringVNDtoNumber(newState.listService[j].rate) * rateUSD));
                        if ((value === "VND" || value === "COMPANY") && newState.currency === "YEN") newState.listService[j].rate = Math.round((changeStringVNDtoNumber(newState.listService[j].rate) * rateYEN));

                        if (value === "USD" && (newState.currency === "VND" || newState.currency === "COMPANY")) {
                            newState.listService[j].rate = Math.round((changeStringVNDtoNumber(newState.listService[j].rate) / rateUSD) * 1000) / 1000;
                        }
                        if (value === "USD" && newState.currency === "YEN") newState.listService[j].rate = Math.round((changeStringVNDtoNumber(newState.listService[j].rate) / (rateUSD / rateYEN)) * 1000) / 1000;

                        if (value === "YEN" && (newState.currency === "VND" || newState.currency === "COMPANY")) {
                            newState.listService[j].rate = Math.round((changeStringVNDtoNumber(newState.listService[j].rate) / rateYEN))
                        };
                        if (value === "YEN" && newState.currency === "USD") newState.listService[j].rate = Math.round((changeStringVNDtoNumber(newState.listService[j].rate) * (rateUSD / rateYEN)));
                    }
                }

                if(target === "isAutoUpdate") newState.isReloadVAT = true;
                newState[target] = value;

            } else {
                if (target.includes("roomRate")) {
                    for (let i = 0; i < newState.listItem.length; i++) {
                        // change Room rate
                        let roomRate = changeStringVNDtoNumber(value);
                        if(i === index) changPriceWithRate(newState.listItem[i], roomRate);
                    
                    }
                }
                    newState.listItem[index][target] = value;
            }
            return newState;
        }
        case getType(makeHotelInvoiceAction.changeListOptionMakeHI): {
            const { index, value, target } = action.payload;
            const newState = { ...state }
            const { item, option } = index;

            if (!newState.listChange.includes(target)) {
                newState.listChange.push(target);
            }

            function addRateToListChange() {
                if (!newState.listChange.includes("rate")) {
                    newState.listChange.push("rate");
                }
            }

            if (target === "content") {
                switch (value) {
                    case "No Show": {
                        newState.listItem[item].listOptions[option].rate = newState.listItem[item]["roomRate" + newState.currency];
                        newState.listItem[item].night = 0;
                        newState.listItem[item]["roomRate" + newState.currency] = 0;
                        addRateToListChange();
                        break;
                    }
                    case "Late Cancel 50% charge": case "Late Out 50%": case "Early Check In 50%": case "Short time within 3hours charge 50%": {
                        newState.listItem[item].listOptions[option].rate = newState.listItem[item]["roomRate" + newState.currency] / 2;
                        addRateToListChange();
                        break;
                    }
                    case "Late Cancel 100% charge": case "Late Out 100%": case "Early Check In 100%":  case  "Short time under 3hours charge 100%": {
                        newState.listItem[item].listOptions[option].rate = newState.listItem[item]["roomRate" + newState.currency];
                        addRateToListChange();
                        break;
                    }
                    default: break;
                }
            }

            newState.listItem[item].listOptions[option][target] = value;
            return newState;
        }
        case getType(makeHotelInvoiceAction.deleteListOptionMakeHI): {
            const newState = { ...state }
            const { item, option } = action.payload;

            function addRateToListChange() {
                if (!newState.listChange.includes("rate")) {
                    newState.listChange.push("rate");
                }
            }

            // if have clear No Show => return room rate by No Show
            if(newState.listItem[item].listOptions[option].content === "No Show") {
                addRateToListChange();
                newState.listItem[item]["roomRate"+newState.currency] = newState.listItem[item].listOptions[option].rate;
            }

            newState.listItem[item].listOptions.splice(option, 1);
            return newState;
        }
        case getType(makeHotelInvoiceAction.addListOptionMakeHI): {
            const { index } = action.payload;
            const newState = { ...state }

            //array.splice(start, n)
            if (newState.listItem[index].listOptions.length < 5) {
                newState.listItem[index].listOptions.push({
                    content: "",
                    rate: "",
                });
            }
            return newState;
        }
        case getType(makeHotelInvoiceAction.addListServiceOrRequestMakeHI): {
            const newState = { ...state }
            const { listName } = action.payload;

            //array.splice(start, n)
            if (newState[listName].length < 5) {
                newState[listName].push({
                    content: "",
                    rate: "",
                });
            }
            return newState;
        }
        case getType(makeHotelInvoiceAction.deleteListServiceOrRequestMakeHI): {
           // console.log("action.payload", action.payload)
            const { listName, listDelete } = action.payload
            const newState = { ...state };

            const returnListDelete = [];
            newState[listName].forEach((item, index) => {
              //  console.log("index on renduce", index)
                if (!listDelete.includes(index)) returnListDelete.push(item)
            })

            newState[listName] = returnListDelete;

            return newState;
        }
        case getType(makeHotelInvoiceAction.changeListServiceOrRequestMakeHI): {
            const { index, value, target, listName } = action.payload;
            const newState = { ...state }

            if (!newState.listChange.includes(target)) {
                newState.listChange.push(target);
            }

            if (!newState.listChange.includes("rate")) {
                newState.listChange.push("rate");
            }

            newState[listName][index][target] = value;

            return newState;
        }
        default: return state;
    }
}

export default makeHotelInvoiceReducer;
