import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faPalette, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useParams } from "react-router-dom";

import { unitsAction, modalAction, dataPickUpAction ,glistAction} from '../../redux/actions';
import { coverNameToID, coverBranchIDToFullName } from '../../utils/Branch/SupportBranch';
import { changeDataToDDMMYYYY, changeDataFullFormat } from '../../utils/Date/SupportDate';
import SupportHk1 from "../../components/Glist/SupportHk1";
import SupportHk2 from "../../components/Glist/SupportHk2";
// import { getToday } from '../../utils/Date/SupportDate';

import * as scheduleAPI from '../../api/schedule';
import * as glistAPI from '../../api/glist';

import ModalSupportOption from '../Unit/Modal/modalSupportOption';
import ModalNotify from '../../features/ModalNotify';
import StatusTabGlist from "../../components/Glist/statusTab";
import FooterButton from "../../utils/FooterButton";

import { errorToast, informationToast, } from '../../utils/Toast/index'; //warningToast
import { AZLL_GLIST, AZKM3_GLIST, AZKM2_GLIST, AZHBT1_GLIST, AZDN_GLIST, AZHP_GLIST, AZTVL2_GLIST } from './testData';

import './glist.css';

function GlistContainer() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const dataBranchOnState = useSelector(state => state.dataPickUp.glist);
    const dataGlist = useSelector(state => state.glist)
    const [selectTab, setSelectTab] = useState("statusTab"); //statusTab
    const [showColor, setShowColor] = useState(false);
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;
    const isFullWith = true;
    // (branchID === 15 || branchID === 8)
    // const { day } = useParams();
    const [data, setData] = useState(AZKM3_GLIST);

    // console.log("dataGlist", dataGlist)
    const cssWithBranch = {
        KM2: {
            statusTabArrayTextArea: {
                padding: '20px',
                height: '75px',
                fontSize: '2rem',
                fontWeight:'600'
            },
            statusTabArrayTitle: {
                fontSize: '1.5rem',
                padding: '5px'
            },
            statusTabArrayTotal: {
                fontSize: '1.6rem',
                padding: '5px',
                height: '30px',
                fontWeight:'600'
            },
            statusTabCheckIn: {
                
            },
            statusTabCheckIn1Tab: {
                fontSize: '2rem',
            },
            statusTabCheckIn2Tab: {
                fontSize: '1.8rem',
            },
            statusTabCheckIn3Tab: {
                fontSize: '1.6rem',
            }
        },
        KM3: {
            statusTabArrayTextArea: {
                fontSize: '1.3rem',
            },
            statusTabArrayTitle: {
                fontSize: '1.3rem',
            },
            statusTabArrayTotal: {
                fontSize: '1.2rem',
                padding: '5px',
                height: '25px'
            },
            statusTabCheckIn: {
                
            },
            statusTabCheckIn1Tab: {
                fontSize: '1.3rem',
            },
            statusTabCheckIn2Tab: {
                fontSize: '1.3rem',
            },
            statusTabCheckIn3Tab: {
                fontSize: '1.3rem',
            }
        },
        LL: {
            statusTabArrayTextArea: {
                fontSize: '1.3rem',
            },
            statusTabArrayTitle: {
                fontSize: '1.3rem',
            },
            statusTabArrayTotal: {
                fontSize: '1.2rem',
                padding: '5px',
                height: '25px'
            },
            statusTabCheckIn: {
                
            },
            statusTabCheckIn1Tab: {
                fontSize: '1.3rem',
            },
            statusTabCheckIn2Tab: {
                fontSize: '1.3rem',
            },
            statusTabCheckIn3Tab: {
                fontSize: '1.3rem',
            }
        },
        HBT1: {
            statusTabArrayTextArea: {
                padding: '20px',
                height: '105px',
                fontSize: '2.5rem',
                fontWeight:'600',
                paddingBottom: '30px',
            },
            statusTabArrayTitle: {
                fontSize: '2.5rem',
                padding: '5px'
            },
            statusTabArrayTotal: {
                fontSize: '1.9rem',
                padding: '5px',
                height: '30px',
                fontWeight:'600'
            },
            statusTabCheckIn: {
                
            },
            statusTabCheckIn1Tab: {
                fontSize: '2.5rem',
            },
            statusTabCheckIn2Tab: {
                fontSize: '2.2rem',
            },
            statusTabCheckIn3Tab: {
                fontSize: '2rem',
            }
        },
        DN: {
            statusTabArrayTextArea: {
                padding: '20px',
                height: '85px',
                fontSize: '2rem',
                fontWeight:'600',
                paddingBottom: '30px',
            },
            statusTabArrayTitle: {
                fontSize: '1.5rem',
                padding: '5px'
            },
            statusTabArrayTotal: {
                fontSize: '1.6rem',
                padding: '5px',
                height: '30px',
                fontWeight:'600'
            },
            statusTabCheckIn: {
                
            },
            statusTabCheckIn1Tab: {
                fontSize: '2rem',
            },
            statusTabCheckIn2Tab: {
                fontSize: '1.8rem',
            },
            statusTabCheckIn3Tab: {
                fontSize: '1.6rem',
            }
        },
        HP: {
            statusTabArrayTextArea: {
                padding: '20px',
                height: '85px',
                fontSize: '2rem',
                fontWeight:'600',
                paddingBottom: '30px',
            },
            statusTabArrayTitle: {
                fontSize: '1.5rem',
                padding: '5px'
            },
            statusTabArrayTotal: {
                fontSize: '1.6rem',
                padding: '5px',
                height: '30px',
                fontWeight:'600'
            },
            statusTabCheckIn: {
                
            },
            statusTabCheckIn1Tab: {
                fontSize: '2rem',
            },
            statusTabCheckIn2Tab: {
                fontSize: '1.8rem',
            },
            statusTabCheckIn3Tab: {
                fontSize: '1.6rem',
            }
        },
        TVL2: {
            statusTabArrayTextArea: {
                fontSize: '2rem',
            },
            statusTabArrayTitle: {
                fontSize: '2rem',
            },
            statusTabArrayTotal: {
                fontSize: '1.6rem',
                padding: '5px',
                height: '25px'
            },
            statusTabCheckIn: {
                
            },
            statusTabCheckIn1Tab: {
                fontSize: '1.3rem',
            },
            statusTabCheckIn2Tab: {
                fontSize: '1.3rem',
            },
            statusTabCheckIn3Tab: {
                fontSize: '1.3rem',
            }
        },
    }

    useEffect(() => {
        if (branchID) {
            document.title = `Glist : ${changeDataToDDMMYYYY(dataBranchOnState.day)}`

            if(branchID === 15) setData(AZKM3_GLIST);
            else if (branchID === 3) setData(AZHBT1_GLIST);
            else if (branchID === 7) setData(AZDN_GLIST);
            else if (branchID === 8) setData(AZKM2_GLIST);
            else if (branchID === 12) setData(AZLL_GLIST);
            else if (branchID === 13) setData(AZHP_GLIST);
            else if (branchID === 9) setData(AZTVL2_GLIST);
            loadData(dataBranchOnState.day);
        }
    }, [branchID]);

     window.addEventListener('keydown', function(event) {
        if (event.keyCode === 80 && (event.ctrlKey || event.metaKey) && !event.altKey && (!event.shiftKey || window.chrome || window.opera)) {
            event.preventDefault();
            if (event.stopImmediatePropagation) {
                event.stopImmediatePropagation();
            } else {
                event.stopPropagation();
            }
            return;
            }
    }, true);

    const loadData = async (date,type) => {
        let dataResult;
        let dataFill;
        dispatch(glistAction.resetDataGlist());

        const loadNewData =  async (noteOld, dataOldGlist) => {
            dispatch(unitsAction.showLoading());

            if(branchID !== 15) {

                dataResult = await glistAPI.loadDataGlist(branchID, date, token);

                if(dataResult.data.status === 2) {
                    dataResult = await scheduleAPI.takeGuestList(branchID, date, token);
               //     console.log("dataResult branchID 9:", dataResult)

                    dataFill= dataResult.data;
                } else {
                    dataFill = dataResult.data.data;
                    dataFill.data = dataResult.data.data.content;
                }
            } else {
                dataResult = await scheduleAPI.takeGuestList(branchID, date, token);
                dataFill= dataResult.data;
            }
            
            //setDataServer(dataResult.data.data);
            dataResult.data.noteOld = noteOld.data.data;
            dataResult.data.restaurant = dataOldGlist.data.data.map(item => {
                item.color = "";
                return item;
            });

            dispatch(glistAction.fillDataGlist(dataFill));
            dispatch(unitsAction.hiddenLoading());
        };

        if( type === "reload" ) {
           loadNewData();
        } else {
            dispatch(unitsAction.showLoading());

            let currentDate = new Date(date);

            // Giảm ngày đi 1
            currentDate.setDate(currentDate.getDate() - 1);

          //  const dataResultByDay = await glistAPI.loadDataGlist(branchID, date, token);
            const noteOldData = await glistAPI.loadOldNote(branchID, date, token);
            const dataOldGlist = await scheduleAPI.takeGuestList(branchID, changeDataFullFormat(currentDate).slice(0,10), token);

            loadNewData(noteOldData, dataOldGlist);
            dispatch(unitsAction.hiddenLoading());
        }
    }

    function onChangeData(tab, col, index, target, value,roomData) {
       const newData = [...dataGlist[tab]];

        if(index === -1) {
            const saveData = {
                color:'white',
                roomNumber: roomData,
                checkOut:"",
                note:"",
                type:"normal"
            }
            saveData[target] = value;
            newData.push(saveData);
        } else {
          newData[index][target] = value;
        }
        dispatch(glistAction.changeMakerGList({target: tab , value: newData}))
      //setDataServer(newData);

    }

    function changeSupportHk(listSupport, value, room, targetTable, targetValue) {
        const newData = {...dataGlist[listSupport]};
        if(targetValue === undefined) {
            newData[targetTable][room] = value;
        } else newData[targetTable][room][targetValue] = value;
        

        dispatch(glistAction.changeMakerGList({target: listSupport , value: newData}))
    }


    function onBlurChangeData(target) {
        //dispatch(glistAction.changeMakerGList({value: dataService, target}))
    }

    function renderTabSelect() {
        let result = [];

        const listKeyOnData = Object.keys(data);

        listKeyOnData.forEach(key => {
            if (key !== "title" && Object.keys(data[key]).length > 0) {
                result.push(<h2 style={{
                    // border: '1px solid black',
                    padding: '4px',
                    width: '150px',
                    backgroundColor: selectTab === key ? 'green' : "",
                    cursor: 'pointer',
                    boxShadow: selectTab === key ? '4px 4px gray' : 'none',
                }}
                    onClick={selectTab === key ? ()=>{} : () => setSelectTab(key)}
                >
                    {key.toLocaleUpperCase()}
                </h2>)
            }
        })

        return result;
    }

    function renderColumGlist(tab) {

        let result2 = [];

        function renderChildren(dataRender, col, dataService, tabSelect) {
            // console.log("dataRender", dataRender)
             let result = [];
           //  const dataService = dataGlist.content;
             dataRender.forEach((item, index) => {
                 const { color, type, roomType } = item;
                 if (type === "full") {
                     if(roomType === "X-hidden") {
                         result.push(<div className="col-item" style={{ width: Number(branchID) === 3 ? "280px" : "180px", border:'0.5px solid'}}> </div>)
                     } else {
                         result.push(<div 
                            className="col-item" 
                            style={{ width: Number(branchID) === 3 ? "280px" : "180px",}}>
                         <div style={{
                             padding: '4px',
                             border: '1px solid black',
                             borderBottom: 'none',
                             fontWeight: '600'
                         }}>{item.roomNumber}{(roomType === "guestNumber" || roomType === "date" || roomType === "maker" ) ? " " : roomType }</div>
                         {
                             ((roomType === "X-hidden")
                             // || roomType === "guestNumber"
                             //   || roomType === "maker"|| roomType ==="date"
                               )? <div style={{
                                     fontSize: '3rem',
                                    //  border: '1px solid black',
                                     rowSpan: 3,
                               }}>
                                 {/* {item.name} */}
                             </div> : 
                             (roomType !== "X-hidden" && <><input
                                 value={roomType === "X-hidden" ? "":
                                 roomType === "date" ? changeDataToDDMMYYYY(dataBranchOnState.day).slice(0,5)
                                 :  roomType === "guestNumber" ? onChangeExtendGuest()
                                 :dataGlist[roomType]}

                                 disabled={roomType === "X"}
                                 onChange={e => dispatch(glistAction.changeMakerGList({target:roomType, value:e.target.value}))}

                                 style={{
                                     width: '100%',
                                     height: Number(branchID) === 3 ? '94px' :  Number(branchID) === 15 ? '72%' : '90%',
                                     display: roomType !== "X-hidden" ? 'block' : 'none',
                                     textAlign: 'center',
                                     fontWeight: '600',
                                     fontSize: item.fontSize,
                                     marginBottom: '-8px',
                                     }}></input>
                             {Number(branchID) === 3 && <div className="d-flex" style={{marginTop:'4px'}}><div 
                                  style={{
                                    //  marginTop: '20px', 
                                     width: '70px', 
                                    //  border: 'solid 1px',
                                     fontSize: '1.4rem',
                                     fontWeight: '600',
                                    //  height: '68px',
                                     paddingTop: '20px'
                                 }}
                             >Lễ tân</div>
                             <input
                                 value={dataGlist["maker"]}
                                 // value = "CHECK"
                                 disabled={roomType === "X"}
                                 onChange={e => dispatch(glistAction.changeMakerGList({target: "maker", value:e.target.value}))}

                                 style={{
                                     marginTop: '7px',
                                     width: '100%',
                                     fontSize: '3rem',
                                     textAlign: 'center',
                             }}></input>
                             </div>}

                             </>)
                          }
                         
                     </div>)
                     }

                 } else {
                     const checkRoom = item.roomNumber;
                     const findIndex = dataService.findIndex(({ roomNumber }) => roomNumber === checkRoom); //dataGlist.content
                     const backgroundColor = tab === "restaurant" ? "" :(findIndex === -1 ? "" : dataService[findIndex].color);

                     result.push(<div className="col-item" 
                                       style={{  width: Number(branchID) === 3 ? "280px" : "180px",  }}>
                         <div style={{
                             padding: '4px',
                             fontSize: '1.3rem',
                             border: '1px solid black',
                             borderBottom: 'none',
                             fontWeight: '600'
                         }}>{item.roomNumber} {item.roomType}</div>
                         <div className="d-flex">
                             <textarea
                                 value={findIndex === -1  ? "" : (tab === "restaurant" && (dataService[findIndex].name === "LOCK ROOM"  || dataService[findIndex].name.includes("L/O"))) ? "" : dataService[findIndex].name.toLocaleUpperCase()}
                                 onChange={e => onChangeData(tabSelect, col,findIndex, "name", e.target.value,item.roomNumber)}
                                 onBlur = {() => onBlurChangeData("content")}
                                 style={{
                                     // border: '1px solid black',
                                     position: 'relative',
                                     height: '90px',
                                     maxHeight: '90px',
                                     minHeight: '90px',
                                     width: '100%',
                                     paddingTop: '8px',
                                     textAlign: 'center',
                                     background: "azure",
                                     fontWeight: '600',
                                     fontSize: '2rem',
                                     backgroundColor: backgroundColor, //findIndex === -1 ? "" : dataService[findIndex].color,
                                     marginBottom: '-4px'
                                 }}>

                             </textarea>
                             <div style={{
                                 position: "absolute",
                                 cursor: 'pointer',
                                 color: "blue"
                             }}>
                                {
                                    tab !== "restaurant" && <FontAwesomeIcon
                                    icon={faPalette}
                                    // onClick={() => onConfirmChangeStyleRow(data[index])}
                                    style={{
                                        cursor: 'pointer',
                                        color: "blue"
                                    }}
                                    onClick={() => setShowColor(!showColor)}
                                    className="set-color"
                                />
                                }
                                 
                                 <div style={{
                                     cursor: 'pointer',
                                     display: showColor ? "block" : "none",
                                 }}
                                 >
                                     <div
                                         style={{ backgroundColor: "#92D050", padding: '2px' }}
                                         onClick={() => {
                                             onChangeData(tabSelect, col, findIndex, "color", "#92D050",item.roomNumber);
                                         }}
                                     >Green</div>
                                     <div style={{ backgroundColor: "yellow", padding: '2px' }}
                                         onClick={() => {
                                             onChangeData(tabSelect, col, findIndex, "color", "yellow",item.roomNumber);
                                         }}
                                     >Yellow</div>
                                     <div style={{ backgroundColor: "white", padding: '2px' }}
                                         onClick={() => {
                                             onChangeData(tabSelect, col, findIndex, "color", "white",item.roomNumber);
                                         }}
                                     >White</div>
                                 </div>
                             </div>
                         </div>
                         <input
                             value={findIndex === -1 ? "" : dataService[findIndex].checkOut}
                             onChange={e => onChangeData(tabSelect, col,findIndex, "checkOut", e.target.value,item.roomNumber)}
                             style={{
                                 // border: '0.1px solid black',
                                //  borderBottom: '1px solid black',
                                 textAlign: 'center',
                                 width: '100%',
                                 height: '30px',
                                 border: 'unset',
                                 borderRight: '1px solid black',
                                 borderLeft: index === 0 ? '1px solid black' : 'unset',
                                 fontSize: '1.5rem',
                                 fontWeight: '600',
                                 backgroundColor: backgroundColor,
                             }}>
                         </input>
                         <input
                             value={findIndex === -1 ? "" : dataService[findIndex].note}
                             onChange={e => onChangeData(tabSelect, col,findIndex, "note", e.target.value,item.roomNumber)}
                             style={{
                                //  border: '0.1px solid black',
                                 height: '30px',
                                 border: 'unset',
                                 borderBottom: '1px solid black',
                                 borderLeft: index === 0 ? '1px solid black' : 'unset',
                                 borderRight: '1px solid black',
                                 textAlign: 'center', width: '100%',
                                 fontSize: '1.5rem',
                                 fontWeight: '600',
                                 backgroundColor: backgroundColor,
                             }}>
                         </input>
                     </div >)
                 }

             })
             return result;
         }

        if (tab.includes("glist1")) {
            const keyOnGlist1 = Object.keys(data[tab]);
            keyOnGlist1.forEach(col => {
                result2.push(<div className="d-flex glist-main">
                    {renderChildren(data[tab][col], col, dataGlist.content,"content")}
                </div>)
            })
        } 
        if (tab.includes("glist2")) {
            const keyOnGlist1 = Object.keys(data[tab]);
            keyOnGlist1.forEach(col => {
                result2.push(<div className="d-flex glist-main">
                    {renderChildren(data[tab][col], col, dataGlist.content,"content")}
                </div>)
            })
        } else if (tab === "statusTab") {
            result2.push(<StatusTabGlist
                        updateStatusTabArray={updateStatusTabArray}
                        onConfirmReload={onConfirmReload}
                        isFullWith={isFullWith}
                        cssWithBranch={cssWithBranch[coverNameToID(Number(branchID))]}
                    //  onReload={onReload}
                    />)
        } else if (tab === "other1") {
            result2.push(
                <ModalSupportOption
                    type={5}
                    title="Show guest information here..."
                    // button1="Reset"
                    // button2="Close"
                    itemOption={{
                        type:"glistCheckIn",
                        data: dataGlist.listGuestCheckIn
                    }}
                    // featureButton1={closeModal}
                    // featureButton2={closeModal}
                />
            )   
        } else if (tab === "other2" && branchID === 13) {
            result2.push(<div>
                <h1>Chia phòng cho buồng</h1>
                <SupportHk1 changeSupportHk = {changeSupportHk} />
            </div>)
        } else if (tab === "other2" && branchID === 9) {
            result2.push(<div>
                <h1 className="no-print">Chia phòng cho buồng</h1>
                <SupportHk2 
                    changeSupportHk = {changeSupportHk} />
            </div>)
        } else if (tab === "restaurant") {
            const keyOnGlist1 = Object.keys(data[tab]);
            keyOnGlist1.forEach(col => {
                result2.push(<div className="d-flex">
                    {renderChildren(data[tab][col], col, dataGlist.restaurant,"restaurant")}
                </div>)
            })
        }
        return result2;
    }

    function onReload() {
        const loadData = async () => {
            dispatch(unitsAction.showLoading());
            const dataResult = await scheduleAPI.takeGuestList(branchID, dataBranchOnState.day, token); 
            let currentDate = new Date(dataBranchOnState.day);

            // Giảm ngày đi 1
            currentDate.setDate(currentDate.getDate() - 1);

          //  const dataResultByDay = await glistAPI.loadDataGlist(branchID, date, token);
            const noteOldData = await glistAPI.loadOldNote(branchID, dataBranchOnState.day, token);
            const dataOldGlist = await scheduleAPI.takeGuestList(branchID, changeDataFullFormat(currentDate).slice(0,10), token);

            dataResult.data.noteOld = noteOldData.data.data;
            dataResult.data.restaurant = dataOldGlist.data.data.map(item => {
                item.color = "";
                return item;
            });

            dispatch(glistAction.fillDataGlist(dataResult.data));
            dispatch(unitsAction.hiddenLoading());
        } 

        loadData();

        // loadData(dataBranchOnState.day, "reload")

      //  dispatch(glistAction.reloadDataGlist({data: dataGlist.content,day: dataBranchOnState.day, branchID: Number(branchID) }));
    }

    function onChangeDataSelect(value,target) {
        const newData = {...dataBranchOnState};
        newData[target] = value;
        loadData(value)
        dispatch(dataPickUpAction.changeDataGlist(newData));
    }

    function updateStatusTabArray(target,value) {
      dispatch(glistAction.changeItemStatusGlist({target,value}));
    }

    function onChangeExtendGuest() {
        let result = 0;
     //   console.log("dataService",dataService)

     //a.split("&").length - 1
        if(selectTab ==="glist1") {
            dataGlist.content.forEach(item => {
                if(item.type !=="full" && item.checkOut !==""  && item.checkOut !== undefined && item.name !== "LOCK ROOM") result ++
                if(item.type !=="full" && item.name.includes("&"))  result = result + (item.name.split("&").length - 1)
            })
        } else if (selectTab ==="restaurant") {
            dataGlist.restaurant.forEach(item => {
                if(item.type !=="full" && item.checkOut !==""  && item.checkOut !== undefined) result ++
                if(item.type !=="full" && item.name.includes("&")) result = result + (item.name.split("&").length - 1)
            })
        }

        return result;
    }

    function onConfirmReload() {
        dispatch(modalAction.modalChange({
            type: "centered",
            title: "Confirm Reset Data Glist.",
            size: 'xs',
            content: <ModalSupportOption
                type={88}
           //     featureSupport1={onDelete}
                title="Automatic make status tab with Glist ?"
                button1="Reload"
                button2="Close"
                itemOption={{
                    color:"red",
                    title:"Cập nhật lại [Tình trạng] theo [Glist]?"}}
                featureButton1={()=>{
                    onReload();
                    closeModal();
                }}
                featureButton2={closeModal}
            //    itemOption={{ schedule: makeRoomOnState.listSchedule, code: codeDelete }}
            />
        }));
        openModal();
    }

    function onConfirmResetGlist() {
        dispatch(modalAction.modalChange({
            type: "centered",
            title: "Confirm Reset Data Glist.",
            size: 'xs',
            content: <ModalSupportOption
                type={88}
           //     featureSupport1={onDelete}
                title="When click reset change all data with server"
                button1="Reset"
                button2="Close"
                itemOption={{
                    color:"red",
                    title:"Click sẽ xóa data đang có và load lại dữ liệu theo server database"}}
                featureButton1={()=>{
                    loadData(dataBranchOnState.day,"reload");
                    closeModal();
                }}
                featureButton2={closeModal}
            //    itemOption={{ schedule: makeRoomOnState.listSchedule, code: codeDelete }}
            />
        }));
        openModal();
    }

    function onSaveGlist(typeSave) {
        // console.log("typeSave", typeSave)
        // console.log("tdataGlist.maker", dataGlist.maker)

        const callApiGlist = async () => {
            try {
                let dataResult;
                if(selectTab === "statusTab") { 
                  //  console.log("dataGlist", dataGlist)
                    dataResult = await glistAPI.makeStatus(branchID,dataBranchOnState.day,dataGlist,token);
                   const dataResult2 = await glistAPI.updateGlist(branchID,dataBranchOnState.day,{
                        ...dataGlist,
                        guestNumber: onChangeExtendGuest()
                     },token);

                  //   console.log("dataResult2", dataResult2)
                  //   window.open("/glist_file/"+dataResult.data.link, "_blank");
                } else {
                    if(selectTab === "restaurant") {
                        dataResult = await glistAPI.updateGlist(branchID,dataBranchOnState.day,{
                            content: dataGlist.restaurant,
                            maker: dataGlist.maker,
                            guestNumber: onChangeExtendGuest()
                         },token)
                    } else {
                        dataResult = await glistAPI.updateGlist(branchID,dataBranchOnState.day,{
                            ...dataGlist,
                            guestNumber: onChangeExtendGuest()
                         },token);
                    }
                }

                 if(dataResult) {
                    if(typeSave === "print") {
                        if(selectTab === "statusTab" && (branchID === 7 || branchID === 13)) {
                            window.open("/glist_file/"+String(dataResult.data.link).replace("Glist","Status"), "_blank");
                        } else  window.open("/glist_file/"+dataResult.data.link, "_blank");
                    }
                 }
                 
                 if(dataResult) {
                    if(dataResult.data.status === 1) return informationToast(""+dataResult.data.msg);
                    else return errorToast("Error when update Glist")
                 } else  return errorToast("Error when update Glist")
            } catch (error) {
                return errorToast("Error when update Glist")
            }
        }

        if(dataGlist.maker === "" && selectTab !== "statusTab" && selectTab !== "other2") {
            dispatch(modalAction.modalChange({
                type: "centered",
                title: "System information.",
                size: 'xs',
                content: <ModalSupportOption
                    type={88}
               //     featureSupport1={onDelete}
                    title="Please input maker."
                    button1="Close"
                    // button2="Close"
                    itemOption={{
                        color:"#482979",
                        title:"Xin hãy thêm tên lễ tân."}}
                    featureButton1={()=>{closeModal();}}
                    featureButton2={closeModal}
                //    itemOption={{ schedule: makeRoomOnState.listSchedule, code: codeDelete }}
                />
            }));
            openModal();
        } else {
            if(selectTab === "statusTab" && typeSave !=="save" && branchID !== 7 && branchID !== 13 ) {
                window.scrollTo(0, 0);
                window.print();
            } else if (selectTab ==="other2") {
                window.scrollTo(0, 0);
                window.print();              
            } else {
                callApiGlist("Glist");
            }    
        }
    }

    function closeModal() { dispatch(modalAction.modalReset()); }
    function openModal() { dispatch(modalAction.modalIsOk()); }

    return (
        <div className="glist">
            <ModalNotify closeModal={closeModal}/>
            <div className="d-flex glist-title no-print">
                <h1 className="">AZUMAYA {coverBranchIDToFullName(Number(branchID)).toLocaleUpperCase()} - GLIST :</h1>
                {/* <div>{"<"}</div> */}
                <input
                    type="date"
                    className="ml-2 no-border"
                    value={dataBranchOnState.day}
                    style={{ fontSize: '1.3rem' }}
                    onChange={e => onChangeDataSelect(e.target.value, "day")}
                ></input>   
                {/* <div>{">"}</div> */}
                <div className="no-print btn btn-primary ml-5"
                onClick={()=> {window.open("https://www.youtube.com/embed/zz-i0YjBlbc?si=c-Gr6OtF5XYBhKpQ")}}>Video tutorial</div>
            </div>
            {data && <div className="ml-5 glist-content">
                    <div className="d-flex mt-2 no-print">
                        {renderTabSelect()}
                    </div>
                    <div className="d-flex" >
                        <div className="content mt-2">
                            {renderColumGlist(selectTab)}
                        </div>
                    </div>
            </div>}

            {
                !dataGlist.isLoading && <FooterButton
                    button1={"SAVE DATA"}
                    iconBtn1="faSave"
                    button2={"PRINT / SAVE PDF"}
                    iconBtn2="faPrint"
                    button4={"RESET DATA"}
                    iconBtn4="faBan"
                //  width="1150px"
                    onClickButton1={() => onSaveGlist("save")}
                    onClickButton2={() => onSaveGlist("print")}
                    onClickButton4={onConfirmResetGlist}
            />
            }
    
    <style>{branchID === 15 || branchID === 12 || branchID === 7 || branchID === 13  ? `
          @media print {
            /* CSS for print */
            @page {
                size: A4 landscape;
                margin-top: 10px;
                overflow: hidden;
            }

            .printable {
                page-break-inside: avoid; 
            }
        
            .no-border {
                border: none;
            }

            .glist {
                margin-top: 24px;
            }

            .glist-content {
                margin-left: 0px;
            }

            .glist .content {
                margin-bottom: 0px;
            }

            .makeRoom-checkInOut_input {
                background-color: white;
            }
          }
        ` 
        : ` @media print {
            /* CSS for print */
            @page {
                size: A4;
                margin-top: 10px;
                overflow: hidden;
            }

            .printable {
                page-break-inside: avoid; 
            }
        
            .no-border {
                border: none;
            }

            .glist {
                margin-top: 24px;
            }

            .glist-content {
                margin-left: 0px;
            }

            .glist .content {
                margin-bottom: 0px;
            }

            .makeRoom-checkInOut_input {
                background-color: white;
            }

            textarea {
                overflow: hidden;
            }
          }`}

             </style>

        </div>
    )
}

export default GlistContainer;
