import React, { useRef, useEffect} from 'react';
import { useState } from 'react';
import { makeRowTextMail, makeRoomList, makeSuggestList, branchNumberToNameWithLang, makeNameBookerWithLang , makeNumberLabel } from './formatMail/formatMail';
import { getToday, changeDataToYYYY_MMMM_DD, changeDataToYYYY_MMMM_DDth, changeDataJpToMM_DD, changeDateToString} from '../../utils/Date/SupportDate';
import { changeStringVNDtoNumber } from '../../utils/Currency/SupportCurrency';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { takeListRoomWithBranch } from '../../api/branch';
import { takeData, updateBooker } from '../../api/booker';

import './mailContainer.css';
// import { faHandPointDown, faEdit } from '@fortawesome/free-regular-svg-icons';
// 
import { informationToast, errorToast, warningToast, } from '../../utils/Toast/index'; // warningToast
import MailComponentRoomSelect from '../../components/MailComponents/MailComponentRoomSelect';
import MailComponentPickUpDropOffDetails from '../../components/MailComponents/MailComponentPickUpDropOffDetails';
import { on } from 'nodemailer/lib/xoauth2';
import MailComponentDateCharge from '../../components/MailComponents/MailComponentDateCharge';

function MailContainer(props) {
    const { type } = props;
    const [selectTabMain, setSelectTabMain] = useState("suggestRoom"); //makeRoom
    const [lang, setLang] = useState("eng");
    // const [isCopyBlocked, setIsCopyBlocked] = useState(true);

    const [copied, setCopied] = useState(false);
    const contentRef = useRef(null);

    const [listRoomData, setListRoomData] = useState({
        listRoomType: [],
        objectRoomType: {},
        objectRoomTypeWithRoomNumber: {}
    });

   

    const [data, setData] = useState({
        booker:"",
        guestName: "",
        email: "",
        lateReply: true,
        askTimeCheckIn: false,
        gender: true,
        guestGender: false,
        companyName:"",
        checkIn1: new Date(),
        dateCancelFree1: subtractDays(getToday(), 2),
        checkIn2: "",
        dateCancelFree2: "",
        checkIn3: "",
        dateCancelFree3: "",
        discount: "5%",
        askVAT: false,
        optionCase:"1 Option",
        option1: "",
        option2: "",
        option3: "",
        priceOption1: "",
        priceOption2: "",
        priceOption3: "",

        vatCase: "not",
        vatCompanyName: "",
        vatAddress: "",
        vatPhone: "",
        vatTax: "",
        vatEmail: "",

        vatAddressCompany: "",

        isLateCheckOut : false,
        askOnigiri: false,
        introPickDropCar: false,
        introLateOut: false,
        pickUpTime: "",
        pickUpFlight: "",
        pickUpPrice: "",
        domestic: true,
        dropOffTime: "",
        dropOffPrice: "",
        rcName:"",

    });

   // console.log("changeDataToYYYY_MMMM_DDth(subtractDays(getToday()), 2)", changeDataToYYYY_MMMM_DDth(subtractDays(getToday(),2)))
    const auth = useSelector(state => state.auth);
    const { role, branchID } = auth.user;

    const makeRoomOnState = useSelector(state => state.makeRoom);
    const makeRoomService = useSelector(state => state.makeRoomOther);

    // Ngăn chặn hành động sao chép
    // useEffect(() => {
    //     const handleCopy = (e) => {
    //       e.preventDefault(); // Ngăn chặn hành động sao chép
    //       //alert("Copying is disabled on this page.");
    //       warningToast("Please use Copy Mail button !!(xin bấm nút Copy Mail !!).")
    //     };
    
    //     document.addEventListener('copy', handleCopy);
    
    //     return () => {
    //       document.removeEventListener('copy', handleCopy);
    //     };
    //   }, []);

    // useEffect(() => {
    //     const handleCopy = (e) => {
    //       if (isCopyBlocked) {
    //         e.preventDefault(); // Block copy default
    //         alert("Copying is disabled on this page.");
    //       }
    //     };
    
    //     document.addEventListener('copy', handleCopy);
    
    //     return () => {
    //       document.removeEventListener('copy', handleCopy);
    //     };
    //   }, [isCopyBlocked]); // Check copy

    useEffect(() => {
        let objectRoomTypeWithRoomNumber;
        let objectRoomType;
        let booker = "";
        // let gender = true;
        let companyName = "";
        let lang= "";
       // let companyName = "";

        function extractEmails(text) {
            // Sử dụng regex để tìm các email trong văn bản
            const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
            const emails = text.match(emailPattern);
            return emails || []; // Trả về danh sách email hoặc mảng rỗng nếu không tìm thấy
          }
          
        //   console.log("email guest:", extractEmails(makeRoomOnState.request2))

        const loadRoomType = async () =>{
            const result = await takeListRoomWithBranch(branchID,"token");
            // console.log("result", result)
            setListRoomData({
                listRoomType: result.data.listRoomType,
                objectRoomType: result.data.objectRoomType,
                objectRoomTypeWithRoomNumber : result.data.objectRoomTypeWithRoomNumber
            })

            objectRoomTypeWithRoomNumber = result.data.objectRoomTypeWithRoomNumber;
            objectRoomType = result.data.objectRoomType;

            // console.log("objectRoomTypeWithRoomNumber", objectRoomTypeWithRoomNumber)
            let mailBooker;

            if(makeRoomOnState.mail === "it@azumayavietnam.com") {
                mailBooker = extractEmails(makeRoomOnState.request2);
                if(mailBooker.length > 0) mailBooker = mailBooker[0];
                else mailBooker = "";
            } else {
                mailBooker = makeRoomOnState.mail
            }
            
            if(mailBooker !== "") {
                const dataBooker = await takeData(mailBooker, "");

                if(dataBooker) {
                    console.log("dataBooker",dataBooker)
                    if(dataBooker.data.status === 1) {
                        booker = dataBooker.data.data.name;
                        companyName = dataBooker.data.data.company_name;
                        lang = dataBooker.data.data.language;
                        setLang(lang)
                    }
                }
            }

            setData({
                ...data,
                option1: result.data.listRoomType[0],
                option2: result.data.listRoomType[0],
                option3: result.data.listRoomType[0],
                priceOption1: result.data.objectRoomType["" + result.data.listRoomType[0]],
                priceOption2: result.data.objectRoomType["" + result.data.listRoomType[0]],
                priceOption3: result.data.objectRoomType["" + result.data.listRoomType[0]],
            })
            
            if(type) {
                let guestName = "";
                let guestGender = true;
                let checkIn1 = new Date();
                let pickUpTime = "";
                let pickUpFlight = "";
                let pickUpPrice = "";
                let dropOffTime = "";
                let dropOffPrice = "";
                let rcName = "";
        
                let option1 = "";
                let option2 = "";
                let option3 = "";
                let optionCase = "1 Option";
                let priceOption1 = "";
                let priceOption2 = "";
                let priceOption3 = "";

                setSelectTabMain(type)

                const checkGuest = makeRoomOnState.listGuestSelect;
                if(checkGuest.length > 0) {
                    if(checkGuest.length === 1) {
                        guestName =  checkGuest[0].name;
                        guestGender = checkGuest[0].gender
                    }
                   // check company 
                    if(checkGuest[0].contract) {
                        companyName = checkGuest[0].company_name;
                    }
                }
        
                const listSchedule = makeRoomOnState.listSchedule;
                checkIn1 = new Date(listSchedule[0].checkin);
        
                // fill option
                if(listSchedule.length === 1) {
                    optionCase = "1 Option";

                    if(listSchedule[0].roomSelect !== "") {
                        if(listSchedule[0].roomSelect in objectRoomTypeWithRoomNumber) option1 = objectRoomTypeWithRoomNumber[listSchedule[0].roomSelect];
                        priceOption1 = objectRoomType[option1];
                    }
                } else if(listSchedule.length === 2) {
                    optionCase = "2 Option";
                    option1 = objectRoomTypeWithRoomNumber[listSchedule[0].roomSelect];
                    option2 = objectRoomTypeWithRoomNumber[listSchedule[1].roomSelect];

                    priceOption1 = objectRoomType[option1];
                    priceOption2 = objectRoomType[option2];
                    
                } else if(listSchedule.length <= 3) {
                    option1 = objectRoomTypeWithRoomNumber[listSchedule[0].roomSelect];
                    option2 = objectRoomTypeWithRoomNumber[listSchedule[1].roomSelect];
                    option3 = objectRoomTypeWithRoomNumber[listSchedule[2].roomSelect];

                    const checkIn1 = listSchedule[0].checkin;
                    const checkIn2 = listSchedule[1].checkin;
                    const checkIn3 = listSchedule[2].checkin;

                    const checkOut1 = listSchedule[0].checkout;
                    const checkOut2 = listSchedule[1].checkout;
                    const checkOut3 = listSchedule[2].checkout;

                    if(checkIn1 === checkIn2 === checkIn3 && checkOut1 === checkOut2 === checkOut3
                        && option1 === option2 === option3) {
                        optionCase = "1 Option";
                        priceOption1 = objectRoomType[option1];
                    } else {
                        optionCase = "3 Option";
                        priceOption1 = objectRoomType[option1];
                        priceOption2 = objectRoomType[option1];
                        priceOption3 = objectRoomType[option1];
                    }
                }

                // Check PickUp
                if(makeRoomService.pickUp.flight !== "") {
                    pickUpTime =  makeRoomService.pickUp.time;
                    pickUpFlight = makeRoomService.pickUp.flight;
                    pickUpPrice = changeStringVNDtoNumber(makeRoomService.pickUp.price);
                }
        
                // check DropOff
                if(makeRoomService.dropOff.time !== "") {
                    dropOffTime =  makeRoomService.dropOff.time;
                    // dropOffFlight = makeRoomService.dropOff.flight;
                    dropOffPrice = changeStringVNDtoNumber(makeRoomService.dropOff.price);
                }
        
                rcName = makeRoomService.maker;
        
                setData({
                    ... data,
                    booker,
                    email : mailBooker,
                    guestName: guestName,
                    lateReply: true,
                    askTimeCheckIn: false,
                    gender: true,
                    guestGender,
                    companyName,
                    checkIn1: checkIn1,
                    dateCancelFree1: subtractDays(getToday(), 2),
                    checkIn2: "",
                    dateCancelFree2: "",
                    checkIn3: "",
                    dateCancelFree3: "",
                    discount: "5%",
                    askVAT: false,
                    vatCase: "not",
                    vatCompanyName: "",
                    vatAddress: "",
                    vatPhone: "",
                    vatTax: "",
                    vatEmail: "",

                    optionCase,
                    option1,
                    priceOption1,
                    option2,
                    priceOption2,
                    option3,
                    priceOption3,
            
                    vatAddressCompany: "",
            
                    isLateCheckOut : false,
                    askOnigiri: false,
                    introPickDropCar: false,
                    introLateOut: false,
                    pickUpTime,
                    pickUpFlight,
                    pickUpPrice,
                    domestic: true,
                    dropOffTime,
                    dropOffPrice,
                    rcName
                })
            }
        }
        // call api take list Room.
        if(branchID) {
           loadRoomType();
        }
        // change title
        document.title = 'Mail Format Web';
      }, [branchID]);

    const copyHtmlToClipboard = () => {
        // setIsCopyBlocked(false); 
        const callApiUpdateBooker = async () => {
            await updateBooker({
                "name": data.booker,
                "gender": data.gender,
                "email": data.email,
                "language": lang,
                "company_name": data.companyName
            },"token")
        }
        if(data.booker === "") {
            errorToast("Please input booker name")
        } else {
          //  if(data.email !== "") callApiUpdateBooker();

            if (contentRef.current) {
                const range = document.createRange();
                range.selectNode(contentRef.current);
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                try {
                    const successful = document.execCommand("copy");
                    if (successful) {
                    setCopied(true);
                    } else {
                    setCopied(false);
                    }
                } catch (err) {
                    console.error("Sao chép thất bại", err);
                }
                selection.removeAllRanges();
                }
        
            informationToast("Success copy mail!");
        // console.log("isCopyBlocked", isCopyBlocked)
        }
    };

    function subtractDays(date, days) {
        // Tạo một đối tượng Date mới từ ngày nhập vào
        if(date !== "") {
            let result = typeof date === "object" ? date : new Date(date);
            // Giảm số ngày đi
            result.setDate(result.getDate() - days);
            return result;
        } else return ""
    }

    function renderLang() {
        // selectTabMain
        const content = [];
    
        content.push(<div className='main-content-lang' style={{ width: !type ? "100%" : "unset" }}>
            <div className="radio">
                <label className="language">
                    <input type="radio" name="language" className=""
                        onClick={() => setLang("eng")}
                        id="english" ng-click="translate('en')" checked={lang === "eng"} />
                    <strong> English </strong>
                </label>

                <label className="language ml-3">
                    <input type="radio" name="language" className=""
                        checked={lang === "jap"}
                        onClick={() => setLang("jap")}
                        ng-click="translate('jp')" id="japanese" />
                    <strong> Japanese </strong>
                </label>
                <label className="language ml-3">
                    <input type="radio" name="language" className=""
                        checked={lang === "vni"}
                        onClick={() => setLang("vni")}
                        ng-click="translate('vn')" id="vietnamese" />
                    <strong> Vietnamese </strong>
                </label>  
            </div>
        </div>)

      return content;
    }

    function onChangeData(target, value, option) {
        const newData = {...data};
        if(target === "checkIn1") {
            newData[target] = value;
            const changaDate = new Date(value);
            newData.dateCancelFree1 = subtractDays(changaDate, 2);
     
        } else if(target === "checkIn2") {
            newData[target] = value;
            if(value === "") {
                newData.dateCancelFree2 = "";
            } else {
                const changaDate = new Date(value);
                newData.dateCancelFree2 = subtractDays(changaDate, 2);
            }
        } else if(target === "checkIn3") {
            newData[target] = value;
            if(value === "") {
                newData.dateCancelFree3 = "";
            } else {
                const changaDate = new Date(value);
                newData.dateCancelFree3 = subtractDays(changaDate, 2);
            }
        } else if(target === "dateCancelFree1") {
            newData[target] = value;
        } else if(target.includes("option")) {

            newData[target] = value;
            newData["price"+capitalizeFirstLetter(target)] = listRoomData.objectRoomType[value];
        } else {
            newData[target] = value;
        }
        setData(newData)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function renderSelectOption() {
        return <div className="" style={{width:"35%", marginTop: '70px', fontSize:'1.2rem'}}>
            <h5><i>* Check data booker on mail : {data.email}</i></h5>
            <div for="company-name" style={{fontWeight:'600', background:'yellow'}}>Booker / Guest Information:</div>

            <div className={!type ? 'd-flex ml-5' : 'd-flex'}>
                <label for="booker" style={{width: !type ? "120px" : "80px", textAlign:'left'}}>Booker:</label>
                <input type="text" id="booker" name="booker" value={data.booker}
                        style={{width:"220px"}}
                        onChange={e => onChangeData(e.target.name, e.target.value)}/>

                <div className="form-check form-switch cursorPointer" style={{width:'180px'}}>
                    <label className="form-check-label mr-5 cursorPointer" for="gender" style={{width:'3px'}}>Male:</label>
                    <input className="form-check-input ml-2 cursorPointer" type="checkbox" id="gender" 
                            name="gender"
                            style={{width:'21px', paddingLeft:'11px'}}
                            checked={data.gender}  
                            onClick={e => onChangeData(e.target.name, !data.gender)} ></input>
                    <label className="form-check-label mr-5 cursorPointer" for="gender" style={{paddingLeft:'30px'}}>Female</label>
                </div>
            </div>

            {
                 selectTabMain === "makeRoom" && <div className={!type ? 'd-flex ml-5' : 'd-flex'}>
                 <label for="guestName" style={{width: !type ? "120px" : "80px", textAlign:'left'}}>Guest:</label>
                 <input type="text" id="guestName" name="guestName" value={data.guestName}
                         style={{width:"220px"}}
                         onChange={e => onChangeData(e.target.name, e.target.value)}/>
 
                 <div className="form-check form-switch cursorPointer" style={{width:'180px'}}>
                     <label className="form-check-label mr-5 cursorPointer" for="guestGender" style={{width:'3px'}}>Male:</label>
                     <input className="form-check-input ml-2 cursorPointer" type="checkbox" id="guestGender" 
                             name="guestGender"
                             style={{width:'21px', paddingLeft:'11px'}}
                             checked={data.guestGender}  
                             onClick={e => onChangeData(e.target.name, !data.guestGender)} ></input>
                     <label className="form-check-label mr-5 cursorPointer" for="guestGender" style={{paddingLeft:'30px'}}>Female</label>
                 </div>
 
               </div>
            }
            
            <div className={!type ? 'd-flex ml-5 mb-3' : 'd-flex'}>
                <label for="companyName" style={{width: !type ? "120px" : "65px", textAlign:'left'}}>Company:</label>
                {/* <label for="companyName" style={{width:"120px"}}>Company:</label> */}
                <input type="text" id="companyName" name="companyName" value={data.companyName}
                        style={{width:"220px"}}
                        onChange={e => onChangeData(e.target.name, e.target.value)}/>

            {data.companyName !== "" && !type && 
               <div>
                    <label for="discount" style={{width:"80px"}}>Discount:</label>         
                    <select id="option" name="option"
                        style={{width:"80px"}}
                        onChange={e => onChangeData("discount", e.target.value)}>
                        <option value="5%">5% OFF</option>
                        <option value="laundry">Free laundry</option>
                        <option value="10%">10% OFF + laundry</option>
                    </select>
                </div>
            }
            </div>

            {
               data.companyName !== "" && type && 
                <div className={'d-flex'}>
                     <label for="discount" style={{width: !type ? "120px" : "65px", textAlign:'left'}}>Discount:</label>     
                     <select id="option" name="option"
                         style={{width:"80px"}}
                         onChange={e => onChangeData("discount", e.target.value)}>
                         <option value="5%">5% OFF</option>
                         <option value="laundry">Free laundry</option>
                         <option value="10%">10% OFF + laundry</option>
                     </select>
                 </div>
            }

            <MailComponentRoomSelect 
                    selectTabMain  = {selectTabMain}
                    onChangeData = {onChangeData}
                    listRoomData = {listRoomData}
                    type={type}
                    data = {data}
                />

            <MailComponentDateCharge
                onChangeData = {onChangeData}
                data = {data}
                type={type}
                selectTabMain  = {selectTabMain}
                />

            <div className='mt-3'>
               <div for="company-name" style={{fontWeight:'600', background:'yellow'}}>Option Area:</div>

                <div className="form-check form-switch cursorPointer" >
                    <label className="form-check-label mr-5 cursorPointer" for="lateReply" style={{width:"120px"}}>Late reply mail:</label>
                    <input className="form-check-input ml-2 cursorPointer" type="checkbox" id="lateReply" 
                           name="lateReply"
                           checked={data.lateReply}  
                           onClick={e => onChangeData(e.target.name, !data.lateReply)} ></input>
                </div>

                <div className="form-check form-switch cursorPointer" >
                    <label className="form-check-label mr-5 cursorPointer" for="askTimeCheckIn" style={{width:"120px"}}>Ask Time Check In :</label>
                    <input className="form-check-input ml-2 cursorPointer" type="checkbox" id="askTimeCheckIn" 
                           name="askTimeCheckIn"
                           checked={data.askTimeCheckIn}  
                           onClick={e => onChangeData(e.target.name, !data.askTimeCheckIn)} ></input>
                </div>

                <div className="form-check form-switch cursorPointer" >
                    <label className="form-check-label mr-5 cursorPointer" for="askOnigiri" style={{width:"120px"}}>Onigiri ( early C/O) :</label>
                    <input className="form-check-input ml-2 cursorPointer" type="checkbox" id="askOnigiri" 
                           name="askOnigiri"
                           checked={data.askOnigiri}  
                           onClick={e => onChangeData(e.target.name, !data.askOnigiri)} ></input>
                </div>

                <div className="form-check form-switch cursorPointer" >
                    <label className="form-check-label mr-5 cursorPointer" for="askVAT" style={{width:"120px"}}>Ask VAT Info:</label>
                    <input className="form-check-input ml-2 cursorPointer" type="checkbox" id="askVAT" 
                           name="askVAT"
                           checked={data.askVAT}  
                           onClick={e => onChangeData(e.target.name, !data.askVAT)} ></input>
                </div>

                {
                    data.askVAT && <div style={{background:'aqua', border:'1px solid'}} className='pt-3 pb-3'>
                        <div className="form-check form-switch cursorPointer" >
                            {/* <label className="form-check-label mr-5 cursorPointer" for="askOnigiri">Case :</label> */}
                            
                            <div>
                                <label for="company-name" style={{width:"120px"}}>Select Case:</label>         
                                <select id="option" name="option"
                                    style={{width:"220px"}}
                                    onChange={e => onChangeData("vatCase", e.target.value)}>
                                    <option value="not">Have not information</option>
                                    <option value="notCom">Have not information (company)</option>
                                    <option value="have">Have information</option>
                                    <option value="haveCom">Have information (company)</option>
                                </select>
                            </div>

                        </div>
                
                            {
                                (data.vatCase === "have" || data.vatCase === "haveCom") && 
                                <>
                                <label className="form-check-label mr-5 cursorPointer" for="askOnigiri" style={{width:"220px"}}>Please input VAT information :</label>
                                <textarea type="text" id="vatCompanyName" name="vatCompanyName" value={data.vatCompanyName}
                                     style={{width:"90%", height:"100px"}}
                                    onChange={e => onChangeData(e.target.name, e.target.value)}></textarea>
                                </>
                            }

                            {
                                data.vatCase === "haveCom" && 
                                <>
                                <label className="form-check-label mr-5 cursorPointer" for="askOnigiri" style={{width:"220px"}}>Please input Company information :</label>
                                <textarea type="text" id="vatAddressCompany" name="vatAddressCompany" value={data.vatAddressCompany}
                                        style={{width:"90%", height:"100px"}}
                                    onChange={e => onChangeData(e.target.name, e.target.value)}></textarea>
                                </>
                            }
                        
                    </div>
                }

            </div>

            <div className='mt-3'>
               <div for="company-name" style={{fontWeight:'600', background:'yellow'}}>Extend Service:</div>

               <div className="form-check form-switch cursorPointer" >
                    <label className="form-check-label mr-5 cursorPointer" for="introPickDropCar" style={{width:"120px"}}>Intro Pick / Drop :</label>
                    <input className="form-check-input ml-2 cursorPointer" type="checkbox" id="introPickDropCar" 
                           name="introPickDropCar"
                           checked={data.introPickDropCar}  
                           onClick={e => onChangeData(e.target.name, !data.introPickDropCar)} ></input>
                </div>

                <div className="form-check form-switch cursorPointer" >
                    <label className="form-check-label mr-5 cursorPointer" for="introLateOut" style={{width:"120px"}}>Intro Late Out :</label>
                    <input className="form-check-input ml-2 cursorPointer" type="checkbox" id="introLateOut" 
                           name="introLateOut"
                           checked={data.introLateOut}  
                           onClick={e => onChangeData(e.target.name, !data.introLateOut)} ></input>
                </div>

                <MailComponentPickUpDropOffDetails
                    selectTabMain = {selectTabMain}
                    data = {data}
                    onChangeData = {onChangeData}
                />
            </div>

            <button className='btn btn-primary mt-5 p-4' 
                    style={{fontSize:'1.3rem'}} 
                    onClick={copyHtmlToClipboard}>Copy mail</button>

        </div>
    }

    function renderDataOfCharge() {
        switch (lang) {
            case "eng": return "before 3pm of "+changeDataToYYYY_MMMM_DDth(data.dateCancelFree1).slice(5,15);
            case "jap": return changeDataJpToMM_DD(data.dateCancelFree1) + "15時まで";
            default: return "";
        }
    }

    function renderMain() {
        let result = [];

     //   return result; 

     function checkList() {
        let result2 = [];
        let count = 1;

        function renderWithText(item, option) {
            switch (item) {
                case "lateReply": return data.lateReply ? result2.push(<div><font face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, lang === "vni" ? makeNameBookerWithLang[lang + data.gender.toString()] : undefined )}</font></div>) : "";
                case "labelReservation": {
                    if(data.introPickDropCar || data.introLateOut || data.pickUpFlight !=="" || data.dropOffTime !== "") {
                        return result2.push(<div>
                            <b><font face="arial, sans-serif" size="2"  style={{backgroundColor: "rgb(255, 255, 0)"}}>{makeRowTextMail(item, lang,)}</font></b>
                            </div>)
                    } 
                    return ""
                }
                case "checkContract": 
                        if(data.companyName !== "") {
                            if(data.discount === "5%") {
                                return result2.push(<> 
                                    <div><font face="arial, sans-serif" size="2" >{makeRowTextMail("contract5%", lang, data.companyName)}</font></div> 
                                    <div><font face="arial, sans-serif" size="2" ><i>{makeRowTextMail("contractChangeLaundry", lang)}</i></font></div> 
                                    <div><br/></div>
                                  </>)
                            } else {
                                return result2.push(<>
                                    <div><font face="arial, sans-serif" size="2" >{makeRowTextMail("contract"+data.discount, lang, data.companyName)}</font></div>
                                    <div><br/></div>
                                </>)
                            }
                        }
                        return ""
                case "bestRegards": {
                            if(lang ==="jap") return result2.push(<>
                                <div><br/></div>
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, branchNumberToNameWithLang[""+branchID][lang])}</font></div>
                            </>
                            )
        
                            if(lang ==="eng") return result2.push(<>
                                <div><br/></div>
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, branchNumberToNameWithLang[""+branchID][lang])}</font></div>
                            </>
                            )    
                            
                            if(lang ==="vni") return result2.push(<>
                                <div><br/></div>
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, branchNumberToNameWithLang[""+branchID][lang])}</font></div>
                            </>
                            )  
                        }
                case "introLateOut": {
                    if(data.introLateOut) {
                        count += 1
                        return result2.push(<> 
                            <div><br/></div>
                            <div>
                                <b style={{backgroundColor: "rgb(255, 255, 0)"}}>
                                    <font  face="arial, sans-serif" size="2" >{makeNumberLabel[count]}</font>
                                    <font  face="arial, sans-serif" size="2" >{makeRowTextMail("introLateOut1", lang)}</font>
                                </b>
                            </div> 
                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introLateOut2", lang)}</font></div> 
                            <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>
                                    <blockquote style={{"margin":"0px 0px 0px 0.8ex",borderLeft:"1px solid rgb(204,204,204)",paddingLeft:"1ex"}} className="gmail_quote">
                                        <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introLateOut3", lang)}</font></div> 
                                        <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introLateOut4", lang)}</font></div> 
                                    </blockquote>
                            </blockquote>
                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introLateOut5", lang)}</font></div> 
                            <div><br/></div>
                            </>)
                    }    
                    return ""
                }
                case "introPickDropCar": {
                    if(data.introPickDropCar) {
                        count += 1
                        return result2.push(<> 
                            {count === 1 ? <div><br/></div>: ""}
                            <div>
                                <b style={{backgroundColor: "rgb(255, 255, 0)"}}>
                                    <font  face="arial, sans-serif" size="2" >{makeNumberLabel[count]}</font>
                                    <font  face="arial, sans-serif" size="2" >{makeRowTextMail("introPickDropCar1", lang)}</font>
                                </b>
                            </div> 
                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introPickDropCar2", lang)}</font></div> 
                            <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>
                                    <blockquote style={{"margin":"0px 0px 0px 0.8ex",borderLeft:"1px solid rgb(204,204,204)",paddingLeft:"1ex"}} className="gmail_quote">
                                        <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introPickDropCar3", lang)}</font></div> 
                                        <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introPickDropCar4", lang)}</font></div> 
                                    </blockquote>
                            </blockquote>
                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introPickDropCar5", lang)}</font></div> 
                            {/* {count === 1 ? <div><br/></div>: ""} */}
                            <div><br/></div>
                          </>)
                    } else return ""
                }
                case "roomShowPrice": {
                    return (<blockquote style={{marginLeft:'40px'}}>
                    {!data.optionCase.includes("1 Option") ? <b><font  face="arial, sans-serif" size="2" >{makeRowTextMail(option, lang,)}</font></b>: <div><br/></div>}
                    <p style={{margin: 0}}><font  face="arial, sans-serif" size="2" >
                      {makeRowTextMail(
                          item, 
                          lang,
                          data[option], 
                          (Number(data["price" + capitalizeFirstLetter(option)])*((data.discount.includes("5%") && data.companyName !=="") ? 0.95 : (data.discount.includes("10%") && data.companyName !=="") ? 0.9 : 1)).toLocaleString())}</font></p>
                        <b><font face="arial, sans-serif" size="2"  style={{backgroundColor: "rgb(255, 255, 0)"}}>{makeRowTextMail('excludeVAT', lang,)}</font></b>
                  </blockquote>)
                }
                default: return "";            
            }
        }

        if(selectTabMain === "makeRoom") {
            makeRoomList[lang].forEach(item => {
                switch(item) {
                    case "dear": {
                        if(lang === "eng") return result2.push(<div>
                          <font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, makeNameBookerWithLang[lang + data.gender.toString()], data.booker)}</font></div>);
                        else if (lang === "jap") return result2.push(<div>
                          <font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, makeNameBookerWithLang[lang + data.gender.toString()], data.booker.toUpperCase())}</font></div>);
                        else return result2.push(<div>
                        <font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, makeNameBookerWithLang[lang + data.gender.toString()], data.booker)}</font></div>);
                    }
                    case "br": return result2.push(<div><br/></div>);
                    case "thankBooking": return result2.push(<div><font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, branchNumberToNameWithLang[""+branchID][lang], makeNameBookerWithLang[lang + data.gender.toString()])}</font></div>)
                    case "labelReservation": {
                        if(data.introPickDropCar || data.introLateOut || data.pickUpFlight !=="" || data.dropOffTime !== "") {
                            return result2.push(<div>
                                <b><font  face="arial, sans-serif" size="2"  style={{backgroundColor: "rgb(255, 255, 0)"}}>{makeRowTextMail(item, lang,)}</font></b>
                                </div>)} 
                        return ""
                    }
                    case "lateReply": return data.lateReply ? result2.push(<div><font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, lang === "vni" ? makeNameBookerWithLang[lang + data.gender.toString()] : undefined )}</font></div>) : "";
                    case "askTimeCheckIn": return data.askTimeCheckIn ? result2.push(<div><font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, lang === "vni" ? makeNameBookerWithLang[lang + data.gender.toString()] : undefined )}</font></div>) : "";
                    case "chargeCancelNotification": 
                        if(data.checkIn2 === "") {
                            return result2.push(<div>
                                <b>
                                    <font  face="arial, sans-serif" size="2" >{makeRowTextMail("chargeCancelNotification1", lang)}</font>
                                    <font  face="arial, sans-serif" size="2"  color="#9900ff">{renderDataOfCharge()}</font>
                                    <font  face="arial, sans-serif" size="2" >{makeRowTextMail("chargeCancelNotification2", lang)}</font>
                                </b>
                            </div>)
                        } else {
                            return result2.push(<>
                                <p><font  face="arial, sans-serif" size="2" >{makeRowTextMail("chargeCancelNotification3", lang)}</font></p>
                                <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>
                                    <blockquote style={{"margin":"0px 0px 0px 0.8ex",borderLeft:"1px solid rgb(204,204,204)",paddingLeft:"1ex"}} className="gmail_quote">
                                        {/* <font  face="arial, sans-serif" size="2"  color="#9900ff">{"before 3pm of "+changeDataToYYYY_MMMM_DDth(data.dateCancelFree1).slice(5,15)}</font> */}
                                        {data.checkIn1 !== "" && <div>
                                            <b><font  face="arial, sans-serif" size="2" >{makeRowTextMail("chargeCancelNotification4", lang, changeDataToYYYY_MMMM_DDth(data.checkIn1).slice(5,15))}</font></b>
                                            <b><font  face="arial, sans-serif" size="2"  color="#9900ff">{"before 3pm of "+changeDataToYYYY_MMMM_DDth(data.dateCancelFree1).slice(5,15)}</font></b>
                                        </div>}
                                        {data.checkIn2 !== "" && <div style={{ marginBottom: data.checkIn3 === "" ? '12px' : "unset"}}>
                                            <b><font  face="arial, sans-serif" size="2" >{makeRowTextMail("chargeCancelNotification4", lang, changeDataToYYYY_MMMM_DDth(data.checkIn2).slice(5,15))}</font></b>
                                            <b><font  face="arial, sans-serif" size="2"  color="#9900ff">{"before 3pm of "+changeDataToYYYY_MMMM_DDth(data.dateCancelFree2).slice(5,15)}</font></b>
                                        </div>}
                                        {data.checkIn3 !== "" &&  <div>
                                            <b><font  face="arial, sans-serif" size="2" >{makeRowTextMail("chargeCancelNotification4", lang, changeDataToYYYY_MMMM_DDth(data.checkIn3).slice(5,15))}</font></b>
                                            <b><font  face="arial, sans-serif" size="2"  color="#9900ff">{"before 3pm of "+changeDataToYYYY_MMMM_DDth(data.dateCancelFree3).slice(5,15)}</font></b>
                                            <b><font  face="arial, sans-serif" size="2"  color="#9900ff">{"before 3pm of "+changeDataToYYYY_MMMM_DDth(data.dateCancelFree4).slice(5,15)}</font></b>
                                        </div>}
                                    </blockquote>
                                </blockquote>
                            </>)
                        }
                      //dateCancelFree
                    case "checkContract": return renderWithText(item)
                    case "askOnigiri": {
                        if(data.askOnigiri) {
                            return result2.push(<> 
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askOnigiri1", lang, data.companyName)}</font></div> 
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askOnigiri2", lang)}</font></div> 
                                <div><br/></div>
                              </>)
                        } else return "";
                    }
                    case "askVAT": {
                        if(data.askVAT) {
                            if(data.vatCase === "not") {
                                return result2.push(<>
                                    <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot1", lang)}</font></div> 
                                    <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>
                                        <blockquote style={{"margin":"0px 0px 0px 0.8ex",borderLeft:"1px solid rgb(204,204,204)",paddingLeft:"1ex"}} className="gmail_quote">
        
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot2", lang)}</font></div> 
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot3", lang)}</font></div> 
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot4", lang)}</font></div> 
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot5", lang)}</font></div> 
        
                                        </blockquote>
                                    </blockquote>
                                    <div><br/></div>
                                </>)
                            } else if (data.vatCase === "notCom") {
                                return result2.push(<>
                                    <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot1", lang)}</font></div> 
                                    <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>
                                        <blockquote style={{"margin":"0px 0px 0px 0.8ex",borderLeft:"1px solid rgb(204,204,204)",paddingLeft:"1ex"}} className="gmail_quote">
        
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot2", lang)}</font></div> 
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot3", lang)}</font></div> 
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot4", lang)}</font></div> 
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot5", lang)}</font></div> 
        
                                        </blockquote>
                                    </blockquote>
                                    <div><br/></div>
    
                                    <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany1", lang)}</font></div> 
                                    <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>
                                        <blockquote style={{"margin":"0px 0px 0px 0.8ex",borderLeft:"1px solid rgb(204,204,204)",paddingLeft:"1ex"}} className="gmail_quote">
        
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany2", lang)}</font></div> 
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany3", lang)}</font></div> 
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany4", lang)}</font></div> 
        
                                        </blockquote>
                                    </blockquote>
                                    <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany5", lang)}</font></div> 
                                    {
                                        lang !=="eng" && <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany5_1", lang)}</font></div> 
                                    }
                                    <div><br/></div>
                                </>)    
                            } else if (data.vatCase === "have" || data.vatCase === "haveCom") {
                                result2.push(<>
                                    <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVAThave1", lang)}</font></div> 
                                    <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVAThave2", lang)}</font></div> 
                                    <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVAThave3", lang)}</font></div> 
    
                                    <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>
                                        <blockquote style={{"margin":"0px 0px 0px 0.8ex",borderLeft:"1px solid rgb(204,204,204)",paddingLeft:"1ex"}} className="gmail_quote">                                        
                                            {data.vatCompanyName === "" ? <>
                                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot2", lang)}</font></div> 
                                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot3", lang)}</font></div> 
                                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot4", lang)}</font></div> 
                                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATnot5", lang)}</font></div> 
                                            </> : <pre>
                                                <b><font  face="arial, sans-serif" size="2"  >
                                                {data.vatCompanyName}
                                                </font></b>
                                            </pre>}
                                        </blockquote>
                                    </blockquote>
                                    { data.vatCase === "have" && <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany5", lang)}</font></div> }
                                    <div><br/></div>
                                </>) 
                            if (data.vatCase === "haveCom") {
                                return result2.push(<>
                                    <p style={{margin:"0"}}><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVAThave1", lang)}</font></p> 
                                    <p style={{margin:"0"}}><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVAThave2", lang)}</font></p> 
                                    <p style={{margin:"0"}}><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVAThave3", lang)}</font></p> 
    
                                    <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>
                                        <blockquote style={{"margin":"0px 0px 0px 0.8ex",borderLeft:"1px solid rgb(204,204,204)",paddingLeft:"1ex"}} className="gmail_quote">                                        
                                            {data.vatAddressCompany === "" ? <>
                                                <p style={{margin:"0"}}><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany2", lang)}</font></p> 
                                                <p style={{margin:"0"}}><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany3", lang)}</font></p> 
                                                <p style={{margin:"0"}}><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany4", lang)}</font></p> 
                                            </>
                                            :<pre>
                                                <b><font  face="arial, sans-serif" size="2"  >
                                                {data.vatAddressCompany}
                                                </font></b>
                                            </pre>}
                                        </blockquote>
                                    </blockquote>
                                    <p style={{margin:"0"}}><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVATcompany5", lang)}</font></p> 
    
                                    <div><br/></div>
    
                                    
                                </>) 
                                }
                            } else return ""   
                        } else {
                            return result2.push(<>
                                <p style={{margin:"0"}}><font  face="arial, sans-serif" size="2" >{makeRowTextMail("askVAT", lang, data.askVAT)}</font></p> 
                        </>)
                        }
    
                    } 
                    case "introPickDropCar": {
                        if(data.introPickDropCar) {
                            count += 1
                            return result2.push(<> 
                                {count === 1 ? <div><br/></div>: ""}
                                <div>
                                    <b style={{backgroundColor: "rgb(255, 255, 0)"}}>
                                        <font  face="arial, sans-serif" size="2" >{makeNumberLabel[count]}</font>
                                        <font  face="arial, sans-serif" size="2" >{makeRowTextMail("introPickDropCar1", lang)}</font>
                                    </b>
                                </div> 
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introPickDropCar2", lang)}</font></div> 
                                <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>
                                        <blockquote style={{"margin":"0px 0px 0px 0.8ex",borderLeft:"1px solid rgb(204,204,204)",paddingLeft:"1ex"}} className="gmail_quote">
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introPickDropCar3", lang)}</font></div> 
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introPickDropCar4", lang)}</font></div> 
                                        </blockquote>
                                </blockquote>
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introPickDropCar5", lang)}</font></div> 
                                {/* {count === 1 ? <div><br/></div>: ""} */}
                                <div><br/></div>
                              </>)
                        } else return ""
                    }
                    case "introLateOut": {
                        if(data.introLateOut) {
                            count += 1
                            return result2.push(<> 
                                <div><br/></div>
                                <div>
                                    <b style={{backgroundColor: "rgb(255, 255, 0)"}}>
                                        <font  face="arial, sans-serif" size="2" >{makeNumberLabel[count]}</font>
                                        <font  face="arial, sans-serif" size="2" >{makeRowTextMail("introLateOut1", lang)}</font>
                                    </b>
                                </div> 
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introLateOut2", lang)}</font></div> 
                                <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>
                                        <blockquote style={{"margin":"0px 0px 0px 0.8ex",borderLeft:"1px solid rgb(204,204,204)",paddingLeft:"1ex"}} className="gmail_quote">
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introLateOut3", lang)}</font></div> 
                                            <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introLateOut4", lang)}</font></div> 
                                        </blockquote>
                                </blockquote>
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("introLateOut5", lang)}</font></div> 
                                <div><br/></div>
                              </>)
                        }    
                        return ""
                    }
                    case "servicePickUp": {
                        if(data.pickUpFlight !== "") {
                            count += 1
                            return result2.push(<> 
                                <div><br/></div>
                                <div>
                                    <b style={{backgroundColor: "rgb(255, 255, 0)"}}>
                                        <font  face="arial, sans-serif" size="2" >{makeNumberLabel[count]}</font>
                                        <font  face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, undefined, undefined, "nameService")}</font>
                                    </b>
                                </div> 
                                <b><font face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, undefined, undefined, "service")}</font></b>
    
                                <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>  
                                <ul>        
                                    <li><div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, data.pickUpFlight, data.pickUpTime, "flightTime")}</font></div> </li>
                                    <li><div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, Number(data.pickUpPrice).toLocaleString(), undefined, "price")}</font></div> </li>
                                </ul>
                                </blockquote>
                                 
                                <b><div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, undefined, undefined, "notice")}</font></div> </b>
                                    
                                    <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>        
                                       {
                                        lang === "eng" ? <ul>
                                            {data.dropOffTime === "" &&<li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, undefined, undefined, "ifChangeFight")}</font></i></li>}
                                            <li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, undefined, undefined, "waitingInColumn1")}{data.domestic ? makeRowTextMail("servicePickUp", lang, undefined, undefined, "arrivalColumn") :""}</font></i></li>
                                            {data.dropOffTime === "" &&<li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, branchNumberToNameWithLang[""+branchID][lang], branchNumberToNameWithLang[""+branchID].phone, "ifHaveNotDriveCallToHotel")}</font></i></li>}
                                            <li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, undefined, undefined, "changerIfGoToOtherCar")}</font></i></li>
                                        </ul> : 
                                        lang === "vni" ? <ul>
                                            {data.dropOffTime === "" &&<li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, undefined, undefined, "ifChangeFight")}</font></i></li>}
                                            <li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, undefined, undefined, "waitingInColumn1")}
                                                {data.domestic ? makeRowTextMail("servicePickUp", lang, undefined, undefined, "arrivalColumn") :""}
                                                { makeRowTextMail("servicePickUp", lang, undefined, undefined, "waitingInColumn2")}
                                                
                                                </font></i></li>
                                            {data.dropOffTime === "" &&<li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, branchNumberToNameWithLang[""+branchID][lang], branchNumberToNameWithLang[""+branchID].phone, "ifHaveNotDriveCallToHotel")}</font></i></li>}
                                            <li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, undefined, undefined, "changerIfGoToOtherCar")}</font></i></li>
                                        </ul> : ""
                                       } 
    
                                    </blockquote>
                                    { data.dropOffTime === "" && <b><div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUp", lang, undefined, undefined, "ruleFreeCancel")}</font></div></b>}
                                    
                                </>)
                        }
                        return ""
                    }
                    case "serviceDropOff": {
                        if(data.dropOffTime !== "") {
                            count += 1
                            return result2.push(<> 
                                <div><br/></div>
                                <div>
                                    <b style={{backgroundColor: "rgb(255, 255, 0)"}}>
                                        <font  face="arial, sans-serif" size="2" >{makeNumberLabel[count]}</font>
                                        <font  face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, undefined, undefined, "nameService")}</font>
                                    </b>
                                </div> 
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, data.dropOffTime, undefined, "confirmBelow")}</font></div> 
                                <b><font face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, undefined, undefined, "service")}</font></b>
    
                                <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>        
                                        <ul>
                                            {/* <li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, undefined, undefined, "notice")}</font></i></li> */}
                                            <li><div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, data.dropOffTime, undefined, "dropOffTime")}</font></div> </li>
                                            <li><div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, Number(data.dropOffPrice).toLocaleString(), undefined, "price")}</font></div> </li>
                                        </ul>
                                </blockquote>
    
                                <b><font face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, undefined, undefined, "notice")}</font></b>
                                <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>        
                                        <ul>
                                            {/* <li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, undefined, undefined, "notice")}</font></i></li> */}
                                            <li><i style={{margin:"0"}}><font  face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, undefined, undefined, "waitingLobby")}</font></i> </li>
                                            {data.pickUpFlight === "" && <li><i><font face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, branchNumberToNameWithLang[""+branchID][lang], branchNumberToNameWithLang[""+branchID].phone, "haveTroubleCallToRC")}</font></i></li>}
                                            {data.pickUpFlight === "" && <li><i style={{margin:"0"}}><font  face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, undefined, undefined, "changerIfGoToOtherCar")}</font></i> </li>}
    
                                        </ul>
                                </blockquote>
                                {data.pickUpFlight === "" &&<b><div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, undefined, undefined, "ruleFreeCancel")}</font></div></b>}
                                </>)
                        }
                        return ""
                    }
                    case "servicePickUpDropOffNoti": {
                        if(data.pickUpFlight !== "" && data.dropOffTime !== "") {
                            return result2.push(<>
                                <div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUpDropOffNoti1", lang)}</font></div>
                                <blockquote style={{"margin":"0 0 0 40px",border:"none",padding:"0px"}}>        
                                    <ul>
                                        {/* <li><div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUpDropOffNoti2", lang)}</font></div></li> */}
                                        <li><div><font face="arial, sans-serif" size="2" >{makeRowTextMail("serviceDropOff", lang, branchNumberToNameWithLang[""+branchID][lang], branchNumberToNameWithLang[""+branchID].phone, "haveTroubleCallToRC")}</font></div></li>
                                        <li><div><font  face="arial, sans-serif" size="2" >{makeRowTextMail("servicePickUpDropOffNoti3", lang)}</font></div></li>
                                        <li><b><div><font  face="arial, sans-serif" size="2" color="#9900ff" >{makeRowTextMail("servicePickUpDropOffNoti4", lang)}</font></div></b></li>
                                    </ul>
                                </blockquote>
                            </>)
                        }
    
                        return ""
                    }
                    case "welcomingGuest": return result2.push(<div>
                           <font  face="arial, sans-serif" size="2" >
                                {makeRowTextMail(item, lang, lang === "jap" ? data.guestName : makeNameBookerWithLang[lang + data.guestGender.toString()+"welcome"]+data.guestName , undefined )}
                           </font>
                        </div>)
                    case "bestRegards": return renderWithText(item)
                    case "rcName":  return result2.push(<div>
                        <font  face="arial, sans-serif" size="2" >{data.rcName} </font>
                    </div>)
                    default: return result2.push(<div><font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, lang === "vni" ? makeNameBookerWithLang[lang + data.gender.toString()] : undefined )}</font></div>)
                }
             });
        } else if (selectTabMain === "suggestRoom") {
            makeSuggestList[lang].forEach(item => {
                switch(item) {
                    case "dear": {
                        if(lang === "eng") return result2.push(<div><font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, makeNameBookerWithLang[lang + data.gender.toString()], data.booker)}</font></div>);
                        else if (lang === "jap") return result2.push(<div>
                          <font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, makeNameBookerWithLang[lang + data.gender.toString()], data.booker.toUpperCase())}</font></div>);
                        else return result2.push(<div>
                        <font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, makeNameBookerWithLang[lang + data.gender.toString()], data.booker)}</font></div>);
                    }
                    case "lateReply": return renderWithText(item);
                    case "labelReservation": return renderWithText(item);
                    case "roomShowPrice": return result2.push(<>
                        {renderWithText(item, "option1")}
                        {!data.optionCase.includes("1 Option") && renderWithText(item, "option2")}
                    </>)
                    case "thankForKindAttention": {
                        if(lang === "vni") return result2.push(<font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, makeNameBookerWithLang[lang + data.gender.toString()], branchNumberToNameWithLang[""+branchID][lang])}</font>);
                        else return result2.push(<font face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, branchNumberToNameWithLang[""+branchID][lang], "")}</font>);
                    }
                    case "br": return result2.push(<div><br/></div>);
                    case "checkContract": return renderWithText(item);
                    case "pleaseSelectRoom": {
                        return result2.push(<div>
                                {
                                    data.optionCase.includes("1 Option") ? 
                                         <font  face="arial, sans-serif" size="2" >{makeRowTextMail('pleaseSelectRoomSign', lang,)}</font>
                                    : <font  face="arial, sans-serif" size="2" >{makeRowTextMail('pleaseSelectRoomMulti', lang,)}</font>
                                }
                                {!data.optionCase.includes("1 Option") && lang === "jap" && <font  face="arial, sans-serif" size="2" >{makeRowTextMail('pleaseSelectRoomMulti2', lang,)}</font>}
                        </div>)
                    }
                    case "dateCancelSuggestRoom": {
                        if(lang === "eng") { 
                            return result2.push(<>
                                <b><font  face="arial, sans-serif" size="2" >{makeRowTextMail('dateCancelSuggestRoom', lang, undefined, undefined, "row1")}</font></b>
                                <b><font  face="arial, sans-serif" size="2"  style={{color:"rgb(153, 0, 255)"}}>{"released after date "}{changeDataToYYYY_MMMM_DDth(data.checkIn1).slice(5,15)}</font></b>
                                <b><font  face="arial, sans-serif" size="2" >{makeRowTextMail('dateCancelSuggestRoom', lang, undefined, undefined, "row2")}</font></b>                           
                            </>)
                        }
                    }
                    case "introLateOut": return renderWithText(item);
                    case "introPickDropCar": return renderWithText(item);
                    case "bestRegards": return renderWithText(item);
                    case "rcName":  return result2.push(<div>
                        <font  face="arial, sans-serif" size="2" >{data.rcName} </font>
                    </div>)
                    default: return result2.push(<div><font  face="arial, sans-serif" size="2" >{makeRowTextMail(item, lang, lang === "vni" ? makeNameBookerWithLang[lang + data.gender.toString()] : undefined )}</font></div>)
                }
            })
        }

        return result2
     }

     return (<div style={{textAlign:"left", width:"60%", border:"1px solid black", minHeight:"600px", paddingLeft:"12px", marginLeft:"48px", marginTop: '70px'}} ref={contentRef}>
                    {checkList()}
                </div>)

    }

    return (<>
         {type === undefined && <h1>Mail Format Content</h1>}
        <div className='d-flex main-content-select-main'>
            <div className={'main-content-select-main_tab' + (selectTabMain === "makeRoom" ? ' select' : '')}
                onClick={() => setSelectTabMain("makeRoom")}>Make Room</div>
            <div className={'main-content-select-main_tab' + (selectTabMain === "suggestRoom" ? ' select' : '')}
                onClick={() => setSelectTabMain("suggestRoom")}>Suggest Room</div>
            <div className={'main-content-select-main_tab' + (selectTabMain === "finishMain" ? ' select' : '')}
                onClick={() => setSelectTabMain("finishMain")}>Finish Mail</div>
        </div>
        { renderLang() }
        {/* <CopyToClipboard onCopy={() => setCopied(true)}> */}
             {/* <button style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    {copied ? 'Copied!' : 'Copy'}
                </button> */}
        <div className='d-flex'>
          { branchID && renderSelectOption() }
          { branchID && renderMain() }
        </div>
        {/* </CopyToClipboard> */}
    </>)
}

export default MailContainer;
