import { unitsAction, getType } from '../../actions';

const initialState = {
    show: false,
    content: "Loading...",
}

const showLoadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(unitsAction.showLoading): {
            return {
                content: action.payload === undefined ? "Loading..." : action.payload,
                show: true,
            }
        }

        case getType(unitsAction.hiddenLoading): {
            return {
                show: false,
                content: "Loading...",
            }
        }

        case getType(unitsAction.changeContentLoading): {

            return {
                ...state,
                content: action.payload
            }
        }

        default: return state;
    }
}

export default showLoadingReducer;
