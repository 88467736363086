import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import * as bookingApi from '../../../api/booking';
import * as invoiceApi from '../../../api/invoice';
import CompanyItemComponent from "../../../components/Company";
import AddBookingTableComponent from "../../../components/MakeHotelInvoice/AddServiceOnBooking/AddBookingTableComponet";
import { unitsAction } from '../../../redux/actions';
import { getToday } from '../../../utils/Date/SupportDate'
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';
import { informationToast, errorToast } from '../../../utils/Toast/index';
import './addServiceOnBooking.css';

function AddServiceOnBooking() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const { branchID, idRoom } = useParams();
    // const auth = useSelector(state => state.auth);
    // const { user } = auth;
    const [data, setData] = useState({
        data: [],
        search: false,
        service: [],
        totalLaundry: 0,
        select: "laundry"
    });
    const [listSelect, setListSelect] = useState([]);

    useEffect(() => {
        const onLoadData = async () => {
            dispatch(unitsAction.showLoading());
            const dataResult2 = await bookingApi.takeDataShowGuestOnHotelInvoice(branchID, idRoom, token);
            const serverNote = await invoiceApi.takeServiceNote(branchID, idRoom, token);

            if (dataResult2) {
                setData({
                    //    service: [{ date: getToday(), name: "", price: 0 },],
                    service: serverNote.data.data,
                    data: dataResult2.data.data,
                    type: "makeHotelInvoice",
                    search: false,
                    select: "laundry"
                })
            }

            //   dispatch(makeRoomAction.takeDataBookingByID({ branchID, id: idRoom }));
            dispatch(unitsAction.hiddenLoading())
        }

        onLoadData()
    }, [token]);

    function onChange(value, target, option) {
        const newData = { ...data };
        if (option !== undefined) {
            newData.service[option][target] = value;
        } else {
            newData[target] = value;
        }
        return setData(newData);
    }

    function onAddNewService() {
        const newData = { ...data };
        newData.service.push({
            date: getToday(),
            content: "",
            rate: 0
        })
        setData(newData);
    }

    function onDeleteSelected() {
        const newData = [...data.service];
        const returnList = [];
        newData.forEach((item, index) => {
            if (!listSelect.includes("" + index)) {
                returnList.push(item);
            }
        })

        return setData({
            ...data,
            service: returnList
        });
    }

    function onSave() {
        const onLoadDataServer = async () => {
            try {
                const result = await invoiceApi.updateServiceNote(branchID, data.service, idRoom, token);
                if (result) {
                    if (result.data.status === 1) return informationToast(result.data.msg)
                    else return errorToast(result.data.msg)
                } else return errorToast("Error updating...")
            } catch (error) {
                errorToast("Error updating...")
            }

        }
        onLoadDataServer();
    }

    function checkTotalDetails() {
        let result = [];
        let calculatorRecord = {
            total: 0
        };

        if (data.service.length > 0) {
            data.service.forEach((item, index) => {
                calculatorRecord.total += changeStringVNDtoNumber(item.rate);
                if (item.content.trim() in calculatorRecord) {
                    calculatorRecord[item.content.trim()] += changeStringVNDtoNumber(item.rate);
                } else {
                    calculatorRecord[item.content.trim()] = 0;
                    calculatorRecord[item.content.trim()] += changeStringVNDtoNumber(item.rate);
                }
            })
        }

        const keyOnCalculator = Object.keys(calculatorRecord);

        keyOnCalculator.forEach(key => {
            if (key.trim() !== "total") result.push(
                <tr>
                    <td style={{ border: '1px solid black', width: '100px' }}>{key}</td>
                    <td style={{ border: '1px solid black', width: '120px' }}>{Number(calculatorRecord[key]).toLocaleString()} VND</td>
                </tr>
            )
        })

        result.push(
            <tr>
                <td style={{ border: '1px solid black', width: '100px', background: 'yellow' }}>Total</td>
                <td style={{ border: '1px solid black', width: '120px', background: 'yellow' }}>{Number(calculatorRecord.total).toLocaleString()} VND</td>
            </tr>
        )

        return result;
    }

    return (
        <div >
            <h1>Add Service On Booking</h1>
            <div className="addService_showData">
                <CompanyItemComponent
                    type={"makeHotelInvoice"}
                    data={data.data}
                    search={data.search}
                />
            </div>
            <div className="addService_detail">
                <div className="addService_detail-title d-flex">
                    <div style={{
                        flex: 6,
                        background: 'rgb(190, 236, 190)',
                        fontSize: '1.4rem',
                        border: '1px solid black',
                        margin: 'auto',
                    }}>
                        <div>SERVER INPUT</div>
                    </div>
                    <div 
                      style={{
                        flex: 3,
                        background: 'rgb(190, 236, 190)',
                        fontSize: '1.4rem',
                        border: '1px solid black',
                        margin: 'auto',
                    }}>
                        <div>SERVICE TOTAL DETAILS:</div>
                    </div>
                </div>

                <div className="addService_detail-content d-flex">
                    <div style={{
                        flex: 6,
                        // fontSize: '1.4rem',
                        borderRight: '1px solid black',
                        //    margin: 'auto',
                    }}>
                        <AddBookingTableComponent
                            data={data.service}
                            key={data.service.length}
                            listSelect={listSelect}
                            setListSelect={setListSelect}
                            onChange={onChange}
                            onAddNewService={onAddNewService}
                            onDeleteSelected={onDeleteSelected}
                            onSave={onSave}
                        />
                    </div>
                    <div style={{ flex: 3, marginTop: '14px', textAlign: 'center' }}>
                        <table style={{ margin: 'auto', fontSize: '1.3rem' }}>
                            <tr>
                                <th style={{ border: '1px solid black', width: '100px' }}>Service</th>
                                <th style={{ border: '1px solid black', width: '70px' }}>Price</th>
                            </tr>
                            {checkTotalDetails()}
                        </table>
                    </div>
                </div>

            </div >

        </div >
    )
}

export default AddServiceOnBooking;
