import React, { useLayoutEffect, useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// import HomePage from './container/Reservation/ReservationContainer';
import LoginContainer from './container/Unit/LoginContainer';
import ReservationContainer from './container/Reservation/ReservationContainer';
import MakeRoomContainer from './container/MakeRoom';
import CompanyContainer from './container/Company';
import GuestInformationContainer from './container/GuestInformation';
import CancelAreaContainer from './container/CancelArea';
import WaitingAreaContainer from './container/WaitingArea';
import MakeBookingBill from './container/MakeBookingBill';
import AddServiceOnBooking from './container/MakeHotelInvoice/AddServiceOnBooking';
import MakeServiceInvoice from './container/MakeHotelInvoice/MakeServiceInvoice';
import MakeHotelInvoice from './container/MakeHotelInvoice/MakeHotelInvoice';
import InvoiceManagerContainer from './container/Invoice';
import ViewPdf from './container/Unit/ViewPdf';
import GlistContainer from './container/Glist/GlistContainer';
import SupportReportContainer from './container/SupportReport';
import GuestNumberContainer from './container/Unit/SupportTools/GuestNumber/GuestNumberContainer';
import GuestCheckInContainer from './container/Unit/SupportTools/GuestCheckIn/GuestCheckInContainer';
import MakePMMRQContainer from './container/MakePMRQ';
import FightManageContainer from './container/FlightManage';
import GuestCheckInManageContainer from './container/GuestCheckInManage';
import GuestCheckOutAccContainer from './container/Unit/SupportTools/GuestCheckOut/GuestCheckOutAccContainer';
import MailContainer from './container/MailContainer';
import MakeServiceBill from './container/MakeServiceBill';
import CompanyEditHistory from './components/Company/companyHistory';

function NewRouter(props) {
    //const auth = useSelector(state => state.auth);
    // const { isLoggedIn, } = auth;
    const firstLogin = localStorage.getItem('firstLogin');

    useLayoutEffect(() => {
        setTimeout(() => {
        }, 5000);
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const checkLogin = useCallback(() => {
    //     if (!firstLogin) return LoginContainer;
    //     else return !isLoggedIn ? LoginContainer : "";
    //     // HomePage
    // })

    return (
        <section style={{ margin: 'auto',width:'maxContent'}}>
            <Switch>
                {/* <Route path="/user/profile" component={!isLoggedIn ? NotFound : ProfileContainer} exact /> */}
                <Route path="/" component={LoginContainer} exact />
                <Route path="/home" component={ReservationContainer} exact />
                <Route path="/cancel" component={CancelAreaContainer} exact />
                <Route path="/glist" component={GlistContainer} exact />
                <Route path="/glist/:branchID/:day" component={GlistContainer} exact />
                <Route path="/invoice" component={InvoiceManagerContainer} exact />
                <Route path="/flight" component={FightManageContainer} exact />
                <Route path="/checkin" component={GuestCheckInManageContainer} exact />
                <Route path="/invoice/:month" component={InvoiceManagerContainer} exact />
                <Route path="/viewPDF/:branch/:link" component={ViewPdf} exact />
                <Route path="/waiting" component={WaitingAreaContainer} exact />
                <Route path="/make_booking_bill/:branchID/:idRoom" component={MakeBookingBill} exact />
                <Route path="/make_booking_bill/:branchID/:type/:idRoom" component={MakeBookingBill} exact />
                <Route path="/make_service_bill/:branchID/:idRoom" component={MakeServiceBill} exact />
                <Route path="/add_service/:branchID/:idRoom" component={AddServiceOnBooking} exact />
                <Route path="/service_invoice/:branchID/:idRoom" component={MakeServiceInvoice} exact />
                <Route path="/make_pmrq/:branchID/:idRoom" component={MakePMMRQContainer} exact />
                <Route path="/make_pmrq/:branchID/:type/:idRoom" component={MakePMMRQContainer} exact />
                <Route path="/make_invoice/:branchID/:idRoom" component={MakeHotelInvoice} exact />
                <Route path="/make_invoice/:branchID/:type/:idRoom" component={MakeHotelInvoice} exact />
                <Route path="/reservation" component={ReservationContainer} exact />
                <Route path="/reservation/:branchID/:month" component={ReservationContainer} exact />
                <Route path="/room" component={MakeRoomContainer} exact />
                <Route path="/room/:branchID/:roomSelect/:checkin" component={MakeRoomContainer} exact />
                <Route path="/room/:branchID/:idRoom" component={MakeRoomContainer} exact />
                <Route path="/room_history/:branchID/:id_history" component={MakeRoomContainer} exact />
                <Route path="/check_room/:branchID/:idRoom/:typeRoom" component={MakeRoomContainer} exact />
                <Route path="/company" component={CompanyContainer} exact />
                <Route path="/company_history/:id" component={CompanyEditHistory} exact />

                <Route path="/guest" component={GuestInformationContainer} exact />
                <Route path="/mail" component={MailContainer} exact />

                <Route path="/guest/:id" component={GuestInformationContainer} exact />
                <Route path="/report" component={SupportReportContainer} exact />
                {/* Support Tools */}
                <Route path="/guest_number" component={GuestNumberContainer} exact />
                <Route path="/guest_checkin" component={GuestCheckInContainer} exact />
                <Route path="/guest_checkout" component={GuestCheckOutAccContainer} exact />
            </Switch>
        </section>
    );
}

export default NewRouter;