import React, { useEffect, } from 'react';
import { useSelector } from 'react-redux';
import ModalSupportOption from '../../../container/Unit/Modal/modalSupportOption';

function MakeRoomGuestSelectList(props) {
    const { style, isEdit } = props;
    const listGuestSelect = useSelector(state => state.makeRoom.listGuestSelect);
    const indexGuestSelect = useSelector(state => state.makeRoom.indexGuestSelect); 
    // const [selectGuest, setSelectGuest] = useState(0);

     useEffect(()=> {

     },[indexGuestSelect]);

    function renderGuestSelect(dataRender) {
        var result = [];

        if (dataRender.length > 0) {
            dataRender.forEach((guest, index) => {
                result.push(
                    <div 
                        style={{
                            fontSize: '1.5rem',
                            width: '48%',
                            fontWeight:'600', 
                            position: 'relative',
                            background: indexGuestSelect === index ? 'yellow': 'white',
                            border:'1px solid black',
                            padding :' 4px',
                            margin: '2px',
                            cursor: 'pointer',
                        }}
                        onClick={()=> {
                            props.onChangeValueMakeRoom(index, "indexGuestSelect")
                        }}
                    >
                        <div>{index+1}. {guest.name}</div>
                        <div style={{
                                position:'absolute', 
                                top:'4px',
                                right:'8px',
                                color: 'red',
                            }}
                            onClick={()=> props.onClearGuest(guest, index)}
                            >X</div>
                    </div>
                   
                )
            })
        }
        return result;
    }

    function handleChangeListChangeGuest(value) {
        props.handleChangeListChangeGuest(value)
    }

    function onChangeGuestDataAfterEdit(value, index) {
        props.onChangeGuestDataAfterEdit(value, index)
    }

    function onChangeValueMakeRoom(value, target, option) {
        props.onChangeValueMakeRoom(value, target, option)
    }

    return (
        <div style={style.container}>
            <h3 className="ml-2">Guest Select: ({listGuestSelect.length} Guest{listGuestSelect.length <=1 ? "" :"s"})</h3>
            {/* {renderGuestSelect(listGuestSelect)} */}
            <div className='d-flex mb-3' style={{flexWrap:'wrap'}}>
                {renderGuestSelect(listGuestSelect)}    
            </div>

            {listGuestSelect.length > 0 && 
            <>
                <h4 className="ml-2">Guest information:</h4>
                {/* <MakeRoomGuestSelect
                    handleChangeListChangeGuest={props.handleChangeListChangeGuest}
                    onChangeGuest={props.onChangeGuest}
                    onClearGuest={props.onClearGuest}
                    isEdit={isEdit}
                    index={selectGuest}
                    data={listGuestSelect[selectGuest]}
                    style={style} />  */}
                <ModalSupportOption
                    type={3}
                    // featureSupport1={onHandleGuestData}
                    // featureSupport2={closeModal}
                    itemOption={{ 
                        data: listGuestSelect[indexGuestSelect] ,
                        index: indexGuestSelect,
                        isEdit: isEdit,
                        createNew: false,
                        type : "listGuestSelect" ,
                        handleChangeListChangeGuest,
                        onChangeGuestDataAfterEdit,
                        onChangeValueMakeRoom,
                    }} />
            </>}
        </div>
    )
};

export default MakeRoomGuestSelectList;
