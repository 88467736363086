import React, { useState, useEffect, } from 'react';
import * as companyAPI from '../../../api/company';
import { useSelector, useDispatch } from 'react-redux';
import { unitsAction } from '../../../redux/actions';
//import { UserContext } from '../../../container/listGuestSelect';
import { informationToast, errorToast, } from '../../../utils/Toast/index';
import { uploadPassPort } from '../../../api/guest';
import DatePicker from "react-datepicker";

import { LIST_GUEST_ID_BLOCK } from '../../../constaints/listID/listID';
import "react-datepicker/dist/react-datepicker.css";
import './makeRoomGuest.css';
// import { checkDayWithOther2Day } from '../../../utils/Date/SupportDate';
// import axios from 'axios';

function GuestInformationDetails(props) {
    const { data, index } = props;
    // console.log("props on GuestInformationDetails", props)
    const linkPassPortDefault = "/img/passport/avatar.png"
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const [listSuggestCompany, setListSuggestCompany] = useState([]);
    const [selectRemark, setSelectRemark] = useState("remark");
    const [inputCompanyName,setInputCompanyName] = useState("");
    const [file, setFile] = useState(null);
    const [dataState, setDataState] = useState({
        _id: "",
        loading: true,
        createNew: true,
        isEdit: true,
        name: "",
        email1: "",
        birth_day: "",
        gender: false,
        passport_number: "",
        passport: linkPassPortDefault, 
        phone: "",
        special: {
            smoking: false,
            pillow: false,
            high: false,
            other: ""
        },
        company_id: 0,
        company_name: "",
        company_details: {
            represent_name: "",
            contract: false,
            address: "",
            special: "",
            special_rate: 0,
        },
        remark: "",
        remarkVAT: "",
    });
    const [isClearCompany,setIsClearCompany] = useState(false);
    const auth = useSelector(state => state.auth);
    const { role, branchID } = auth.user;
    //const today = new Date()
    //  const { handleChangeListChangeGuest } = useContext(UserContext);
    //console.log("data", data)
    
    // console.log("props.data.type", props)
    //  console.log("data dataState", dataState)
    useEffect(() => {
        //console.log("vo herer");
        const findCompany = async () => {
            if (props.data.type !== "listGuestSelect") {
                // make default data
                let findCompanyResults = {
                    data : {
                        represent_name: "",
                        contract: "",
                        address: "",
                        special: "",
                        special_rate: "",
                    }
                }

                if(data.data.company_id ) {
                    if(data.data.company_id !== 0) { // have not company
                        findCompanyResults = await companyAPI.findCompanyByRequest("company_id", data.data.company_id , token);
                    }
                }

                setInputCompanyName(data.data.company_name);
                setDataState({
                    name: data.name,
                    email1: data.email1,
                    birth_day: data.birth_day,
                    gender: data.gender,
                    passport_number: data.passport_number,
                    passport: data.passport,
                    special: data.special,
                    phone: data.phone,
                    remark: data.remark,

                    isEdit: data.isEdit && (role === 0 || role === 2 || role === 3),
                    loading: false,
                    createNew: false,
                    company_details: data ? {
                        represent_name: findCompanyResults.data.represent_name,
                        contract: findCompanyResults.data.contract,
                        address: findCompanyResults.data.address,
                        special: findCompanyResults.data.special,
                        special_rate: findCompanyResults.data.special_rate,
                    } : {
                        represent_name: "",
                        contract: "",
                        address: "",
                        special: "",
                        special_rate: "",
                    },
                });
            } else {
                if (data.data.inputCompanyName === undefined) {
                    setInputCompanyName(data.data.company_name);
                } else {
                    setInputCompanyName(data.data.inputCompanyName);
                }
        
                if("company_details" in data.data) { 
                    setDataState({
                        ...data.data,
                        isEdit: data.data.isEdit && (role === 0 || role === 2 || role === 3),
                        loading: false,
                        createNew: false,
                        company_name: data.data.company_name,
                        company_details: {
                            company_name: data.data.company_details.company_name,
                            represent_name: data.data.company_details.represent_name,
                            contract: data.data.company_details.contract,
                            address: data.data.company_details.address,
                            special: data.data.company_details.company_special,
                            special_rate: data.data.company_details.special_rate,
    
                        }
                    });
                } else {
                    setDataState({
                        ...data.data,
                        isEdit: data.data.isEdit && (role === 0 || role === 2 || role === 3),
                        loading: false,
                        createNew: false,
                        company_name: data.data.company_name,
                        company_details: {
                            represent_name: data.data.represent_name,
                            contract: data.data.contract,
                            address: data.data.address,
                            special: data.data.company_special,
                            special_rate: data.data.special_rate,
                        }
                    });
                }

            }
        }

        if (props.data.type === 'makeRoom') {
           // console.log("vo here 1")
            setDataState({
                ...dataState,
                isEdit: data.isEdit,
                loading: false,
                createNew: true,
                name: data.guestName
            })
        } else if (props.data.type === 'Edit Guest') {
            // console.log("vo here 2")
            // console.log("data", data)
            setDataState({
                ...data.data,
                company_name: data.data.company_name,
                company_details: {
                    represent_name: data.data.represent_name,
                    contract: data.data.contract,
                    address: data.data.address,
                    special: data.data.company_special,
                    special_rate: data.data.special_rate,
                },
                isEdit: LIST_GUEST_ID_BLOCK.includes(data.data._id) ? false:  data.isEdit,
                loading: false,
                createNew: false,
                name: data.data.name
            });
            setInputCompanyName(data.data.company_name);
        }  else {
           // console.log("vo here 3")
            if(data.data) findCompany();
        }

    }, [token, data]);

    useEffect(()=>{
        setInputCompanyName(dataState.company_name);
        setListSuggestCompany([]);
    },[dataState.company_name])

    const handleFileChange = (e) => {
        // if (Number(branchID) === 15)
        // else setFile(e.target.files[0]);    
        handleImageChange(e)    
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement("canvas");    
                const MAX_WIDTH = 1500; // Set maximum width for the resized image
                const scaleFactor = MAX_WIDTH / img.width;
                canvas.width = MAX_WIDTH;
                canvas.height = img.height * scaleFactor;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              //  const resizedDataURL = canvas.toDataURL("image/png");
                let resizedDataURL 
                canvas.toBlob((blob) => {
                    resizedDataURL = new File([blob], "fileName.jpg", { type: "image/jpeg" })
                    setFile(resizedDataURL);
                }, 'image/jpeg');
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);
    };

    const handleUploadPassport = async () => {
        const formData = new FormData();
        formData.append('image', file);
        //formData.append('image_data', file);
        // image_data

        try {
          const response = await fetch('/upload', { //+dataState._id
            method: 'POST',
            body: formData,
            headers: { Authorization: dataState._id, },
          });
    
          //console.log("response", response);

          if (response.ok) {
            const data = await response.json();
            //console.log('URL ảnh đã tải lên:', data.imageUrl);
            
            const responseUpPassport = await uploadPassPort(dataState._id, { link:data.imageUrl }, token);

            //console.log("responseUpPassport", responseUpPassport);

            if(responseUpPassport) {
                if(responseUpPassport.data.status === 1) informationToast(responseUpPassport.data.msg);
                else errorToast(responseUpPassport.data.msg);
            }
            // update to server
            setDataState({
                ...dataState,
                passport: data.imageUrl
            })
          } else {
            errorToast("Error when upload Passport, Please check the photo size < 1Mb")
            //console.error('Lỗi tải lên ảnh.');
          }
        } catch (error) {
          console.error('Lỗi kết nối máy chủ:', error);
        }
    };

    function onBlueData(inputCompanyName) {
        if(data.type === "listGuestSelect") {
            const newData = {...dataState}
            if (inputCompanyName === "" && dataState.company_details.company_name !== "") {
                setIsClearCompany(true);
            }
            //newData.company_details
            newData.inputCompanyName = inputCompanyName;
          //  console.log("newData on Blue Data", newData)
            data.handleChangeListChangeGuest(newData);
            data.onChangeGuestDataAfterEdit(newData, data.index);
        }
    }

    function onClearCompanyDetails() {
        const newData = {...dataState}

        if(data.type === "listGuestSelect") {
            newData.company_details = {
                represent_name: "",
                contract: false,
                address: "",
                special: "",
                special_rate: 0,
            }

            newData.company_name= "";
            newData.represent_name = "";
            newData.address = "";
            newData.contract = false
            newData.tax = "";
            newData.company_special= "";
            newData.special_rate = 0;
            newData.company_id = 0;

            data.onChangeGuestDataAfterEdit(newData, data.index);
        } else {
            newData.company_details = {
                represent_name: "",
                contract: false,
                address: "",
                special: "",
                special_rate: 0,
            }
            setDataState(newData);
        }
        data.handleChangeListChangeGuest(newData);
        setIsClearCompany(false);
    }

    function changeSpecialRequest(target, boolean) {
        let newDataReturn;
        // console.log("target", target)
        // console.log("boolean", boolean)

        if(target === "smoking" && !boolean) {
            if(dataState.remark ==="") newDataReturn = "Non-Smoking, " + dataState.remark 
            else if(dataState.remark.includes("Smoking,")) newDataReturn = dataState.remark.replace("Smoking", "Non-Smoking")
            else newDataReturn = "Non-Smoking," + dataState.remark 
        }

        if(target === "smoking" && boolean) {
            if(dataState.remark ==="") newDataReturn = "Smoking," + dataState.remark 
            else if(dataState.remark.includes("Smoking")) newDataReturn = dataState.remark.replace("Non-Smoking", "Smoking")
            else newDataReturn = "Smoking," + dataState.remark 
        }

        if(target === "male" && !boolean) {
            if(dataState.remark ==="") newDataReturn = "";
            else if(dataState.remark.includes("Female,")) newDataReturn = dataState.remark.replace("Female,", "")
            else newDataReturn = "Female, " + dataState.remark 
        }

        if(target === "male" && boolean) newDataReturn = "Female, " + dataState.remark;

        if(target === "high" && !boolean) {
            if(dataState.remark ==="") newDataReturn = "";
            else if(dataState.remark.includes("High Floor,")) newDataReturn = dataState.remark.replace("High Floor,", "")
            else newDataReturn = "High Floor," + dataState.remark 
        }

        if(target === "high" && boolean) newDataReturn = "High Floor," + dataState.remark;

        if(target === "pillow" && !boolean) {
            if(dataState.remark ==="") newDataReturn = "";
            else if(dataState.remark.includes("Pillow,")) newDataReturn = dataState.remark.replace("Pillow,", "")
            else newDataReturn = "Pillow," + dataState.remark 
        }

        if(target === "pillow" && boolean) newDataReturn = "Pillow," + dataState.remark;

        return newDataReturn;
    }

    function onChangeData(value, target, option) {
        const newData = { ...dataState };
        switch (target) {
            case "special": {
                if (option === "other") {
                    
                } else {
                    newData.special[option] = !newData.special[option];
                    //if(newData.remark === "" && newData.special[option]) 
                    newData.remark = changeSpecialRequest(option, newData.special[option]) ;
                   // else if(newData.remark === changeSpecialRequest(option) ) newData.remark = "" ;
                }
                break;
            }
            case "male": {
                newData.gender = !newData.gender;
                newData.remark = changeSpecialRequest(target, newData.gender) ;
                break;
            }
            case "company_suggest": {
                //console.log("value company_suggest", value)
               // setInputCompanyName(value.name);
               
                newData.company_id = value.company_id;
                newData.company_name = value.name;
                newData.company_details = {
                    company_name : value.name,
                    represent_name: value.represent_name,
                    address: value.address,
                    phone: value.phone,
                    tax: value.tax, 
                    contract: value.contract,
                    special: value.special,
                    special_rate: value.special_rate,
                }
                break;
            }
            case "company_name": {
                newData[target] = value
                onChangeFindCompany(value)
                // setListSuggestCompany([{ company_name: "Azumaya", company_id: 1 }, { company_name: "Azumaya2", company_id: 2 }]);
                break;
            }
            case "birth_day": {
            //   //  console.log("value", value);
            //    const check = checkDayWithOther2Day(value, new Date());
            //  //  console.log("check", check)
            //    if(check === 1 || check === 0) return errorToast("Date of birth must be less than current date");
            //    else newData[target] = value;

               newData[target] = value;
               break;
            }
            default: newData[target] = value;
        };

        if(data.type === "listGuestSelect") {
            // console.log("vo here data.type === listGuestSelect")
            data.handleChangeListChangeGuest(newData);
            data.onChangeGuestDataAfterEdit(newData, data.index);
            // data.handleChangeListChangeGuest(newData);
            //data.onChangeGuestDataAfterEdit(newData, data.index)
        }
       // console.log("newData", newData);
        setDataState(newData);
    }

    function onChangeInputCompany(data) {
        setInputCompanyName(data);
        onChangeFindCompany(data);
    }

    function onChangeFindCompany(value) {
        dispatch(unitsAction.delay());
        dispatch(unitsAction.delay());
        dispatch(unitsAction.delay());
        if (value.length >= 3) {
            const findCompany = async () => {
                const findCompanyResults = await companyAPI.findCompanyByRequest("quick_name", value, token);
                if (findCompanyResults) { setListSuggestCompany(findCompanyResults.data); }
            }
            findCompany();
        } else {
            setListSuggestCompany([])
        }
    };

    function onCheckCompanyName() {
        let dataUpdate = { ...dataState };
        if (inputCompanyName === "") {
            dataUpdate.company_name = "";
            dataUpdate.company_id = 0;
            dataUpdate.company_contract = false;
            setDataState(dataUpdate);
        }

        props.onSave(dataUpdate, !data.createNew ? "change" : "create_new", data.index)
    }

    function renderSuggestCompany() {
        var result = [];

        if (listSuggestCompany.length > 0) {
            listSuggestCompany.forEach((company, index) => {
                result.push(<div style={{
                    cursor: 'pointer',
                    padding: '6px',
                    borderBottom: index !== listSuggestCompany.length - 1 ? '1px solid aqua' : ""
                }}
                    onClick={() => 
                        {
                           //  console.log("vo here")
                           // setInputCompanyName(company.name);
                            onChangeData(company, "company_suggest", "")
                        }
                    }
               >{company.name}</div>)
          //      >{inputCompanyName}</div>)
            });
        }
        return result;
    };

    function renderCompanyDetails() {
        const { represent_name, address, phone, tax, special, special_rate, contract } = dataState.company_details
        return (<table 
        //     style={{ 
        //     border: '1px solid', 
        //     backgroundColor: "#efefef", 
        //     width: "90%", 
        //     marginLeft: '34px', 
        //     marginTop: '4px',
        //     zIndex: '99',
        // } }

            className={"collapse show makeRoom_company_detail"} id={"company" + index}>
            <tbody>
                <tr style={{ fontSize: '1.3rem', textAlign: 'left', }}>
                    <td style={{ width: '50px', border: '1px solid' }}>Company Name:</td>
                    <td style={{ border: '0.2px solid black', color: contract ? 'black' : 'red' }}>{dataState.company_name}</td>
                </tr>
                <tr style={{ fontSize: '1.3rem', textAlign: 'left' }}>
                    <td style={{ width: '55px', border: '0.2px solid black' }}>Represent'sName:</td>
                    <td style={{ border: '0.2px solid black' }}>{represent_name}</td>
                </tr>
                <tr style={{ fontSize: '1.3rem', textAlign: 'left' }}>
                    <td style={{ width: '55px', border: '0.2px solid black' }}>Address:</td>
                    <td style={{ border: '0.2px solid black' }}>{address}</td>
                </tr>
                <tr style={{ fontSize: '1.3rem', textAlign: 'left' }}>
                    <td style={{ width: '55px', border: '0.2px solid black',  }}>Contract	:</td>
                    <td style={{ border: '0.2px solid black', color: contract ? 'black' : 'red'  }}>{contract ? "Yes": "No"}</td>
                </tr>
                <tr style={{ fontSize: '1.3rem', textAlign: 'left' }}>
                    <td style={{ width: '55px', border: '0.2px solid black' }}>Tax	:</td>
                    <td style={{ border: '0.2px solid black' }}>{tax}</td>
                </tr>
                <tr style={{ fontSize: '1.3rem', textAlign: 'left' }}>
                    <td style={{ width: '55px', border: '0.2px solid black' }}>Phone	:</td>
                    <td style={{ border: '0.2px solid black' }}>{phone}</td>
                </tr>
                <tr style={{ fontSize: '1.3rem', textAlign: 'left' }}>
                    <td style={{ width: '55px', border: '0.2px solid black' }}>Special	:</td>
                    <td style={{ border: '0.2px solid black' }}>{special}</td>
                </tr>
                <tr style={{ fontSize: '1.3rem', textAlign: 'left' }}>
                    <td style={{ width: '55px', border: '0.2px solid black' }}>Special	Rate:</td>
                    <td style={{ border: '0.2px solid black' }}>{special_rate}</td>
                </tr>
            </tbody>
        </table>);

    }

    function getDate(date) {
        return (new Date(date)).getDate();
    }

    const renderDayContents = (day, date) => {
        return <span
        style={{
            // border:"0.5px solid black", 
            padding : "4px"
        }} >{getDate(date)}</span>;
    };

    return (
        <>
            {!dataState.loading
                ?
                // height: '570px', minHeight: '570px',
                <div style={{  maxHeight: '570px', overflowY: 'scroll' }}>
                    <div className="d-flex justify-content-between">
                        <div style={{ width: '120%',flex: 2 }}>
                            <div className='makeRoom_children '>
                                <label className='makeRoom_label'>Name(*)</label>
                                <input
                                    type="text"
                                    maxlength="150"
                                    style={{ width: '80%' }}
                                    value={dataState.name}
                                    disabled={!dataState.isEdit}
                                    onChange={e => onChangeData(e.target.value.toUpperCase(), "name", "")}
                                    onBlur={e=>{
                                        if(e.target.value !== dataState.name) onBlueData()
                                    }}
                                />
                            </div>
                            <div style={{ position: 'relative' }} className='makeRoom_children ' >
                                <label className='makeRoom_label'
                                    data-toggle="collapse"
                                    data-target={"#company"+ index}
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls={"company" + index} >Company</label>
                                <input
                                    type="text"
                                    // maxlength="150"
                                    style={{ width: '80%' }}
                                    value={inputCompanyName}
                                    disabled={!dataState.isEdit}
                                    onChange={e => onChangeInputCompany(e.target.value)}
                                    onBlur={e => {
                                        if(dataState.company_details.address === "" && listSuggestCompany.length === 0) {
                                            setInputCompanyName("")
                                            onBlueData()
                                        } else {
                                            if(e.target.value !== dataState.company_name) {
                                                onBlueData(e.target.value)
                                            }
                                        }
                                    }}
                                />

                                <div style={{ display: isClearCompany ? 'flex' : 'none', }}
                                     className='makeRoom_children-company-clear_company_title'
                                    >Remove company from guest ?  
                                        <div style={{marginLeft:'8px', background:'green',}}
                                             className='makeRoom_children-company-clear_company_button'
                                             onClick={()=> onClearCompanyDetails()}
                                                >V</div>
                                        <div style={{background:'red',}}
                                             className='makeRoom_children-company-clear_company_button'
                                             onClick={()=>{
                                                setIsClearCompany(false);
                                                setInputCompanyName(data.data.company_name);
                                             }}
                                            >X</div>
                                    </div>

                                <div style={{
                                    border: listSuggestCompany.length === 0 ? "unset" : '1px solid red',
                                }}
                                className="makeRoom-suggest-company-list">
                                    {renderSuggestCompany()}
                                </div>
                                {dataState.company_id !== 0 && renderCompanyDetails()}
                            </div>

                            <div className='makeRoom_children d-flex justify-content-between'>
                                <div style={{width:'40%'}} className='d-flex'>
                                    <div className='makeRoom_label' style={{width:'40%'}}>Birth Day</div>
                                    {/* style={{marginLeft: data.type === "listGuestSelect" ? '4px' :'36px'}} */}
                                    <div className='makeRoom_text' style={{ width:'60%' }}>
                                        <DatePicker
                                            selected={dataState.birth_day === "" ? "" : new Date(dataState.birth_day)}
                                            dateFormat="dd/MM/yyyy"
                                            // endDate = {new Date("2023-11-18")}
                                            disabled={!dataState.isEdit}
                                            onChange={(date) => onChangeData(date, "birth_day", index)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="Empty date"
                                            renderDayContents={renderDayContents}
                                            />
                                    </div>
                                </div>
                                {/* <label className='makeRoom_label'>Phone</label>
                                <input type="text" style={{ width: '30%' }}
                                    value={dataState.phone}
                                    disabled={!dataState.isEdit}
                                    onChange={e => onChangeData(e.target.value, "phone", "")}
                                /> */}
                                <div style={{width:'50%'}} className='d-flex'>
                                    <div className='makeRoom_label mr-1' style={{width:'30.5%', textAlign:'end'}}>Passport</div>
                                    <input type="text" style={{ width:'60%', fontSize:'1.2rem' }}
                                        maxlength="20"
                                        value={dataState.passport_number}
                                        disabled={!dataState.isEdit}
                                        onChange={e => onChangeData(e.target.value, "passport_number", "")}
                                    />
                                </div>
                            </div>
                      
                            <div className='makeRoom_children'>
                                <label className='makeRoom_label'>Phone</label>
                                <input type="text" style={{ width: '80%', fontSize:'1.3rem' }}
                                    value={dataState.phone}
                                    disabled={!dataState.isEdit}
                                    onChange={e => onChangeData(e.target.value, "phone", "")}
                                />
                            </div>
                            <div className='makeRoom_children'>
                                <label className='makeRoom_label'>Email(*)</label>
                                <input type="email" style={{ width: '80%', fontSize:'1.3rem' }}
                                    maxlength="150"
                                    value={dataState.email1}
                                    disabled={!dataState.isEdit}
                                    onChange={e => onChangeData(e.target.value, "email1", "")}
                                />
                            </div>
                            <div className="d-flex">
                                <div class="input-group-prepend ">
                                    <div class="input-group-text">
                                        <input type="checkbox"
                                            id="gender"
                                            aria-label="Checkbox for following text input"
                                            checked={dataState.gender}
                                            disabled={!dataState.isEdit}
                                            onChange={e => onChangeData(e.target.checked, "male", "")}
                                        />
                                        <label className='makeRoom_text' for="gender">Female</label>
                                    </div>
                                </div>
                                <div class="input-group-prepend ">
                                    <div class="input-group-text">
                                        <input
                                            type="checkbox"
                                            id="smoking"
                                            aria-label="Checkbox for following text input"
                                            checked={!dataState.special.smoking}
                                            disabled={!dataState.isEdit}
                                            onChange={e => onChangeData(e.target.checked, "special", "smoking")}
                                        />
                                        <label className='makeRoom_text' for="smoking">Non-Smoking</label>
                                    </div>
                                </div>
                                <div class="input-group-prepend ">
                                    <div class="input-group-text">
                                        <input type="checkbox"
                                            id="high"
                                            aria-label="Checkbox for following text input"
                                            checked={dataState.special.high}
                                            disabled={!dataState.isEdit}
                                            onChange={e => onChangeData(e.target.checked, "special", "high")}
                                        />
                                        <label className='makeRoom_text' for="high">High Floor</label>
                                    </div>
                                </div>

                                {
                                    (branchID !== 97 && branchID !== 11 ) && <div class="input-group-prepend ">
                                    <div class="input-group-text">
                                        <input type="checkbox"
                                            id="pillow"
                                            aria-label="Checkbox for following text input"
                                            checked={dataState.special.pillow}
                                            disabled={!dataState.isEdit}
                                            onChange={e => onChangeData(e.target.checked, "special", "pillow")}
                                        />
                                        <label className='makeRoom_text' for="pillow">Low-pillow</label>
                                    </div>
                                </div>
                                }
                                
                            </div>

                            <div className='makeRoom_children'>
                                <label style={{
                                    width: '50%',
                                    padding: '2px',
                                    border: '1px solid black',
                                    backgroundColor: selectRemark === "remark" ? "yellow" : "white",
                                }}
                                    className='makeRoom_label'
                                    onClick={() => setSelectRemark("remark")}
                                >
                                    Remark</label>
                                <label style={{
                                    width: '50%',
                                    padding: '2px',
                                    border: '1px solid black',
                                    backgroundColor: selectRemark === "remarkVAT" ? "yellow" : "white",
                                }}
                                   className='makeRoom_label'
                                    onClick={() => setSelectRemark("remarkVAT")}
                                >Remark VAT</label>
                                <textarea style={{ width: '100%', height: '120px',fontSize: '1.2rem'}}
                                    // maxlength="512"
                                    disabled={!dataState.isEdit}
                                    onChange={e => onChangeData(e.target.value, selectRemark, "")}
                                    value={dataState[selectRemark]}></textarea>
                            </div>
                        </div>
                        <div className='makeRoom_children' style={{flex:1}}>
                            <div>
                                <img
                                    alt="passport"
                                    style={{ width: '100%', height: props.data.type === 'Edit Guest' ? '400px' : '300px' }}
                                    src={dataState.passport === "" ? linkPassPortDefault : 'https://database.azumayareport.com' + dataState.passport + "?v=" + (new Date().getTime()) } />
                                    {
                                        data.type !== "listGuestSelect" ? <>
                                        <input type="file" accept="image/*" name="file" onChange={handleFileChange}  />
                                        <div className='mt-1'>
                                            <button className='btn btn-primary' onClick={handleUploadPassport} >Update</button>
                                            <button className="btn btn-warning" href={dataState.passport} 
                                                    onClick={props.data.type === "listGuestSelect" ? () => props.data.onChangeValueMakeRoom("","viewPassPort")
                                                                      :() => window.open(dataState.passport, "_blank")}
                                                    target="_blank" 
                                                    rel="noreferrer">View Full</button>
                                        </div>
                                    </> :
                                        !LIST_GUEST_ID_BLOCK.includes(data.data._id) && <>
                                            <input type="file" accept="image/*" name="file" onChange={handleFileChange}  />
                                            <div className='mt-1'>
                                                <button className='btn btn-primary' onClick={handleUploadPassport} >Update</button>
                                                <button className="btn btn-warning" href={dataState.passport} 
                                                    onClick={props.data.type === "listGuestSelect" ? () => props.data.onChangeValueMakeRoom("","viewPassPort")
                                                                      :() => window.open(dataState.passport, "_blank")}
                                                    target="_blank"
                                                    rel="noreferrer">View Full</button>
                                            </div>
                                        </>
                                    }
                            </div>
                        </div>

                    </div>
                    <div className={data.type === "listGuestSelect" ? "op-hidden" :"mt-1"}>
                        <button className="btn btn-primary"
                            onClick={() => onCheckCompanyName()}>
                            {!data.createNew ? "Save Edit Guest" : "Make New"}
                        </button>
                        <button className={data.createNew ? "op-hidden" : "btn btn-danger ml-2"}
                            onClick={() => props.onDelete(dataState._id)}>
                            {data.createNew  ? "" : "Delete"}
                        </button>
                        <button className="btn btn-danger ml-2"
                            onClick={() => props.onClose()}>Cancel</button>
                    </div>
                </div>
                : ""}
        </>
    )
}

export default GuestInformationDetails;
