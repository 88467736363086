import { unitsAction, getType } from '../../actions/index';

const initialState = {
    name: "",
    address: "",
    phone: "",
    listBranchArea: [],
    bookingRequest1: [],
    signature : {
        list: [],
        select : []
    },
}

const branchReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(unitsAction.fetchBranchInformation): {
            return action.payload;
        }
        case getType(unitsAction.resetDataBranchInformation): {
            return initialState;
        }
        default: return state;
    }
}
export default branchReducer;
