import React, { useState, useEffect } from 'react';

function ComponentSupportContentRequest1(props) {

    const [dataState, setDataState] = useState([]);

    useEffect(() => {
        setDataState(props.data)
    }, [props.data])

    function onChange(value, target, index) {
        const newDate = [...dataState];
        newDate[index][target] = value;
        setDataState(newDate);
    }

    function render() {
        var result = [];
        dataState.forEach((item, index) => {
            result.push(<div className="d-flex">
                <input
                    style={{ border: '1px solid black', width: '130px', padding: '8px' }}
                    onChange={e => onChange(e.target.value, "name", index)}
                    maxLength={20}
                    value={item.name}
                />

                <textarea
                    onChange={e => onChange(e.target.value, "value", index)}
                    style={{ border: '1px solid black', width: '330px', padding: '8px' }}
                    value={item.value}
                />
            </div>)
        })
        return result;
    }

    return (
        <>
            <div className="ml-3 mr-2">
                <div className="d-flex">
                    <div style={{ border: '1px solid black', width: '130px', padding: '8px' }}>Show Name</div>
                    <div style={{ border: '1px solid black', width: '330px', padding: '8px' }}>Content</div>
                </div>
                {render()}
            </div >
            <div className="mt-2">
                <button className="btn btn-primary"
                    onClick={() => props.onSave(dataState)}
                >Config</button>
                <button className="btn btn-danger ml-2"
                    onClick={props.onClose}
                >Cancel</button>
            </div>
        </>
    )
}

export default ComponentSupportContentRequest1;
