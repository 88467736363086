import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import DropDownSelectBranch from  './DropDownSelectBranch';

MultiBranchSelect.propTypes = {
};

function MultiBranchSelect(props) {
  const auth = useSelector(state => state.auth);
  const { role, branchID } = auth.user;

  const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);
  const isMultiArea = (role === 7 || role === 1 || role === 8);

  return (
    <>
      <div className={(isMultiArea) ? "" : "op-hidden"}>
        <DropDownSelectBranch
          onSelect={props.changeSelectArea}
          type="area"
          selectValue={props.selectArea}
          listRender={props.listArea}
        />
      </div>
      <div className={(isMultiBranch || (role === 4 && branchID === 1)) ? "" : "op-hidden"}>
        <DropDownSelectBranch
          onSelect={props.changeSelectBranchID}
          type="branch"
          selectValue={props.selectBranch}
          listRender={props.listBranch}
        />
      </div>
    </>
  );
}

export default memo(MultiBranchSelect);
