import axios from 'axios';

export const loadDataGlist = (branchID, day,token) => {
    let newURL = "/glist/" + branchID + "/take_data/" + day;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const updateGlist = (branchID, day,data, token) => {
    let newURL = "/glist/" + branchID + "/update/" + day;
    return axios.post(`${newURL}`,data, {
        headers: { Authorization: token }
    });
}

export const makeStatus = (branchID, day,data, token) => {
    let newURL = "/glist/" + branchID + "/make_status/" + day;
    return axios.post(`${newURL}`,data, {
        headers: { Authorization: token }
    });
}

export const loadOldNote = (branchID, day, token) => {
    let newURL = "/glist/" + branchID + "/find_old_note/" + day;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

