import axios from 'axios';

export const takeServiceNote = (branchID, id, token) => {
    let newURL = "/invoice/" + branchID + "/take_service_note/" + id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const updateServiceNote = (branchID, data, id, token) => {
    let newURL = "/invoice/" + branchID + "/update_service_note/" + id;
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const updateHotelInvoice = (branchID, data, id, token) => {
    let newURL = "/invoice/" + branchID + "/update_hotel_invoice/" + id;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

// router.get('/:branchID/check_count_hotel_invoice/:id', auth, invoiceCtrl.checkCountHotelInvoiceNumberWithBookingID);
export const checkCountHotelInvoiceNumberWithBookingID = (branchID, id, token) => {
    let newURL = "/invoice/" + branchID + "/check_count_hotel_invoice/" + id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const saveHotelInvoicePdf = (branchID, data, token) => {
    let newURL = "/invoice/" + branchID + "/save_pdf_test"
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const printHotelInvoiceWithID = (branchID, data, id, token) => {
    let newURL = "/invoice/" + branchID + "/print_hotel_invoice_with_id/" + id
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const checkInvoiceNumberWithBooking = (data, token) => {
    //check_increase_invoice_number
    let newURL = "/invoice/check_increase_invoice_number";
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const getInvoiceWithMonth = (branchID, month, token) => {
    let newURL = "/invoice/" + branchID + "/get_invoice_with_month/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const getPdfByLink = (branch, name, token) => {
    let newURL = "/invoice_file/invoice/" + branch + "/" + name;
    return axios.get(`${newURL}`, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/vnd.ms-excel',
            'Access-Control-Expose-Headers': 'X-Suggested-Filename',
            Authorization: token
        }
    });
}

export const checkScheduleChange = (branchID, data, token) => {
    let newURL = "/invoice/" + branchID + "/check_schedule_change";
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const takeDataWithHotelInvoice = (branchID, data, token) => {
    let newURL = "/invoice/" + branchID + "/get_invoice_with_type2/invoice_id_pmrq";
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

                // const dataResult = await axios.post(
                //     `${AZ_DATABASE_URL}/invoice/${branch}/get_invoice_with_type/${type}`,
                //     dataOnBody,
                //     {headers: { Authorization: CODE_AUTH_OTHER_BRANCH }})