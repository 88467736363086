import React, { useState } from "react";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useRowSelect } from 'react-table'
import NumberFormat from 'react-number-format';
import DropDownAction from "../../../features/DropDown";

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    data,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    isAutomaticInput0,
    // onChange,
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    const onChange = e => {
        setValue(e.target.value)
    }
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        if (isAutomaticInput0 && id === 'rate') {
            setValue(value + ",000")
            updateMyData(value + ",000", id, index)
        } else updateMyData(value, id, index)
    }

    const onChangeItem = (value, id, index) => {
        setValue(value)
        updateMyData(value, id, index)
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    switch (id) {
        case "selection": return <input
            style={{ width: '120px' }}
            type="date"
            value={value}
            onChange={onChange}
            onBlur={onBlur} />
        case "date": return <input
            style={{ width: '120px' }}
            type="date"
            value={value}
            onChange={onChange}
            onBlur={onBlur} />
        case "content": return <DropDownAction
            value={value}
            target="content"
            indexData={index}
            data={data[index]}
            onChange={onChangeItem}
            onBlur={onBlur}
            dropDownItem={{
                "yellow": ["Laundry", "Minibar"],
                "prime": ["Pick up", "Drop off", "Rent car",],
                blue: ["Massage 40", "Massage 70", "Massage 100"]
            }
            } />
        // <input
        //     style={{ width: '200px', textAlign: 'left' }}
        //     value={value}
        //     onChange={onChange}
        //     onBlur={onBlur} />
        case "rate": return <NumberFormat
              thousandSeparator={true}
              type="text"
              name="pickupPrice" 
              min="0"
              style={{ width: '120px', textAlign: 'center' }}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
       />

        default: return <input value={value} onChange={onChange} onBlur={onBlur} />
    }
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    // const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span >
            Search:{' '}
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Enter key search...`}
                style={{
                    border: '1px solid black',
                    fontSize: '1.1rem',

                }}
            />
        </span>
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} style={{ border: '1px solid black' }} />
            </>
        )
    }
)


// Our table component
function Table({ columns, data, onChange, updateMyData, skipPageReset, onSelect, isAutomaticInput0}) {
    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )


    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: "",
            Cell: EditableCell,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,

        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            autoResetPage: !skipPageReset,
            filterTypes,
            onChange,
            updateMyData,
            isAutomaticInput0
        },
        useFilters, // useFilters!
        useGlobalFilter,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // Header: ({ getToggleAllPageRowsSelectedProps }) => (
                    //     <div>
                    //         <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()}
                    //         />
                    //     </div>
                    // ),
                    Cell: ({ row }) => (
                        <div >
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()}
                                onClick={() => onSelect(row.id)} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    )

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    // const firstPageRows = rows.slice(0, 15)

    return (
        <>
            <div className="mb-1" style={{ textAlign: 'left' }}>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </div>

            <div className="pagination mb-1" style={{ height: '20px', margin: 'auto' }}>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                    style={{ minWidth: '50px', fontSize: '1.1rem' }}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}
                    style={{ minWidth: '50px', fontSize: '1.1rem' }}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}
                    style={{ minWidth: '50px', fontSize: '1.1rem' }}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}
                    style={{ minWidth: '50px', fontSize: '1.1rem' }}>
                    {'>>'}
                </button>{' '}
                <span className="ml-3 mr-3">
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                {/* <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '} */}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>

            <table {...getTableProps()}
                style={{ margin: 'auto' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}
                                    style={{ border: '1px solid black' }}>
                                    {column.render('Header')}
                                    {/* Render the columns filter UI */}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                        style={{ ...cell.column.style }}
                                    >{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

filterGreaterThan.autoRemove = val => typeof val !== 'number'

function AddBookingTableComponent(props) {
    const { listSelect } = props;
    const [isAutomaticInput0,setIsAutomaticInput0] = useState(true)
    // const [skipPageReset, setSkipPageReset] = React.useState(false)

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'date',
                class: 'with-100',
                style: { fontSize: '1.3rem', width: '120px', border: '1px solid black' }
            },
            {
                Header: 'Service Content',
                accessor: 'content',
                class: 'with-100',
                style: { fontSize: '1.3rem', width: '200px', textAlign: 'left', paddingLeft: '4px', border: '1px solid black' },
                Filter: SelectColumnFilter,
                filter: 'includes',
            },
            {
                Header: 'Rate',
                accessor: 'rate',
                class: 'with-100',
                style: { fontSize: '1.3rem', width: '120px', border: '1px solid black' }
            },
        ],
        []
    )

    const data = React.useMemo(() => props.data, [props.data]);

    data.sort((a, b) => {
        if (a.date > b.date) return -1;
        else if (a.date < b.date) return 1;
        else return 0;
    });

    function onChange(value, target, option) {
        props.onChange(value, target, option)
    }

    function updateMyData(value, target, option) {
        props.onChange(value, target, option)
    }

    function onSelect(id) {
        if (listSelect.includes(id)) {
            listSelect.splice(id, 1);
        } else {
            listSelect.push(id);
        }
        props.setListSelect(listSelect)
    }

    return (
        <div className="mt-2"
            style={{
                display: 'flex',
                minHeight: '100px',
            }}>
            <div>
                <Table
                    updateMyData={updateMyData}
                    onChange={onChange}
                    onSelect={onSelect}
                    isAutomaticInput0={isAutomaticInput0}
                    columns={columns}
                    data={data} />
            </div>
            <div className="addService_btn ml-2"
                style={{ marginTop: '50px' }}>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                        onClick={()=>setIsAutomaticInput0(!isAutomaticInput0)}
                        checked={isAutomaticInput0}/>
                    <label className="form-check-label ml-1 mb-2" for="flexCheckChecked">
                        Automatic add ",000"
                    </label>
                </div>
                <div>
                    <button className="btn btn-danger"
                        onClick={() => props.onDeleteSelected()}
                    >-Delete</button>
                    <button className="btn btn-success ml-2"
                        onClick={() => props.onAddNewService()}>+Add More</button>
                </div>
                <div className="mt-2">
                    <button className="btn btn-primary" onClick={() => props.onSave()}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default AddBookingTableComponent;
