import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector, useDispatch } from 'react-redux';
import * as companyAPI from "../../api/company";
import { useState } from "react";
import { coverNameToID } from '../../utils/Branch/SupportBranch';
import { changeDataFullFormat } from '../../utils/Date/SupportDate';

function CompanyEditHistory(props) {
    const { id } = useParams();
    const token = useSelector(state => state.token);
    const [data, setData] = useState({});

    useEffect(()=> {
        // load data by Id
        if(token) {
            const loadDataCompany = async  () => {
                const dataResult = await companyAPI.findCompanyByRequest("company_id", id, token);
                // console.log("dataResult: " , dataResult.data[0])
                setData(dataResult.data[0]);
            }

            loadDataCompany();
        }
    },[token])

    function renderLogEdit() {
        let result = [];

        data.logEdit.forEach((item, index) => {
            result.push(
                <tr>
                <td style={{fontSize:'1.4rem', background: item.date ? "": "#58ced43b", border:'1px solid', padding: '4px', width:'100px'}}>
                    {item.date ? <>
                        <div>{item.date.slice(0,10)}</div>
                        <div>{item.date.slice(10,17)}</div>
                    </> : <>
                        <div>{changeDataFullFormat(data.createdAt).slice(0,10)}</div>
                        <div>{changeDataFullFormat(data.createdAt).slice(11,17)}</div>
                    </> }
                </td>
                <td style={{fontSize:'1.4rem', background: item.date ? "": "#58ced43b", border:'1px solid', padding: '4px', width:'300px'}}>{item.name}</td>
                <td style={{fontSize:'1.4rem', background: item.date ? "": "#58ced43b", border:'1px solid', padding: '4px'}}>{item.represent_name}</td>
                <td style={{fontSize:'1.4rem', background: item.date ? "": "#58ced43b", border:'1px solid', padding: '4px', width:'400px'}}>{item.address}</td>
                <td style={{fontSize:'1.4rem', background: item.date ? "": "#58ced43b", border:'1px solid', padding: '4px'}}>{item.tel}</td>
                <td style={{fontSize:'1.4rem', background: item.date ? "": "#58ced43b", border:'1px solid', padding: '4px'}}>{item.tax}</td>
                <td style={{fontSize:'1.4rem', background: item.date ? "": "#58ced43b", border:'1px solid', padding: '4px'}}>
                    {item.contract === undefined ? "" : (item.contract ? "Yes" : "No")}
                    </td>
                <td style={{fontSize:'1.4rem', background: item.date ? "": "#58ced43b", border:'1px solid', padding: '4px', width:'400px'}}>{item.special}</td>
                <td style={{fontSize:'1.4rem', background: item.date ? "": "#58ced43b", border:'1px solid', padding: '4px'}}>{item.date ? item.note : (index === 0 ? <div className="red-color">Initialization</div> : "")}</td>
                <td style={{fontSize:'1.4rem', background: item.date ? "": "#58ced43b", border:'1px solid', padding: '4px'}}>
                    { item.date ? "AZ"+coverNameToID(item.branchID) : data.branch}
                </td>
                {/* <td style={{fontSize:'1.4rem', border:'1px solid', padding: '4px'}}>AZ KM2</td> */}
            </tr>
            )
        })

        return result;
    }

    return (
        <div>
            <h1>View Edit Company History</h1>
            {Object.keys(data).length > 0 && <>
                <div>
                    <div>----</div>
                    <h2>Current data</h2>
                    <table style={{margin:'auto'}}>
                        <tr>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>ID</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Name</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Represent</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Address</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Phone</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Tax</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Contract</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Special</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Branch</th>
                        </tr>

                        <tr>
                            <td style={{fontSize:'1.4rem', border:'1px solid', padding: '4px'}}>{data.company_id}</td>
                            <td style={{fontSize:'1.4rem', border:'1px solid', padding: '4px', width:'300px'}}>{data.name}</td>
                            <td style={{fontSize:'1.4rem', border:'1px solid', padding: '4px'}}>{data.represent_name}</td>
                            <td style={{fontSize:'1.4rem', border:'1px solid', padding: '4px', width:'400px'}}>{data.address}</td>
                            <td style={{fontSize:'1.4rem', border:'1px solid', padding: '4px'}}>{data.tel}</td>
                            <td style={{fontSize:'1.4rem', border:'1px solid', padding: '4px'}}>{data.tax}</td>
                            <td style={{fontSize:'1.4rem', border:'1px solid', padding: '4px'}}>{data.contract ? "Yes" : "No"}</td>
                            <td style={{fontSize:'1.4rem', border:'1px solid', padding: '4px', width:'400px'}}>{data.special}</td>
                            <td style={{fontSize:'1.4rem', border:'1px solid', padding: '4px'}}>{data.branch}</td>
                        </tr>

                    </table>

                    <div>----</div>
                    <h2>Edit history</h2>
                    <table style={{margin:'auto'}}>
                        {data.logEdit.length > 0 && <tr>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Time Edit</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Name</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Represent</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Address</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Phone</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Tax</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Contract</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Special</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Reason</th>
                            <th style={{fontSize:'1.4rem', border:'1px solid', background:'yellow'}}>Branch</th>
                        </tr>}

                        {
                            data.logEdit.length > 0 ? renderLogEdit() : <div>
                                <h2>NOT DATA</h2>
                                <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1628744932/store/not-report_wq5mnq.png" alt="have not data"/>
                            </div>
                        }


                    </table>
                </div>
            </>}

            {/* Add your component here */}
        </div>
    );
}

export default CompanyEditHistory;