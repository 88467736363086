import React, { useEffect, useState } from 'react';
import { changeDataToDDMMYYYY } from '../../../utils/Date/SupportDate';

function ComponentSupportContentSortSchedule(props) {

    const { data } = props;
    const [dataState, setDataState] = useState({
        schedule:[],
        main: "",
    })
    // useEffect(() => {
    //     setDataState(props.data)
    // }, [props.data])

    useEffect(() => {
        setDataState({
            schedule: data.schedule,
            main: data.schedule[0].booking_id,
        })
    },[])

    function onChangeData(value, target) {
        const newData = {...dataState };

        function moveElementToFront(array, index) {
            if (index > 0 && index < array.length) {
                const element = array.splice(index, 1)[0]; // Remove the element at index and store it
                array.unshift(element); // Add the element to the beginning of the array
            }
            return array;
        }

        switch (target) {
            case "main" : 
                setDataState({
                    schedule: moveElementToFront(newData.schedule,value),
                    main: newData.schedule[0].booking_id,
                })
                break;
            case "sort": 
                if (value === "date") {
                    setDataState({
                        schedule: newData.schedule.sort((a,b) => {
                            if (a.checkin > b.checkin) return 1;
                            else if (a.checkin < b.checkin) return -1;
                            else return 0;
                        }),
                        main: newData.schedule[0].booking_id,
                    })
                }

                if (value === "room") {
                    setDataState({
                        schedule: newData.schedule.sort((a,b) => {
                            if (a.roomSelect > b.roomSelect) return 1;
                            else if (a.roomSelect < b.roomSelect) return -1;
                            else return 0;
                        }),
                        main: newData.schedule[0].booking_id,
                    })
                }
                break;
            default: break;
        }
    }


    function renderSchedule() {
        var result = [];
        if (dataState.schedule.length > 0) {
            dataState.schedule.forEach((schedule, index) => {
                //console.log("schedule", schedule)

                return result.push(<tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>
                        <input type="radio" 
                               name="main"
                               checked={schedule.booking_id === dataState.main}
                               onClick={() => onChangeData(index, "main")} ></input>
                    </td>
                    <td style={{ border: '1px solid black' }}>{schedule.roomSelect}</td>
                    <td style={{ border: '1px solid black' }}>{changeDataToDDMMYYYY(schedule.checkin)}</td>
                    <td style={{ border: '1px solid black' }}>{changeDataToDDMMYYYY(schedule.checkout)}</td>
                </tr>)
            })
        } else {
            result.push(<tr style={{ border: '1px solid black' }}>
                <td style={{ border: '1px solid black' }}>{dataState.roomSelect}</td>
                <td style={{ border: '1px solid black' }}>{changeDataToDDMMYYYY(dataState.checkin)}</td>
                <td style={{ border: '1px solid black' }}>{changeDataToDDMMYYYY(dataState.checkout)}</td>
            </tr>)
        }

        return result;
    }
    return (
        <>
            <div className="ml-3 mr-2">
                {
                    data.schedule && <>
                        <h4>You want to sort schedule by list?</h4>
                        {/* <h5 className='red-color'>* Select Main schedule</h5> */}

                        <div className="mt-1 mb-2">
                            <button className="btn btn-light mr-2" onClick={() => onChangeData("room", "sort")}>Sort room</button>
                            <button className="btn btn-light " onClick={() => onChangeData("date", "sort")}> Sort date</button>
                        </div>

                        <table style={{ margin: 'auto' }}>
                            <tr style={{ border: '1px solid black' }}>
                                <th style={{ border: '1px solid black', background: 'yellow' }}>Main?</th>
                                <th style={{ border: '1px solid black', background: 'yellow' }}>Room Number</th>
                                <th style={{ border: '1px solid black', background: 'yellow', width: '120px' }}>Check In</th>
                                <th style={{ border: '1px solid black', background: 'yellow', width: '120px' }}>Check Out</th>
                            </tr>
                            {renderSchedule()}
                        </table>
                    </>
                }
            </div>
            <div className="mt-2">
                <button className="btn btn-primary mr-2" onClick={() => props.onChangeSortListSchedule(dataState.schedule)}>Confirm change</button>
                <button className="btn btn-danger" onClick={() => props.onClose()}> Cancel</button>
            </div>
        </>
    )
}

export default ComponentSupportContentSortSchedule;
