import React from "react";
import { useSelector } from "react-redux";

function SupportHk2(props) {
    const { changeSupportHk } = props;

    const dataGlist = useSelector(state => state.glist)
    const { listGuestStay, listGuestCheckOut, listGuestCheckIn, listSupportHK2 } = dataGlist;
    const dataBranchOnState = useSelector(state => state.dataPickUp.glist);

    const listKey = Object.keys(listSupportHK2);

    const DATA = {
        "29_TVL_Tình Trạng tầng 1 ": ["103", "104", "105"],
        "29_TVL_Tình Trạng tầng 2 - 3": ["201", "202", "203", "204", "205", "301", "302", "303", "304", "305"],
        "29_TVL_Tình Trạng tầng 4 - 5": ["401", "402", "403", "404", "405", "501", "502", "503", "504", "505"],
        "29_TVL_Tình Trạng tầng 6 - 7": ["602", "603", "605", "703", "704", "705" ],
    }

    const DATA3 = {
        "29_TVL_Tình Trạng tầng 1 ": 
         { "103": {
                stay: "",
                checkout: "",
                checkin: "",
                note: ""
            }, 
            "104": {
                stay: "",
                checkout: "",
                checkin: "",
                note: ""
            }, 
            "105": {
                stay: "",
                checkout: "",
                checkin: "",
                note: ""
            },
        },
        "29_TVL_Tình Trạng tầng 2 - 3": 
        {"201": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "202": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "203": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "204": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "205": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "301": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "302": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "303": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "304": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "305": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        },},
        "29_TVL_Tình Trạng tầng 4 - 5": {"401": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "402": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "403": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "404": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "405": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "501": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "502": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "503": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "504": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "505": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        },},
        "29_TVL_Tình Trạng tầng 6 - 7": {"602": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "603": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "605": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "703": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "704": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "705": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        },},
    }

    const DATA4 = {
        "27 TVL_ Tình Trạng tầng G +1": {"006": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "007": { 
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "106": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "107": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "109": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        },},
        "27_TVL_Tình Trạng tầng 2 - 3": {"206": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "207": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "208": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "209": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "306": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "307": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "308": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "309": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        },},
        "27_TVL_Tình Trạng tầng 4 - 5": {"406": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "407": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "408": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "409": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "506": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "507": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "508": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "509": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        },},
        "27_TVL_Tình Trạng tầng 6-7": {"606": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "607": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "608": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "609": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "706": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "707": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        }, "709": {
            stay: "",
            checkout: "",
            checkin: "",
            note: ""
        },},
    }

    const DATA2 = {
        "27 TVL_ Tình Trạng tầng G +1": ["006", "007", "106", "107", "109"],
        "27_TVL_Tình Trạng tầng 2 - 3": ["206", "207", "208", "209", "306", "307", "308", "309"],
        "27_TVL_Tình Trạng tầng 4 - 5": ["406", "407", "408", "409", "506", "507", "508", "509"],
        "27_TVL_Tình Trạng tầng 6-7": ["606", "607", "608", "609", "706", "707", "709"],
    }

    function makeTable(dataRender, title) {
        let result = [];

        function renderOneRow(item) {
            return (<tr>
                <td>{item.room}</td>
                <td><input style={{border:"none", textAlign:"center"}} value={item.stay}
                        onChange={e => changeSupportHk("listSupportHK2", e.target.value, item.room, title, "stay")}></input></td>
                <td><input style={{border:"none", textAlign:"center"}} value={item.checkout}
                        onChange={e => changeSupportHk("listSupportHK2", e.target.value, item.room, title, "checkout")}></input></td>
                <td><input style={{border:"none", textAlign:"center"}} value={item.checkin}
                        onChange={e => changeSupportHk("listSupportHK2", e.target.value, item.room, title, "checkin")}></input></td>
                <td><input style={{border:"none", textAlign:"center", width:"100%"}} value={item.note}
                        onChange={e => changeSupportHk("listSupportHK2", e.target.value, item.room, title, "note")}></input></td>
            </tr>)
        }

        const listKeyOnDataRender = Object.keys(dataRender)
        listKeyOnDataRender.forEach(item => {
            result.push(renderOneRow(dataRender[item]))
        });

        return (<table className={title === "27_TVL_Tình Trạng tầng 6-7" ? "mr-5" : "mb-4 mr-5"}
                    style={{ marginBottom: (title === "27_TVL_Tình Trạng tầng 6-7" ? "200px !important" : "unset")}}>
            <thead>
                <tr>
                    <th colspan="4">{title}</th>
                    <th>Ngày {dataBranchOnState.day}</th>
                </tr>
                <tr>
                    <th style={{width:'80px', minWidth:'80px', maxWidth:'80px'}}>Số phòng</th>
                    <th style={{width:'80px', minWidth:'80px', maxWidth:'80px'}}>Đang ở</th>
                    <th style={{width:'80px', minWidth:'80px', maxWidth:'80px'}}>Check out</th>
                    <th style={{width:'80px', minWidth:'80px', maxWidth:'80px'}}>Check in</th>
                    <th style={{width:'500px', minWidth:'500px', maxWidth:'500px'}} >Chú thích</th>
                </tr>

        {/* <tr>
            <th>Company</th>
            <th>Contact</th>
            <th>Country</th>
            <th>Country</th>
            <th>Country</th>
        </tr> */}

            </thead>
            <tbody>
                {result}
            </tbody>
        </table>)
    }

    function renderTable(dataRender) {
        let result = [];
        // const listKey = Object.keys(dataRender);
        listKey.forEach(key => {
            if(key === "27_TVL_ Tình Trạng tầng G +1") result.push(<div style={{marginTop:"200px"}}></div>)
            result.push(makeTable(dataRender[key], key));
        })

        return result;
    }

    return (
        <>
        {
            listKey.length > 0 && <div className="support_HK2">{renderTable(listSupportHK2)}</div>
        }
         {/* {renderTable(DATA)}
         <div style={{marginTop:"200px"}}></div>
         {renderTable(DATA2)} */}
        </>
    );
}

export default SupportHk2;