import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataPickUpAction, unitsAction } from '../../redux/actions/index'
// api
import * as bookingApi from "../../api/booking";
import * as branchApi from "../../api/branch";
// Component
import CompanyItemComponent from "../../components/Company";
import { coverNameToID } from '../../utils/Branch/SupportBranch';
import { changeDataToYYYY_MMMM } from '../../utils/Date/SupportDate'
import { errorToast, informationToast, warningToast, } from '../../utils/Toast/index';

function CancelAreaContainer() {
    const dispatch = useDispatch();
    const dataBranchOnState = useSelector(state => state.dataPickUp.cancel);
    const dataBranchInformation = useSelector(state => state.branchInfor);

    const token = useSelector(state => state.token);
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;
    const [dataState, setDataState] = useState([]);

    useEffect(() => {
        document.title = `Az${coverNameToID(Number(branchID))} Cancel Area`;
        // load data cancel on this month
        const checkListCancel = async () => {
            try {
                dispatch(unitsAction.showLoading());
                const resultData = await bookingApi.takeListBookingAreaByType(branchID, "cancel", dataBranchOnState.month, token);
              //  console.log("resultData", resultData);
                
                const branchInformation = await branchApi.findElementBranch(branchID, "makeRoom", token);
                dispatch(unitsAction.hiddenLoading());
                if (resultData) {
                    dispatch(unitsAction.fetchBranchInformation(branchInformation.data.data));
                    setDataState(resultData.data.data);
                    informationToast("" + resultData.data.msg);
                }
            } catch (error) { 
                errorToast("Error when load Invoice"); 
            }

        }

        if(branchID) {
            checkListCancel()
        }
    }, [dataBranchOnState.month, branchID]);

    function onChangeDataSelect(e, target) {
        const newData = {
            ...dataBranchOnState,
            ["" + target]: e
        };
        dispatch(dataPickUpAction.changeDataCancel(newData));
    }

    return (
        <>
            <h1 className="mt-5">AZUMAYA {dataBranchInformation.name.toUpperCase()} - CANCEL AREA : {changeDataToYYYY_MMMM(dataBranchOnState.month)}</h1>
            <div className="d-flex">
                <input
                    type="month"
                    style={{ fontSize: '1.3rem' }}
                    onChange={e => onChangeDataSelect(e.target.value, "month")}
                    value={dataBranchOnState.month}
                ></input>
            </div>
            <div className="" style={{ background: "mintcream" }}>
                <CompanyItemComponent
                    type="cancelList"
                    data={dataState} />
                {/* : ""} */}
            </div>
        </>
    )
}

export default CancelAreaContainer;
