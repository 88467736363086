import React, {  useState, useLayoutEffect, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

// import { actModalChange, actModalIsOk, actResetModal } from '../../actions/modal';
import './login.css'
import { useDispatch } from 'react-redux';
//import { actLogin, actGetPositions } from '../../actions/loginAction/login'
import { authAction, modalAction, unitsAction } from '../../../redux/actions';
import ModalSupportOption from '../Modal/modalSupportOption';
import ModalNotify from '../../../features/ModalNotify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// import ModalSupportOption from '../../../components/SupportComponents/Modal/ModalOption';
// '../../components/SupportComponents/Modal/ModalOption';
const initialState = {
    email: '',
    password: '',
    err: '',
    success: ''
}

function LoginContainer() {
    const [user, setUser] = useState(initialState);
    const [err, setError] = useState("");
    //  const token = useSelector(state => state.auth.token)
    const dispatch = useDispatch();
    const history = useHistory();
    const firstLogin = localStorage.getItem('firstLogin');
    const [typePassword, setTypePassword] = useState(false)
    const { email, password, } = user;

    useLayoutEffect(() => {
        if (firstLogin) {
            history.push("/reservation")
        }
    }, [firstLogin]);

    // useEffect(()=> {
    //     console.log("vo here")
    //     const checkToken = async () => {
    //         console.log("vo here call API")
    //         const res = await axios.post(`https://azumayareport.com/user/refresh_token`);
    //         console.log("res", res);
    //     }

    //     checkToken();
    // },[])

    const handleChangeInput = e => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value, err: '', success: '' })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            dispatch(authAction.checkOnServer());
           // const URL = process.env.REACT_APP_HOTEL_API;
            const res = await axios.post(`/user/login`, { email, password });
            //console.log("res", res)
            
            if (res.data.status === 0) {
                dispatch(modalAction.modalChange({
                    type: "centered",
                    title: "Block by Team IT",
                    size: 'xs',
                    content: <ModalSupportOption
                        type={88}
                        itemOption={{
                            color:"black",
                            title: <>
                                <div >Please contact to Team IT to activate this Browser</div>
                                <div>Account: {email} </div>
                                <div style={{color:'red'}}>Coder Request {res.data.code}</div>
                                <div>---</div>
                                <div style={{fontSize:'1.3rem'}}>Contact support:</div>
                                <div style={{fontSize:'1.3rem'}}>Zalo / Phone: 0971474102</div>
                                <div style={{fontSize:'1.3rem'}}>Mail: it@azumayavietnam.com</div>
                            </>
                        }}

                        // featureSupport1={onDelete}
                        // featureSupport2={closeModal}
                        // itemOption={{ schedule: makeRoomOnState.listSchedule, code: codeDelete }}
                    />
                }));
                openModal();

                dispatch(unitsAction.hiddenLoading());
            } else {
                setUser({ ...user, err: '', success: res.data.msg })
                setError(res.data.msg);
                localStorage.setItem('firstLogin', true);

                dispatch(unitsAction.hiddenLoading());
                dispatch(authAction.login())
                history.push("/home")
            }

        } catch (error) {
            setError("Please check again e-mail / password");
            dispatch(unitsAction.hiddenLoading());
        }
    };

    function closeModal() { dispatch(modalAction.modalReset()); }
    function openModal() { dispatch(modalAction.modalIsOk()); }

    return (
        <>
             <ModalNotify closeModal={closeModal} />
            <div className="login">
           
            <Link to="/" className="login__header">
                *** AZUMAYA NEW DATABASE ***
            </Link>
           <h1> LOGIN SYSTEM</h1>
           <form className="login__form" onSubmit={handleSubmit}>

               <div className="login__form__group" >
                   <label class="login__form__email--label col-md-2">E-Mail:</label>
                   <input className="login__form__group--input col-md-4"
                       type="text" placeholder="Enter your email" id="email"
                       onFocus={() => setError('')}
                       value={email} name="email"
                       onChange={handleChangeInput}
                   ></input>
               </div>
               <div className="login__form__group" style={{position: 'relative'}}>
                   <label htmlFor="password" className="login__form__email--label col-md-2">Password:</label>
                   <input className="login__form__group--input col-md-4"
                       onFocus={() => setError('')}
                       type={!typePassword ? "password" : "text"}
                       placeholder="Enter your password"
                       id="password"
                       value={password} name="password"
                       onChange={handleChangeInput} ></input>
                    <FontAwesomeIcon
                        icon={faEyeSlash}
                        // onClick={() => onConfirmChangeStyleRow(data[index])}
                        style={{
                            cursor: 'pointer',
                            color: "black",
                            position: 'absolute',
                            right:'165px',
                            top:'10px'
                        }}
                        onClick={() => setTypePassword(!typePassword)}
                        className="set-color"
                    />
               </div>

               {
                   err !== '' ?
                       <div style={{ fontSize: '1.6rem', backgroundColor: 'red', color: 'white', align: 'center', padding: '4px' }}>
                           {/* <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1626411475/store/loading_short_daigrr.gif" alt="loading" /> */}
                           <p style={{ fontSize: '1.6rem', align: 'center' }}>{err}</p>
                       </div>
                       : ''
               }

               <button type="submit" className="login__form__button">LOGIN</button>
           </form>
           <Link to="/forgot" className="login__forgot">Forgot Password?</Link>
       </div>
        </>

    );
}

export default LoginContainer;
