import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import * as invoiceApi from '../../../api/invoice';

function ViewPdf() {
    const { link, branch } = useParams();
    const fileName = branch + "-" + link.slice(link.length - 10, link.length);

    useEffect(() => {
        const onLoad = async () => {
            try {
                //00001B.pdf
                await invoiceApi.getPdfByLink(branch, link, "")
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
            } catch (error) {

            }
        }

        onLoad();
    }, []);

    return (
        <div>
        </div>
    );
}

export default ViewPdf
