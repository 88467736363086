/* eslint-disable default-case */
import React from 'react';
// import {  useDispatch } from 'react-redux'
import './header.css'
import { useSelector } from 'react-redux';
// import axios from 'axios';

Header.propTypes = {
};

function Header() {
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;
    const isHCMAreaBranch = (Number(branchID) === 1 || Number(branchID) === 4 || Number(branchID) === 9 || Number(branchID) === 96);

    const queryParams = window.location.href;
    const listQueryParams = queryParams.split("/");
    const checkAction = listQueryParams[3];


    function renderHeader() {
        var result = [];
        const listRender = [
            { name: "Reservation", go: '/reservation' },
            { name: "Room Cancel", go: "/cancel", },
            { name: "Room Waiting", go: "/waiting", },
            { name: "Invoice", go: "/invoice" },
            { name: "Company", go: "/company" },
            { name: "Guest Information", go: "/guest" },
            // { name: "Check In", go: "/checkin" },
            // { name: "Car Information", go: "/flight" },
        ]

        if (isHCMAreaBranch) listRender.push({ name: "Check In", go: "/checkin" }, { name: "Car Information", go: "/flight" },)
        if (branchID === 15 || branchID === 12 ||branchID === 8 || branchID === 3 || branchID === 7 || branchID === 13) listRender.push({ name: "Glist", go: "/glist" },)

        listRender.forEach(item => {
            result.push(<li className={item.go === checkAction ? 'nav-item active' : 'nav-item'}
                onClick={() => window.open(item.go, (item.go === "home" ? "_self" : "_blank"))}
            >
                <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1661232117/database/icon/home.svg"
                    className={item.name !== 'Reservation' ? 'op-hidden' : ''}
                    alt={"home"}
                    style={{ width: '25px', height: '35px', verticalAlign: 'middle', color: 'red' }} />
                {item.name}
            </li >)
        })
        return result;
    }

    return (
        <React.Fragment>
            {/* <ModalNotify closeModal={closeModal} /> */}
            <header className="nav-fix-top d-flex justify-content-between">
                <div>
                    <ul className="nav">
                        <span className="">
                            {renderHeader()}
                        </span>
                    </ul>
                </div>
            </header >
            <div className="range-mb-26">---</div>
        </React.Fragment >
    );
}

export default Header;