import { authAction, getType } from '../../actions/index';
const shortid = require('shortid');

const initialState = {
    user: [],
    isLoggedIn: false,
    isAdmin: false,
    isManage: false,
    isSuper: false,
    isAccount: false,
    isReception: false,
    isAccLeader: false,
    isAccChief: false,
    isAM: false,
    isCEO: false,
    message: "",
    notifiSelectID: ""
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(authAction.login):
            return {
                ...state,
                isLoggedIn: true
            }
        case getType(authAction.getUser):
            return {
                ...state,
                user: action.payload.user,
                isAdmin: action.payload.isAdmin,
                isManage: action.payload.isManage,
                isReception: action.payload.isReception,
                isSuper: action.payload.isSuper,
                isAccount: action.payload.isAccount,
                isAccLeader: action.payload.isAccLeader,
                isAccChief: action.payload.isAccChief,
                isAM: action.payload.isAM,
                isCEO: action.payload.isCEO,
            }
        default:
            return state
    }
}

export default authReducer;