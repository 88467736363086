import React from "react";

function ListEditLog(props) {
    const { listHistory, setSelectHistoryIndex, selectHistoryIndex} = props;
    console.timeLog("listHistory", listHistory)
    function renderList() {
        let result = [];
    
        listHistory.forEach(item => {
            result.push(<div 
                    onClick={()=> setSelectHistoryIndex(item._id)}
                style={{border:'1px solid', marginBottom:'4px', background: selectHistoryIndex === item._id ? "yellow" :""}}>
                <div style={{fontSize:'1.2rem', fontWeight:'600'}}>{item.date}</div>
                <div style={{fontWeight:'600'}} >Device: {item.deviceName}</div>
                <div >Session: {item._id.slice(0,8)}</div>
            </div>)
        })

        return result;
    }

    return (                        
        
            listHistory.length > 0  && <div style={{width:'10%', padding: '0px'}}>
                <div style={{background:'aqua', width:'100%', fontSize:'1.3rem'}}>List Time Edit</div>
                {renderList()}
            </div>
         )
}

export default ListEditLog;
