import { call, put, select } from 'redux-saga/effects';
import { guestInformationAction } from '../../actions';
import * as guestApi from '../../../api/guest';
import { errorToast } from '../../../utils/Toast/index'

export function* fillDataGuestInformation() {
    //   yield put(unitsAction.showLoading());
    const state = yield select();
    const { token, guestInformation } = state;

    function checkTypeSearch() {
        switch (guestInformation.typeSearch) {
            case "Name": return "quick_name";
            case "Email": return "email1";
            case "ID": return "id";
            default: return "quick_name";
        }
    }

    try {
        const guestData = yield call(() => guestApi.findGuestByRequest(checkTypeSearch(), guestInformation.keySearch, token));
       // console.log("guestData",guestData)
        if (guestData) {
            yield put(guestInformationAction.fillGuestInformation(guestData.data));
        } else errorToast("Error when load data Guest!!");
    } catch (error) {
        errorToast("Error when load data Guest!!");
    }
}
