import axios from 'axios';

export const getDataPaymentRequest = (branchID, type, id, token) => {
    let newURL = "/pmrq/" + branchID + "/load_payment/" + type + "/"+ id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const updatePaymentRequest = (branchID, id, data, token) => {
    let newURL = "/pmrq/" + branchID + "/update_payment/" + id;
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}