import React, { useEffect, useState } from "react";
import { coverNameToID, } from '../../utils/Branch/SupportBranch';

import './status.css'
import { useSelector } from "react-redux";
function StatusTabArray(props) {
    const { title, data,target, isFullWith, cssWithBranch, height, branchID, updateStatusTabArray } = props;
    const dataGlist = useSelector(state => state.glist);
    
    const [dataRender, setDataRender] = useState({
        data: [],
        css: {
            statusTabArrayTextArea: {},
            statusTabArrayTitle: {},
            statusTabArrayTotal: {}
        }
    });

    useEffect(()=>{
            if(data) {
                setDataRender({
                    data,
                    css: {
                        statusTabArrayTextArea: branchID ?  cssWithBranch.statusTabArrayTextArea : {},
                        statusTabArrayTitle: branchID ?  cssWithBranch.statusTabArrayTitle : {},
                        statusTabArrayTotal: branchID ?  cssWithBranch.statusTabArrayTotal : {},
                    }
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data])

    function checkLength(test) {
        if(test.length >0) {
            if (test === "" && !test.includes(",")) return 0;
            const check = test.split(",");
            return check.length
        } else return 0;
    }

    function onFilterList(data, set) {
        const check = data.trim().split(",");
        const listCheck = [];

        const checkValueSort = (value) => {

            if (Number.isNaN(Number(value.replace(" ", "").slice(0, 1)))) {
                if (Number.isNaN(Number(value.replace(" ", "").slice(1, 3)))) { // after char "A" have note data => return 0
                    return 0
                } else return Number(value.replace(" ", "").slice(1, 3))
            } else {
                if (!Number.isNaN(Number(value.replace(" ", "").slice(0, 3)))) {
                    return Number(value.replace(" ", "").slice(0, 3))
                } else return 0
            }
        }

        check.forEach(item => {
            if (Number.isNaN(Number(item))) {
                listCheck.push({
                    value: item,
                    valueSort: checkValueSort(item)

                });
            } else {
                listCheck.push({
                    value: item,
                    valueSort: Number(item)
                });
            }
        })

        listCheck.sort((a, b) => {
            if (a.valueSort > b.valueSort) return 1;
            else if (a.valueSort < b.valueSort) return -1;
            else return 0;
        });

        const returnList = listCheck.map(item => {
            return item.value
        });

        // set({
        //     data: returnList.toString()
        // });
        props.updateStatusTabArray(target,returnList.toString());
    }

    return (<>
        <div className="checkout">
                <div style={{
                    ...dataRender.css.statusTabArrayTitle,
                    border: '1px solid black',
                    // padding: '4px',
                    fontWeight: '600',
                    backgroundColor: 'rgb(194 246 139 / 43%)',
                    // color: 'white',
                    width: isFullWith ? "100%" :"600px",
                }}>{title} 
                {/* = TỔNG: <input
                style={{ width: '50px', border: '0px solid black', fontSize: '1.5rem',backgroundColor: '#92D050',
                color: 'white', }}
                value={checkLength(dataRender)}
                maxlength="2"></input> */}
                </div>
                {/* <input  style={{width:"100%", height:'50px', fontSize: '1.8rem'}}
                        value={dataRender}
                        onChange={e => setDataRender(e.target.value)}
                        onBlur={e => onFilterList(e.target.value, setDataRender)}
                
                ></input> */}
                <textarea style={
                    {
                    ...dataRender.css.statusTabArrayTextArea,
                 //   fontSize:'2rem',
                    width: isFullWith ? "100%" :"600px",
                    display: 'block',
                    minHeight: height,
                    letterSpacing: '2px'
                }
            }
                    value={""+dataGlist[target]}
                    onChange={e => updateStatusTabArray(target, e.target.value)}
                    onBlur={e => onFilterList(e.target.value)}
                >
                </textarea>
                <div style={{ 
                    ...dataRender.css.statusTabArrayTotal,
                    textAlign: 'left', 
                    fontWeight: '600', 
                    border: '1px solid black',
                }}>
                    <label className="ml-3">Tổng: </label>
                    <input
                        style={{ 
                            width: '50px', 
                            border: 'unset', 
                            height: '17px',
                            marginLeft: '8px', 
                        }}
                        value={checkLength(dataGlist[target])}
                        maxlength="2"></input>
                </div>
            </div>  
    </>)
}

export default StatusTabArray;
