import React from 'react';
import './headerFeature.css'
import MultiBranchSelect from '../../../features/DropDown/MultiBranchSelect';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

HeaderFeature.propTypes = {
};

function HeaderFeature(props) {
    const { type, title, dataBranchOnState, feature1, feature2, data } = props;

    function renderHeaderFeature() {
        switch (type) {
            case "reservation": {
                const { listArea, listBranch, isMulti } = data;
                return (
                    <div className="d-flex">
                        <MultiBranchSelect
                            listArea={listArea}
                            changeSelectArea={feature1}
                            changeSelectBranchID={feature1}
                            selectArea={dataBranchOnState.area}
                            selectBranch={dataBranchOnState.branch}
                            listBranch={listBranch}
                        />

                        {/* <div className="input-group-prepend" style={{ fontSize: '1.5rem', width: '185px' }}>
                            <button className="btn btn-outline-secondary dropdown-toggle"
                                style={{ width: '180px', fontSize: '1.3rem', backgroundColor: '#beecbe' }}
                                type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">View Month</button>
                            <div className="dropdown-menu" style={{ backgroundColor: '#999' }}>
                                <span className="dropdown-item" style={{ fontSize: '1.4rem', }} href="#">View Month</span>
                                <span className="dropdown-item" style={{ fontSize: '1.4rem', }} href="#">View Day (Glist )</span>
                                <span className="dropdown-item" style={{ fontSize: '1.4rem', }} href="#">View Week</span>
                                <div role="separator" className="dropdown-divider"></div>
                                <span className="dropdown-item" style={{ fontSize: '1.4rem', }} href="#">View Select</span>
                            </div>
                        </div> */}

                        {/* <div style={{ backgroundColor: '#beecbe', fontSize:'2.5rem' }}>{"<"}</div> */}
                        <button className='header-arrow' 
                            onClick={() => feature1(-1, "month")}
                            >{"<"}</button>

                        <input type="month"
                            value={dataBranchOnState.month}
                            onChange={e => feature1(e.target.value, "month")}
                            style={{ backgroundColor: '#04436C', color:'white', fontSize:'1.6rem' }}
                            // style={{ backgroundColor: '#006400', color:'white', fontSize:'1.6rem' }}

                        ></input>
                        {/* <button className="btn btn-primary" onClick={() => feature2}>Load Data</button> */}
                        <button className='header-arrow' 
                            onClick={() => feature1(1, "month")}
                            >{">"}</button>

                        {isMulti ? <Link
                            className="nav-item-sub btn_load_data"
                            id="modal_inputdata"
                            //nameBranchToBranchID()
                            to={`/reservation/${dataBranchOnState.branchID}/${dataBranchOnState.month}`}
                            onClick={() => feature2()}
                            >
                            Load Data
                         </Link> : ""} 

                    </div>
                )
            }
            case "cancel": {
                return (
                    <div className="d-flex">
                        <div className="input-group-prepend" style={{ fontSize: '1.5rem', width: '185px' }}>

                        </div>
                        <input type="month"
                            value={dataBranchOnState.month}
                            onChange={e => feature1(e.target.value, "month")}
                            style={{ backgroundColor: '#beecbe' }}
                        ></input>
                        <button className="btn btn-primary">Load Data</button>
                    </div>
                )
            }
            default: return ""
        }
    }

    return (
        <>
            <div className="d-flex header-feature">
                {renderHeaderFeature()}
                <span className="pl-5 header-feature_title">{title}</span>
            </div>
        </>
    );
}

export default HeaderFeature;