import React from "react";
import FooterButton from "../../../utils/FooterButton";
import { LIST_GUEST_ID_BLOCK } from "../../../constaints/listID/listID";
import { useSelector } from "react-redux";

function MakeRoomFooterButton(props) {
    const { idRoom, idGuest, makeRoomService, option, id_history} = props;
    const auth = useSelector(state => state.auth);
    const { role } = auth.user;

    function renderButton() {
        if (idRoom === undefined && (role === 0 || role === 2 || role === 3)) {
            return <FooterButton
                button1={"MAKE NEW ROOM"}
                iconBtn1="faNewspaper"
                iconBtn2="faCalendarCheck"
                button2={option.isHiddenBtn === "" ? undefined : "CHECK ROOM STATUS"}
                onClickButton1={props.onConfirmMakeRoom}
                onClickButton2={props.onConfirmCheckRoomStatus}
            />
        } else if (idRoom === "make_waiting" && (role === 0 || role === 2 || role === 3)) {
            return <FooterButton
                button1={"MAKE WAITING ROOM"}
                onClickButton1={props.onConfirmMakeRoom}
            />
        } else {
            if (makeRoomService.area === "cancel" ) {
                if ((role === 0 || role === 2 || role === 3)) {
                        return <FooterButton
                        button1={"REDO GUEST"}
                      //  button2={"RELEASE"}
                        button2={(makeRoomService.isMakeHI && !LIST_GUEST_ID_BLOCK.includes(idGuest) )? "CHECK OUT" : undefined}

                        button3={"VIEW HISTORY"} 
                        iconBtn1={"faHistory"}
                        iconBtn2="faWindowClose"
                        iconBtn3="faHistory"
                        onClickButton1={props.onConfirmRedo}
                     //   onClickButton2={props.onConfirmDeleteSchedule}
                        onClickButton2={() => props.onConfirmMakeHotelInvoice("Cancel")}

                       // onClickButton2={props.onConfirmMakeHotelInvoice}

                        onClickButton3={props.onViewHistory}
                    />
                } 
                else if (role === 4 || role === 9)  {
                    return <FooterButton
                    button1={"HISTORY"}
                    button2={"MAKE PMRQ"}
                    iconBtn2="faFileInvoice"
                    iconBtn1="faHistory"
                    onClickButton2={() => props.onConfirmMakeHotelInvoice("PMRQ")}
                    onClickButton1={props.onViewHistory}
                />
                } else {
                    return <FooterButton
                    button1={"MAKE PMRQ"}
                    button2={"VIEW HISTORY"} 
                    iconBtn2="faHistory"
                    onClickButton1={props.onViewHistory}
                />
                }
            }
            if ( makeRoomService.area === "waiting") {
                if ((role === 0 || role === 2 || role === 3)) {
                        return <FooterButton
                        button1={"SAVE EDIT"}
                        button2={"REDO GUEST"}
                        button3={"RELEASE"}
                        button4={"VIEW HISTORY"} 
                        iconBtn1="faSave"
                        iconBtn2={"faHistory"}
                        iconBtn3="faWindowClose"
                        iconBtn4="faHistory"
                        onClickButton1={props.onConfirmEdit}
                        onClickButton2={props.onConfirmRedo}
                        onClickButton3={props.onConfirmDeleteSchedule}
                        onClickButton4={props.onViewHistory}
                    />
                } else {
                    return <FooterButton
                    button1={"VIEW HISTORY"} 
                    iconBtn1="faHistory"
                    onClickButton1={props.onViewHistory}
                />
                }
            }
            else if (makeRoomService.area === "delete") {

            } else {
                if ((role === 0 || role === 2 || role === 3)) {
                    return <FooterButton
                        button1={"SAVE EDIT"}
                        button2={"RELEASE"}
                        button3={LIST_GUEST_ID_BLOCK.includes(idGuest) ? undefined : "MAKE BOOKING"}
                        button4={(makeRoomService.isMakeHI && !LIST_GUEST_ID_BLOCK.includes(idGuest) )? "CHECK OUT" : undefined}
                        button5={"HISTORY"}
                        // button6={"MAIL FORMAT"}
                        iconBtn1="faSave"
                        iconBtn2="faWindowClose"
                        iconBtn3="faPrint"
                        iconBtn4="faFileInvoice"
                        iconBtn5="faHistory"
                        // iconBtn6="faMailBulk"
                        onClickButton1={props.onConfirmEdit}
                        onClickButton2={props.onConfirmDeleteSchedule}
                        onClickButton3={() => props.onConfirmMakeHotelInvoice("Booking")}
                        onClickButton4={props.onConfirmMakeHotelInvoice}
                        onClickButton5={props.onViewHistory}
                        // onClickButton6={props.onViewMailFormat}
                    />
                } else {
                    if(role === 4 || role === 9) {
                        return <FooterButton
                        button1={"HISTORY"}
                        button2={"MAKE PMRQ"}
                        iconBtn2="faFileInvoice"
                        iconBtn1="faHistory"
                        onClickButton2={() => props.onConfirmMakeHotelInvoice("PMRQ")}
                        onClickButton1={props.onViewHistory}
                    />
                    } else {
                        return <FooterButton
                        button1={"HISTORY"}
                        iconBtn1="faHistory"
                        onClickButton1={props.onViewHistory}
                    />
                    }
                }
            }
        }
    }

    return (
        <>
           {id_history === undefined && renderButton()}
        </>
    )
}

export default MakeRoomFooterButton;
