import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import MakeRoomDataOtherDetails from './otherDetails';
import MakeRoomDataRoomDetails from './roomDetails';
import MakeRoomDataRoomServiceDetail from './serverDetails';

import { modalAction } from '../../../redux/actions';
import { UserContext } from '../../../container/MakeRoom/index';

function MakeRoomDataRoom(props) {
    const { style } = useContext(UserContext);
    const dispatch = useDispatch();
    var takeData;

    function onOpenSettingRequest1() {
        props.onOpenSettingRequest1()
    }

    function onConfirmDeleteSchedule(index) {
        props.onConfirmDeleteSchedule(index)
    }

    function onChangeValueMakeRoom(value, target, option) {
        props.onChangeValueMakeRoom(value, target, option)
    }

    function onChangeValueMakeRoomOther(value, target, option) {
        props.onChangeValueMakeRoomOther(value, target, option)
    }

    function changeAll(value) {
        props.onChangeAllDataValueMakeRoom(value)
    }

    function closeModal() {
        dispatch(modalAction.modalReset());
    }

    function openModal() {
        dispatch(modalAction.modalIsOk());
    }

    return (
        <div style={{ flex: 1, width: '50%', }}>
            <h2 style={{ border: '1px solid black' }}>Room Information</h2>
            <MakeRoomDataRoomDetails
                takeData={takeData}
                changeAll={changeAll}
                onChangeValueMakeRoom={onChangeValueMakeRoom}
                onOpenSettingRequest1={onOpenSettingRequest1}
                onConfirmDeleteSchedule={onConfirmDeleteSchedule}
                onSortListSchedule={props.onSortListSchedule}
                onAddMoreSameListSchedule={props.onAddMoreSameListSchedule}
                onBlur={onChangeValueMakeRoom} />
            {/* SERVER DETAILS */}
            <div className="mt-3"></div>
            <MakeRoomDataRoomServiceDetail
                openModal={openModal}
                closeModal={closeModal}
                style={style}
                onChangeValueMakeRoomOther={onChangeValueMakeRoomOther} />
            <div className="mt-3"></div>
            <MakeRoomDataOtherDetails
                style={style}
                onChangeValueMakeRoomOther={onChangeValueMakeRoomOther}
                onChangeValueMakeRoom = {onChangeValueMakeRoom}
                onReturnDefaultSignature={props.onReturnDefaultSignature}
                onUpdateSignature={props.onUpdateSignature}
                onBlur={onChangeValueMakeRoomOther} />
        </div >
    )
};

export default MakeRoomDataRoom;
