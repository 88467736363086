import { unitsAction, getType } from '../../actions/index';

const initialState = {}

const allBranchReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(unitsAction.fetchAllBranchInformation): {
            return action.payload;
        }
        case getType(unitsAction.resetDataAllBranchInformation): {
            return {};
        }
        default: return state;
    }
}
export default allBranchReducer;
