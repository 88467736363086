import React from "react";
import { changeDataToDDMMYYYY } from '../../../utils/Date/SupportDate.js';

const style = {
    table: {width: '100%'},
    border: {border:'1px solid black'},
}
function ModalShowBillDouble(props) {

    const { data } = props;
    return (<>
        <table style={style.table}>
            <tr>
                <th style={style.border}>Room</th>
                <th style={style.border}>Check In</th>
                <th style={style.border}>Check Out</th>
            </tr>
            <tr>
                <td style={style.border}>{data.roomSelect}</td>
                <td style={style.border}>{changeDataToDDMMYYYY(data.checkin)}</td>
                <td style={style.border}>{changeDataToDDMMYYYY(data.checkout)}</td>
            </tr>
        </table>
    </>)
}

export default ModalShowBillDouble;
