import React from 'react';
import LoadingIcon from '../../assests/img/loading.gif';
import { useSelector } from 'react-redux';
import './globalloading.css';

function GlobalLoading(props) {
    const Loading = useSelector(state => state.showLoading);
    const { show, content } = Loading;
    return (
        <>
            {show && (
                <div className="globalloading">
                    <img src={LoadingIcon} alt="loading" className="globalloading-icon" />
                    <h4 className="globalloading-text">{content === "" ? "Loading..." : content}</h4>
                </div>
            )}
        </>
    )
}

export default GlobalLoading;
