import axios from 'axios';

export const makeNewGuestData = (data, token) => {
    let newURL = "/guest/make_new";
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const editGuestData = (data, id, token) => {
    let newURL = "/guest/edit_guest/" + id;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const takeMultiGuest = (id, listGuest) => {
    let newURL = "/guest/take_multi";
    return axios.post(`${newURL}`, listGuest, {});
}

export const findGuestByRequest = (type, value, token) => {
    let newURL = "/guest/find_guest/" + type;
    return axios.post(`${newURL}`, { value }, {
        headers: { Authorization: token }
    });
}

// router.get('/view_history/:id', auth, guestCtrl.viewGuestHistory);
export const viewGuestHistory = (id, token) => {
    let newURL = "/guest/view_history/" + id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const checkMultiGuestChange = (data, token) => {
    let newURL = "/guest/check_multi_change_guest";
    return axios.patch(`${newURL}`, data,{
        headers: { Authorization: token }
    });
}

// export const uploadPassPort = (id, data, token) => {
//     let newURL = "/guest/upload_passport/" + id;
//     return axios.post(`${newURL}`, data, {
//         headers: { Authorization: token }
//     });
// }

export const uploadPassPort = (id, data, token) => {
    let newURL = "/guest/upload_passport/"+id ;
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token, },
    });
}

export const deleteGuest = (id, token) => {
    let newURL = "/guest/delete_guest/"+id ;
    return axios.delete(`${newURL}`, {
        headers: { Authorization: token, },
    });
}