import { call, put, select } from 'redux-saga/effects';
import {  companyAction } from '../../actions';
import * as companyAPI from '../../../api/company';
import { errorToast } from '../../../utils/Toast/index'

export function* fillDataCompany() {
    //   yield put(unitsAction.showLoading());

    const state = yield select();
    const { token, company } = state;

    function checkTypeSearch() {
        switch (company.typeSearch) {
            case "Name": return "quick_name";
            case "Tax": return "tax";
            case "ID": return "company_id";
            default: return "quick_name";
        }
    }

    try {
        const companyData = yield call(() => companyAPI.findCompanyByRequest(checkTypeSearch(), company.keySearch, token));
        if (companyData) {
            yield put(companyAction.fillCompany(companyData.data));
        } else errorToast("Error when load data Company 2!!");
    } catch (error) { errorToast("Error when load data Company!!"); }

    // const findCompany = async () => {
    //     const findCompanyResults = await companyAPI.findCompanyByRequest("quick_name", value, token);
    //     if (findCompanyResults) { setListRender(findCompanyResults.data); }
    // }
    // findCompany()

    //  rcSumData = yield call(() => reception.getSum(branchID, dateSelected, token));

}
