const mailFormat = {
    labelReservation: {
        eng: "① Reservation room:",
        jap: "①予約について：",
        vni: "①Về đặt phòng:"
    },
    labelIntroCar: {
        eng:"Car information:",
        jap: "",
        vni: ""
    },
    labelIntroCar: {
        eng:"Late out:",
        jap: "レイトチェックアウト",
        vni: "Trả phòng muộn:"
    },
    labelPickUp: {
        eng: "Pick up:",
        jap: "ピックアップ",
        vni: ""
    },
    labelDropOff: {
        eng:"Drop off :",
        jap: "",
        vni: ""
    },
    labelRentCar: {
        eng:"Rent Car :",
        jap: "",
        vni: ""
    },
    dear: {
        eng: "Dear $TEXT1 $TEXT2",
        jap: "$TEXT2様",
        vni: "Thân gửi $TEXT1 $TEXT2",
    },
    osewani: {
        eng: "",
        jap: "いつもお世話になっております。",
        vni: "",
    },
    roomAvailable: {
        eng: "",
        jap: "ご要望の通りのお部屋をご用意させていただきます。",
        vni: "",
    },
    thankBooking: {
        eng: "Thank you so much for your kind attention and support to Azumaya $TEXT1 branch.",
        jap: "この度は、東屋$TEXT1店にご予約いただきまして誠にありがとうございます。",
        vni: "Cảm ơn $TEXT2 đã luôn quan tâm và ưu ái đặt phòng tại $TEXT2 nhánh Azumaya $TEXT1.",
    },
    lateReply: {
        eng: "We are really sorry for our late reply.",
        jap: "お返事が遅くなり大変申し訳ございません。",
        vni: "Mong $TEXT1 thông cảm giúp chúng em vì sự hồi âm chậm trễ."
    },
    checkAttachFile: {
        eng: "Please feel free to check the attached file for further details.",
        jap: "詳細は添付の予約確認書をご参照ください。",
        vni: "Em xin phép gửi $TEXT1 bản xác nhận đặt phòng trong file đính kèm, $TEXT1 vui lòng tham khảo để biết thêm chi tiết."
    },
    cardNotification: {
        eng: "Credit cards are accepted for payment: VISA, Master card, JCB, Napas (Only VND)",
        jap: "ご利用可能なクレジットカード（ベトナムドンのみ）は、Visa、Master card、JCB、Napasとなります。",
        vni: "Những loại thẻ tín dụng có thể sử dụng để thanh toán là VISA, Master card, JCB, Napas (chỉ nhận thanh toán bằng VND)."
    },
    askTimeCheckIn: {
        eng: "To prepare better for the room, Could you please give us the check in time of customer.",
        jap: "お部屋をよりよく準備できるようにチェックインの時間を教えていただけないでしょうか。",
        vni: "Để có thể chuẩn bị phòng tốt hơn, $TEXT1 vui lòng cung cấp giờ nhận phòng của khách giúp chúng em."
    },
    "contract5%": {
        eng: "$TEXT1 have contract discount 5%",
        jap: "$TEXT1コーポレート5%オフ",
        vni: "Ưu đãi: $TEXT1 có hợp đồng 5% giảm giá",
    },
    askCheckInTime: {
        eng: "",
        jap: "",
        vni: "",
    },
    contractChangeLaundry: {
        eng: "*If you want to change to free laundry 120,000VND/day, please don’t hesitate to contact us.",
        jap: "＊無料ランドリーサービス（1日120,000VNDまで）に変更することも可能です。変更をご希望の場合はご連絡くださいませ。",
        vni: "*Trường hợp cần thay đổi đổi sang ưu đãi  miễn phí set giặt là 120,000VND/1 ngày. xin vui lòng thông tin đến chúng em ạ.",
    },
    contractlaundry: {
        eng: "$TEXT1 have contract free laundry 120,000VND/day",
        jap: "$TEXT1コーポレート 無料ランドリーサービス（1日120,000VNDまで）",
        vni: "$TEXT1 có hợp đồng miễn phí set giặt là 120,000VND/1 ngày.",
    } , 
    "contract10%": {
        eng: "$TEXT1 discount 10% and free laundry 120,000VND/day",
        jap: "$TEXT1コーポレート10%オフと無料ランドリーサービス（1日120,000VNDまで）",
        vni: "Ưu đãi: $TEXT1 có hợp đồng 10% giảm giá và miễn phí set giặt là 120,000VND/1 ngày",
    },
    askOnigiri1: {
        eng: "Breakfast will be served from 6:00am, so if you have a departure schedule before 6:00am, ",
        jap: "朝食は6時からのためご出発が6時より早い場合はおにぎりをご用意することが可能です。",
        vni: "Giờ phục vụ ăn sáng của chúng em là từ 6:00 giờ sáng nên nếu khách trả phòng sớm khách sạn chúng em cũng có thể chuẩn bị được 1 suất cơm nắm cho khách. "
    },
    askOnigiri2: {
        eng: "we can prepare rice balls for you.If you have request, please order with Receptionist before 1 day in advance.",
        jap: "ご希望の際は前日までに受付にお申し付けくださいませ。",
        vni: "Trường hợp Quý khách có nhu cầu, xin vui lòng đặt trước tại quầy lễ tân trước 1 ngày."
    },
    chargeCancelNotification1: {
        eng: "To avoid cancellation charges, please contact us by email ",
        jap: "キャンセル料金が発生しないように",
        vni: "Để tránh phát sinh phí xin chị vui lòng thông báo khi có hủy phòng hoặc thay đổi lịch trình "
    },
    chargeCancelNotification2: {
        eng: " if there are any changes or cancellations.",
        jap: "にご連絡をお願いいたします。",
        vni: "qua email giúp chúng em."
    },
    chargeCancelNotification3: {
        eng:"To avoid cancellation charges, please contact us by email if there are any changes or cancellations before the time as below:",
        jap: "ご予約をキャンセル、またはチェックイン日を予定日より後日に変更される場合は、キャンセル料金が発生しないよう下記の日時までにご連絡をお願いいたします。",
        vni: ""
    },
    chargeCancelNotification4: {
        eng:" - Schedule from $TEXT1 to _: please contact us ",
        jap: "$TEXT1~_月_日のご宿泊→　$TEXT215時までに",
        vni: "Lịch trình từ ngày $TEXT1 ngày - : vui lòng thông báo trước 15:00 giờ $TEXT2"
    },
    checkBookingCancellationFree: {
        eng: "Please check booking confirmation to make reference about cancellation fee.",
        jap: "キャンセル要項については予約確認書をご参照くださいませ",
        vni: "Nhờ chị tham khảo xác nhận đặt phòng để biết chi tiết vì phí huỷ."
    },
    introPickDropCar1: {
        eng: "About pick up service, we are serving with the pick up fare as below:",
        jap: "ピックアップのご予約をいただくことは可能でございます。",
        vni: "Về yêu cầu của chị đặt xe đón khách, chúng em xin gửi chị giá dịch vụ như sau:"
    },
    introPickDropCar2: {
        eng: "Four or seven-seat car (price per car) ",
        jap: "7人もしくは4人乗りのお車（1台料金）",
        vni: "Xe 4 hoặc 7 chỗ (mỗi xe)",
    },
    introPickDropCar3: {
        eng: "6:00～20:59 =>625.000VND/ turn",
        jap: "6:00～20:59 =>625.000VND/回",
        vni: "Từ 6:00 giờ- 20:59 giờ => giá 625.000VND/ lượt",
    },
    introPickDropCar4: {
        eng: "21:00～5:59 =>750.000VND/ turn",
        jap: "21:00～5:59 =>750.000VND/回",
        vni: "Từ 21:00 giờ - 5:59 giờ => giá 750.000VND/ lượt",
    },
    introPickDropCar5: {
        eng: "Please inform us your flight number and arrival time if you would like to book our pickup service.",
        jap: "ご搭乗の便名とご到着時間を事前にお知らせください。",
        vni: "Khi đặt đón sân bay cho khách, nhờ chị vui lòng cho chúng em thông tin về chuyến bay (số hiệu chuyến bay, thời gian đến, từ đâu đến,...) và xác nhận, chúng em sẽ sắp xếp xe và gửi xác nhận đến chị ở email tiếp theo.",
    },
    introLateOut1: {
        eng: "The check out time of our hotel is before 12:00.",
        jap: ".当ホテルのチェックアウトは12時までとなっております。",
        vni: "Theo quy định giờ trả phòng bên em là trước 12:00 nên giờ trả phòng muộn bên em xin được tính như sau:"
    },
    introLateOut2: {
        eng: "Late check out fee is charged as below:",
        jap: "レイトチェックアウトのチャージ料金は下記をご参照くださいませ。",
        vni: "Theo quy định giờ trả phòng bên em là trước 12:00 nên giờ trả phòng muộn bên em xin được tính như sau:"
    },
    introLateOut3: {
        eng: "Before 18:00 -> 50% charge of one night room rate",
        jap: "18時までのチェックアウト→ー泊分の50%チャージ",
        vni: "Trước 18:00  -> Tính phí 50% tiền phòng của 1 đêm"
    },
    introLateOut4: {
        eng: "After 18:00 -> 100% charge of one night room rate",
        jap: "18時以降のチェックアウト→ー泊分の100%チャージ",
        vni: "Sau 18:00 -> Tính phí 100% tiền phòng của 1 đêm "
    },
    introLateOut5: {
        eng: "Please give us the late check out time, then we will keep and prepare the room for you",
        jap: "レイトチェックアウトご希望の際は、事前にお時間をお伝えください。",
        vni: "Xin chị cung cấp giờ trả phòng muộn của bác đến bên em chuẩn bị phòng được tốt nhất cho bác, "
    },
    welcomingGuest: {
        eng: "We are looking forward to welcoming $TEXT1 to our hotel soon.",
        jap: "東屋スタッフ一同、 $TEXT1様のお越しを心よりお待ち申し上げております。",
        vni: "Chúng em rất mong được chào đón $TEXT1"
    },
    ifHaveSomeQuest: {
        eng: "If you have some questions, please do not hesitate to contact us.",
        jap: "ご不明な点がございましたら、お気軽にご連絡くださいませ。",
        vni: "Có thắc mắc hay cần trao đổi thêm thông tin chị đừng ngại liên hệ chúng em nhé."
    },
    thankVeryMush: {
        eng: "",
        jap: "どうぞよろしくお願いいたします。",
        vni: "Chúng em cảm ơn $TEXT1 rất nhiều."
    },
    bestRegards: {
        eng: "Best regards",
        jap: "東屋ホテル$TEXT1",
        vni: "Trân trọng,​"
    },
    askVAT: {
        eng: "* If you need VAT, please give us information of VAT before the day Guest check in or Check in time",
        jap: "*VATインボイスが必要な場合は事前に用意が必要なためチェックイン日までにお知らせください。",
        vni: "*Nếu Quý công ty cần VAT hay thanh toán chuyển khoản, vui lòng cung cấp thông tin ghi và gửi hóa đơn VAT trước hoặc muộn nhất tại thời điểm check in."
    },
    servicePickUp: {
        service: {
            eng: "Service:",
            jap: "サービス：",
            vni: "Dịch vụ: "
        },
        nameService: {
            eng: "Service Pick Up:",
            jap: "PICK UPのサービスにつきまして",
            vni: "Dịch vụ đón sân bay ( Pick Up)"
        },
        flightTime: {
            eng: "Flight number: $TEXT1 Time: $TEXT2 ",
            jap: "フライトナンバー：$TEXT1 、時間：$TEXT2",
            vni: " Số hiệu chuyến bay: $TEXT1 Thời gian đến: $TEXT2"
        },
        price: {
            eng: "Price: $TEXT1 VND",
            jap: "料金：$TEXT1 VND/回",
            vni: "Giá: $TEXT1 VND"
        },
        notice: {
            eng: "Notice:",
            jap: "注意：",
            vni: "Ghi chú:"
        },
        ifChangeFight: {
            eng: "Please let us know if your flight schedule changed.",
            jap: "ご搭乗の便やお時間に変更がございましたら事前にご連絡頂けますようお願い致します。",
            vni: "Vui lòng cho chúng tôi biết nếu lịch trình chuyến bay thay đổi."
        },
        arrivalColumn: {
            eng : " on Arrival A1 - Column 8.",
            jap : "出口(A1) 8番 ",
            vni : " A1 cột số 8 ",
        },
        waitingInColumn1: {
            eng: "Please check before leaving airport, our driver is waiting for you with a sign having your name",
            jap: "ドライバーはお客様のお名前とホテル名を記載した紙を持って空港出口(A1) 8番の柱の前でお待ちしております。",
            vni: "Tài xế sẽ đợi khách tại sân bay, sẽ cầm bảng có tên khách tại của ra "
        },
        waitingInColumn2: {
            eng: "",
            jap: "の柱の前でお待ちしております。",
            vni: "tại sân bay, sẽ cầm bảng có tên khách"
        },
        viewBoardAndNameHotel: {
            eng: "",
            jap: "お乗り間違えの無いよう、お客様のお名前とホテル名を記載した紙を持っている運転手をお探しください。",
            vni: "Xin chỉ lên xe của tài xế có biển tên đón khách của Azumaya"
        },
        ifHaveNotDriveCallToHotel: {
            eng : "If you do not see driver, or you have any trouble, please call reception for checking as soon as possible Azumaya $TEXT1 Reception: $TEXT2",
            jap : "万が一ドライバーが見えない場合はホテルにご連絡頂けますようお願い致します。東屋ホテル$TEXT1の電話番号：$TEXT2",
            vni : "Nếu khách không thấy tài xế, hoặc có vấn đề nào tại sân bay, xin vui lòng liên hệ về Lễ tân khách sạn, theo số điện thoại sau: Khách sạn Azumaya $TEXT1: $TEXT2· "
        },
        changerIfGoToOtherCar: {
            eng : "Please do understand that if you leave airport without checking with our receptionist, the pick-up fee is still charged because we already arranged car for your request.",
            jap : "ご予約をされているにも関わらずご連絡なしにタクシーをご利用になられた場合でも、ピックアップ料金のチャージ対象となりますのでご注意ください。",
            vni : "Xin chị thông cảm là theo quy định công ty, khách tự đi đến bằng taxi hay loại xe khác, không phải xe do dịch vụ khách sạn đã chuẩn bị sẵn theo yêu cầu trước đó của khách, mà không có sự liên hệ và xác nhận giữa khách và nhân viên Lễ tân khách sạn, thì phí đón sân bay vẫn sẽ bị tính phí"
        },
        ruleFreeCancel: {
            eng : "Avoiding cancellation fee, please contact before 3 hours from the picking time.",
            jap : "ご予約をされているにも関わらずご連絡なしにタクシーをご利用になられた場合でも、ピックアップ料金のチャージ対象となりますのでご注意ください。",
            vni : "Để không phát sinh phí huỷ xin vui lòng liên lạc trước 3 giờ đồng hồ tính từ thời điểm xe đón. "
        }
    },
    serviceDropOff: {
        nameService: {
            eng: "Service Drop Off:",
            jap: "DROP OFFのサービスにつきまして",
            vni: "Dịch vụ tiễn sân bay ( Drop Off)"
        },
        service: {
            eng: "Service:",
            jap: "サービス：",
            vni: "Dịch vụ: "
        },
        confirmBelow: {
            eng: "We would like to confirm, as below:",
            jap: "DROP OFFのご予約内容は下記の通り承りました。",
            vni: "Chúng em xin được xác nhận như sau:"
        },
        dropOffTime: {
            eng: "Time: $TEXT1 (this is the time you need to departure from hotel)",
            jap: "時間： $TEXT1 （ホテルより出発）",
            vni: "Thời gian khởi hành từ khách sạn: $TEXT1"
        },
        price: {
            eng: "Price: $TEXT1 VND",
            jap: "料金：$TEXT1 VND/回",
            vni: "Giá: $TEXT1 VND"
        },
        notice: {
            eng: "Notice:",
            jap: "注意：",
            vni: "Ghi chú:"
        },
        waitingLobby: {
            eng: "Please be at Lobby & confirm with Reception about the car driver 5 minutes before departure time.",
            jap: "出発時間の5分前にロビーにお越しいただき受付スタッフとの確認をお願いします。",
            vni: "Xin quý khách vui lòng có mặt tại sảnh khách sạn và xác nhận với Lễ tân về tài xế trước giờ khởi hành là 5 phút."
        },
        haveTroubleCallToRC: {
            eng: "If you have any trouble, please call reception for checking as soon as possible Azumaya $TEXT1: $TEXT2",
            jap: "万が一何か問題が起こった際にはすぐに確認いたしますのでホテルにご連絡頂けますようお願い致します。東屋ホテル$TEXT1の電話番号：$TEXT2",
            vni: "Nếu có vấn đề gì trong suốt chuyến đi, xin vui lòng liên hệ về Lễ tân khách sạn, theo số điện thoại sau:  Khách sạn Azumaya $TEXT1: $TEXT2"
        },
        changerIfGoToOtherCar: {
            eng: "Please do understand that if you leave hotel by another car or taxi without checking with our receptionist, the DROP OFF fee is still charged because we already arranged car for your request. ",
            jap: "当ホテルスタッフとの確認無しに他の車もしくはタクシーをご利用になられた場合でも、DROP OFF料金のチャージ対象となりますのでご注意ください。",
            vni: "Xin hãy thông cảm là theo quy định công ty, khách tự ý đi bằng taxi hay loại xe khác, không phải xe do dịch vụ khách sạn đã chuẩn bị sẵn theo yêu cầu trước đó của khách, mà không có sự liên hệ và xác nhận giữa khách và nhân viên Lễ tân khách sạn, thì phí đưa tiễn vẫn sẽ bị tính phí."
        },
        ruleFreeCancel: {
            eng: "Avoiding cancellation fee, could you please contact before 3 hours from the departure time. ",
            jap: "ドライバーはお客様のお名前とホテル名を記載した紙を持って空港出口(A1) 8番の柱の前でお待ちしております。",
            vni: "Để không phát sinh phí huỷ xin vui lòng liên lạc trước 3 giờ đồng hồ tính từ thời điểm xe đón.",
        }
    },
    servicePickUp6: {
        eng: "Please do understand that if you leave airport without checking with our receptionist, the pick-up fee is still charged because we already arranged car for your request.",
        jap: "乗り間違えの無いよう、お客様のお名前とホテル名を記載した紙を持っている運転手をお探しください。",
        vni: "Biển tên bên em sẽ chuẩn bị như file đính kèm."
    },
    servicePickUp7: {
        eng: "Please check before leaving airport, our driver is waiting for you with a sign having your name",
        vni: "Để không phát sinh phí huỷ xin vui lòng liên lạc trước 3 giờ đồng hồ tính từ thời điểm xe đón.",
        jap: "ドライバーはお客様のお名前とホテル名を記載した紙を持って空港出口(A1) 8番の柱の前でお待ちしております。",
    },
    servicePickUpDropOffNoti1: {
        eng: "* NOTICE:",
        jap: "* 注意",
        vni: "* Lưu ý:"
    },
    serviceDropOff1: {
        eng: "Service Drop Off:",
        jap: "DROP OFFのサービスにつきまして",
        vni: "Dịch vụ đón sân bay ( Pick Up)"
    },
    serviceDropOff2: {
        eng: "Time: $TEXT1 (this is the time you need to departure from hotel)",
        jap: "",
        vni: ""
    },
    serviceDropOff3: {
        eng: "Price: $TEXT1VND",
        jap: "料金: $TEXT1VND",
        vni: "Giá: $TEXT1VND"
    },
    serviceDropOff4: {
        eng: "Notice:",
        jap: "注意:",
        vni: "Lưu ý:"
    },
    serviceDropOff5: {
        eng: "Please be at Lobby & confirm with Reception about the car driver 5 minutes before departure time.",
        jap: "出発時間の5分前にロビーにお越しいただき受付スタッフとの確認をお願いします。",
        vni: "Xin quý khách vui lòng có mặt tại sảnh khách sạn và xác nhận với Lễ tân về tài xế trước giờ khởi hành là 5 phút."
    },
    servicePickUpDropOffNoti2: {
        eng: "If you have any trouble, please call reception for checking as soon as possible Azumaya $TEXT1: $TEXT2",
        jap: "万が一何か問題が起こった際にはすぐに確認いたしますのでホテルにご連絡頂けますようお願い致します。東屋ホテル$TEXT1の電話番号: $TEXT2",
        vni: "Nếu có vấn đề gì trong suốt chuyến đi, xin vui lòng liên hệ về Lễ tân khách sạn, theo số điện thoại sau: Khách sạn Azumaya $TEXT1: $TEXT2"
    },
    servicePickUpDropOffNoti3: {
        eng: "Please understand that if you leave hotel by another car or taxi without checking with our receptionist, the fee is still charged because we already arranged car for your request.",
        jap: "ドライバーが見つからない場合や送迎に際にトラブルが起きた場合はご連絡ください。東屋ホテル$TEXT1の電話番号: $TEXT2",
        vni: "Xin hãy thông cảm là theo quy định công ty, khách tự ý đi bằng taxi hay loại xe khác, không phải xe do dịch vụ khách sạn đã chuẩn bị sẵn theo yêu cầu trước đó của khách, mà không có sự liên hệ và xác nhận giữa khách và nhân viên Lễ tân khách sạn, thì phí vẫn sẽ bị tính phí. "
    },
    servicePickUpDropOffNoti4: {
        eng: "Avoiding cancellation fee, could you please contact before 3 hours from the picking time.",
        jap: "キャンセル料金が発生しないようにご到着時間から3時間以上までにご連絡をお願いいたします。",
        vni: "Để không phát sinh phí huỷ xin vui lòng liên lạc trước 3 giờ đồng hồ tính từ thời điểm xe đón."
    },
    askVATnot1: {
        eng: "* If you need VAT, please give us information of VAT before the day Guest check in and fill information like below:",
        jap: "* VATインボイスが必要な場合は事前に用意が必要なためチェックイン日までにお知らせください。",
        vni: "* Nếu Quý công ty cần VAT hay thanh toán chuyển khoản, vui lòng cung cấp thông tin ghi và gửi hóa đơn VAT trước và tại thời điểm check in như dưới đây bằng tiếng Việt:",
    },
    askVATnot2: {
        eng: "Company name (Vietnamese):",
        jap: "会社名：（ベトナム語）",
        vni: "Tên công ty:",
    },
    askVATnot3: {
        eng: "Company address (Vietnamese):",
        jap: "会社住所：（ベトナム語)",
        vni: "Địa chỉ công ty:",
    },
    askVATnot4: {
        eng: "Tax code:",
        jap: "税コード:",
        vni: "Mã số thuế:",
    },
    askVATnot5: {
        eng: "Email address:",
        jap: "メールアドレス：",
        vni: "Địa chỉ email nhận thư:",
    },
    askVATcompany1: {
        eng: "In case payment by company transfer, our hotel would like to send the information related to transferring money, please fill information like below:",
        jap: "振り込みの場合、当ホテルから振込先情報を後ほどお送りいたします。合わせて下記の情報につきましても、ご教示願います。貴社名及び担当者様氏名：",
        vni: "Đối với công ty thanh toán (trả sau) khách sạn sẽ gửi bộ chứng từ thanh toán về cho Quý công ty, kính nhờ Quý công ty điền vào form dưới.",
    },
    askVATcompany2: {
        eng: "Company name & Name of receiver:",
        jap: "貴社名及び担当者様氏名：",
        vni: "Tên công ty và tên người nhận:",
    },
    askVATcompany3: {
        eng: "Phone number:",
        jap: "電話番号：",
        vni: "Điện thoại:",
    },
    askVATcompany4: {
        eng: "Address (Vietnamese)",
        jap: "住所：",
        vni: "Địa chỉ nhận thư:",
    },
    askVATcompany5: {
        eng: "* We will send VAT by mail. Please contact us if you have not been received.",
        jap: "* VATインボイスをメールでお送りさせていただきます。",
        vni: "* Hóa đơn điện tử (HĐĐT) sẽ được gửi qua email cho Quý khách hàng.",
    },
    askVATcompany5_1: {
        eng: "",
        jap: "万が一届かない場合は、お手数ですがご連絡いただけますようお願い申し上げます。",
        vni: "Trường hợp không nhận được xin vui lòng liên lạc lại với chúng em.",
    },
    askVATcompany6: {
        eng: "In case payment by company transfer, our hotel would like to send the information related to transferring money, please fill information like below:",
        jap: "会社振り込みの場合、当ホテルが関連資料を後でお送りいたします。",
        vni: "Đối với công ty thanh toán (trả sau), hóa đơn chứng từ thanh toán sẽ được gửi về địa chỉ như thông tin như sau: ",
    },
    askVATcompany7: {
        eng: "* Note: Mail header:E - Invoice from Azumaya Hotel ( --Number invoice )",
        jap: "※ E VATインボイスの件名： E - Invoice from Azumaya Hotel ( --Number invoice ) ",
        vni: "* Lưu ý: Tiêu đề mail gửi đến:  E - Invoice from Azumaya Hotel ( --Number invoice) ",
    },
    askVAT3: {
        eng: "We will prepare VAT invoice as before.",
        jap: "VATインボイスは以前と同様にご用意させていただきます。",
        vni: "Hóa đơn VAT chúng em xin phép xuất như mọi lần với thông tin như dưới đây.",
    },
    askVAThave1: {
        eng: "We will prepare VAT invoice as before.",
        jap: "VATインボイスは以前と同様にご用意させていただきます。",
        vni: "Hóa đơn VAT chúng em xin phép xuất như mọi lần với thông tin như dưới đây.",
    },
    askVAThave2: {
        eng: "If there is any change in information of VAT invoice, please let us know by check in date.",
        jap: "VATインボイス情報を変更される場合、チェックイン日までにご連絡をお願いいたします。",
        vni: "Nếu có bất cứ sự thay đổi nào về thông tin hóa đơn VAT, xin Quý khách vui lòng thông báo giúp chúng em trước và tại thời điểm check in. ",
    },
    askVAThave3: {
        eng: "Please let us know in advance if you need VAT invoice for the service price when you use hotel service?",
        jap: "ホテルのサービスを利用される際、サービス代のVATインボイスが必要な際は事前にお知らせくださいませ。",
        vni: "Khi phát sinh phí dịch vụ khác ngoài tiền phòng, vui lòng thông báo giúp chúng em nếu Quý khách yêu cầu xuất VAT cho phí dịch vụ đó.",
    },
    askVAThave4: {
       // eng: "Company name (Vietnamese): $TEXT1",
        eng: "$TEXT1", 
        jap: "$TEXT1",
        vni: "$TEXT1",
    },
    askVAThave5: {
        //eng: "Company address (Vietnamese): $$TEXT1",
        eng: "$TEXT1",
        jap: "$TEXT1",
        vni: "$TEXT1",
    },
    askVAThave6: {
        //eng: "Tax code: $$TEXT1",
        eng: "$TEXT1",
        jap: "$TEXT1",
        vni: "$TEXT1",
    },
    askVAThave7: {
        //eng: "Email address: $$TEXT1"
        eng: "$TEXT1",
        jap: "$TEXT1",
        vni: "$TEXT1",
    },
    askVAThave8: {
        // eng: "Email address: $$TEXT1",
        eng: "$TEXT1",
        jap: "$TEXT1",
        vni: "$TEXT1",
    },
    rcName: {
        eng: "",
        jap: "",
        vni: "",
    },
    // suggest room
    thankForKindAttention : {
        eng: "Thank you so much for your kind attention and support to our Azumaya $TEXT1",
        jap: "この度は東屋$TEXT1店にご予約いただきまして誠にありがとうございます。",
        vni: "Cảm ơn $TEXT1 đã luôn quan tâm và ưu ái đặt phòng tại khách sạn Azumaya $TEXT2 chúng em.",
    },
    option1: {
        eng: "Option 1:",
        jap: "オプション1:",
        vni: "Lựa chọn 1: ",
    },
    option2: {
        eng: "Option 2:",
        jap: "オプション2:",
        vni: "Lựa chọn 2: ",
    },
    option3: {
        eng: "Option 3:",
        jap: "オプション3:",
        vni: "Lựa chọn 3: ",
    },
    roomShowPrice: {
        eng: "Room type: $TEXT1  Room price: $TEXT2VND+",
        jap: "$TEXT1のお部屋 $TEXT2VND+/泊",
        vni: "Loại phòng: $TEXT1, giá phòng: $TEXT2 VND+",
    },
    excludeVAT: {
        eng: "※ Prices exclude 8% VAT",
        jap: "※ 消費税(8%VAT)別途",
        vni: "※ Giá phòng chưa bao gồm 8%VAT ",
    },
    pleaseSelectRoomSign:{
        eng: "Please confirm which room you would like to reserve.",
        jap: "こちらでよろしければ予約確認書をお送りさせていただきます。",
        vni: "Nhờ $TEXT1 hỏi ý kiến khách giúp chúng em.",
    },
    pleaseSelectRoomMulti:{
        eng: "Please confirm if you would like us to keep the room",
        jap: "以上のオプションにてご検討をお願いいたします。",
        vni: "Nhờ $TEXT1 hỏi ý kiến khách giúp chúng em. ",
    },
    pleaseSelectRoomMulti2:{
        eng: "",
        jap: "上記よりお選び頂ければ、次のメールにて予約確認書をお送りさせていただきます。",
        vni: "",
    },
    pleaseSelectRoomOtherType:{
        eng: "And if it is accepted, please tell us (all guests’ name) and each guest’s identified room)",
        jap: "上記でよろしければ各お部屋ご利用のお客様のお名前（お部屋割り）もお教え願います。",
        vni: "Nếu chị đồng ý với đề xuất của chúng em, phiền chị cung cấp thông tin đầy đủ khách nào sẽ ở phòng nào để chúng em chuẩn bị tốt hơn ạ.)",
    },
    sendBookingNextMail: {
        eng: "Then, BOOKING CONFIRMATION will be attached in the next email.",
        jap: "",
        vni: "Sau khi có thông tin về loại phòng chị muốn đặt, chúng em sẽ gửi BẢN XÁC NHẬN ĐẶT PHÒNG trong email tiếp theo. ",
    },
    dateCancelSuggestRoom: {
        row1: {
            eng: "Please do understand that this suggested room is ",
            jap: "お返事は",
            vni: "Chúng em xin phép giữ phòng"
        },
        row2: {
            eng: " if we do not have your confirmation. ",
            jap: "までにいただければ幸いでございます。",
            vni: ".Nếu sau ngày này chúng em vẫn chưa nhận được hồi âm từ chị, chúng em sẽ HỦY giữ phòng này."
        },
        row3: {
            eng: "",
            jap: "お返事を期日までにいただけない場合にはお部屋は次のお客様にお譲りさせていただくことがありますので予めご了承頂きますようお願い申し上げます。",
            vni: ""
        }
    }
    
}

export const branchNumberToNameWithLang = {
    "1": {
        eng: "Thai Van Lung 1",
        jap: "タイバンルン1",
        vni: "Thái Văn Lung 1",
        phone: "028 3824 6835"
    },
    "3": {
        eng: "Hai Ba Trung 1",
        jap: "ハイバーチュン1",
        vni: "Hai Bà Trưng 1",
        phone: "024 6278 6688"
    },
    "4": {
        eng: "Le Thanh Ton",
        jap: "レタントン",
        vni: "Lê Thánh Tôn",
        phone: "028 3827 1110"
    },
    "7": {
        eng: "Da Nang",
        jap: "ダナン",
        vni: "Đà Nẵng",
        phone: "0236 3743 888"
    },
    "8": {
        eng: "Kim Ma 2",
        jap: "キンマー2",
        vni: "Kim Mã 2",
        phone: "024 3724 7570"
    },
    "9": {
        eng: "Thai Van Lung 2",
        jap: "タイバンルン2",
        vni: "Thái Văn Lung 2",
        phone: "028 3827 1071"
    },
    "12": {
        eng: "Linh Lang",
        jap: "リンラン",
        vni: "Linh Lang",
        phone: "024 38620 620"
    },
    "13": {
        eng: "Hai Phong",
        jap: "ハイフォン",
        vni: "Hải Phòng",
        phone: "0225 3828 969"
    },
    "15": {
        eng: "Kim Ma 3",
        jap: "キンマー3",
        vni: "Kim Mã 3",
        phone: "024 3734 9730"
    }
}

export const makeNameBookerWithLang = {
    "engtrue" : "Ms.",
    "engfalse" : "Mr.",
    "vnitrue" : "chị",
    "vnifalse" : "anh",
    "vnitruewelcome": "Ms.",
    "vnifalsewelcome": "Mr.",

}

export const makeNumberLabel = {
    1 : "①",
    2 : "②",
    3 : "③",
    4 : "④",
    5 : "⑤",
    6 : "⑥",
}

export const makeRowTextMail = (target,lang, text1, text2, option) => {
    let checkContent;
    
    if(option) {
        checkContent = mailFormat[target][option][lang];
    } else checkContent = mailFormat[target][lang];

    if(checkContent) {
        if(text1) checkContent = checkContent.replace("$TEXT1", text1).replace("$TEXT1", text1);
        if(text2) checkContent = checkContent.replace("$TEXT2", text2).replace("$TEXT2", text2);
        return checkContent;
    }
}

export const makeRoomList = {
    eng: [
        "dear", 
        "br", 
        "thankBooking", 
        "lateReply", 
        "labelReservation",
        "checkAttachFile", 
        "cardNotification",
        "br",
        "checkContract",
        "askTimeCheckIn",
        "askOnigiri",
        "askVAT",
        "chargeCancelNotification",
        "checkBookingCancellationFree",
        "introLateOut",
        "introPickDropCar",
        "servicePickUp",
        "serviceDropOff",
        "servicePickUpDropOffNoti",
        "welcomingGuest",
        "br",
        "ifHaveSomeQuest",
        "bestRegards",
        "rcName"
    ],
    jap: [
        "dear",
        "br",
        "osewani",
        "thankBooking",
        "lateReply",
        "labelReservation",
        "roomAvailable",
        "checkAttachFile",
        "cardNotification",
        "br",
        "checkContract",
        "askTimeCheckIn",
        "askOnigiri",
        "askVAT",
        "chargeCancelNotification",
        "checkBookingCancellationFree",
        "introLateOut",
        "introPickDropCar",
        "servicePickUp",
        "serviceDropOff",
        "servicePickUpDropOffNoti",
        "welcomingGuest",
        "br",
        "ifHaveSomeQuest",
        "thankVeryMush",
        "bestRegards",
        "rcName"
    ]
    ,
    vni: [
        "dear",
        "br",
        "thankBooking",
        "lateReply",
        "labelReservation",
        "checkAttachFile",
        "cardNotification",
        "br",
        "checkContract",
        "askTimeCheckIn",
        "askOnigiri",
        "askVAT",
        "chargeCancelNotification",
        "checkBookingCancellationFree",
        "introLateOut",
        "introPickDropCar",
        "servicePickUp",
        "serviceDropOff",
        "servicePickUpDropOffNoti",
        "welcomingGuest",
        "br",
        "ifHaveSomeQuest",
        "thankVeryMush",
        "bestRegards",
        "rcName"
    ],
}

export const makeSuggestList = {
    eng: [
        "dear", 
        "br", 
        "thankForKindAttention",
        "lateReply",
        "labelReservation",
        "roomShowPrice",
        "checkContract",

        "pleaseSelectRoom",
        "sendBookingNextMail",
        "dateCancelSuggestRoom",
        "introLateOut",
        "introPickDropCar",
        "ifHaveSomeQuest",
        "bestRegards",
        "rcName"
    ],
    jap: [
        "dear", 
        "br", 
        "osewani",
        "thankForKindAttention",
        "lateReply", 
        "labelReservation",
        "roomShowPrice",
        "checkContract",

        "pleaseSelectRoom",
        "ifHaveSomeQuest",
        "bestRegards",
        "rcName"
    ],
    vni: [
        "dear", 
        "br",
        "thankForKindAttention",
        "lateReply",
        "labelReservation",
        "roomShowPrice",
        "checkContract", 

        "ifHaveSomeQuest",
        "bestRegards",
        "rcName"
    ]
}