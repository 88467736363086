import React, { useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalAction, invoiceManageAction, dataPickUpAction, unitsAction } from '../../redux/actions';

import ModalNotify from '../../features/ModalNotify';
import CompanyItemComponent from "../../components/Company";
import * as invoiceApi from '../../api/invoice';
import { coverNameToID, coverBranchIDToFullName } from '../../utils/Branch/SupportBranch';
import { changeDataToYYYY_MMMM } from '../../utils/Date/SupportDate'
import { errorToast, informationToast, } from '../../utils/Toast/index'; //warningToast, 
// import { useParams } from "react-router-dom";

function InvoiceManagerContainer() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const invoiceData = useSelector(state => state.invoiceManage);
    const dataBranchOnState = useSelector(state => state.dataPickUp.invoiceManage);

    // const { month } = useParams();
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    // console.log("month", month)

    useEffect(() => {
        if (branchID) {
            onLoadDataInvoiceWithMonth(dataBranchOnState.month);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataBranchOnState.month, branchID]);

    function onLoadDataInvoiceWithMonth(monthReq) {
        const onLoad = async () => {
            try {
                dispatch(unitsAction.showLoading());
                const dataResult = await invoiceApi.getInvoiceWithMonth(branchID, monthReq, token);

                dispatch(unitsAction.hiddenLoading());
                if (dataResult) {
                    if (dataResult.data.status === 1) {
                        dispatch(invoiceManageAction.fillInvoiceManage({
                            content: dataResult.data.data,
                            loading: false,
                        }))
                        informationToast("" + dataResult.data.msg);
                    }
                    else errorToast("Error when load Invoice");
                } else return errorToast("Error when load Invoice");

            } catch (error) { errorToast("Error when load Invoice"); }
            document.title = `Az${coverNameToID(Number(branchID))} Invoice Manage`;
        }

        onLoad();
    }

    function onChangeDataSelect(e, target) {
        const newData = {
            ...dataBranchOnState,
            ["" + target]: e
        };
        dispatch(dataPickUpAction.changeDataInvoiceManage(newData));
    }

    function onDownloadPdf(link) {
        const onLoad = async () => {
            try {
                 await invoiceApi.getPdfByLink(branchID, link, "")
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'format_input_ACC_Sum.pdf'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    });;
            } catch (error) {

            }
        }

        onLoad();
    }

    function closeModal() { dispatch(modalAction.modalReset()); }
  //  function openModal() { dispatch(modalAction.modalIsOk()); }

    return (<>
        <ModalNotify closeModal={closeModal} />
        <h1 className="mt-5">AZUMAYA {coverBranchIDToFullName(Number(branchID)).toLocaleUpperCase()} - INVOICE MANAGE : {changeDataToYYYY_MMMM(dataBranchOnState.month)}</h1>
        <div className="d-flex">
            <div className="d-flex">
                <div className="d-flex">
                    <div style={{ margin: 'auto', fontSize: '1.4rem' }}> Select Month:</div>
                    <input
                        type="month"
                        className="ml-2"
                        style={{ fontSize: '1.3rem' }}
                        onChange={e => onChangeDataSelect(e.target.value, "month")}
                        value={dataBranchOnState.month}
                    ></input>
                </div>
            </div>
        </div>
        <div className="" style={{ background: "aliceblue" }}>
            {invoiceData.loading ? "" :
                <CompanyItemComponent
                    data={invoiceData.content}
                    onDownloadPdf={onDownloadPdf}
                    type="invoiceManage"
                />}

        </div>
    </>)
}

export default InvoiceManagerContainer
