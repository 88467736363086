import React, { useEffect, useState } from 'react';
import ReactTooltip from "react-tooltip";
import ReservationShowDetail from '../ReservationShowDetail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot } from '@fortawesome/free-regular-svg-icons'; //faLock
import { getToday, checkDayWithOther2Day } from '../../../utils/Date/SupportDate';
import './reservationSchedule.css'
import { useSelector } from 'react-redux';

ReservationScheduleComponent.propTypes = {
};

const style = {
    backgroundColor: {
        oldDay: "rgba(211 211 211 / 50%)",
        newDay: "rgba(144 238 144 / 65%)"
    }
}

function ReservationScheduleComponent(props) {
    const { data, selectRoomType, listRender, onFinish, renderNumber,showComponent } = props;
    // console.log("branchID",branchID)
  //  console.log("listNumberRoom", listNumberRoom)
    const [show, setShow] = useState(false);
    const [id, setID] = useState("");
    const isCheckMode = false//true;// 
    let numbers = 0;
    const ruleRenderNumber = 18;

    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    useEffect(() => {
        if(!isCheckMode) {
            const handleClick = () => setShow(false);
            window.addEventListener('click', handleClick);
            return () => window.removeEventListener('click', handleClick);
        }
    }, []);

    useEffect(() => {
        if (numbers === ruleRenderNumber - 1 && listRender === "listReservation" && renderNumber === 1) {
          onFinish();
        } else if(renderNumber === 2 && listRender ==="listReservation" && numbers === data.listNumberRoom.length - 1) {
            onFinish();
        } else if (listRender === "listWaiting" && numbers === Object.keys(data[listRender]).length - 1) {
            onFinish();
        }
      }, [onFinish]);

    function checkRoomWithStyle(room) {
        var result;
        const listKeyOnListRoom = Object.keys(data.listRoom)
        listKeyOnListRoom.forEach(roomCheck => {
            if (data.listRoom[roomCheck].includes(room)) result = roomCheck;
        })
        return result;
    }

    function renderSchedule() {
        var result = [];

        // render 
        if(listRender === "listReservation") { 
            if(renderNumber === 1) {
                for (let i = 0; i < ruleRenderNumber; i++) {
    
                    if (selectRoomType === checkRoomWithStyle(data.listNumberRoom[i]) || selectRoomType === "All") {
                        result.push(<div className="d-flex rsv-ca ">
                            {renderRoom(data.listNumberRoom[i])}
                        </div>)
                    }
                    numbers = i;

                }
            } else {
                for (let j = ruleRenderNumber; j < data.listNumberRoom.length; j++) {
                    numbers = j;
    
                    if (selectRoomType === checkRoomWithStyle(data.listNumberRoom[j]) || selectRoomType === "All") {
                        result.push(<div className="d-flex rsv-ca ">
                            {renderRoom(data.listNumberRoom[j])}
                        </div>)
                    }
                }
            }

        } else {
            const listKey = Object.keys(data[listRender]);
            for (let i = 0; i < listKey.length; i++) {
                numbers = i;
                if (selectRoomType === checkRoomWithStyle(listKey[i]) || selectRoomType === "All") {
                    result.push(<div className="d-flex rsv-ca ">
                        {renderRoom(listKey[i])}
                    </div>)
                }
            }
        }

        return result;
    };

    function onContextMenu(e) {
       // console.log("vo here onContextMenu")
        e.preventDefault();
        //setShow(!show);
        //setID(roomNumber + i)
        // setPoints({ x: e.pageX - 560, y: e.pageY });
    }

    function renderRoom(roomNumber) {
        var result = [];
        function distributionRoomEmpty(dataShorten, dataExtend, index, dataRoom) {
            //console.log("dataRoom", dataRoom)
            // console.log("dataExtend", dataExtend)
            // console.log("dataShorten", dataShorten)
            // console.log("index", index)

            if(index === 0) {
                if(dataExtend === "" && index !== (data.listShowDate.length - 1)) {
                    return "rsv-room";
                }

                if(dataExtend !== "") {
                    if(dataExtend.timeEarly.slice(0,2) < 15) return "rsv-room_half"
                    else return "rsv-room";
                }
            } else {

                if (dataExtend !=="" && dataShorten !== "" && dataExtend && dataShorten) {
                    if("timeEarly" in dataExtend && "timeLateOut" in dataShorten ) {
                        if (index > 0 
                           && Number(dataExtend.timeEarly.slice(0, 2)) < 15 
                            && dataShorten.timeLateOut !== "12:00") {
                                if(dataShorten.nights ===0) return "rsv-room_half"
                                return "op-hidden"
                        }
                        
                    }
                }

                if (index > 0 && dataShorten !== "" && dataShorten.timeLateOut !== "12:00"  
                    && Number(dataShorten.timeLateOut.slice(0,2)) > 12 
                    && dataShorten.nights !==0) {
                    if(dataExtend !== "" && dataExtend !== undefined) {
                        if(Number(dataExtend.timeEarly.slice(0, 2)) < 15 ) {
                            // console.log("out hide")
                            return "rsv-room";
                        } else {
                            return "rsv-room_half"
                        }
                    } else {
                        return "rsv-room_half"
                    };
                    }
                else if (index > 0 && index !== (data.listShowDate.length - 1)
                        && dataExtend !== "" && Number(dataExtend.timeEarly.slice(0, 2)) < 15 //&& dataShorten.timeLateOut !== "12:00" 
                        && dataExtend.nights ===0 ) {
                    //console.log("out hide")
                    return "rsv-room_half";
                }
                else if (index > 0 && index !== (data.listShowDate.length - 1)
                    && dataExtend !== "" && Number(dataExtend.timeEarly.slice(0, 2)) < 15 && dataExtend.nights !==0 ) {
                    return "rsv-room_half";
                }
                // else if (index > 0 && Number(dataExtend.timeEarly.slice(0, 2)) < 15 && dataShorten.timeLateOut !== "12:00" ) {
                //     return ""
                // }
                else {
                    if(index > 0 && dataShorten !=="") {
                        // if(dataShorten.id ==="99c0997db4") { 

                        //     console.log("vo here distributionRoomEmpty", dataShorten.timeLateOut)
                        // }
                        
                        // if(dataShorten.timeLateOut !== "12:00") return "rsv-room_half"
                        // // if("id" in dataShorten){
                        //     if(dataShorten.id ==="99c0997db4") {
                        //         console.log("vo here distributionRoomEmpty")
                        //     }
                        // }
                    }

                    return "rsv-room";
                }
            }}

        const today = getToday();
        const dataRoom = data[listRender] === undefined ? undefined : data[listRender][roomNumber];

        function checkShowSmallIcon(numberCheck, data) {
            if(data) {
                const { move, pickUp, rentCar, dropOff, payment } = data//dataRoom[i];
                let numberShow = 0;

                if(move) numberShow +=1;
                if(payment === "Pre-Pay") {
                    numberShow +=1;
                    if(numberCheck === 2 ) return numberShow;
                }
                if(pickUp !== "") {
                    numberShow +=1;
                    if(numberCheck === 3 ) return numberShow;
                }
                if(rentCar) numberShow +=1;

                return numberShow;
            }
        }

        if (dataRoom) {
            for (let i = 0; i <= (data.listShowDate.length - 1); i++) {
                const checkMonth = checkDayWithOther2Day(today, (data.month + "-" + String(i + 1).padStart(2, '0')));
                const canNotEdit = checkMonth === 1 || listRender ==="listCancel" || listRender ==="listWaiting"
                if (dataRoom[i] === "") {
                    result.push(<div
                        className={distributionRoomEmpty(
                            Array.isArray(dataRoom[i - 1]) ? dataRoom[i - 1][dataRoom[i - 1].length - 1] : dataRoom[i - 1], 
                            Array.isArray(dataRoom[i + 1]) ? dataRoom[i + 1][0] : dataRoom[i + 1], 
                            i,
                            dataRoom)}
                        style={{ backgroundColor: canNotEdit ? style.backgroundColor.oldDay : "" }}
                        onClick={canNotEdit ? "" : () => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                        onContextMenu={(e) => onContextMenu(e)}
                    >
                        {/* {String(i + 1).padStart(2, '0')} */}
                        {(isCheckMode ? "X1" : "")+String(i + 1).padStart(2, '0')}
                        
                        {id === (roomNumber + i) && !canNotEdit && show && (
                            <div className="right_click_menu" >
                                {/* <h5 style={{ textAlign: 'center', backgroundColor: 'yellow' }}>{roomNumber + "(" + String(i + 1).padStart(2, '0') + ")"}</h5>
                                <ul className="right_click_menu-list"
                                //style={{ listStyleType: 'none', padding: '4px' }}
                                >
                                    <li className="right_click_menu-item"
                                        style={{ color: 'black', }}
                                        onClick={() => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                                    >
                                        Open Full Edit 2
                                    </li>
                                    <li className="right_click_menu-item" style={{ color: 'black', }}>Make Keep</li>
                                    <li className="right_click_menu-item" style={{ color: 'black', }}>Make Look</li>
                                </ul> */}
                            </div>
                        )}
                    </div>)
                }
                else if (!Array.isArray(dataRoom[i])) {
                    if ("name" in dataRoom[i]) {
                        //console.log("------------------------")
                        const lengthRoom = dataRoom[i].nights;
                        const checkMonthStartMonth = (i === 0 && checkDayWithOther2Day(dataRoom[i].checkin, (data.month + "-01")) === 1);
                        //console.log("checkDayWithOther2Day(dataRoom[i].checkin, (data.month 01))", checkDayWithOther2Day(dataRoom[i].checkin, (data.month + "-01")))
                        // console.log("data room", dataRoom[i].name)
                        // console.log("lengthRoom", lengthRoom)
                        // console.log("dataRoom[i].checkin", dataRoom[i].checkin)
                        //console.log("checkDayWithOther2Day", checkDayWithOther2Day(dataRoom[i].checkin, (data.month + "-01")) === 1)
                        // console.log("dataRoom[i].checkout", dataRoom[i].checkout)

                        const lateOut = (dataRoom[i].timeLateOut.slice(0, 2) > 12 && dataRoom[i].timeLateOut !== "12:00") ? 40 : 0;

                       // const lateOut = (dataRoom[i].timeLateOut !== "12:00") ? 40 : 0;

                        const earlyCheckInShorten = (Number(dataRoom[i].timeEarly.slice(0, 2)) > 15 
                                    && dataRoom[i].timeEarly !== "15:00") ? i===0 ? (checkMonthStartMonth ? 40: 0 ) : 40 : 0 // checkMonthStartMonth  ?: 0;
                        const earlyCheckInExtend = (Number(dataRoom[i].timeEarly.slice(0, 2)) < 15 && dataRoom[i].timeEarly !== "15:00") ? 40 : 0;
                       
                        if(earlyCheckInShorten === 40 && dataRoom[i].nights !== 0 && i > 0 ) { //&& i > 0
                            if( dataRoom[i - 1] ==="" || (dataRoom[i - 1].timeLateOut === "12:00" )) { //i === (data.listShowDate.length - 1) ||
                                result.push(<div //|| (dataRoom[i].night === 0 && lateOut!==0)
                                    className="rsv-room"
                                    onClick={() => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                                    style={{
                                            width: '40px',//checkWith2(),
                                            backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""
                                        }}>
                                        {(isCheckMode ? "Y1" : "")+String(i + 1).padStart(2, '0')}
                                </div>);
                            } else {
                                if(dataRoom[i - 1]) {
                                    if(Array.isArray(dataRoom[i - 1])) {
                                        if(dataRoom[i-1].length === 1) {
                                            if(dataRoom[i-1][0].timeLateOut.slice(0,2) <=18) {
                                                result.push(<div //|| (dataRoom[i].night === 0 && lateOut!==0)
                                                className="rsv-room"
                                                onClick={() => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                                                style={{
                                                        width: '40px',//checkWith2(),
                                                        backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""
                                                    }}>
                                                    {(isCheckMode ? "Y1x" : "")+String(i + 1).padStart(2, '0')}
                                            </div>);
                                            }
                                        }
                                    }
                                }
                            }

                        }

                        if(earlyCheckInShorten === 40 && dataRoom[i].nights !== 0 && i === 0) { // && Number(dataRoom[i + 1].timeEarly) === "15:00"  
                            result.push(<div //|| (dataRoom[i].night === 0 && lateOut!==0)
                            className="rsv-room"
                            onClick={() => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                            style={{
                                    width:'40px',
                                    backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""
                                }}>
                                {(isCheckMode ? "Z" : "") +String(i + 1).padStart(2, '0') + "+" + dataRoom[i].name}
                           </div>);
                        } 

                        function checkWith() {
                            if(dataRoom[i].nights === 0) {
                                if(earlyCheckInShorten!==0) return 40;
                                if(earlyCheckInExtend !==0 && lateOut !== 0 ) return 80;
                                if(earlyCheckInExtend !==0 && lateOut === 40) return 80;
                            } 

                            if(dataRoom[i].nights === 1 && earlyCheckInShorten === 40) {
                                if(lateOut === 40) return 80;
                                return 40;
                            }
                            
                            if(i === 0 && earlyCheckInExtend  === 40) {
                                return (lengthRoom * 80 + lateOut - earlyCheckInShorten  )
                            }

                            if(dataRoom[i-1] && earlyCheckInExtend === 40) {



                                if(dataRoom[i-1] === "")  return (lengthRoom * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend )
                                else {
                                    if(Array.isArray(dataRoom[i-1])) {
                                        if(dataRoom[i-1].length ===1) {
                                            if(dataRoom[i-1][0].timeLateOut !== "12:00") return (lengthRoom * 80 + lateOut - earlyCheckInShorten )
                                        } else {
                                            if(dataRoom[i-1][1].timeLateOut !== "12:00") return (lengthRoom * 80 + lateOut - earlyCheckInShorten )
                                        }
                                    } else {
                                        if("timeLateOut" in dataRoom[i-1] ) {

                                            if(dataRoom[i-1].timeLateOut !== "12:00") return (lengthRoom * 80 + lateOut - earlyCheckInShorten )
                                            if("timeEarly" in dataRoom[i-1] ) {                                                
                                                if (dataRoom[i-1].timeEarly.slice(0,2) > 15) return (lengthRoom * 80 + lateOut - earlyCheckInShorten );
                                                else if (dataRoom[i - 1].timeLateOut === "12:00" && earlyCheckInExtend === 40) return (lengthRoom * 80 + lateOut); 
                                                else return (lengthRoom * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend )
                                            } else return (lengthRoom * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend )
                                        } else {
                                            return (lengthRoom * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend )
                                        }
                                    }
                                 }
                            }

                            // check i - 1 data have checkout 12:00-13:00
                            
                            if(dataRoom[i-1]) {
                                let checkTime
                                if(Array.isArray(dataRoom[i-1])) {
                                    if(dataRoom[i-1][dataRoom[i-1].length]) {
                                        if("timeLateOut" in dataRoom[i-1][dataRoom[i-1].length] ) checkTime = Number(dataRoom[i-1][dataRoom[i-1].length].timeLateOut.slice(0,2))
                                    }
                                } else {
                                    if("timeLateOut" in dataRoom[i-1] ){
                                        checkTime = Number(dataRoom[i-1].timeLateOut.slice(0,2))
                                    }
                                }

                                if(checkTime >12 && checkTime < 15) {
                                    return (lengthRoom * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend -40)
                                 }
                                 
                            }

                            return (lengthRoom * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend )
                        }


                        result.push(<div
                            // className={(dataRoom[i - 1] === "" || i === 0) ? "rsv-room active"
                            //     : (dataRoom[i - 1] === "" || i === 0) ? "rsv-room_half" : "rsv-room active"}
                            className={"rsv-room active"}
                            onClick={() => window.open(`/room/${data.branchID}/${dataRoom[i].id}`, "_blank")}
                            onContextMenu={(e) => onContextMenu(e)}
                            data-tip
                            data-for={"data" + roomNumber + dataRoom[i].id+i}
                            style={{
                                backgroundColor: listRender ==="listWaiting" ? "gray":
                                         dataRoom[i].status === "confirm" ? dataRoom[i].color : "black",
                                width:checkWith(),
                            }}>

                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex  align-items-center" >
                                {checkWith() >=80 && <FontAwesomeIcon
                                        className="mr-1"
                                        icon={(dataRoom[i].timeEarly !== "15:00" ) ? faCircleDot : ""} />  }
                                {(isCheckMode ? "X2" : "") + dataRoom[i].name}
                                      
                                </div>
                                {dataRoom[i].move && <i className={"fa-solid fa-lock show-small-icon_left1"}></i>}
                                {dataRoom[i].payment === "Pre-Pay" && <i className={`fa-solid fa-sack-dollar show-small-icon_left${checkShowSmallIcon(2, dataRoom[i])}`}></i>}
                                {dataRoom[i].pickUp !== "" && <i className={`fa-solid fa-plane show-small-icon_left${checkShowSmallIcon(4, dataRoom[i])}`}></i>}
                                {dataRoom[i].rentCar && <i className={`fa-solid fa-car show-small-icon_left${checkShowSmallIcon(3, dataRoom[i])}`}></i>}
                                {dataRoom[i].dropOff !== "" && <i className={"fa-solid fa-plane show-small-icon_right1"}></i>}

                                {checkWith() >=80 && <FontAwesomeIcon icon={dataRoom[i].timeLateOut !== "12:00" ? faCircleDot : ""} />}
                                
                            </div>

                            {<ReactTooltip
                                id={"data" + roomNumber + dataRoom[i].id+i}
                                className="bg_white"
                                border={false}
                                type="light"
                            >
                                <ReservationShowDetail
                                    showComponent={showComponent}
                                    roomNumber={roomNumber}
                                    show = {show}
                                    dataRender={dataRoom[i]}
                                />
                            </ReactTooltip>}

                            {id === (roomNumber + i) && show && (
                                // dataRoom[i].nights !== 0 ?
                                // <div style={{
                                //         width: '200px', backgroundColor: 'aqua',
                                //         color: 'back', position: 'absolute',
                                //         zIndex: 999, left: (points.x)
                                //     }}>
                                //     <ul><li
                                //             className="right_click_menu-item"
                                //             style={{ color: 'black', }}
                                //             onClick={() => window.open(`/room/${data.branchID}/${dataRoom[i].id}`, "_blank")}
                                //         >Open Edit</li>
                                //         <li className="right_click_menu-item" style={{ color: 'black', }}>Quick Edit</li>
                                //         <li className="right_click_menu-item" style={{ color: 'black', }}>Change Guest</li>
                                //         <li className="right_click_menu-item" style={{ color: 'black', }}>Cancel</li>
                                //     </ul>
                                // </div>   :
                                <div className="right_click_menu" >
                                    {/* <h5 style={{ textAlign: 'center', backgroundColor: 'yellow', color:'black' }}>{roomNumber + "(" + String(i + 1).padStart(2, '0') + ")"}</h5>
                                    <ul className="right_click_menu-list" >
                                        <li className="right_click_menu-item"
                                            style={{ color: 'black', }}
                                            onClick={() => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                                        >Open Full Edit </li>
                                        <li className="right_click_menu-item" style={{ color: 'black', }}>Make Keep</li>
                                        <li className="right_click_menu-item" style={{ color: 'black', }}>Make Look</li>
                                    </ul> */}
                                </div>
                            )}

                        </div >)
                        
                    }
                }
                else if (Array.isArray(dataRoom[i])) {
                 //   console.log("dataRoom[i]", dataRoom[i]);
                    dataRoom[i].forEach((roomOnDataRoom, index)=> {
                        const checkTimeEarlyHour = Number(roomOnDataRoom.timeEarly.slice(0, 2))
                      //  console.log("roomOnDataRoom 234", roomOnDataRoom)
                       // const lengthRoom = calculateNightsAccSum(roomOnDataRoom.checkin, roomOnDataRoom.checkout, data.month)//roomOnDataRoom.nights;
                        const lateOut = (roomOnDataRoom.timeLateOut.slice(0, 2) > 12 && roomOnDataRoom.timeLateOut !== "12:00") ? 40 : 0;
                        const earlyCheckInShorten = ((checkTimeEarlyHour > 15 || (checkTimeEarlyHour === 15 && roomOnDataRoom.timeEarly.slice(3, 5) > 0) )
                                                         && roomOnDataRoom.timeEarly !== "15:00") ? 40 : 0;
                        const earlyCheckInExtend = (checkTimeEarlyHour < 15 && roomOnDataRoom.timeEarly !== "15:00") ? 40 : 0;

                        // console.log("====== check Array =====")
                        // console.log("roomOnDataRoom", roomOnDataRoom);
                        // console.log("lateOut", lateOut);d

                        function renderHalfRoom(keyNote) {
                            return result.push(<div 
                                className= "rsv-room_half"
                                onClick={canNotEdit ? "" : () => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                                style={{backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""}}
                                onContextMenu={(e) => onContextMenu(e)}>
                                {/* {String(i + 1).padStart(2, '0')} */}
                                {(isCheckMode ? keyNote : "")+String(i + 1).padStart(2, '0')}
                                </div>)
                        }

                        if(index !== 1) {
                            if(earlyCheckInShorten !== 0 && lateOut === 0) {
                                renderHalfRoom("K1.1")
                                // result.push(<div 
                                //     className= "rsv-room_half"
                                //     onClick={canNotEdit ? "" : () => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                                //     style={{backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""}}
                                //     onContextMenu={(e) => onContextMenu(e)}>
                                //     {/* {String(i + 1).padStart(2, '0')} */}
                                //     {(isCheckMode ? "K1" : "")+String(i + 1).padStart(2, '0')}
                                //     </div>)
                            }


                            if(earlyCheckInShorten === 40 && lateOut === 40) {
                                if(dataRoom[i-1]) {
                                    if(dataRoom[i-1] === "") renderHalfRoom("K1.2")
                                     else {
                                        if(Array.isArray(dataRoom[i-1])) {
                                        } else if ("timeLateOut" in dataRoom[i-1]) {
                                            if(dataRoom[i-1].timeLateOut === "12:00") renderHalfRoom("K1.2")
                                        }
                                     }
                                } else {
                                    renderHalfRoom("K1.2")
                                }
                            } 
                        }

                       function checkNightZero(dataShort, dataExtend) {


                        //11fa80598d
                           if (dataRoom[i].length === 2 && (dataRoom[i][1].nights === 0 || dataRoom[i][1].nights === 1)) { 

                            if(dataRoom[i][1].nights === 0 && index === 1) return 40;
                            if(dataRoom[i][1].nights === 1 && index === 1) {
                                if(dataShort) {
                                    return 40;
                                } else {
                                  if(earlyCheckInShorten) return 40;
                                  else return 80;
                                }
                            }

                           return 40
                           }

                            if(roomOnDataRoom.nights >= 1) {

                                if(dataShort) {
                                    if(dataRoom[i].length === 2) {
                                        //&& dataExtend.id === roomOnDataRoom[i][1].id
                                        return roomOnDataRoom.nights * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend
                                    }
                                    if("timeLateOut" in dataShort && earlyCheckInShorten === 40) {
                                        return roomOnDataRoom.nights * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend - 40
                                    }
                                }
                                return roomOnDataRoom.nights * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend
                            } else {         
                                if(lateOut === 40 && (roomOnDataRoom.timeLateOut.slice(0, 2)) < 15) {
                                    return 40
                               //     const lateOut = (roomOnDataRoom.timeLateOut.slice(0, 2) > 12 && roomOnDataRoom.timeLateOut !== "12:00") ? 40 : 0;
                                } else {
                                    if( lateOut === 40 && earlyCheckInShorten === 40) return 40;
                                    if( lateOut === 40 && earlyCheckInExtend === 40) {
                                        if(dataShort) {
                                            if("timeLateOut" in dataShort) {
                                              if(dataExtend === "") return 80;
                                              else return 40;
                                            } else {
                                                return 80;
                                            }
                                        } else if (i === 0 && dataRoom[i][0].nights === 0) return 40;
                                        else return 80
                                    }
                                }

                            }
                            return 40;
                       }

                       const checkWith =checkNightZero( 
                            Array.isArray(dataRoom[i - 1]) ? dataRoom[i - 1][dataRoom[i - 1].length - 1] : dataRoom[i - 1], 
                            Array.isArray(dataRoom[i + 1]) ? dataRoom[i + 1][0] : dataRoom[i + 1], )

                        result.push(<div
                            // className={(dataRoom[i - 1] === "" || i === 0) ? "rsv-room active"
                            //     : (dataRoom[i - 1] === "" || i === 0) ? "rsv-room_half" : "rsv-room active"}
                            className={"rsv-room active"}
                            onClick={() => window.open(`/room/${data.branchID}/${roomOnDataRoom.id}`, "_blank")}
                            onContextMenu={(e) => onContextMenu(e)}
                            data-tip
                            data-for={"data" + roomNumber + roomOnDataRoom.id + index }
                            style={{
                                backgroundColor: roomOnDataRoom.status === "confirm" ? roomOnDataRoom.color : "black",
                                // width:(roomOnDataRoom.nights === 0 && earlyCheckInShorten!==0) ? 45 :
                                // (roomOnDataRoom.nights=== 0 && earlyCheckInExtend !==0 && lateOut !== 0 ) ? 80 :
                                // (roomOnDataRoom.nights === 0 && earlyCheckInExtend !==0 && lateOut === 40) ? 45 :
                                // (lengthRoom * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend),
                                // width : roomOnDataRoom.nights === 0 ? checkNightZero(earlyCheckInShorten, lateOut) : 
                                // (lengthRoom * 80 + lateOut - earlyCheckInShorten + earlyCheckInExtend),
                                 width: checkWith //40
                            }}>

                            <div className="d-flex justify-content-between align-items-center ">
                                <div className="d-flex align-items-center">
                                {
                                    checkWith >=80 && <FontAwesomeIcon
                                    className="mr-1"
                                    icon={(roomOnDataRoom.timeEarly !== "15:00") ? faCircleDot : ""} />
                                }
                                    {(isCheckMode ? "Y2" : "") + roomOnDataRoom.name}
                                    {/* {roomOnDataRoom.name} */}
                                </div>
                                {roomOnDataRoom.move && <i className={"fa-solid fa-lock show-small-icon_left1"}></i>}
                                {roomOnDataRoom.payment === "Pre-Pay" && <i className={`fa-solid fa-sack-dollar show-small-icon_left${checkShowSmallIcon(2, roomOnDataRoom)}`}></i>}
                                {roomOnDataRoom.pickUp !== "" && <i className={`fa-solid fa-plane show-small-icon_left${checkShowSmallIcon(4, roomOnDataRoom)}`}></i>}
                                {roomOnDataRoom.rentCar && <i className={`fa-solid fa-car show-small-icon_left${checkShowSmallIcon(3, roomOnDataRoom)}`}></i>}
                                {roomOnDataRoom.dropOff !== "" && <i className={"fa-solid fa-plane show-small-icon_right1"}></i>}

                                {
                                    checkWith >=80 && <FontAwesomeIcon icon={roomOnDataRoom.timeLateOut !== "12:00" ? faCircleDot : ""} />
                                }
                             
                            </div>
                            {!show && (<ReactTooltip
                                id={"data" + roomNumber + roomOnDataRoom.id + index}
                                type="light">
                                <ReservationShowDetail
                                    showComponent={showComponent}
                                    roomNumber={roomNumber}
                                    show={show}
                                    dataRender={roomOnDataRoom}
                                />
                              
                            </ReactTooltip>)}

                            {id === (roomNumber + i) && show && (
                                <div className="right_click_menu" >
                                    {/* <h5 style={{ textAlign: 'center', backgroundColor: 'yellow', color:'black' }}>{roomNumber + "(" + String(i + 1).padStart(2, '0') + ")"}</h5>
                                    <ul className="right_click_menu-list" >
                                        <li className="right_click_menu-item"
                                            style={{ color: 'black', }}
                                            onClick={() => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                                        >Open Full Edit </li>
                                        <li className="right_click_menu-item" style={{ color: 'black', }}>Make Keep</li>
                                        <li className="right_click_menu-item" style={{ color: 'black', }}>Make Look</li>
                                    </ul> */}
                                </div>
                            )}

                        </div >)
                        // if night = 0 check full / half night
                        let classNameCheck;
                        if(dataRoom[i].length > 1 && index ===1) {
                            //dataRoom[i - 1], dataRoom[i + 1]
                            if(lateOut === 0) {
                                if(earlyCheckInShorten === 0  && earlyCheckInExtend ===0) {
                                    // rsv-room_half
                                    classNameCheck = "rsv-room"
                                 }

                                 if(earlyCheckInShorten !== 0) classNameCheck = "rsv-room_half";
                                 if(earlyCheckInExtend !== 0) {
                                    result.push(<div 
                                            className= "rsv-room"
                                            style={{backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""}}
                                            onContextMenu={(e) => onContextMenu(e)}
                                            >
                                            {(isCheckMode ? "X3" : "") + String(i + 1).padStart(2, '0')}
                                            {/* {String(i + 1).padStart(2, '0')} */}
                                           </div>)
                                 }
                            } else {
                                if(roomOnDataRoom.timeEarly === "15:00" || earlyCheckInExtend !==0) {
                                // Checkin normal + late out
                                result.push(<div 
                                        className= "rsv-room_half"
                                        style={{backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""}}
                                        onContextMenu={(e) => onContextMenu(e)}
                                        >
                                        {(isCheckMode ? "K2" : "") + String(i + 1).padStart(2, '0')}
                                        {/* {String(i + 1).padStart(2, '0')} */}
                                   </div>)
                                }
                            }
                        } else {

                            if(dataRoom[i+1] ==="" && dataRoom[i].length === 1 ) {
                                if(lateOut === 0) {
                                  //  console.log("vo case 2.1")
                                    if(earlyCheckInShorten === 0  && earlyCheckInExtend ===0) {
                                        // rsv-room_half
                                        classNameCheck = "rsv-room"
                                     }
    
                                     if(earlyCheckInShorten !== 0) classNameCheck = "rsv-room_half";
    
                                     if(earlyCheckInExtend !== 0) {
                                        result.push(<div 
                                                className= "rsv-room"
                                                style={{backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""}}
                                                onClick={canNotEdit ? "" : () => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                                                onContextMenu={(e) => onContextMenu(e)}
                                                >
                                                {/* {String(i + 1).padStart(2, '0')} */}
                                                {(isCheckMode ? "X" : "") + String(i + 1).padStart(2, '0')}
                                               </div>)
                                     }
                                } else {
                                    if((roomOnDataRoom.timeEarly === "15:00" || earlyCheckInExtend !==0)) {
                                    // Checkin normal + late out
                                   // console.log("vo case 2.2")
                                    result.push(<div 
                                        className= "rsv-room_half"
                                        onClick={canNotEdit ? "" : () => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                                        style={{backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""}}
                                        onContextMenu={(e) => onContextMenu(e)}>
                                            {(isCheckMode ? "K3" : "") + String(i + 1).padStart(2, '0')}
                                       </div>)
                                    }
                                }
                            } 
                            else if (dataRoom[i].length === 1 && (roomOnDataRoom.nights === 0 || roomOnDataRoom.nights === 1) 
                                        && roomOnDataRoom.timeEarly === "15:00" && lateOut === 40 ) {
                                    // check next Date Room have e
                                    const nextDay = Array.isArray(dataRoom[i + 1]) ? dataRoom[i + 1][0] : dataRoom[i + 1];

                                    if(nextDay) {
                                        
                                    }

                                result.push(<div 
                                    className= "rsv-room_half"
                                    onClick={canNotEdit ? "" : () => window.open(`/room/${data.branchID}/${roomNumber}/${data.month}-${String(i + 1).padStart(2, '0')}`, "_blank")}
                                    style={{backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""}}
                                    onContextMenu={(e) => onContextMenu(e)}
                                    >
                                    {(isCheckMode ? "K4" : "") + String(i + 1).padStart(2, '0')}
                                   </div>)
                                }
                            else {

                                if(dataRoom[i].length !== 2) {
                                    if (data[i+1]) {
                                        if(dataRoom[i+1].timeEarly === "15:00") {
                                            if(roomOnDataRoom.nights !== 0) {
                                                result.push(<div
                                                    className= "rsv-room_half"
                                                    style={{backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""}}
                                                    onContextMenu={(e) => onContextMenu(e)}>
                                                    {(isCheckMode ? "K51" : "") + String(i + 1).padStart(2, '0')}
                                                    {/* {String(i + 1).padStart(2, '0')} */}
                                                   </div>)
                                            } else {
                                                if(earlyCheckInExtend === 40 && lateOut === 40) {
                                                    renderHalfRoom("K1.3")
                                                }
                                            }
                                        }
                                    } else {
                                        if(earlyCheckInShorten === 0 && earlyCheckInExtend === 40 ) {
                                            result.push(<div
                                                className= {lateOut === 40 ? "rsv-room_half" : "rsv-room"}
                                                style={{backgroundColor: canNotEdit ? style.backgroundColor.oldDay : ""}}
                                                onContextMenu={(e) => onContextMenu(e)}>
                                                {(isCheckMode ? "K52" : "") + String(i + 1).padStart(2, '0')}
                                                {/* {String(i + 1).padStart(2, '0')} */}
                                               </div>)
                                        }

                                    }
                                }
                            }
                        }
                    })
                }
            };
        }

        return result;
    };

    return (
        <div className={listRender ==="listReservation" ? "d-flex" : "d-flex"}
            style={{paddingTop: (listRender ==="listReservation" && renderNumber === 1) ? '7.3px' : 'unset'}}>
            <div className="rsv">
                <div div style={{ paddingTop: (listRender ==="listReservation" && renderNumber === 1) ? '26px' : '' }}>
                    {branchID && renderSchedule()}
                </div>
            </div>
        </div>
    );
}

export default ReservationScheduleComponent;
