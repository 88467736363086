import React from 'react';
// import { faSave, faPaperPlane, faWindowClose, faEdit, faDownload } from "@fortawesome/free-solid-svg-icons";
import * as checkFont from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './btn.css';

FooterButton.propTypes = {
};

function FooterButton(props) {
    const { button1, button2, button3, button4, button5, button6,
        iconBtn1, iconBtn2, iconBtn3, iconBtn4, iconBtn5, iconBtn6,
        width
        // styleBtn1, styleBtn2, styleBtn3, styleBtn4, styleBtn5, styleBtn6
    } = props;

    return (
        <React.Fragment>
            <div className="mt-16 d-flex footer-btn justify-content-center no-printer"
                style={{
                    position: 'fixed', padding: '4px', bottom: '0',
                    width: width ? width : '100%',
                    backgroundColor: '#888', zIndex: '99'
                }}>

                <button
                    className="btn btn__footer btn-light d-flex mr-2"
                    onClick={() => props.onClickButton1()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn1]} className="btn__add_icon icon mr-2" />
                    {/* <FontAwesomeIcon icon="fa-solid fa-floppy-disk" /> */}
                    <span className="btn__add_text">{button1}</span>
                </button >
                <button
                    className={(button2 === undefined ? "op-hidden" : "btn btn__footer btn-light d-flex mr-2")}
                    onClick={() => props.onClickButton2()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn2]} className="btn__add_icon icon mr-2" />
                    <span className="btn__add_text">{button2}</span>
                </button >
                <button
                    className={(button3 === undefined ? "op-hidden" : "btn btn__footer btn-light d-flex mr-2")}
                    onClick={() => props.onClickButton3()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn3]} className="btn__add_icon icon mr-2" />
                    <span className="btn__add_text">{button3}</span>
                </button >
                <button
                    className={(!button4 ? "op-hidden" : "btn btn__footer btn-light d-flex mr-2")}
                    onClick={() => props.onClickButton4()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn4]} className="btn__add_icon icon mr-2" />
                    <span className="btn__add_text">{button4}</span>
                </button >
                <button
                    className={(!button5 ? "op-hidden" : "btn btn__footer btn-light d-flex mr-2")}
                    onClick={() => props.onClickButton5()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn5]} className="btn__add_icon icon mr-2" />
                    <span className="btn__add_text">{button5}</span>
                </button >
                <button
                    className={(!button6 ? "op-hidden" : "btn btn__footer btn-light d-flex")}
                    onClick={() => props.onClickButton6()}>
                    <FontAwesomeIcon icon={checkFont[iconBtn6]} className="btn__add_icon icon mr-2" />
                    <span className="btn__add_text">{button6}</span>
                </button >
            </div>
        </React.Fragment>
    );
}

export default FooterButton;