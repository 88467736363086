import React, { useEffect, useState, } from "react";
import { useSelector } from "react-redux";
import { changeDataToYYYY_MMMM_DD } from '../../../utils/Date/SupportDate';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';
import { changeDataToDDMMYYYY } from '../../../utils/Date/SupportDate.js';

function MakeListRentCarComponent(props) {
    const makeRoomService = useSelector(state => state.makeRoomOther);
    const [fileName, setFileName] = useState("");
    const { rentCarList } = makeRoomService;
    const { data } = props;
    const { isAutomaticInput0 } = data;
    const [listSelect, setListSelect] = useState([]);
    const makeRoomOnState = useSelector(state => state.makeRoom);

    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;
    const isVietNamBranch = (branchID !== 97 && branchID !== 11)
  //  const makeRoomOnStateOther = useSelector(state => state.makeRoomOther);

    var sumTotal = 0;

    useEffect(() => {
        const fileNameSave = ` (${changeDataToDDMMYYYY(makeRoomOnState.listSchedule[0].checkin).slice(0, 2)}-${changeDataToDDMMYYYY(makeRoomOnState.listSchedule[0].checkout)})`;
        const guestNameList = [];

        if (makeRoomOnState.listGuestSelect.length > 0) {
            makeRoomOnState.listGuestSelect.forEach(guestData => {
                guestNameList.push((guestData.gender ? "Ms." : "Mr.") + guestData.name);
            })

           // setFileNameSave("Pick_up_" + [guestNameList[0]]);
            setFileName("Rent_Car_" + guestNameList[0] + fileNameSave);

        }
    },[])

    function onChange(valueChange, target, index) {
        if(target === "date") {
            props.onChange(changeDataToYYYY_MMMM_DD("" + valueChange), "rentCarList", { target, index })
        } else props.onChange(valueChange, "rentCarList", { target, index });
    }

    function onblurPrice(valueChange, target, index) {
        if(isAutomaticInput0)  props.onChange(valueChange +",000", "rentCarList", { target, index });
    }

    function buttonFunction(type) {
        if (rentCarList.length < 10 && type !== "delete") {
            const newValue = [...rentCarList];
            newValue.push({ date: "", price: 0, content: "", note: "" });
            props.onChange(newValue, "rentCarList");
            //   setValue(newValue);
        } else if (type === "delete") {
            if(listSelect.length > 0) {
                const newValue = [...rentCarList];
                const newListUpdate = newValue.filter((item, index) => {
                  if (!listSelect.includes(index)) return item;
                });
                setListSelect([]);
                props.onChange(newListUpdate,"rentCarList");
            }
        } else {
            alert("Max length 10 rows")
        }
    };

    function onClickCheckBox(index) {
        const newList = [...listSelect];
        if(newList.length === 0) newList.push(index);
        else {
            if(!newList.includes(index)) newList.push(index);
            else newList.splice(newList.indexOf(index), 1);
        }
        setListSelect(newList);
    }

    function renderItem(value) {
        var result = []
        value.forEach((item, index) => {
            sumTotal = sumTotal + changeStringVNDtoNumber(item.price);
            return result.push(<tr key={index}>
                <td style={{ border: '0.8px solid black' }}>
                    <div className="form-check form-check-inline ">
                        <label className="form-check-label mr-2" for={"inlineCheckbox" + index}>{index + 1}</label>
                        <input className="form-check-input select-schedule" type="checkbox" id={"inlineCheckbox" + index} 
                            value={index}
                            checked = {listSelect.includes(index)}
                            onChange={e => onClickCheckBox(index)}
                             />
                    </div>
                </td>
                <td style={{ border: '0.8px solid black', width: '150px' }}>
                    <div>
                        <DatePicker 
                           //selected=""
                            selected = {item.date === "" ? "" : new Date(item.date)} 
                            dateFormat="dd-MM-yyyy"
                            onChange = {(date) => onChange(date, "date", index)} 
                        />
                    </div>
                </td>
                <td style={{ border: '0.8px solid black' }} >
                    <textarea
                        value={item.content}
                        style={{ width: '100%', minHeight: '70px' }}
                        onChange={e => onChange(e.target.value, "content", index)}
                    ></textarea>
                </td>
                <td style={{ border: '0.8px solid black', width: '100px' }}>
                        <NumberFormat
                            thousandSeparator={true}
                            type="text"
                            name="pickupPrice" min="0"
                            style={{ width: '100px' }}
                            value={item.price}
                            onChange={e => onChange(e.target.value, "price", index)}
                            onBlur = {e => onblurPrice(e.target.value, "price", index)}
                        />
                </td>
                <td style={{ border: '0.8px solid black' }}>
                    <textarea
                        value={item.note}
                        style={{ width: '100%', minHeight: '70px' }}
                        onChange={e => onChange(e.target.value, "note", index)}
                    ></textarea>
                </td>
            </tr >)
        })

        result.push((<tr>
            <td style={{ border: '0.8px solid black', }}></td>
            <td style={{ border: '0.8px solid black', width: '150px', }}></td>
            <td style={{ border: '0.8px solid black', width: '400px', }}></td>
            <td style={{ border: '0.8px solid black', width: '50px', }}>{sumTotal.toLocaleString()} {isVietNamBranch ? "VND" : "USD"}</td>
            <td style={{ border: '0.8px solid black', width: '400px', }}></td>
        </tr>))

        return result;
    };

    return (
        <>
            <table id="customers" style={{ backgroundColor: 'ghostwhite', margin: 'auto' }} >
               <tbody>
                <tr>
                    <td style={{ border: '0.8px solid black', backgroundColor: 'yellow', padding: '4px' }}>No.</td>
                    <th style={{ border: '0.8px solid black', width: '130px', backgroundColor: 'yellow', padding: '4px' }}>Date</th>
                    <th style={{ border: '0.8px solid black', width: '400px', backgroundColor: 'yellow', padding: '4px' }}>Content Rent Car</th>
                    <th style={{ border: '0.8px solid black', width: '100px', backgroundColor: 'yellow', padding: '4px' }}>Price</th>
                    <th style={{ border: '0.8px solid black', width: '400px', backgroundColor: 'yellow', padding: '4px' }}>Note</th>
                </tr>
                {rentCarList.length === 0 ? <></> : renderItem(rentCarList)}
                </tbody>
            </table>

            <div className="d-flex mt-5 bt-2" style={{marginLeft : '30px'}}>
                    <div className="">File name :</div>
                    <textarea
                        maxlength="150"
                        value={fileName}
                        onChange={e => setFileName(e.target.value)}
                        style={{ height: "30px", width: '60%' }} ></textarea>
            </div>

            <div className="d-flex mt-2 ml-5">
                <button className="btn btn-success"
                    style={{ fontSize: '1.2rem' }}
                    onClick={() => buttonFunction("add")}>Add new</button>
                {/* <button className="btn btn-primary" 
                        style={{fontSize: '1.2rem' }}
                        onClick={() => buttonFunction("copy")}>Copy</button> */}
                <button className="btn btn-danger ml-1"
                    style={{ fontSize: '1.2rem' }}
                    onClick={() => buttonFunction("delete")}>Delete</button>
                <button className="btn btn-primary ml-4"
                    style={{ fontSize: '1.2rem' }}
                    disabled={rentCarList.length === 0}
                    onClick={() => props.onDownload(rentCarList,"guest", fileName)}>Down PDF for guest</button>
                <button className="btn btn-primary ml-1"
                    style={{ fontSize: '1.2rem' }}
                    disabled={rentCarList.length === 0}
                    onClick={() => props.onDownload(rentCarList,"driver", fileName)}>Down PDF for Driver</button>
            </div>
        </>
    )
}

export default MakeListRentCarComponent
