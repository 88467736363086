/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import ComponentSupportContentRequest1 from '../../../components/MakeRoom/MakeRoomDataRoom/changeRequest1';
import ComponentSupportContentConfirmDeleteSchedule from '../../../components/MakeRoom/MakeRoomDataRoom/confirmDeleteSchedule';
import ComponentConfirmMakeHotelInvoice from '../../../components/MakeRoom/MakeRoomDataRoom/confirmMakeHotelInvoice';
import MakePickUpBoard from '../../../components/MakeRoom/MakeRoomDataRoom/makePickUpBoard';
import MakeListRentCarComponent from '../../../components/MakeRoom/MakeRoomDataRoom/makeRentcarList';
import GuestInformationDetails from '../../../components/MakeRoom/MakeRoomGuest/guestInformationDetails';
import ComponentViewHistory from '../../../components/Unit/VierwHistory';
import CompanyEditDetails from '../../../components/Company/companyEditDetails';
import ComponentConfirmMakeNewNumber from '../../MakeHotelInvoice/MakeHotelInvoice/modalConfirmMakeNewNumber';
import ModalNotifyTextAndButton from '../../../components/SupportComponents/Modal/ModalComponets/ModalNotifyTextAndButton';
import ModalShowBillDouble from '../../../components/MakeRoom/MakeRoomDataRoom/ModalShowBillDouble';
import ComponentSupportContentSortSchedule from '../../../components/MakeRoom/MakeRoomDataRoom/confirmSortSchedule';
import ComponentSupportContentSameSchedule from '../../../components/MakeRoom/MakeRoomDataRoom/confirmMakeSameSchedule';
import ModalShowInvoice from '../../MakePMRQ/ModalShowInvoice';
import ModalShowRoomAfterCheckType from '../../../components/MakeRoom/Other/ModalShowRoomAfterCheckType';
import MailContainer from '../../MailContainer';

ModalSupportOption.propTypes = {
};

function ModalSupportOption(props) {
    const { button1, button2, button3, button4,
        // value1, value2, value3, value4,
        featureSupport1, featureSupport2,
        typeButton1, typeButton2, typeButton3, typeButton4,
        featureButton1, featureButton2, featureButton3, featureButton4,
        component, type, title, max_width,
        itemOption, itemTotal
    } = props;

    function showComponent(type) {
        /*
        case 1: RentCar list
        */
        switch (type) {
            // SHOW RENTCAR
            case 1: {
                return <MakeListRentCarComponent
                    onChange={featureSupport1}
                    onDownload={featureSupport2}
                    data={itemOption} />
            }
            // SHOW DOWNLOAD BOARD
            case 2: {
                return <MakePickUpBoard
                    onDownload={featureSupport1}
                    onClose={featureSupport2}
                    data={itemOption} />
            }
            // create new guest
            case 3: {
                return <GuestInformationDetails
                    onSave={featureSupport1}
                    onClose={featureSupport2}
                    onDelete={featureButton3}
                    data={itemOption}
                />
            }
            // Edit Company
            case 4: {
                return <CompanyEditDetails
                    onSave={featureSupport1}
                    onClose={featureSupport2}
                    onDelete={featureButton3}
                    type={itemOption.type}
                    data={itemOption.data}
                />
            }
            // view history
            case 5: {
                return <ComponentViewHistory
                    // type="guest"
                    type={itemOption.type}
                    data={itemOption}
                />
            }
            // view history company
            case 6: {
                return <ComponentViewHistory
                    type="company"
                    data={itemOption}
                />
            }
            case 7: {
                return <ComponentViewHistory
                    type="company"
                    data={itemOption}
                />
            }
            case 8: {
                return <ComponentSupportContentRequest1
                    data={itemOption}
                    onSave={featureSupport1}
                    onClose={featureSupport2}
                />
            }
            case 9: {
                return <ComponentSupportContentConfirmDeleteSchedule
                    data={itemOption}
                    onDelete={featureSupport1}
                    onClose={featureSupport2}
                />
            }
            case 10: {
                return <ComponentConfirmMakeHotelInvoice
                    onReload={featureSupport1}
                    onClose={featureSupport2}
                    onOpenAddService={featureButton2}
                    onChange={featureButton3}
                    onPrintAll={featureButton4}
                    type={itemOption.type}
                    group_id={itemOption.group_id}
                    data={itemOption}
                    isAddService={itemOption.isAddService}
                    invoice_id={itemOption.invoice_id}
                    typeInvoice= {itemOption.typeInvoice}
                />
            }
            case 11: {
                return <ComponentConfirmMakeNewNumber
                    onSave={featureSupport1}
                    onClose={featureSupport2}

                />
            }
            case 12: {
                return <ModalShowBillDouble 
                       data={itemOption}
                       onClose={featureSupport2}
                />
            }
            case 13: {
                return <ComponentSupportContentSortSchedule
                    data={itemOption}
                    onChangeSortListSchedule={featureSupport1}
                    onClose={featureSupport2}
                />
            }
            case 14: {
                return <ComponentSupportContentSameSchedule
                    data={itemOption}
                    onCheckAutomatic={featureSupport1}
                    onClose={featureSupport2}
                    onSelectRoom = {featureButton1}
                />
            }
            case 15: {
                return <ModalShowInvoice
                     data={itemOption}
                     featureButton1={featureButton1}
                     featureButton2={featureButton2}
                     onHandleFindInvoice={featureSupport1}
                />
            }
            case 16: {
                return <ModalShowRoomAfterCheckType
                    data={itemOption}
                    featureButton1={featureButton1}
                    featureButton2={featureButton2}
                />
            }
            // mail format
            case 17: {
                return < MailContainer
                     type={itemOption.type}
                     branchID= {itemOption.branchID}
                />
            }
            case 88: {
                return <ModalNotifyTextAndButton 
                      color = {itemOption.color}
                      title = {itemOption.title}
                    //   btnName1 = {itemOption.btnName1}
                    //   btnName2 = {itemOption.btnName2}
                    //   featureButton1 = {featureButton1}
                    //   featureButton2 = {featureButton2}
                    //   featureButton3 = {featureButton3}
                />
            }
            default: return <></>
        }
    }

    return (
        <React.Fragment>
            <div style={{ alignItems: type===3 ? "":'center', textAlign: type===3 ? "":'center' }}>
                {title === undefined ? <></> : <h3 className="mb-3">{title}</h3>}
                {showComponent(type)}
                {component === undefined ? <></> : component}

                <div style={{ borderBottom: '1px solid #888', marginTop: '8px', marginBottom: '8px' }}></div>

                <div style={{ textAlign: 'end' }}>
                    <div className="modal-footer-btn">
                        <button
                            type="button"
                            className={"btn btn-modal__close mr-2 "
                                + (typeButton1 === undefined ? "" : typeButton1)
                                + (button1 === undefined ? " op-hidden" : "")}
                            onClick={featureButton1}
                        >{button1}</button>
                        <button
                            type="button"
                            className={"btn btn-modal__ok mr-2 "
                                + (typeButton2 === undefined ? " btn-primary" : typeButton2)
                                + (button2 === undefined ? " op-hidden" : "")}
                            onClick={featureButton2}
                        >{button2}</button>
                        <button
                            type="button"
                            className={"btn btn-modal__ok mr-2 "
                                + (typeButton3 === undefined ? " btn-primary" : typeButton3)
                                + (button3 === undefined ? " op-hidden" : "")}
                            onClick={featureButton3}
                        >{button3}</button>
                        <button
                            type="button"
                            className={"btn btn-modal__ok mr-2 "
                                + (typeButton4 === undefined ? " btn-primary" : typeButton4)
                                + (button4 === undefined ? " op-hidden" : "")}
                            onClick={featureButton4}
                        >{button4}</button>
                    </div>
                </div>

            </div>
        </React.Fragment >
    );
}

export default ModalSupportOption;
