import React from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";

function MailComponentDateCharge(props) {
    const { onChangeData, data, type, selectTabMain} = props;


    const DatePickUpCalenderContainer = ({ className, children }) => {
        return (
          <div style={{ padding: "3px", background: "#216ba5", color: "#fff" }}>
            <CalendarContainer className={className}>
              <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
          </div>
        );
      };
      
    return (selectTabMain === "makeRoom" && <>
        <div className={!type ? 'd-flex ml-5 mb-3' : 'd-flex'}>
                <label for="checkIn1" style={{width: !type ? "100px" : "65px", textAlign:'left'}}>Check In:</label>
                    {/* <label for="checkIn1" style={{width:"100px"}}>Check In:</label> */}
                    <div style={{width:"90px"}}>
                        <DatePicker
                                selected={data.checkIn1} 
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => onChangeData( "checkIn1", date)} 
                                selectsEnd
                                // disabled={(role !== 0 && role !== 2 && role !== 3)}
                                startDate={new Date()} 
                                minDate={new Date()} 
                                // endDate={dataRender.checkout === "" ? "" :  new Date(dataRender.checkout)} 
                                // minDate={dataRender.checkin === "" ? "" :  new Date(dataRender.checkin)} 
                                monthsShown={2}
                                popperClassName="some-custom-class"
                                popperPlacement="bottom-start"
                                style={{width:"90px"}}
                                calendarContainer={DatePickUpCalenderContainer}
                                popperModifiers={[
                                    {
                                        name: "offset",
                                        options: {
                                        offset: [5, 10],
                                        },
                                    },
                                    {
                                        name: "preventOverflow",
                                        options: {
                                        rootBoundary: "viewport",
                                        tether: false,
                                        altAxis: true,
                                        },
                                    },
                                    ]}
                            />
                    </div>
    
                    <label for="dataCancelFree1" style={{width:"90px", marginLeft:'24px'}}>Charge Date:</label>
                    <div style={{width:"90px", }}>
                        <DatePicker
                            selected={data.dateCancelFree1} 
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => onChangeData( "dateCancelFree1", date)} 
                            selectsEnd
                            // disabled={(role !== 0 && role !== 2 && role !== 3)}
                            startDate={new Date()} 
                            minDate={new Date()} 
                            // endDate={dataRender.checkout === "" ? "" :  new Date(dataRender.checkout)} 
                            // minDate={dataRender.checkin === "" ? "" :  new Date(dataRender.checkin)} 
                            monthsShown={2}
                            popperClassName="some-custom-class"
                            popperPlacement="bottom-start"
                            style={{width:"90px"}}
                            calendarContainer={DatePickUpCalenderContainer}
                            popperModifiers={[
                                {
                                    name: "offset",
                                    options: {
                                    offset: [5, 10],
                                    },
                                },
                                {
                                    name: "preventOverflow",
                                    options: {
                                    rootBoundary: "viewport",
                                    tether: false,
                                    altAxis: true,
                                    },
                                },
                                ]}
                        />
                    </div>
                </div>
            <span className="d-flex mr-2 mt-1"
                data-toggle="collapse"
                data-target="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample">
                <h5 className="ml-2" style={{color:'red'}}>+ Add more:</h5>
                {/* <FontAwesomeIcon
                    icon={faHandPointDown}
                    // style={style.icon}
                     /> */}
            </span>
            <div className="collapse" id="collapseExample"> 
                 <div className={!type ? 'd-flex ml-5 mb-3' : 'd-flex'}> 
                 <label for="checkIn1" style={{width: !type ? "100px" : "65px", textAlign:'left'}}>Check In:</label>
                    <div style={{width:"90px"}}>
                        <DatePicker
                                selected={data.checkIn2} 
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => onChangeData( "checkIn2", date)} 
                                selectsEnd
                                // disabled={(role !== 0 && role !== 2 && role !== 3)}
                                startDate={new Date()} 
                                minDate={new Date()} 
                                // endDate={dataRender.checkout === "" ? "" :  new Date(dataRender.checkout)} 
                                // minDate={dataRender.checkin === "" ? "" :  new Date(dataRender.checkin)} 
                                monthsShown={2}
                                popperClassName="some-custom-class"
                                popperPlacement="bottom-start"
                                style={{width:"90px"}}
                                calendarContainer={DatePickUpCalenderContainer}
                                popperModifiers={[
                                    {
                                        name: "offset",
                                        options: {
                                        offset: [5, 10],
                                        },
                                    },
                                    {
                                        name: "preventOverflow",
                                        options: {
                                        rootBoundary: "viewport",
                                        tether: false,
                                        altAxis: true,
                                        },
                                    },
                                    ]}
                            />
                    </div>


                    <label for="dataCancelFree1" style={{width:"90px", marginLeft:'24px'}}>Charge Date:</label>
                    <div style={{width:"90px", }}>
                        <DatePicker
                            selected={data.dateCancelFree2} 
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => onChangeData( "dateCancelFree2", date)} 
                            selectsEnd
                            // disabled={(role !== 0 && role !== 2 && role !== 3)}
                            startDate={new Date()} 
                            minDate={new Date()} 
                            // endDate={dataRender.checkout === "" ? "" :  new Date(dataRender.checkout)} 
                            // minDate={dataRender.checkin === "" ? "" :  new Date(dataRender.checkin)} 
                            monthsShown={2}
                            popperClassName="some-custom-class"
                            popperPlacement="bottom-start"
                            style={{width:"90px"}}
                            calendarContainer={DatePickUpCalenderContainer}
                            popperModifiers={[
                                {
                                    name: "offset",
                                    options: {
                                    offset: [5, 10],
                                    },
                                },
                                {
                                    name: "preventOverflow",
                                    options: {
                                    rootBoundary: "viewport",
                                    tether: false,
                                    altAxis: true,
                                    },
                                },
                                ]}
                        />
                    </div>
                </div>

                <div className={!type ? 'd-flex ml-5 mb-3' : 'd-flex'}> 
                    <label for="checkIn1" style={{width: !type ? "100px" : "65px", textAlign:'left'}}>Check In:</label>
                    <div style={{width:"90px"}}>
                        <DatePicker
                                selected={data.checkIn3} 
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => onChangeData( "checkIn3", date)} 
                                selectsEnd
                                // disabled={(role !== 0 && role !== 2 && role !== 3)}
                                startDate={new Date()} 
                                minDate={new Date()} 
                                // endDate={dataRender.checkout === "" ? "" :  new Date(dataRender.checkout)} 
                                // minDate={dataRender.checkin === "" ? "" :  new Date(dataRender.checkin)} 
                                monthsShown={2}
                                popperClassName="some-custom-class"
                                popperPlacement="bottom-start"
                                style={{width:"90px"}}
                                calendarContainer={DatePickUpCalenderContainer}
                                popperModifiers={[
                                    {
                                        name: "offset",
                                        options: {
                                        offset: [5, 10],
                                        },
                                    },
                                    {
                                        name: "preventOverflow",
                                        options: {
                                        rootBoundary: "viewport",
                                        tether: false,
                                        altAxis: true,
                                        },
                                    },
                                    ]}
                            />
                    </div>


                    <label for="dataCancelFree1" style={{width:"90px", marginLeft:'24px'}}>Charge Date:</label>
                    <div style={{width:"90px", }}>
                        <DatePicker
                            selected={data.dateCancelFree3} 
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => onChangeData( "dateCancelFree3", date)} 
                            selectsEnd
                            // disabled={(role !== 0 && role !== 2 && role !== 3)}
                            startDate={new Date()} 
                            minDate={new Date()} 
                            // endDate={dataRender.checkout === "" ? "" :  new Date(dataRender.checkout)} 
                            // minDate={dataRender.checkin === "" ? "" :  new Date(dataRender.checkin)} 
                            monthsShown={2}
                            popperClassName="some-custom-class"
                            popperPlacement="bottom-start"
                            style={{width:"90px"}}
                            calendarContainer={DatePickUpCalenderContainer}
                            popperModifiers={[
                                {
                                    name: "offset",
                                    options: {
                                    offset: [5, 10],
                                    },
                                },
                                {
                                    name: "preventOverflow",
                                    options: {
                                    rootBoundary: "viewport",
                                    tether: false,
                                    altAxis: true,
                                    },
                                },
                                ]}
                        />
                    </div>
                </div>
            </div>
    </>)
}

export default MailComponentDateCharge;