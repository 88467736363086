import React, { useState } from 'react';
import { changeDataToDDMMYYYY } from '../../../utils/Date/SupportDate';

function ComponentSupportContentConfirmDeleteSchedule(props) {

    const { data } = props;
    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    // useEffect(() => {
    //     setDataState(props.data)
    // }, [props.data])

    function onChange(value) {
        setValue(value)
    }

    function onCheckCode(type) {
        if (value !== data.code) {
            setError("The code is incorrect !!");
        } else {
            setError("");
            if(type==="delete") props.onDelete();
           // props.onDelete();
        }
    }

    function renderSchedule() {
        var result = [];
        if (data.schedule.length > 0) {
            data.schedule.forEach((schedule) => {
                return result.push(<tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>{schedule.roomSelect}</td>
                    <td style={{ border: '1px solid black' }}>{changeDataToDDMMYYYY(schedule.checkin)}</td>
                    <td style={{ border: '1px solid black' }}>{changeDataToDDMMYYYY(schedule.checkout)}</td>
                </tr>)
            })
        } else {
            result.push(<tr style={{ border: '1px solid black' }}>
                <td style={{ border: '1px solid black' }}>{data.schedule.roomSelect}</td>
                <td style={{ border: '1px solid black' }}>{changeDataToDDMMYYYY(data.schedule.checkin)}</td>
                <td style={{ border: '1px solid black' }}>{changeDataToDDMMYYYY(data.schedule.checkout)}</td>
            </tr>)
        }

        return result;
    }
    return (
        <>
            <div className="ml-3 mr-2">
                {
                    data.schedule && <>
                        {
                            data.code === "sort" && <>
                                  <h4>You want to sort schedule by list?</h4>
                                  <h5 className='red-color'>* Select Main schedule</h5>
                                </>
                        }
                        {
                            data.code !=="sort" && <h4>You want to delete the schedule</h4>
                        }
                        <table style={{ margin: 'auto' }}>
                            <tr style={{ border: '1px solid black' }}>
                                <th style={{ border: '1px solid black', background: 'yellow' }}>Room Number</th>
                                <th style={{ border: '1px solid black', background: 'yellow', width: '120px' }}>Check In</th>
                                <th style={{ border: '1px solid black', background: 'yellow', width: '120px' }}>Check Out</th>
                            </tr>
                            {renderSchedule()}
                        </table>
                    </>
                }
                <>
                        <h4>------</h4>
                        <h4>Please write "{data.code}"" to delete the {data.schedule ? "Schedule" :"Company"}</h4>
                        <input
                            value={value}
                            onFocus={() => setError("")}
                            onBlur={() => onCheckCode("check")}
                            onChange={e => onChange(e.target.value)}
                        ></input>
                </>
                
            </div>
            <div className={error === "" ? "op-hidden" : "op-redcolor"}>{error}</div>
            <div className="mt-2">
                <button className="btn btn-primary mr-2" onClick={() => onCheckCode("delete")}>Confirm</button>
                <button className="btn btn-danger" onClick={() => props.onClose()}> Cancel delete</button>
            </div>
        </>
    )
}

export default ComponentSupportContentConfirmDeleteSchedule;
