import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { changeDataToDDMMYYYY } from '../../../utils/Date/SupportDate.js';

function MakePickUpBoard(props) {
    const { data, } = props;
    const [guestName, setGuestName] = useState("");
    const [fileName, setFileName] = useState("");
    const [fontSize, setFontSize] = useState(90);
    const [valueArray, setValueArray] = useState([]);
    const [pickupSub, setPickupSub] = useState(false);
    const { branchInformation } = data;
    // console.log("pickUp", pickUp);
    const makeRoomOnState = useSelector(state => state.makeRoom);
    const makeRoomOnStateOther = useSelector(state => state.makeRoomOther);
    const { pickUp } = makeRoomOnStateOther;
    const [listGuestSelect, setListGuestSelect] = useState([]);

    useEffect(() => {
        var guestNameList = [];

        const fileNameSave = ` (${changeDataToDDMMYYYY(makeRoomOnState.listSchedule[0].checkin).slice(0, 2)}-${changeDataToDDMMYYYY(makeRoomOnState.listSchedule[0].checkout)})`;
        
        if (data.isHCMAreaBranch) setPickupSub(true);

        if (makeRoomOnState.listGuestSelect.length > 0) {
            makeRoomOnState.listGuestSelect.forEach(guestData => {
                guestNameList.push((guestData.gender ? "Ms." : "Mr.") + guestData.name);
            })
            setListGuestSelect(guestNameList);
            setValueArray(guestNameList);
            setGuestName(guestNameList[0]);
           // setFileNameSave("Pick_up_" + [guestNameList[0]]);
            setFileName("Pick_up_" + guestNameList[0] + fileNameSave);

        }
    }, [makeRoomOnState.listGuestSelect])

    function onCheckGuestSelect(value) {
        const newArray = [...valueArray];

        if (!newArray.includes(value)) {
            newArray.push(value)
            setValueArray(newArray);
        } else {
            const filler = newArray.filter(item => item !== value);
            setValueArray(filler);
        }
    }

    function onFillGuestToTextArea() {
        setGuestName(valueArray.join(' & '));
        if (valueArray.length === 1) setFontSize(90);
        if (valueArray.length === 2) setFontSize(70);
        if (valueArray.length === 3) setFontSize(60);
    }

    function renderListGuestSelect() {
        var result = [];
        listGuestSelect.forEach((guest, index) => {
            result.push(<div style={{ marginLeft: '12px', textAlign: 'left' }}>
                <input
                    class="form-check-input"
                    type="checkbox"
                    value={guest}
                    checked={valueArray.includes(guest)}
                    onChange={e => onCheckGuestSelect(e.target.value)}
                    id={"selectGuest" + index} />
                <label class="form-check-label" for={"selectGuest" + index}>
                    {guest}
                </label>
            </div>)
        })
        return result;
    }
    // backgroundColor: 'aqua',
    return (
        <div className="d-flex">
            <div style={{ border: '1px solid black', width: '80%', height: '500px', backgroundColor: 'aqua' }}>
                <div style={{ border: '0.8px solid black', margin: '8px', height: '480px' }}>
                    <div className="d-flex mt-3 ml-4">
                        <img src="https://database.azumayareport.com/img/logo/logo_az.png" 
                                alt="logo." class="img-responsive logo" 
                                style={{width: '220px'}}></img>
                        <div style={{margin:'auto'}}>
                          <div style={{ fontSize: '4rem', marginTop: '10px'}}>AZUMAYA HOTEL</div>
                          <div style={{ fontSize: '4rem', marginTop: '10px'}}>{branchInformation.name.toLocaleUpperCase()}</div>
                        </div>

                    </div>

                    {/* <div style={{ fontSize: '1.8rem', marginTop: '10px' }}>{branchInformation.address.toLocaleUpperCase()}</div>
                    <div style={{ fontSize: '1.8rem', marginTop: '10px' }}>Phone: {branchInformation.phone.toLocaleUpperCase()}</div> */}

                    {/* <input style={{ marginTop: '34px', height: '250px', width: '80%', fontSize: 80, padding: 0 }} /> */}

                    <textarea style={{
                        marginTop: '34px',
                        maxHeight: '250px',
                        minHeight: '250px',
                        height: '250px',
                        width: '80%',
                        fontSize: fontSize,
                        padding: 0,
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }}
                        onChange={e => setGuestName(e.target.value)}
                        value={guestName}
                    > </textarea>

                    <div className="d-flex justify-content-center">
                        <div style={{ fontSize: '3rem', marginTop: '10px' }}>
                                            {`PICK UP: ${pickUp ? pickUp.flight : ""} - Time: ${pickUp ? pickUp.time + " - Date: "+ changeDataToDDMMYYYY(makeRoomOnState.listSchedule[0].checkin)
                                             : ""}`}</div>
                        {/* <div style={{ fontSize: '3rem', marginTop: '10px' }}>{"- Date: "+ changeDataToDDMMYYYY(makeRoomOnState.listSchedule[0].checkin)}</div> */}
                    </div>


                </div>
            </div>

            <div style={{ backgroundColor: 'yellow', width: '20%', height: '100px' }}>
                <div className="mt-2" style={{ backgroundColor: 'yellow' }}>
                    <div className="mb-2">Set Font Size</div>
                    <input
                        type="number"
                        onChange={e => setFontSize(Number(e.target.value))}
                        onBlur={e => setFontSize(e.target.value)}
                        style={{ width: '70px' }}
                        value={fontSize} />
                    <div>
                        <button className="btn btn-primary" style={{}}
                            onClick={() => setFontSize(Number(fontSize) + 1)}>Up</button>
                        <button className="btn btn-warning ml-2"
                            onClick={() => setFontSize(Number(fontSize) - 1)}
                            style={{}}>Down</button>
                        <button className="btn btn-success mt-2 mb-3"
                            onClick={() => setFontSize(80)}
                            style={{}}>Reset (80)</button>
                    </div>

                </div>

                <div className="mt-2 mb-3 pt-3 pb-2" style={{ backgroundColor: 'yellow', }}>
                    <div className="">Quick select guests</div>
                    <div class="form-check mt-2">
                        {renderListGuestSelect()}
                    </div>

                    <button className="btn btn-primary mt-2"
                        onClick={() => onFillGuestToTextArea()}
                        style={{}}>
                        Select & Reset
                    </button>

                    <div className="mt-3" style={{ fontSize: '1.3rem', color: 'green', marginLeft: '20px' }}>
                        <div style={{ textAlign: 'left' }}>* Tips:</div>
                        <div style={{ textAlign: 'left' }}>* 1 guest  = 100 font size</div>
                        <div style={{ textAlign: 'left' }}>* 2 guests = 90 font size</div>
                        <div style={{ textAlign: 'left' }}>* 3 guests = 80 font size</div>
                    </div>
                </div>

                <div className="mt-2 mb-3 pt-3 pb-2" style={{ backgroundColor: 'yellow', }}>
                    <div className="">File name :</div>
                    <textarea
                        className="mb-2"
                        maxlength="100"
                        value={fileName}
                        onChange={e => setFileName(e.target.value)}
                        style={{ height: "60px", width: '100%' }} ></textarea>
                </div>

                {data.isHCMAreaBranch && <div style={{ marginLeft: '22px', textAlign: 'left' }}>
                    <input
                        class="form-check-input"
                        type="checkbox"
                        value={pickupSub}
                        checked={pickupSub}
                        onChange={() => setPickupSub(!pickupSub)}
                        id={"pickupSub"}
                    />
                    <label class="form-check-label" for={"pickupSub"}>
                        Add Waiting drive on page 2
                    </label>
                </div>} 

                <div className="mt-2 mb-3 pt-3 pb-2">
                    <button className="btn btn-primary"
                        style={{ fontSize: '1.2rem' }}
                        onClick={() => props.onDownload(fontSize, guestName, fileName, pickupSub)}>
                        Download PDF
                    </button>
                    <button className="btn btn-danger ml-2" style={{ fontSize: '1.2rem' }}
                        onClick={() => props.onClose()}>
                        Cancel
                    </button>
                </div>
            </div>


        </div >
    )
}

export default MakePickUpBoard
