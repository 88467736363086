import { makeRoomAction, getType } from '../../actions';

const initialState = {
    pickUp: {
        time: "",
        flight: "",
        price: 0
    },
    dropOff: {
        time: "",
        flight: "",
        price: 0
    },
    rentCarList: [],
    changeRentCar: false,
    vat: false,
    remarkVAT: "",
    status: "waiting",
    confirm: false,
    isMakeHI: false,
    area: "schedule",
    color: "black",
    maker: "",
    signature: {
        list: ["", "", "", "", ""],
        select: [1,2,3]
    },
    loading: true,
}

const makeRoomOtherReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(makeRoomAction.fillMakeRoomOther): {
            return {
                ...state,
                ...action.payload,
                confirm: action.payload.status === "confirm",
                isMakeHI: action.payload.status === "confirm",
                loading: false
            };
        }

        case getType(makeRoomAction.fillMakeRoomOtherSignature): {
            return {
                ...state,
                signature: action.payload
            }
        }

        case getType(makeRoomAction.resetMakeRoom): {
            return initialState;
        }

        case getType(makeRoomAction.changeChiddenMakeRoomOther): {
            const { value, target, option } = action.payload;

            const newState = { ...state }
            switch (target) {
                case "rentCarList": {
                    if (option === undefined) {
                        newState[target] = value;
                    } else {
                        newState[target][option.index][option.target] = value;
                    }
                    newState.changeRentCar = true;
                    break;
                }
                case "pickUp": case "dropOff": {
                    newState[target][option] = value;
                    break;
                }
                case "signature": {
                    if(option.target === "list") newState[target][option.target][option.index] = value;
                    else if (option.target === "return") newState[target] = value;
                    else newState[target][option.target] = value;
                    break;
                }
                case "vat": {
                    if (value === true) newState.color = "red";
                    newState[target] = value;

                    break;
                }
                default: {
                    newState[target] = value;
                    break;
                }
            }

            return newState;
        }
        default: return state;
    }
}

export default makeRoomOtherReducer;
