import React, { useEffect, useState } from "react";
import CompanyItemComponent from "../../Company";

const styles = {
    label: {
        border: '0.8px solid black',
        width: '120px',
        padding: '4px',
        cursor: 'pointer'
    }
}
function ComponentViewHistory(props) {
    const { type } = props.data;
    const [isLoading, setIsLoading] = useState(true);
    const [select, setSelect] = useState("");

    useEffect(() => {
        // call API check history
        if (typeof props.data === 'object') {
            // if (type === "guestEditStory" || type === "companyEditStory") {
            //     setSelect("Edit");
            // }
            setSelect("Edit");
            setIsLoading(false);
        }

    }, [props.data]);

    return (
        <>
            {isLoading === false &&
                <>
                    <div className="d-flex">
                        <div style={{
                            ...styles.label,
                            backgroundColor: select === "Edit" ? "aqua" : "white"
                        }}
                            onClick={() => setSelect("Edit")}
                        >Edit Log</div>
                        <div style={{
                            ...styles.label,
                            backgroundColor: select === "Stay" ? "aqua" : "white",
                            display: (type === "guest" || type === "guestEditStory") ? "block" : "none"
                        }}
                            onClick={() => setSelect("Stay")}
                            >Stay History</div>
                        <div style={{
                            ...styles.label,
                            width: '150px',
                            backgroundColor: select === "Stay" ? "aqua" : "white",
                            display: (type === "companyEditStory") ? "block" : "none"
                        }}
                            onClick={() => setSelect("Stay")}
                            >Guest On Company</div>
                    </div>
                    <div style={{
                        display: select === "Edit" ? "block" : "white",
                        backgroundColor: select === "Edit" ? "#F5F5F5" : "white",
                        height: '450px',
                        overflowY: 'auto'
                    }}>
                        {
                            select === "Edit" ? <CompanyItemComponent
                                type={type}
                                data={type === "bookingEditStory" ? props.data.data : type === "glistCheckIn" ? props.data.data : props.data.data.logEdit}
                            /> : <></>
                            // <CompanyItemComponent
                            //         type={type === "companyEditStory" ? "guestOnCompany" : type}
                            //         data={type === "companyEditStory" ? props.data.data.guest : props.data.data.history}
                            // />
                        }
                    </div>
                </>
            }
        </>
    )
}

export default ComponentViewHistory
