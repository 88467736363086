import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataPickUpAction, unitsAction } from '../../redux/actions/index'

// api
import * as bookingApi from "../../api/booking";
import * as branchApi from "../../api/branch";
import CompanyItemComponent from "../../components/Company";
import { coverNameToID } from '../../utils/Branch/SupportBranch';
import { changeDataToYYYY_MMMM } from '../../utils/Date/SupportDate'

function WaitingAreaContainer() {
    const dispatch = useDispatch();
    const dataBranchOnState = useSelector(state => state.dataPickUp.waiting);
    const dataBranchInformation = useSelector(state => state.branchInfor);

    const token = useSelector(state => state.token);
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;
    const [dataState, setDataState] = useState([]);

    useEffect(() => {
        document.title = `Az${coverNameToID(Number(branchID))} Waiting Area`;
        // load data cancel on this month
        const checkListCancel = async () => {
            dispatch(unitsAction.showLoading())
            const resultData = await bookingApi.takeListBookingAreaByType(branchID, "waiting", dataBranchOnState.month, token);
            const branchInformation = await branchApi.findElementBranch(branchID, "makeRoom", token);
            if (resultData) {
                dispatch(unitsAction.fetchBranchInformation(branchInformation.data.data));
                setDataState(resultData.data.data);
            }
            dispatch(unitsAction.hiddenLoading())
        }
        checkListCancel()
    }, [branchID, dataBranchOnState.month]);

    function onChangeDataSelect(e, target) {
        const newData = {
            ...dataBranchOnState,
            ["" + target]: e
        };
        dispatch(dataPickUpAction.changeDataWaiting(newData));
    }

    return (
        <>
            <h1 className="mt-5">AZUMAYA {dataBranchInformation.name.toUpperCase()} WAITING AREA :{changeDataToYYYY_MMMM(dataBranchOnState.month)}</h1>
            <div className="d-flex">
                <input
                    type="month"
                    style={{ fontSize: '1.3rem' }}
                    onChange={e => onChangeDataSelect(e.target.value, "month")}
                    value={dataBranchOnState.month}
                ></input>
                <a className="btn btn-primary ml-2"
                    href={"/room/" + branchID + "/make_waiting"} target="_blank" rel="noreferrer">Add Guest Waiting</a>
            </div>
            {/* <HeaderFeature
                feature1={onChangeDataSelect}
                dataBranchOnState={dataBranchOnState}
                type="cancel"
                title="AZUMAYA LINH LANG " /> */}

            {/* {dataState.length > 0 ? */}
            <div className="" style={{ background: "aliceblue" }}>
                <CompanyItemComponent
                    // onOpenEditCompany={onOpenEditGuest}
                    // onOpenHistory={onOpenHistory}
                    type="cancelList"
                    data={dataState} />
                {/* : ""} */}
            </div>

        </>
    )
}

export default WaitingAreaContainer;
