import axios from 'axios';

export const takeData = (email, token) => {
    let newURL = "/booker/take_data/"+email;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const updateBooker = (data, token) => {
    let newURL = "/booker/update_booker/";
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}
