import axios from 'axios';

export const takeBookingDataById = (branchID, id, token) => {
    let newURL = "/booking/" + branchID + "/take_booking_by_id/" + id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const takeBookingDataByIdWithType = (branchID, id, type, token) => {
    let newURL = "/booking/" + branchID + "/take_booking_by_id/" + type + "/" + id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const takeBookingScheduleListById = (branchID, id, type, token) => {
    let newURL = "/booking/" + branchID + "/take_booking_schedule_by_id/" + type + "/" + id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const takeDataShowGuestOnHotelInvoice = (branchID, id, token) => {
    let newURL = "/booking/" + branchID + "/take_data_show_hi/" + id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const takeListBookingAreaByType = (branchID, type, month, token) => {
    let newURL = "/booking/" + branchID + "/take_booking_area/" + type + "/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const makeNewBooking = (branchID, data, token) => {
    let newURL = "/booking/" + branchID + "/make_new";
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const updateBookingById = (branchID, id, data, token) => {
    let newURL = "/booking/" + branchID + "/update_booking_by_id/" + id;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const updateGroupId = (branchID, id, data, token) => {
    let newURL = "/booking/" + branchID + "/update_group_id_by_id/" + id;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const takeBookingHistory = (branchID, id, token) => {
    let newURL = "/booking/" + branchID + "/take_booking_history_by_id/" + id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const deleteScheduleById = (branchID, id, token) => {
    let newURL = "/booking/" + branchID + "/delete_booking_by_id/" + id;
    return axios.delete(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const redoGuest = (branchID, id, type, data, token) => {
    let newURL = "/booking/" + branchID + "/redo_guest/" + type +"/" + id;
    ///:branchID/redo_guest/:type/:id
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

// === report ===
export const takeDataDailyReport = (branchID, date, token) => {
    let newURL = "/booking/" + branchID + "/take_data_daily_report/" + date;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const findGuestNumber = (branchID, month, token) => {
    let newURL = "/booking/" + branchID + "/take_guest_number/" + month + "/full" ;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const getDataGuestCheckIn = (branchID, date, token) => {
    let newURL = "/booking/" + branchID + "/get_data_guest_check_in/" + date ;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const takeListGuestCheckOutOnDate = (branchID, date, token) => {
    let newURL = "/booking/" + branchID + "/take_data_guest_check_out_on_date/" + date ;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

//=== Make Hotel Invoice ====

export const takeDataHotelInvoiceByID = (branchID, type, id, token) => {
    let newURL = "/booking/" + branchID + "/take_data_hotel_invoice/" + type + "/" + id ;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const takeDataHotelInvoiceByID2 = (branchID, type, id, token) => {
    let newURL = "/booking/" + branchID + "/take_data_hotel_invoice2/" + type + "/" + id ;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const automaticCheckRoom = (branchID, data, token) => {
    let newURL = "/booking/" + branchID + "/automatic_check_room";
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const takeListRentCarMonth = (branchID, month, token) => {
    let newURL = "/booking/" + branchID + "/take_list_rentcar_month/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const takeListGuestCheckInMonth = (branchID, month, token) => {
    let newURL = "/booking/" + branchID + "/take_guest_check_in_on_month/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const checkRoomWithType = (branchID, id, type, token) => {
    let newURL = "/booking/" + branchID + "/check_room_with_type_room/"+id +"/"+type 
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const checkRoomWithCalender = (branchID, id, type, data, token) => {
    let newURL = "/booking/" + branchID + "/check_room_with_calender/" + id + "/" + type
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

//checkRoomStatus

export const changeRoomAfterCheckWithType = (branchID, id, room, token) => {
    let newURL = "/booking/" + branchID + "/change_data_booking_after_check_room_with_type/" + room + "/" + id
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}
//takeListGuestCheckOutOnDate

export const getHistoryOnLog = (branchID, id, token) => {
    let newURL = "/booking/" + branchID + "/get_log_history/" + id
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const getListHistoryOnLog = (branchID, id, token) => {
    let newURL = "/booking/" + branchID + "/get_list_log_history/" + id
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}