import React, { useEffect, useState } from 'react';
import { changeDataToDDMMYYYY } from '../../../utils/Date/SupportDate';
import RoomDropDown from '../../Unit/RoomDropDown';

function ComponentSupportContentSameSchedule(props) {

    const { data } = props;
    const [dataState, setDataState] = useState({
        schedule:[],
        selectRoomList: [],
        checkin:"",
        checkout:"",
        request: {
            room: 1,
            same: true,
        }
    })

    useEffect(() => {
        let selectRoomList = []
        if(data.schedule.length > 0) {
            data.schedule.forEach(room => {
                selectRoomList.push(room.roomSelect);
            })
        }

        setDataState({
            schedule: data.schedule,
            checkin: data.checkin,
            checkout: data.checkout,
            request: {
                room: 1,
                same: true,
            },
            selectRoomList
        })


    },[data])

    function onChangeData(value, target, option) {
        const newData = {...dataState };

        // console.log("target: ", target)
        // console.log("option: ", option)
        // console.log("value: ", value)

        function automaticAddSchedule(value) {
            newData.schedule = [];
            newData.selectRoomList = [];
            for(let i = 0; i < value; i++) {
                newData.schedule.push({
                    roomSelect: "",
                    checkin: dataState.checkin,
                    checkout: dataState.checkout,
                    price: 0,
                    discount: 0,                    
                })

                newData.selectRoomList.push("")
            }    
        }

        if(option === "request") {
            newData[option][target] = value;
            if(value === false) {
                automaticAddSchedule(newData.request.room);
            }
        }

        if (option === "request" & target ==="room" && newData.request.same === false) {
            automaticAddSchedule(value);
        }

        if(target === "selectRoomList") {
            if(newData[target].includes(value)) {
                const listReturn = [];
                dataState[target].forEach(item => {
                    if(item !== value) listReturn.push(item)
                })

                newData[target] = listReturn;
            } else newData[target].push(value)
        }

        if(typeof option === "number") {
            newData["schedule"][option][target] = value;
            newData["schedule"][option].price = data.listRoomPrice[value];
            newData.selectRoomList.push(value);
        }

        setDataState(newData);
    }

    function onCheckAddToSchedule() {
        if(dataState.request.same === true) {
            props.onSelectRoom(dataState.selectRoomList)
        } else {
            props.onSelectRoom(dataState.schedule, "notSame")
        }
    }


    function renderSchedule() {
        var result = [];
        if (dataState.schedule.length > 0) {
            dataState.schedule.forEach((schedule, index) => {
                //console.log("schedule", schedule)

                return result.push(<tr style={{ border: '1px solid black' }}>
                        {
                            dataState.request.same === true ? <td style={{ border: '1px solid black' }}>
                            <input type="checkbox"
                                onChange = {() => onChangeData(schedule.roomSelect, "selectRoomList",)}
                                checked = {dataState.selectRoomList.includes(schedule.roomSelect)}
                            ></input>
                        </td>: ""
                        }
                        
                        <td style={{ border: '1px solid black' }}>{index + 1}</td>
                        {
                            dataState.request.same === true ? <td style={{ border: '1px solid black' }}>{schedule.roomSelect}</td>: <>
                            <input
                                type="text"
                                maxlength="4"
                                style={{ width: '50px', fontSize: '1.4rem' }}
                                value={schedule.roomSelect}
                               // onFocus={() => setRoomError(false)}
                             //   onBlur={e => checkRoomNumber(e.target.value, index)}
                                onChange={e => onChangeData(e.target.value, "roomSelect", index)}
                            />

                            <RoomDropDown
                                onBlur={value => onChangeData(value, "roomSelect", index)}
                                data={data.listRoomNumber}
                                room={schedule.roomSelect} />
                        </>
                        }
                        <td style={{ border: '1px solid black' }}>{Number(schedule.price).toLocaleString()}</td>
                        {/* <td style={{ border: '1px solid black' }}>{schedule.discount}</td> */}
                        <td style={{ border: '1px solid black', width: '120px' }}>{changeDataToDDMMYYYY(schedule.checkin)}</td>
                        <td style={{ border: '1px solid black', width: '120px' }}>{changeDataToDDMMYYYY(schedule.checkout)}</td>
                </tr>)
            })
        }

        return result;
    }

    return (
        <>
            <h4 style={{width:'100%', border:'1px solid', backgroundColor:'yellow', padding:'4px'}}>Select requirements</h4>
            <div className='d-flex'>
                <div>
                    <div className='d-flex'>
                        <div style={{width:'200px', border:'1px solid', textAlign:'right', padding:'4px'}}>Number Room make</div>
                        <input type='number' 
                               style={{width:'85px'}}
                               maxlength="2"
                               onChange={e=>onChangeData(e.target.value,"room","request")}
                               value={dataState.request.room}></input>
                    </div>
                    <div className='d-flex'>
                        <div style={{width:'200px', border:'1px solid', textAlign:'right', padding:'4px'}}>Same price</div>
                        <div style={{border:'1px solid', padding:'4px'}}
                           //  onClick={()=>onChangeData(true,"same","request")}
                             >
                            <label for="requestTrue" htmlFor="requestTrue">
                                <input type="radio" 
                                       value = {true}
                                       id="requestTrue"
                                       onChange={()=>onChangeData(true,"same","request")}
                                       checked={dataState.request.same === true}/>Yes</label>
                            <label className='ml-2 mt-2' for="requestFalse" htmlFor="requestFalse">
                                <input type="radio" 
                                       value={false}
                                       id="requestFalse"
                                       onChange={()=>onChangeData(false,"same","request")}
                                       checked={dataState.request.same === false}/>No</label>
                        </div>
                    </div>
                </div>
                {dataState.request.same === true && <div>
                    <button style={{
                        width:'60px', 
                        marginLeft:'12px', 
                        marginTop:'12px', 
                        textAlign:'center'
                    }} 
                    className='btn btn-primary'
                    onClick={() => props.onCheckAutomatic(dataState)}
                    >Automatic make room</button>
                </div>}
            </div>  

            <br/>
            {
                dataState.schedule.length  > 0 && <>
                    <table style={{ margin: 'auto' }}>
                        <tr style={{ border: '1px solid black' }}>
                            {dataState.request.same === true ?  
                             <th style={{ border: '1px solid black', background: 'aqua' }}>Take</th> : "" 
                            }
                            <th style={{ border: '1px solid black', background: 'aqua' }}>No.</th>
                            <th style={{ border: '1px solid black', background: 'aqua' }}>Room Number</th>
                            <th style={{ border: '1px solid black', background: 'aqua' }}>Price</th>
                            <th style={{ border: '1px solid black', background: 'aqua', width: '120px' }}>Check In</th>
                            <th style={{ border: '1px solid black', background: 'aqua', width: '120px' }}>Check Out</th>
                        </tr>
                        {renderSchedule()}
                    </table>
                    <div>
                        <button className='btn btn-success'
                            onClick={() => onCheckAddToSchedule()}>Add to Schedule</button>
                     </div>
                </>
            }

        </>
    )
}

export default ComponentSupportContentSameSchedule;
