import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { informationToast, errorToast, warningToast } from '../../../utils/Toast/index';

// import * as bookingApi from '../../../api/booking';
// import * as invoiceApi from '../../../api/invoice';

import { getToday, changeDataToDDMMYYYY } from '../../../utils/Date/SupportDate'
// import { unitsAction } from '../../../redux/actions';
import NumberFormat from 'react-number-format';

import './makeService.css';

function MakeServiceInvoice() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const { idRoom } = useParams();
    const [data, setDate] = useState({
        date: getToday(),
        name: "",
        room: "",
        server: "",
        price: "",
        vnd: "",
        usd: "",
        jpy: "",
        card: ""
    });

    useEffect(() => {
        if (idRoom === "new") {
            // load new invoice
        } else {
            // load data on server
        }
    }, [token])

    function onChange(value, target, options) {
        const newDate = { ...data };
        newDate[target] = value;
        setDate(newDate);
    }

    return (
        <div className="d-flex" style={{ flex: '10' }}>
            <div className="SI" style={{ flex: '9' }}>
                <h1 className="SI_name no-printer" style={{ color: '#5f497a' }}>MAKE SERVICE INVOICE SYSTEM</h1>
                <div className="SI_header d-flex justify-content-between mt-5" style={{ width: '1100px' }}>
                    <div className="d-flex">
                        <div style={{ width: '100%', }} align="right">
                            <img
                                style={{ width: '170px' }}
                                src="/logo_az.png"
                                className="img-responsive logo"
                                alt="image-logo" ></img>
                        </div>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ fontSize: '1.4rem', width: '180px', fontWeight: '500' }}>AZUMAYA KIM MA 3 HOTEL</div>
                            <div style={{ fontSize: '1.2rem', width: '300px' }}>Add: 68 Pham Huy Thong, Ngoc Khanh, Ba Dinh, Ha Noi</div>
                            <div style={{ fontSize: '1.2rem' }}>Tel: (+84 24) 3734 9730</div>
                        </div>
                    </div>
                    <div style={{ fontSize: '2rem' }}>
                        <span>Invoice Number:</span>
                        <strong>0001</strong>
                    </div>
                </div>
                <div className="SI_name" style={{ fontSize: '3rem', fontWeight: '600' }}>SERVICE INVOICE</div>
                <div className="SI_content">
                    <div className="SI_content-item">
                        <label className="label">Guest's name:</label>
                        <input className="input"
                            value={data.name.toLocaleUpperCase()}
                            onChange={e => onChange(e.target.value, "name")} />
                    </div>
                    <div className="SI_content-item">
                        <label className="label">Room number:</label>
                        <input className="input" value={data.room}
                            onChange={e => onChange(e.target.value, "room")} />
                    </div>
                    <div className="SI_content-item">
                        <label className="label">Service:</label>
                        <input className="input" value={data.service}
                            onChange={e => onChange(e.target.value, "service")} />
                    </div>
                    <div className="SI_content-item">
                        <label className="label">Price:</label>
                        <NumberFormat
                            className="input"
                            thousandSeparator={true}
                            type="text"
                            min="0"
                            value={data.price}
                            onChange={e => onChange(e.target.value, "price")}
                        />

                    </div>
                </div>
                <div className="SI_total">
                    <div style={{
                        border: '1px solid black',
                        borderBottom: '0',
                        textAlign: 'left',
                        padding: '8px',
                        paddingLeft: '50px',
                        fontWeight: '600'
                    }}>TOTAL</div>
                    <div className="d-flex SI_total-content" style={{ flex: 10 }}>
                        <div style={{ flex: 3, border: '1px solid black', textAlign: 'left', paddingLeft: '50px', alignItems: 'center', borderRight: '0' }}>
                            <div style={{ fontWeight: '600', paddingTop: '60px', textAlign: 'right', marginRight: '30%' }}>PAYMENT TERM</div>
                        </div>
                        <div style={{ flex: 7, border: '1px solid black' }}>
                            <div className="SI_total-content-item" style={{ border: 0, borderBottom: '1px solid black' }}>
                                <label className="label">VND</label>
                                <NumberFormat
                                    className="input"
                                    thousandSeparator={true}
                                    type="text"
                                    min="0"
                                    value={data.vnd}
                                    onChange={e => onChange(e.target.value, "vnd")}
                                />
                            </div>
                            <div className="SI_total-content-item" style={{ border: 0, borderBottom: '1px solid black' }}>
                                <label className="label">USD</label>
                                <NumberFormat
                                    className="input"
                                    thousandSeparator={true}
                                    type="text"
                                    min="0"
                                    value={data.usd}
                                    onChange={e => onChange(e.target.value, "usd")}
                                />
                            </div>
                            <div className="SI_total-content-item" style={{ border: 0, borderBottom: '1px solid black' }}>
                                <label className="label">JPY</label>
                                <NumberFormat
                                    className="input"
                                    thousandSeparator={true}
                                    type="text"
                                    min="0"
                                    value={data.jpy}
                                    onChange={e => onChange(e.target.value, "jpy")}
                                />
                            </div>
                            <div className="SI_total-content-item" style={{ border: 0 }}>
                                <label className="label">CREDIT CARD</label>
                                <NumberFormat
                                    className="input"
                                    thousandSeparator={true}
                                    type="text"
                                    min="0"
                                    value={data.card}
                                    onChange={e => onChange(e.target.value, "card")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="SI_footer justify-content-between">
                    <div className="SI_footer-item">
                        <div className="d-flex">
                            <label className="label">Date:</label>
                            <input className="input no-printer" type="date" value={data.date}
                                onChange={e => onChange(e.target.value, "date")}
                                style={{ width: '230px', textAlign: 'center' }} />
                            <div className="input hidden printer" style={{ width: '230px', textAlign: 'center' }}>{changeDataToDDMMYYYY(data.date)}</div>
                        </div>
                        <div className="mt-4">
                            <label className="label ">Receptionist:</label>
                            <input className="input" />
                        </div>
                    </div>
                    <div className="SI_footer-item mt-4">
                        <label className="label" style={{ width: '40%' }}>Guest's signature:</label>
                        <input className="input" style={{ width: '70%' }} />
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '100px', flex: '1' }} className="no-printer" >
                <div>Button </div>
                <button className="btn btn-primary">SAVE</button>
                <button className="btn btn-success mt-2">PRINT</button>
            </div>
        </div>)
}

export default MakeServiceInvoice
