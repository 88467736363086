import React, { useEffect, useState } from "react";
import * as branchApi from '../../api/branch';
import * as bookingApi from '../../api/booking';
import { useSelector } from "react-redux";
import { getToday } from "../../utils/Date/SupportDate"

function SupportReportContainer() {
    const [select, setSelect] = useState("daily");
    const [isLoading, setIsLoaing] = useState(true);
    const [dataDaily, setDataDaily] = useState({
        "checkIn": 0,
        "checkOut": 0,
        "stay": 0,
        "guestOnDay":0,
        "capacity": 0,
        "VAT_after": 0,
        "VAT_company": 0,
        "exchange": {
            "VCB_USD": "",
            "Exchange_USD": "",
            "VCB_JPY": "",
            "Exchange_JPY": ""
        }
    });
    const token = useSelector(state => state.token);
    const auth = useSelector(state => state.auth);
    const { user } = auth;
    const { branchID } = user;

    useEffect(() => { 
        const loadDate = async () => {
            const result = await bookingApi.takeDataDailyReport(branchID, getToday() ,token);
            setDataDaily({
                ...result.data
            })
        }

        setIsLoaing(false);

        if(token) {
            loadDate();
        }
    }, [token, select]);

    return (<>
        <div className="mt-5 mb-5" style={{fontSize:'4rem'}}>Support Report</div>
        <div className="d-flex justify-content-center" style={{textAlign:'center', alignItems:'center'}}>
            <h2 style={{
                padding: '8px',
                cursor: select === "daily" ? "" :'pointer', 
                border:'1px solid', 
                width:'50%', 
                background: select ==="daily" ?"yellow" :"white"
            }}
                onClick={select === "daily" ? "" : () => setSelect("daily")}
            >DaiLy Report Content on Day</h2>
            <h2 style={{
                padding: '8px',
                cursor: select === "vat" ? "" :'pointer',
                border:'1px solid', 
                width:'50%', 
                background: select ==="vat" ?"yellow" :"white"
            }}
                onClick={select === "vat" ? "" : () => setSelect("vat")}
            >VAT information</h2>
        </div>

        <div style={{width:'50%'}}>
          <div className="d-flex justify-content-center">
            <div style={{
                    border:'1px solid',
                    background:'aqua',
                    fontSize:'1.3rem',
                    fontWeight:'600',
                    width:'200px',
                    textAlign: 'center'
                  }}>Number Guest Information</div>
            <div style={{width:'250px', fontSize:'1.3rem'}}>
              <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>Check in:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.checkIn}</div>
              </div>
              <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>Check Out:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.checkOut}</div>
              </div>
              <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>Stay:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.stay}</div>
              </div>
              <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>Guest On day:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.guestOnDay}</div>
              </div>
              <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>Capacity:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.capacity}</div>
              </div>
            </div>
        </div>
          
        <div className="d-flex justify-content-center">
          <div style={{
                      border:'1px solid',
                      background:'aqua',
                      fontSize:'1.3rem',
                      fontWeight:'600',
                      width:'200px',
                      textAlign: 'center'
                    }}>
            Number Guest Information</div>
          <div style={{width:'250px', fontSize:'1.3rem'}}>
            <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>VAT after:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.VAT_after}</div>
            </div>
            <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>VAT Company:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.VAT_company}</div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div style={{
                      border:'1px solid',
                      background:'aqua',
                      fontSize:'1.3rem',
                      fontWeight:'600',
                      width:'200px',
                      textAlign: 'center'
                    }}>
            Exchange Rate</div>

          <div style={{width:'250px', fontSize:'1.3rem'}}>
            <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>VCB_USD:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.exchange.VCB_USD}</div>
            </div>
            <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>Exchange_USD:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.exchange.Exchange_USD}</div>
            </div>
            <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>VCB_JPY:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.exchange.VCB_JPY}</div>
            </div>
            <div className="d-flex" style={{border:'1px solid'}}>
                <div style={{flex:1, textAlign:'left', marginLeft:'6px'}}>Exchange_JPY:</div>
                <div style={{flex:1, textAlign:'left', marginLeft:'4px'}}>{dataDaily.exchange.Exchange_JPY}</div>
            </div>
          </div>
        </div>
      </div>
    </>)
}

export default SupportReportContainer;