import React, { useState } from 'react';
import CompanyItemComponent from '../../components/Company';

function ModalShowInvoice(props) {
    //const [dataInvoice,setDataInvoice] = useState();
    const [listSelect, setListSelect] = useState([]);
    const [typeSearch, setTypeSearch] = useState("");

    function onFilterDataWithSelect() {
        let dataReturn = []
        props.data.forEach((item,index) => {
            if(listSelect.includes(index)) {
                delete item["version"];
                dataReturn.push(item);
            }
        })
        props.featureButton1(dataReturn);
    }

    function onChangeListSelect(data) {
        const newListSelect = [...listSelect];
        if(newListSelect.length === 0) {
            newListSelect.push(data)
        } else {
            if(!newListSelect.includes(data)) {
                newListSelect.push(data)
            } else {
                newListSelect.splice(newListSelect.indexOf(data), 1)
            }
        }

        setListSelect(newListSelect)

    }

    return (
        <div>
            {
                <div className='d-flex'>
                <input onBlur={e => props.onHandleFindInvoice(e.target.value,typeSearch)}></input>
                <div className="nav-item-sub" >
                        <label className="mr-3">Find by:</label>
                        <select
                            style={{ minWidth: '140px', textAlign: 'center' }}
                            value={typeSearch}
                            onChange={e =>setTypeSearch(e.target.value)} >
                            <option value={"By Invoice"} > by Invoice </option>
                            <option value={"By Name"} > by Name </option>
                            {/* <option value={"bySI"} > by Service Invoice </option>
                            <option value={"byPay"} > by payment(number) </option> */}
                        </select>
                    </div>
            </div>
            }
           {
            props.data.length > 0 && 
            <CompanyItemComponent
                    data={props.data}
                    type="invoiceManage2"
                    supportFunction1={onChangeListSelect}
                />

            // <RCSumTableItemReactTable 
            //         Items = {{
            //             data: props.data,
            //             edit: false,
            //         }} 
            //         branchID = {props.branchID}
            //         listDelete={listDelete}
            //         setListDelete={setListDelete}
            //         type={props.type}
            //     />
           } 
           {
            props.data.length > 0 && <div>
                    <button onClick={()=>onFilterDataWithSelect()} className="btn btn-primary">Take Data</button>
                    <button onClick={()=>props.featureButton2()} className="btn btn-danger">Close</button>
            </div>
           }

        </div>
    );
}

export default ModalShowInvoice;
