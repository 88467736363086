import React, { useEffect } from 'react';
import { getToday,  getTodayMonth, checkMonthWithOther2Day } from '../../../utils/Date/SupportDate';
import { useSelector } from 'react-redux';

ReservationShowDateComponent.propTypes = {
};

function ReservationShowDateComponent(props) {
    const { data, type} = props;
    const branchID = useSelector(state => state.reservation.branchID);
    let numbers = 0;

    function renderReservationDate() {
        var result = [];
        if ("listShowDate" in data) {
            data.listShowDate.forEach((date, index) => {
                numbers = index;

                if(type ==="cancel") {
                    result.push(<div className="rsv-day bg_pink"
                         style={{background: '#F06292'}}
                     >{"-"}</div>)
                } else {
                    result.push(<div className={"rsv-day " + (date.slice(0, 3) === "Sun" || date.slice(0, 3) === "Sat" ? "bg_red" : "")}
                    style={{cursor: type==="waiting" ? "pointer" : "", background : type==="waiting" ? 'orange' : ''}}
                    onClick={
                        type ==="waiting" ?
                        () => window.open(`/room/${branchID}/make_waiting`, "_blank")
                        : ""}
                >{date}</div>)
                }

            })
        }

        return result;
    }

    return (
        <div className="d-flex rsv-ca"
            style={{
                position: 'sticky',
                zIndex: '9',
                top: '34px'
            }} >
            {renderReservationDate()}
        </div>
    );
}

export default ReservationShowDateComponent;
