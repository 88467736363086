import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHandPointDown } from '@fortawesome/free-regular-svg-icons';
import {  warningToast } from '../../../utils/Toast/index';
import jsPDF from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import { font } from './font.js'
import NumberFormat from 'react-number-format';
import logoAz from './logo_az.png'
import pickupSub from './pickupSub.jpg'

// Component
import ModalNotify from '../../../features/ModalNotify';

// api & action & unit
import { modalAction } from '../../../redux/actions';
import ModalSupportOption from '../../../container/Unit/Modal/modalSupportOption';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';
import { changeDataToDDMMYYYY } from '../../../utils/Date/SupportDate'

function MakeRoomDataRoomServiceDetails(props) {
    const { style } = props;
    const dispatch = useDispatch();
    const branchInformation = useSelector(state => state.branchInfor);
    const makeRoomData = useSelector(state => state.makeRoom);
    const makeRoomService = useSelector(state => state.makeRoomOther);
   // const dataMakeRoomOther = useSelector(state => state.makeRoomOther);
    const auth = useSelector(state => state.auth);
    const { role, branchID } = auth.user;
    const isVietNamBranch = (branchID !== 97 && branchID !== 11)
    const isEdit = makeRoomService.area !=="cancel"  && (role === 0 || role === 2 || role === 3); //&& makeRoomService .area !=="waiting"
    const [isAutomaticInput0,setIsAutomaticInput0] = useState(isVietNamBranch ? false : true)
    const isHCMAreaBranch = (Number(branchID) === 1 || Number(branchID) === 4 || Number(branchID) === 9 || Number(branchID) === 96);

    let valueTimeSave = useRef("");

    function onChangeData(value, target, option) {
        if(option === "time" && valueTimeSave.current === "") {
            valueTimeSave.current = makeRoomService[target][option];
         }

       //  if (target ==="pickUp" || target === "dropOff")
        props.onChangeValueMakeRoomOther(value, target, option);
    };

    function onBlurPickUpDropOffRentCar(value, target, option) {
        if (value === "" || value === "0") {
            props.onChangeValueMakeRoomOther("0", target, option)
        } else {
            if (isAutomaticInput0 && (value !== "" || value !== 0)) props.onChangeValueMakeRoomOther(value + ",000", target, option);
        }
    }

    function onBlurCheckTimeFormat(value, target, option) {
        const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
        if ((value === 0 || value ==="") ) {
            props.onChangeValueMakeRoomOther("", target, option);
            props.onChangeValueMakeRoomOther(0, target, "price");

            valueTimeSave.current = "";
        } else {
            if (isValid) {
                valueTimeSave.current = "";
            } else {
                props.onChangeValueMakeRoomOther(valueTimeSave.current, target, option);
                valueTimeSave.current = "";
            }
        }
    }

    function onOpenRentCarSchedule() {
        var changeModal;

        changeModal = modalAction.modalChange({
            type: "centered",
            title: "RENT CAR VIEW",
            size: 'xl',
            content: <ModalSupportOption
                type={1}
                itemOption={{
                    data : makeRoomService.rentCarList,
                    isAutomaticInput0
                }}
                featureSupport1={props.onChangeValueMakeRoomOther}
                featureSupport2={handleLPrintListRentCar}
               // isAutomaticInput0={isAutomaticInput0}
            //type,size ,title, content, button1, button2, componentContent,
            />
        })
        dispatch(changeModal);
        openModal();
    }

    function onOpenPickUpBoardView() {
        if (makeRoomData.listGuestSelect.length === 0) {
            warningToast("Have not guest select !!!");
        } else {
            var changeModal;
            // call API take data
            changeModal = modalAction.modalChange({
                type: "centered",
                title: "PICK UP BOARD VIEW",
                size: 'xl',
                content: < ModalSupportOption
                    type={2}
                    featureSupport1={handleLDownloadBoardPickUp}
                    featureSupport2={closeModal}
                    itemOption={{ 
                        branchInformation, 
                        pickup: makeRoomService.pickUp,
                        isHCMAreaBranch
                    }} />
            })
            dispatch(changeModal);
            openModal();
        }
    }

    function handleLDownloadBoardPickUp(fontsize, guestName, fileName, isAddPickupSub) {

        // function getImgFromUrl(logo_url, callback) {
        //     img.src = logo_url;
        //     img.onload = function () {
        //         callback(img);
        //     };
        // } 
        
        var doc = new jsPDF({ orientation: "landscape" }); // putOnlyUsedFonts: true
        // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        doc.setLineWidth(1);
        doc.rect(10, 5, 280, 195);

        const startLine = { title: 20 };

        // TITLE
        doc.setFontSize(40);
        var img = new Image();
        img.src = 'https://database.azumayareport.com/img/logo/logo_az.png'
       // logoAz
        doc.addImage(logoAz, 'PNG', 20, 10, 80, 40, '', 'FAST')

    //    doc.addImage(hotelLogoPNG, 'png', 20, 10, 80, 40)
        doc.setFont("WorkSans", 'bold');

        doc.text("AZUMAYA HOTEL", (pageWidth - 120), 20, { align: 'center' }) // (5,5) = (colum,row)
        doc.text(branchInformation.name.toLocaleUpperCase(), pageWidth -120, 40, { align: 'center' }) // (5,5) = (colum,row)

        // doc.setFontSize(20);
        // doc.text(branchInformation.address.toLocaleUpperCase(), pageWidth / 2, startLine.title + 12, { align: 'center' });

        // doc.setFontSize(20);
        // doc.text("PHONE NUMBER: " + branchInformation.phone, pageWidth / 2, startLine.title + 22, { align: 'center' });

        doc.addFileToVFS("WorkSans-normal.ttf", font);
        doc.addFont("WorkSans-normal.ttf", "WorkSans", "normal");
        doc.setFont("WorkSans", 'bold');
        //doc.setFont("WorkSans", 'normal');

        // CONTENT
        doc.setFontSize(fontsize - 4);
        var splitTitle = doc.splitTextToSize(guestName, 250);
        doc.text(splitTitle, pageWidth / 2, 90, { align: 'center' });

        doc.setFont("WorkSans", 'bold');
        // doc.setFont("WorkSans", 'normal');
        doc.setFont("WorkSans", 'bold');

        //pickup
        doc.setFontSize(35);
        doc.text(`PICK UP: ${makeRoomService.pickUp.flight} - Time: ${makeRoomService.pickUp.time} - Date: `+ (makeRoomData.listSchedule.length > 0 ? changeDataToDDMMYYYY(makeRoomData.listSchedule[0].checkin) : "")
                , pageWidth / 2, 185, { align: 'center' });
        // doc.text(` Date: ` + makeRoomData.listSchedule.length > 0 ? "" : changeDataToDDMMYYYY(makeRoomData.listSchedule[0].checkin) , pageWidth / 2, 185, { align: 'center' });

        if (isHCMAreaBranch && isAddPickupSub) {
            doc.addPage();
            doc.addImage(pickupSub, 'JPEG', 10, 10, 240, 200, '', 'FAST');
       }

        doc.save(fileName + '.pdf');

       // doc.text("\u79c1\u306f\u8eca\u3092\u53d6\u308a\u306b\u884c\u3063\u3066\u6765\u307e\u3059\u306e\u3067\u3001\u3053\u3061\u3089\u3067\u5c11\u3005\u304a\u5f85\u3061\u304f\u3060\u3055\u3044\u3002",10, 10)
       
    }

    function calculatorRentCar(dataTotal) {
        var total = 0;
        if (dataTotal) {
            if (dataTotal.length === 0) return total
            else {
                dataTotal.forEach(schedule => {
                    total = total + changeStringVNDtoNumber(schedule.price)
                })
                return total;
            }
        }
    }

    function handleLPrintListRentCar(data,type, fileName) {
        // setDataState();

        function makeGuestName() {
            let guestName = "";
            const { listGuestSelect } = makeRoomData;

            listGuestSelect.forEach(item => {
                guestName = guestName + (item.gender ?"Ms. ":"Mr. ") + item.name + ", ";
            })

            return guestName;
        }

        function addMoreTotal() {
            const newData = [...data];
            if(type ==="guest") {
                newData.push({
                    date: "",
                    content: "TOTAL",
                    price: String(calculatorRentCar(newData))
                });
            };

            return newData;
        }

        var listWidth = type ==="guest" ?  [60, 280, 40] : [40, 220, 120] ;
        var listNameHeader = type ==="guest" ? ["Date\nyyyy/mm/dd", "Schedule detail Rent Car", "Price (VND)"]
                                              :["Date\nyyyy/mm/dd", "Schedule detail Rent Car", "Note"] ;

        function createHeaders(keys) {
            var result = [];
            for (var i = 0; i < keys.length; i += 1) {
                result.push({
                    id: keys[i],
                    name: keys[i],
                    prompt: listNameHeader[i],
                    width: listWidth[i],
                    height: "auto",
                    align: (i === 1) ? "left" : "center",
                    padding: 0,
                    fontSize: '30px'
                });
            }
            return result;
        }

        var headers = createHeaders( type ==="guest" ? [
            "date",
            "content",
            "price",
        ] : [
            "date",
            "content",
            "note"
        ]);

        var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });

        doc.addFileToVFS("WorkSans-normal.ttf", font);
        doc.addFont("WorkSans-normal.ttf", "WorkSans", "normal");
        doc.setFont("WorkSans");

        doc.setFontSize(23);
        doc.text("Rent Car Azumaya List", 100, 15,) // (5,5) = (colum,row)

        doc.setFontSize(14);
        doc.text("Hotel: AZUMAYA " + branchInformation.name.toLocaleUpperCase(), 5, 25, { align: "justify", }) // (5,5) = (colum,row)
        doc.text("Address: " + branchInformation.address.toLocaleUpperCase(), 5,30, { align: "justify", }) // (5,5) = (colum,row)
        doc.text("Phone: " + branchInformation.phone.toLocaleUpperCase(), 5, 35, { align: "justify", }) // (5,5) = (colum,row)
        doc.text("Guest: " + makeGuestName(), 5, 40, { align: "justify", }) // (5,5) = (colum,row)

        doc.table(5, 45, addMoreTotal(), headers, { autoSize: false, fontSize: 13 }).setFont("WorkSans");

        doc.save(`${fileName + (type === "guest" ?"":"_Driver")}.pdf`);
    }

    function closeModal() {
        dispatch(modalAction.modalReset());
    }

    function openModal() {
        dispatch(modalAction.modalIsOk());
    }

    return (
        <>
            <ModalNotify closeModal={props.closeModal} />
            <div style={{ ...style.container, backgroundColor: 'pink' }} >
                <span className="d-flex"
                >
                    <h3 className="ml-2"
                        data-toggle="collapse"
                        data-target="#serviceDetails"
                        role="button"
                        aria-expanded="false"
                        aria-controls="serviceDetails">* Service Details</h3>
                    <FontAwesomeIcon
                        icon={faHandPointDown}
                        style={style.icon} />
                </span>

                <div className="collapse show" id="serviceDetails">
                    {/* Pick Up */}
                    <div className="makeRoom_children d-flex">
                        <label className="makeRoom_label ml-2" > Pick Up </label>
                        <div className="ml-2 mr-2 d-flex">
                            <label className="makeRoom_text pr-2" >Time</label>
                            <input 
                                type= "text"    
                                value={makeRoomService.pickUp.time}
                                className="form-control"
                                disabled={!isEdit}
                                style={{...style.time, border: '1px solid black', width: '76px', height: '25px', padding: '2px'}}
                                onChange={e => onChangeData(e.target.value, "pickUp", "time")}
                                onBlur={e => onBlurCheckTimeFormat(e.target.value, "pickUp", "time")}
                            />
                        </div>
                        <div className="ml-2 mr-2">
                            <label className="makeRoom_text pr-2">Flight Number:</label>
                            <input
                                type="text"
                                value={makeRoomService.pickUp.flight}
                                disabled={!isEdit}
                                onChange={e => onChangeData(e.target.value.toUpperCase(), "pickUp", "flight")}
                                style={{ width: '60px', fontSize: '1.3rem' }} />
                        </div>
                        <div className="ml-2 mr-2">
                            <label className="makeRoom_text pr-2" >{isVietNamBranch ? "Price (VND)" : "Price (USD)"}:</label>
                            <NumberFormat
                                thousandSeparator={true}
                                type="text"
                                name="pickupPrice" 
                                //  min="0"
                                disabled={!isEdit}
                                style={{ width: '80px', fontSize: '1.3rem' }} 
                                value={makeRoomService.pickUp.price}
                                onChange={e => onChangeData(e.target.value, "pickUp", "price")}
                                onBlur = {e => onBlurPickUpDropOffRentCar(e.target.value, "pickUp", "price")}
                            />
                        </div>
                        <button
                            className={(makeRoomService.pickUp.time === "" || makeRoomService.pickUp.flight === "") ? "op-hidden" : "btn btn-primary"}
                            disabled={!isEdit}
                            onClick={() => onOpenPickUpBoardView()}
                        >Print board</button>
                    </div>

                    {/* Drop Off */}
                    <div className="makeRoom_children d-flex">
                        <label className="makeRoom_label ml-2" >Drop Off</label>
                        <div className="ml-2 mr-2 d-flex">
                            <label className="makeRoom_text pr-2" >Time</label>
                            <input 
                                type= "text"
                                value={makeRoomService.dropOff.time}
                                className="form-control"
                                disabled={!isEdit}
                                style={{...style.time, border: '1px solid black', width: '76px', height: '25px', padding: '2px'}}
                                onChange={e => onChangeData(e.target.value, "dropOff", "time")}
                                onBlur={e => onBlurCheckTimeFormat(e.target.value, "dropOff", "time")}
                            />
                        </div>
                        <div className="ml-2 mr-2">
                            <label className="makeRoom_text pr-2">Flight Number:</label>
                            <input
                                type="text"
                                value={makeRoomService.dropOff.flight}
                                disabled={!isEdit}
                                onChange={e => onChangeData(e.target.value, "dropOff", "flight")}
                                style={{ width: '60px', fontSize: '1.3rem' }} />
                        </div>
                        <div className="ml-2 mr-2">
                            <label className="makeRoom_text pr-2" >{isVietNamBranch ? "Price (VND)" : "Price (USD)"}:</label>   
                            <NumberFormat
                                thousandSeparator={true}
                                type="text"
                                name="pickupPrice" 
                                //  min="0"
                                disabled={!isEdit}
                                style={{ width: '80px', fontSize: '1.3rem' }} 
                                value={makeRoomService.dropOff.price}
                                onChange={e => onChangeData(e.target.value, "dropOff", "price")}
                                onBlur = {e => onBlurPickUpDropOffRentCar(e.target.value, "dropOff", "price")}
                            />
                        </div>
                    </div>

                    <div className="makeRoom_children d-flex">
                        <label className="makeRoom_label ml-2" >
                            RentCar
                            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'blue' }} />
                        </label>

                        <div 
                        className='makeRoom_text ml-2'
                        style={{
                            backgroundColor: calculatorRentCar(makeRoomService.rentCarList) === 0 ? 'noset' : 'yellow',
                            width: '150px'
                        }}> {isVietNamBranch ? "Price (VND)": "Price (USD)"}: {calculatorRentCar(makeRoomService.rentCarList).toLocaleString()}</div>

                        {isEdit &&  <button className=" btn btn-primary"
                            onClick={() => onOpenRentCarSchedule()}
                        >Open RentCar Details</button> }
                        {
                            (branchID !== 11 && branchID !== 97) ?  <div className="form-check ml-5">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                                onClick={()=>setIsAutomaticInput0(!isAutomaticInput0)}
                                checked={isAutomaticInput0}
                                />
                            <label 
                                className="form-check-label ml-1 mb-2" 
                                for="flexCheckChecked"
                                style={{ color :'blue'}}
                            >
                                Automatic add ",000"
                            </label>
                        </div> : ""
                        }
                       
                    </div>
                </div>
            </div >
        </>
    )
};

export default MakeRoomDataRoomServiceDetails;
