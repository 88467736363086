import { glistAction, getType } from '../../actions';
import { changeDataToDDMMYYYY,increaseTodayWithNumber } from '../../../utils/Date/SupportDate'

const initialState = {
    loading: true,
    content: [],
    content2: [],
    content3: [],
    listGuestCheckIn: [],
    listGuestCheckInSub1: [],
    listGuestCheckInSub2: [],
    listGuestCheckInSub3: [],
    listGuestCheckOut: [],
    listGuestCheckOutNextDay: [],
    listGuestStay: [],
    listLockRoom: "",
    listCleanRoom: "",
    listDirtyRoom: "",
    listSupportHK1: {
        row1: [],
        row2: [],
    },
    listSupportHK2: {},
    dataService: [],
    maker:"",
    showType: "1Tab",
    guestNumber:"",
    X:"",
    branchID: 0,
    note: "",
    noteOld: "",
    restaurant: [],
}

const glistReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(glistAction.fillDataGlist): {
            // delete LOCK ROOM on restaurant list
            const newRestaurant = action.payload.restaurant.filter(item => {
                if(item.name !== "LOCK ROOM") return item;
            })

            return {
                ...state,
                loading: false,
                note: action.payload.note,
                noteOld: action.payload.noteOld,
                showType: action.payload.showType,
                content: action.payload.data,
                // content2: action.payload.data,
                // content3: action.payload.data,
                restaurant: newRestaurant,
                listSupportHK1: action.payload.listSupportHK1,
                listSupportHK2: action.payload.listSupportHK2,
                listGuestCheckIn: action.payload.listGuestCheckIn,
                listGuestCheckInSub1: action.payload.listGuestCheckInSub1,
                listGuestCheckInSub2: action.payload.listGuestCheckInSub2,
                listGuestCheckInSub3: action.payload.listGuestCheckInSub3,
                listLockRoom: typeof action.payload.listLockRoom === "object" ? "": action.payload.listLockRoom,
                listCleanRoom: typeof action.payload.listCleanRoom === "object" ? "": action.payload.listCleanRoom,
                listDirtyRoom: typeof action.payload.listDirtyRoom === "object" ? "": action.payload.listDirtyRoom,
                listGuestCheckOut: action.payload.listGuestCheckOut,
                listGuestCheckOutNextDay: action.payload.listGuestCheckOutNextDay,
                listGuestStay: action.payload.listGuestStay,
            };
        }
        case getType(glistAction.fillDataGlistByServer): {
            return {
                ...state,
                ...action.payload
            };
        }
        case getType(glistAction.resetDataGlist): {
            return initialState;
        }
        case getType(glistAction.changeItemStatusGlist) : {
            const { target, value } = action.payload;
            const newData = {...state};
            newData[target] = value;

            return newData;
        }
        case getType(glistAction.changeMakerGList) : {
            const { target, value } = action.payload;
            const newData = {...state};
            newData[target] = value;

            return newData;
        }
        case getType(glistAction.changeSupportHK1) : {
            const {target,value, row } = action.payload;
            const newData = {...state.listSupportHK1};
            newData[row][target] = value;

            newData[target] = value;

            return {
                ...state,
                listSupportHK1: newData,
            };
        }
        case getType(glistAction.changeEmptyItem): {
            const {value, room} = action.payload;
            const newContent = {...state.content};
            newContent.push({
                roomNumber: room,
                name: value,
                checkOut:"",
                note:"",
                color: "",
                type:"normal"
            })

            return {
                ...state,
                content: newContent,
            };
        }
        case getType(glistAction.reloadDataGlist): {
            // check on content => load 
            const {data, day, branchID} = action.payload;

            const listGuestCheckIn = [];
            let listGuestStay ="";
            let listGuestCheckOutNextDay = "";

           const nextDay = increaseTodayWithNumber(day,"day", 1)

            function checkNote(note,name) {
                let result = "";
                if(note !=="" && note !== undefined) {
                    if(note.includes("NSM") || note.includes("NS")) result = result + (branchID === 3 ? "Không hút thuốc," : "K.hút thuốc,");
                    if(note.includes("PL") || note.includes("P/L")) result = result + "Gối thấp,";
                    if(name.includes("MS.")) result = result + "Khách nữ,";
                }

                return result;
            }

            data.forEach(item => {
                if(item.color ==="#92D050") {
                    const findIndex = state.listGuestCheckIn.findIndex(({ room }) => room === item.roomNumber);

                    if(findIndex === -1) {
                        listGuestCheckIn.push({
                            room: item.roomNumber,
                            content: checkNote(item.note,item.name),
                        });
                    } else {
                        listGuestCheckIn.push({
                            room: item.roomNumber,
                            content: state.listGuestCheckIn[findIndex].content,
                        }); 
                    }

                } else {
                    if(item.checkOut !=="" && item.checkOut !== undefined && item) {
                        if(item.checkOut.slice(0,5) === changeDataToDDMMYYYY(nextDay).slice(0,5)) {
                            listGuestCheckOutNextDay= listGuestCheckOutNextDay +item.roomNumber+","
                        }
                        listGuestStay= listGuestStay +item.roomNumber+","
                    }
                }
            })

            listGuestCheckIn.sort((a, b) => {
                if (a.room > b.room) return 1;
                else if (a.room < b.room) return -1;
                else return 0;
            });

            return {    
                ...state,
                loading: true,
                listGuestCheckIn,
                listGuestCheckOutNextDay,
                listGuestStay:  listGuestStay.trim().split(",").sort((a, b) => {
                    if (a> b) return 1;
                    else if (a< b) return -1;
                    else return 0;
                }).toString()
            };
        }

        default: return state;
    }
}

export default glistReducer;
