import React, { useEffect, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { unitsAction, modalAction, guestInformationAction } from '../../redux/actions';

import * as guestAPI from '../../api/guest';
import CompanyItemComponent from '../../components/Company';
import ModalSupportOption from '../Unit/Modal/modalSupportOption';
import ModalNotify from '../../features/ModalNotify';
import { errorToast, informationToast, warningToast } from '../../utils/Toast/index'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function GuestInformationContainer() {
    const [value, setValue] = useState("");
    const dispatch = useDispatch();
    const { id } = useParams();
    const token = useSelector(state => state.token);
    const guestInformation = useSelector(state => state.guestInformation);
    const auth = useSelector(state => state.auth);
    const { role, branchID } = auth.user;

    useEffect(() => {
        document.title = "Guest Management"
        if(id && token) {
            // console.log("vo here check id");
            setValue(id);
            dispatch(guestInformationAction.changeValueKeySearchGuestInformation(id));
            dispatch(guestInformationAction.changeTypeSearchGuestInformation("ID"));
            checkGuestById(id);
        }
    },[token]);

    const checkGuestById = async (id) => {
        //console.log("vo here call api")
        const guestData = await guestAPI.findGuestByRequest("fullCompanyName", id, token);
        //const takeListStayGuest = await guestAPI
        // console.log("guest Data", guestData);
        if (guestData) {
            dispatch(guestInformationAction.fillGuestInformation(guestData.data));
        } else errorToast("Error when load data Guest!!");
    }

    function onLoadDataGuest() {
        dispatch(guestInformationAction.callApiLoadDataGuestInformation());
    }

    function onCheckEnter(e) {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code === 13) {
            dispatch(guestInformationAction.changeValueKeySearchGuestInformation(e.target.value))
            onLoadDataGuest();
        }
    }

    function onCreateNewGuest() {
        dispatch(modalAction.modalChange({
            type: "centered",
            title: "Create New Guest",
            size: 'xl',
            content: < ModalSupportOption
                type={3}
                featureSupport1={onCreateNew}
                featureSupport2={closeModal}
                itemOption={{
                    type : "makeRoom",
                    createNew : true,
                    isEdit: true,
                }}
            />
        }));

        openModal();

        function onCreateNew(dataChange) {
            if (dataChange.name === "") return errorToast("Please add Guest Name");
            else if (dataChange.email1 === "") return errorToast("Please add Mail");

            const updateToServer = async () => {
                dispatch(unitsAction.showLoading());
                // dispatch(unitsAction.delay());
                const resultData = await guestAPI.makeNewGuestData(dataChange, token);
              //  console.log("resultData", resultData)
                if (resultData) {
                    if (resultData.data.status === 2) return errorToast("" + resultData.data.msg);
                    else {
                        closeModal()

                        dispatch(modalAction.modalChange({
                            type: "centered",
                            title: "Edit Guest",
                            size: 'xl',
                            content: <ModalSupportOption
                                type={3}
                                featureSupport1={onSaveData}
                                featureSupport2={closeModal}
                                itemOption={{ 
                                    data: resultData.data.data, 
                                    type :"Edit Guest",
                                    isEdit: true,
                                    index: undefined }}
                            />
                        }));
                        openModal();

                        dispatch(unitsAction.hiddenLoading());
                        return informationToast("" + resultData.data.msg);
                    }
                } else return errorToast("" + resultData.data.msg);
            }

            updateToServer();
            dispatch(unitsAction.hiddenLoading());
        }
    }

    function onComfirmDeleteGuest(id) {
        const codeDelete = branchID + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

        const onDelete = async () =>  {
            //deleteCompany
            try {
                const result = await guestAPI.deleteGuest(id, token);
                if(result) {
                    if(result.data.status === 1) {
                        informationToast("" + result.data.msg);
                        window.location.reload(false);
                    } else return errorToast("Error deleting guest")
                } else return errorToast("Error deleting guest")
            } catch (error) {
                return errorToast("Error deleting guest")
            }
        }

        dispatch(modalAction.modalChange({
            type: "centered",
            title: "Confirm Delete Guest",
            size: 'xs',
            content: < ModalSupportOption
                type={9}
                featureSupport1={onDelete}
                featureSupport2={closeModal}
                itemOption={{ schedule: undefined, code: codeDelete }}
            />
        }));
        openModal();
    }

    function onOpenEditGuest(index, type) {
        // console.log("index on open edit guest", index)
        // console.log("index on open edit guest", index >=0 ? "Edit Guest" : "Create New Guest3")

        // Call API check guest information details

        const checkDataGuestDetails = async () => {
            //guestInformation.content[index]._id
            // fullCompanyName\
            const guestData = await guestAPI.findGuestByRequest("fullCompanyName", guestInformation.content[index]._id, token);
           // console.log("guestData", guestData);
            
            if (guestData) {
                dispatch(modalAction.modalChange({
                    type: "centered",
                    title: index >= 0 ? "Edit Guest" : "Create New Guest",
                    size: 'xl',
                    content: < ModalSupportOption
                        type={3}
                        featureSupport1={onSaveData}
                        featureSupport2={closeModal}
                        featureButton3={onComfirmDeleteGuest}

                        itemOption={{ 
                            createNew: index >=0 ? false : true,
                            type : "Edit Guest",
                            index, 
                            isEdit: true,
                            data: guestData.data //guestInformation.content[index] 
                        }}
                    />
                }));
                openModal();
            } else errorToast("Error when load guest information")
        }

        checkDataGuestDetails();
    }

    function onSaveData(dataChange, type, index) {
        const editGuest = async () => {
            const resultData = await guestAPI.editGuestData(dataChange, dataChange._id, token);
            if (resultData) {
                if (resultData.data.status === 2) return warningToast("" + resultData.data.msg);
                else {
                    if (index) dispatch(guestInformationAction.changeChildrenGuestInformation({ data: dataChange, index }))
                    return informationToast("" + resultData.data.msg);
                }
            } else return errorToast("Error when edit Guest")
        }
        editGuest();
    }

    function onOpenHistory(index, page) {
        const loadHistory = async () => {
            try {
                const dataResult = await guestAPI.viewGuestHistory(guestInformation.content[index]._id, token);

                dispatch(modalAction.modalChange({
                    type: "centered",
                    title: "View History Guest data",
                    size: 'xl',
                    content: < ModalSupportOption
                        type={5}
                        featureSupport1={onSaveData}
                        featureSupport2={closeModal}
                        itemOption={{ data: dataResult.data.data, type: "guestEditStory" }}
                    />
                }));
            } catch (error) {
                errorToast("Error when view guest history");
            }
        }

        loadHistory();
        openModal();
    }

    function closeModal() { dispatch(modalAction.modalReset()); }
    function openModal() { dispatch(modalAction.modalIsOk()); }

    return (
        <div >
            <ModalNotify closeModal={closeModal} />
            <h1 className="mt-5">GUEST INFORMATION</h1>
            <div className="d-flex justify-content-center">
                <div className="d-flex">
                    <input value={value}
                        className="ml-5"
                        style={{ width: '200px', fontSize: '1.4rem' }}
                        onChange={e => { setValue(e.target.value) }}
                        onKeyUp={e => onCheckEnter(e)}
                        onBlur={e => {
                            if (e.target.value.length >= 3) {
                                dispatch(guestInformationAction.changeValueKeySearchGuestInformation(e.target.value));
                                onLoadDataGuest();
                            }
                        }}
                    ></input>
                    <div className="dropdown">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            style={{ fontSize: '1.3rem' }}
                            type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            by {guestInformation.typeSearch}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <span
                                className="dropdown-item"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => dispatch(guestInformationAction.changeTypeSearchGuestInformation("Name"))}>
                                By Name</span>
                            <span
                                className="dropdown-item"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => dispatch(guestInformationAction.changeTypeSearchGuestInformation("Email"))}>
                                By Mail</span>
                            <span
                                className="dropdown-item"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => dispatch(guestInformationAction.changeTypeSearchGuestInformation("ID"))}>
                                By ID</span>
                        </div>
                    </div>
                </div>

                {
                    role === 0 && <button className="btn btn-primary ml-2"
                    onClick={() => onCreateNewGuest()}>Create New Guest</button>
                }
                
            </div>
            {guestInformation.content.length > 0 ?
                <CompanyItemComponent
                    onOpenEditCompany={onOpenEditGuest}
                    onOpenHistory={onOpenHistory}
                    type="guest"
                    role = {role}
                    data={guestInformation.content} />
                : ""}

        </div >
    )
}

export default GuestInformationContainer;
